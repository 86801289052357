import { Question, SurveyModel } from 'survey-core'
import WorkExperienceModel from '../../common/survey/work-experience/WorkExperienceModel'

// eslint-disable-next-line import/prefer-default-export
export function enableEditMode(questions: Question[], model: SurveyModel) {
  questions.forEach((question) => {
    const questionByName = model.getQuestionByName(question.name)
    if (question.getType() === 'boolean') {
      questionByName.setPropertyValue('mode', 'radio')
    } else if (question.getType() === WorkExperienceModel.TYPE) {
      questionByName.setPropertyValue('mode', 'no-complete')
    }
  })
}
