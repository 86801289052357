import { SurveyValidator, ValidatorResult } from 'survey-core'
import FieldError from './field-error'

function isEmpty(s?: string | null) {
  return !s?.trim()
}

export default class RequiredFieldValidator<T> extends SurveyValidator {
  constructor(public readonly field: keyof T, public readonly errorMessage: string) {
    super()
  }

  override validate(value: T): ValidatorResult {
    const error =
      !value || !value[this.field] || isEmpty(`${value[this.field]}`)
        ? new FieldError(String(this.field), this.errorMessage)
        : undefined
    return new ValidatorResult(value?.[this.field], error)
  }
}
