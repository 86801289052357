import { Box, SxProps } from '@mui/material'
import React from 'react'

function Circle({ diameter, children }: React.PropsWithChildren & { diameter: number }) {
  return (
    <Box width={diameter} height={diameter}>
      {children}
    </Box>
  )
}

export default function BackgroundPattern({ color, sx }: { color: string; sx?: SxProps }) {
  return (
    <Box
      sx={{
        display: 'inline-block',
        position: 'absolute',
        left: '-132px',
        top: '-127px',
        maskImage: 'radial-gradient(50.00% 50.00% at 50.00% 50.00%, #000 0%, rgba(0, 0, 0, 0.00) 100%)',
        order: -1,
        '.MuiBox-root': {
          display: 'flex',
          alignItems: 'center',
          border: `1px solid ${color}`,
          borderRadius: '50%',
          margin: 'auto',
        },
        ...sx,
      }}
    >
      <Circle diameter={336}>
        <Circle diameter={288}>
          <Circle diameter={240}>
            <Circle diameter={192}>
              <Circle diameter={144}>
                <Circle diameter={96} />
              </Circle>
            </Circle>
          </Circle>
        </Circle>
      </Circle>
    </Box>
  )
}
