import { makeAutoObservable } from 'mobx'
import { JobPostingDto } from '../../../_generated/portal-api'

export default class JobPosting {
  constructor(public readonly dto: JobPostingDto) {
    makeAutoObservable(this, undefined, { autoBind: true })
  }

  assign(dto: JobPostingDto) {
    Object.assign(this.dto, dto)
  }

  get jobTitle() {
    return this.dto.jobTitle || ''
  }

  get company() {
    return this.dto.company || ''
  }

  get location() {
    return [this.dto.city, this.dto.state, this.dto.zipCode].filter((s) => !!s).join(' ') || ''
  }
}
