import { Accordion, AccordionDetails, AccordionProps, AccordionSummary, Box, Chip, Typography } from '@mui/material'
import React, { SyntheticEvent, useCallback, useContext } from 'react'
import { observer } from 'mobx-react'
import { runInAction } from 'mobx'
import ChevronRight from '../../../../common/icons/ChevronRight'
import CandidatesContext from '../context/CandidatesContext'
import { colors } from '../../../../common/mui-theme'

export type FilterAccordionProps = AccordionProps & { title: React.ReactNode; chip?: React.ReactNode; id: string }

function FilterAccordion({ id, children, title, chip, ...other }: FilterAccordionProps) {
  const { filter } = useContext(CandidatesContext)
  const onChange = useCallback(
    (event: SyntheticEvent, expanded: boolean) => {
      if (expanded)
        runInAction(() => {
          filter.expanded = id
        })
      else if (filter.expanded === id)
        runInAction(() => {
          filter.expanded = undefined
        })
    },
    [filter, id],
  )
  return (
    <Accordion {...other} onChange={onChange} expanded={id === filter.expanded}>
      <AccordionSummary expandIcon={<ChevronRight width={20} height={20} />}>
        <Box
          style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="h6" color={colors.neutral['600']}>
            {title}
          </Typography>
          {chip && <Chip size="small" label={chip} />}
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{ display: 'flex', flexDirection: 'column' }}>{children}</AccordionDetails>
    </Accordion>
  )
}

export default observer(FilterAccordion)
