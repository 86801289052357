import { ChangeEvent, useCallback, useContext, useMemo, useState } from 'react'
import {
  Button,
  debounce,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  useEventCallback,
} from '@mui/material'
import { action, runInAction } from 'mobx'
import { z } from 'zod'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useCreateNewCandidate, useEmailExistenceCheck } from '../../../api/hooks/candidateHooks'
import CandidatesContext from '../context/CandidatesContext'
import CandidatesGridContext, { Columns } from '../grid/CandidatesGridContext'
import MaskedPhoneInput, { PHONE_REGEX } from '../../../../common/components/MaskedPhoneInput'
import CustomSnackbar from '../../../../common/alerts/CustomSnackbar'
import DialogCloseButton from '../../../../common/components/dialog/DialogCloseButton'

const defaultValues = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
}

const emailSchema = z.string().trim().min(1, 'E-mail address is required').email('Please enter a valid e-mail address')

const candidateSchema = z.object({
  firstName: z
    .string()
    .trim()
    .regex(/.*\w.*/, 'First name is required'),
  lastName: z
    .string()
    .trim()
    .regex(/.*\w.*/, 'Last name is required'),
  email: emailSchema,
  phone: z.string().trim().regex(PHONE_REGEX, 'Please enter a valid phone number').or(z.literal('')).optional(),
})

function CandidateCreatorDialog({ open, onClose }: { open: boolean; onClose: (event: object) => void }) {
  const [emailToCheck, setEmailToCheck] = useState(null)
  const { data: emailAlreadyExists } = useEmailExistenceCheck(emailToCheck)
  const { search } = useContext(CandidatesContext)
  const grid = useContext(CandidatesGridContext)
  const { virtuoso, sort, candidates, ctx } = grid
  const { filter } = ctx

  const {
    control,
    register,
    reset,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitted, isSubmitting },
  } = useForm<z.infer<typeof candidateSchema>>({
    resolver: zodResolver(candidateSchema),
    defaultValues,
  })

  const closeDialog = useCallback(() => {
    onClose(() => false)
    reset(defaultValues)
  }, [reset, onClose])

  const createCandidate = useCreateNewCandidate()

  const emailCheck = useMemo(() => debounce((s) => setEmailToCheck(s), 500), [])

  const updateEmail = useEventCallback((e: ChangeEvent<HTMLInputElement>) => {
    setValue('email', e.target.value, { shouldValidate: isSubmitted })
    try {
      const email = emailSchema.parse(e.target.value).toLowerCase()
      emailCheck(email)
    } catch {
      emailCheck.clear()
      setEmailToCheck(null)
    }
  })

  const onSubmit = useEventCallback(
    handleSubmit(async (data) => {
      const value = await createCandidate.mutateAsync(data)
      closeDialog()
      CustomSnackbar.show('Candidate added')
      runInAction(() => {
        search.text = ''
        filter.clear()
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        grid.sort = Columns.appliedDate.sort!
        grid.highlightedCandidateId = value.data.id
        sort.desc = false
      })
      setTimeout(() => {
        virtuoso?.current?.scrollToIndex({ index: candidates.length - 1, behavior: 'smooth' })
      }, 500)

      setTimeout(
        action(() => {
          grid.highlightedCandidateId = value.data.id
        }),
        5000,
      )
    }),
  )

  return (
    <Dialog open={open} onClose={closeDialog}>
      <DialogTitle>
        Add Candidate
        <DialogCloseButton onClick={closeDialog} />
        <Typography variant="body1" component="p">
          * Indicates a required field
        </Typography>
      </DialogTitle>
      <DialogContent>
        <TextField
          label="First Name *"
          {...register('firstName')}
          error={!!errors.firstName}
          helperText={errors.firstName?.message || ' '}
          fullWidth
          inputProps={{
            maxLength: 45,
          }}
        />
        <TextField
          label="Last Name *"
          {...register('lastName')}
          error={!!errors.lastName}
          helperText={errors.lastName?.message || ' '}
          fullWidth
          inputProps={{
            maxLength: 45,
          }}
        />
        <TextField
          label="Email Address *"
          {...register('email')}
          onChange={updateEmail}
          error={!!errors.email}
          helperText={errors.email?.message || (emailAlreadyExists ? 'This e-mail address already exists' : ' ')}
          fullWidth
          inputProps={{
            maxLength: 255,
          }}
        />
        <Controller
          control={control}
          render={({ field }) => (
            <TextField
              label="Phone Number"
              {...field}
              onInput={field.onChange}
              error={!!errors.phone}
              helperText={errors.phone?.message || ' '}
              InputProps={{
                inputComponent: MaskedPhoneInput,
              }}
              fullWidth
              inputProps={{
                maxLength: 100,
              }}
            />
          )}
          name="phone"
        />
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={closeDialog} fullWidth>
          Cancel
        </Button>
        <Button onClick={onSubmit} fullWidth disabled={isSubmitting}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default function CandidateCreator() {
  const [open, setOpen] = useState(false)
  const openDialog = useCallback(() => setOpen(true), [])
  return (
    <>
      <Button size="large" onClick={openDialog} aria-label="Edit Candidate Profile">
        Add Candidate
      </Button>
      <CandidateCreatorDialog open={open} onClose={() => setOpen(false)} />
    </>
  )
}
