import { IconProps } from './IconProps'

function Star({ width = 24, height = 24, bgOpacity = 0.12, style }: IconProps & { bgOpacity?: number }) {
  return (
    <svg width={width} height={height} style={style} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity={bgOpacity}
        d="M11.2827 3.45258C11.5131 2.98564 11.6284 2.75218 11.7848 2.67758C11.9209 2.61268 12.0791 2.61268 12.2152 2.67758C12.3717 2.75218 12.4869 2.98564 12.7174 3.45258L14.9041 7.88255C14.9721 8.0204 15.0061 8.08933 15.0558 8.14284C15.0999 8.19023 15.1527 8.22862 15.2113 8.25589C15.2776 8.28669 15.3536 8.29781 15.5057 8.32004L20.397 9.03497C20.9121 9.11026 21.1696 9.1479 21.2888 9.2737C21.3925 9.38316 21.4412 9.53356 21.4215 9.68304C21.3988 9.85485 21.2124 10.0364 20.8395 10.3996L17.3014 13.8457C17.1912 13.9531 17.136 14.0068 17.1004 14.0707C17.0689 14.1273 17.0487 14.1895 17.0409 14.2538C17.0321 14.3264 17.0451 14.4023 17.0711 14.554L17.906 19.4214C17.994 19.9348 18.038 20.1914 17.9553 20.3438C17.8833 20.4763 17.7554 20.5693 17.6071 20.5967C17.4366 20.6283 17.2061 20.5071 16.7451 20.2647L12.3724 17.9651C12.2361 17.8935 12.168 17.8576 12.0962 17.8436C12.0327 17.8311 11.9673 17.8311 11.9038 17.8436C11.832 17.8576 11.7639 17.8935 11.6277 17.9651L7.25492 20.2647C6.79392 20.5071 6.56341 20.6283 6.39297 20.5967C6.24468 20.5693 6.11672 20.4763 6.04474 20.3438C5.962 20.1914 6.00603 19.9348 6.09407 19.4214L6.92889 14.554C6.95491 14.4023 6.96793 14.3264 6.95912 14.2538C6.95132 14.1895 6.93111 14.1273 6.89961 14.0707C6.86402 14.0068 6.80888 13.9531 6.69859 13.8457L3.16056 10.3996C2.78766 10.0364 2.60121 9.85485 2.57853 9.68304C2.55879 9.53356 2.60755 9.38316 2.71125 9.2737C2.83044 9.1479 3.08797 9.11026 3.60304 9.03497L8.49431 8.32004C8.64642 8.29781 8.72248 8.28669 8.78872 8.25589C8.84736 8.22862 8.90016 8.19023 8.94419 8.14284C8.99391 8.08933 9.02793 8.0204 9.09597 7.88255L11.2827 3.45258Z"
        fill="currentColor"
      />
      <path
        d="M11.2827 3.45258C11.5131 2.98564 11.6284 2.75218 11.7848 2.67758C11.9209 2.61268 12.0791 2.61268 12.2152 2.67758C12.3717 2.75218 12.4869 2.98564 12.7174 3.45258L14.9041 7.88255C14.9721 8.0204 15.0061 8.08933 15.0558 8.14284C15.0999 8.19023 15.1527 8.22862 15.2113 8.25589C15.2776 8.28669 15.3536 8.29781 15.5057 8.32004L20.397 9.03497C20.9121 9.11026 21.1696 9.1479 21.2888 9.2737C21.3925 9.38316 21.4412 9.53356 21.4215 9.68304C21.3988 9.85485 21.2124 10.0364 20.8395 10.3996L17.3014 13.8457C17.1912 13.9531 17.136 14.0068 17.1004 14.0707C17.0689 14.1273 17.0487 14.1895 17.0409 14.2538C17.0321 14.3264 17.0451 14.4023 17.0711 14.554L17.906 19.4214C17.994 19.9348 18.038 20.1914 17.9553 20.3438C17.8833 20.4763 17.7554 20.5693 17.6071 20.5967C17.4366 20.6283 17.2061 20.5071 16.7451 20.2647L12.3724 17.9651C12.2361 17.8935 12.168 17.8576 12.0962 17.8436C12.0327 17.8311 11.9673 17.8311 11.9038 17.8436C11.832 17.8576 11.7639 17.8935 11.6277 17.9651L7.25492 20.2647C6.79392 20.5071 6.56341 20.6283 6.39297 20.5967C6.24468 20.5693 6.11672 20.4763 6.04474 20.3438C5.962 20.1914 6.00603 19.9348 6.09407 19.4214L6.92889 14.554C6.95491 14.4023 6.96793 14.3264 6.95912 14.2538C6.95132 14.1895 6.93111 14.1273 6.89961 14.0707C6.86402 14.0068 6.80888 13.9531 6.69859 13.8457L3.16056 10.3996C2.78766 10.0364 2.60121 9.85485 2.57853 9.68304C2.55879 9.53356 2.60755 9.38316 2.71125 9.2737C2.83044 9.1479 3.08797 9.11026 3.60304 9.03497L8.49431 8.32004C8.64642 8.29781 8.72248 8.28669 8.78872 8.25589C8.84736 8.22862 8.90016 8.19023 8.94419 8.14284C8.99391 8.08933 9.02793 8.0204 9.09597 7.88255L11.2827 3.45258Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Star
