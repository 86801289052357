import * as React from 'react'
import { useCallback } from 'react'
import { Dayjs } from 'dayjs'
import { observer } from 'mobx-react'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { runInAction } from 'mobx'
import DateRangeFilter from '../../context/answers/date-range-filter'
import FilterAccordion from '../FilterAccordion'
import CustomDatePicker from '../../../../../common/survey/custom-date-picker/CustomDatePicker'

const MMDDYY = 'MM/DD/YY'

function DateFilter({ title, id, filter }: { title: string; id: string; filter: DateRangeFilter }) {
  const { startDate, endDate } = filter

  const toggle = useCallback(
    (param: string) => (data: Dayjs | null) => {
      runInAction(() => {
        const fltr = filter
        if (param === 'from') fltr.startDate = data
        else if (param === 'to') fltr.endDate = data
        return filter
      })
    },
    [filter],
  )

  const from = startDate?.isValid() ? startDate.format(MMDDYY) : ''
  const to = endDate?.isValid() ? endDate.format(MMDDYY) : ''

  // eslint-disable-next-line no-nested-ternary
  const chip = from ? (to ? `${from} - ${to}` : `From: ${from} - `) : to ? ` - To: ${to}` : undefined

  return (
    <FilterAccordion title={title} id={id} chip={chip}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <CustomDatePicker
          label="From"
          format={MMDDYY}
          value={startDate}
          views={['year', 'month', 'day']}
          onChange={toggle('from')}
          onAccept={toggle('from')}
          maxDate={endDate?.isValid() ? endDate : undefined}
          helperText=" "
          fullWidth
        />
        <CustomDatePicker
          label="To"
          format={MMDDYY}
          value={endDate}
          views={['year', 'month', 'day']}
          onChange={toggle('to')}
          onAccept={toggle('to')}
          minDate={startDate?.isValid() ? startDate : undefined}
          fullWidth
        />
      </LocalizationProvider>
    </FilterAccordion>
  )
}

export default observer(DateFilter)
