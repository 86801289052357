import { keys, makeAutoObservable, observable, remove, set } from 'mobx'
import dayjs, { Dayjs } from 'dayjs'
import { CandidateProfileDto } from '../../../_generated/portal-api'
import { Answers } from '../../../../demo/survey-model'
import { IDPAnswers } from '../../retention/retention-surveys'

export default class CandidateProfile {
  currentTime = dayjs()

  private innerAnswers: Answers & IDPAnswers = {}

  constructor(public readonly dto: CandidateProfileDto) {
    makeAutoObservable(this, { currentTime: observable.ref }, { autoBind: true })
    this.answers = this.computedAnswers
  }

  get answers(): Answers & IDPAnswers {
    return this.innerAnswers
  }

  set answers(answers: Answers) {
    Object.keys(answers).forEach((key) => {
      const k = key as keyof Answers
      set(this.answers, k, answers[k])
    })
    keys(this.answers)
      .filter((k) => !Object.hasOwn(answers, k) && typeof k === 'string')
      .forEach((k) => remove(this.answers, k as string))
  }

  get fullName() {
    return [this.dto.firstName, this.dto.lastName].filter((s) => !!s).join(' ')
  }

  assign(dto: CandidateProfileDto) {
    Object.assign(this.dto, dto)
  }

  get computedAnswers() {
    return this.dto.resultJsonString ? (JSON.parse(this.dto.resultJsonString) as Answers & IDPAnswers) : {}
  }

  get snoozeDate(): Dayjs | undefined {
    return this.dto.archiveSnoozeDate ? dayjs(this.dto.archiveSnoozeDate) : undefined
  }

  get archived() {
    return this.snoozeDate && this.currentTime.isBefore(this.snoozeDate)
  }

  updateCurrentTime() {
    this.currentTime = dayjs()
  }
}
