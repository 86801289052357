import React, { MouseEventHandler, useEffect, useRef } from 'react'
import { Link } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import { ArrowBackward } from '../../../common/icons/Arrows'
import { getTopOffset } from '../../../common/scroll-offset'

function BackToJob({
  children = 'Back to Job Placement',
  to = ``,
  onClick,
}: {
  children?: React.ReactNode
  to?: string
  onClick?: MouseEventHandler
}) {
  const ref = useRef<HTMLAnchorElement>(null)

  useEffect(() => {
    if (ref.current) {
      const top = getTopOffset(ref.current)
      if (window.scrollY > top) {
        window.scrollTo({ top, behavior: 'smooth' })
      }
    }
  }, [])

  return (
    <Link
      ref={ref}
      component={RouterLink}
      underline="hover"
      to={to}
      sx={{ display: 'flex', alignItems: 'center', gap: '8px', m: '27px 0 22px 0' }}
      color="secondary"
      onClick={onClick}
    >
      <ArrowBackward />
      {children}
    </Link>
  )
}

export default BackToJob
