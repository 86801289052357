import React, { useMemo } from 'react'
import { Route, Routes } from 'react-router-dom'
import JobDetailsPage from './JobDetailsPage'
import JobsPage from './JobsPage'
import JobPostingsContext, { JobPostingsContextValue } from '../../components/jobs/context/JobPostingsContext'
import JobsHeader from '../../components/JobsHeader'
import { usePortalApi } from '../../api/PortalApiContext'

export default function JobRouter() {
  const api = usePortalApi()
  const jobPostingsContext = useMemo(() => new JobPostingsContextValue(api), [api])

  return (
    <JobPostingsContext.Provider value={jobPostingsContext}>
      <JobsHeader />
      <Routes>
        <Route path="/" element={<JobsPage />} />
        <Route path="/:id" element={<JobDetailsPage />} />
      </Routes>
    </JobPostingsContext.Provider>
  )
}
