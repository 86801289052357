import { SurveyQuestionElementBase } from 'survey-react-ui'
import { TextField } from '@mui/material'
import React from 'react'

export default class MuiTextarea extends SurveyQuestionElementBase {
  protected renderElement(): JSX.Element | null {
    const placeholder = this.questionBase.renderedPlaceholder
    return (
      <TextField
        multiline
        fullWidth
        id={this.questionBase.inputId}
        className={this.questionBase.className}
        disabled={this.questionBase.isInputReadOnly}
        label={placeholder}
        value={this.questionBase.value}
        onChange={(e) => {
          this.questionBase.value = e.target.value
          this.setState({ value: e.target.value }) // just to rerender
        }}
        rows={this.questionBase.rows}
        aria-required={this.questionBase.a11y_input_ariaRequired}
        aria-label={this.questionBase.a11y_input_ariaLabel}
        aria-labelledby={this.questionBase.a11y_input_ariaLabelledBy}
        aria-invalid={this.questionBase.a11y_input_ariaInvalid}
        aria-describedby={this.questionBase.a11y_input_ariaDescribedBy}
        style={{ resize: this.questionBase.resizeStyle }}
        InputProps={{ readOnly: this.questionBase.isInputReadOnly }}
        inputProps={{
          maxLength: 1000,
        }}
      />
    )
  }
}
