import { createContext, useContext } from 'react'
import { makeAutoObservable, runInAction } from 'mobx'
import { FeatureFlag } from '../_generated/portal-api'
import { usePortalApi } from '../api/PortalApiContext'

class FeatureFlags {
  flags: FeatureFlag[] | null = null

  loader: Promise<FeatureFlag[]> | null = null

  constructor() {
    makeAutoObservable(this, { loader: false }, { autoBind: true })
  }

  get enabled() {
    return new Set<string>((this.flags || []).filter((ff) => !ff.disabled).map((ff) => ff.name))
  }
}

export const featureFlags = new FeatureFlags()

const FeatureFlagsContext = createContext(featureFlags)

export default FeatureFlagsContext

export function useFeatureFlags() {
  const ctx = useContext(FeatureFlagsContext)
  const api = usePortalApi()
  if (!ctx.flags && !ctx.loader) {
    const loader = api.featureFlags.getAll().then((r) => r.data)
    ctx.loader = loader
    loader
      .then((flags) =>
        runInAction(() => {
          ctx.flags = flags
        }),
      )
      .finally(() => {
        ctx.loader = null
      })
  }
  return ctx
}

export function useFeatureFlag(featureFlagName: string) {
  const { enabled } = useFeatureFlags()
  return enabled.has(featureFlagName)
}
