import { ComponentsOverrides, createTheme, PaletteOptions } from '@mui/material/styles'
import { Theme } from '@mui/material'
import { CSSProperties } from 'react'

const fontFamily = "'Satoshi', sans-serif"

export const colors = {
  base: {
    white: '#FFF',
  },
  neutral: {
    '50': '#FAFBFC',
    '75': '#F2F5F7',
    '100': '#EAEBEC',
    '200': '#C2C4C8',
    '250': '#BDBCBC',
    '300': '#868992',
    '400': '#4A4E5B',
    '600': '#0A0F1B',
    '800': '#030509',
  },
  primary: {
    '25': '#FCF8F5',
    '50': '#FCF8F5',
    '100': '#FDF2EC',
    '200': '#FAD9C7',
    '300': '#F5B48F',
    '400': '#F08E57',
    '500': '#EB6920',
    '600': '#B04E18',
    '700': '#753410',
    '800': '#3A1A08',
    '900': '#241005',
  },
  // this is not used
  secondary: {
    '100': '#E9F3F4',
    '200': '#BFDCDE',
    '300': '#7FBABE',
    '400': '#3F989D',
    '500': '#00767D',
    '600': '#00585D',
    '700': '#003B3E',
    '800': '#001D1F',
    '900': '#001213',
  },
  success: {
    '25': '#F5FAF8',
    '50': '#E8F7F0',
    '100': '#D1F0E1',
    '200': '#A2E0C3',
    '300': '#74D1A6',
    '400': '#45C188',
    '500': '#17B169',
    '600': '#128E55',
    '700': '#0E6B40',
    '800': '#09472A',
    '900': '#052415',
  },
  warning: {
    '25': '#FFFCFA',
    '50': '#FDF1E9',
    '100': '#FCE4D3',
    '200': '#F8C9A7',
    '300': '#F5AD7A',
    '400': '#F1924E',
    '500': '#EE7722',
    '600': '#BE5F1B',
    '700': '#8F4714',
    '800': '#5F300E',
    '900': '#301807',
  },
  error: {
    '25': '#FEFAFA',
    '50': '#FEECEB',
    '100': '#FCDAD7',
    '200': '#F9B4AF',
    '300': '#F68F88',
    '400': '#F36960',
    '500': '#F04438',
    '600': '#C0362D',
    '700': '#902922',
    '800': '#601B16',
    '900': '#300E0B',
  },
} as const

export const palette = {
  // background: {},
  // action: {},
  common: {
    white: colors.base.white,
    black: colors.neutral['800'],
  },
  // tonalOffset: {},
  grey: colors.neutral,
  text: {
    primary: colors.neutral['400'],
    secondary: colors.neutral['300'],
    // disabled: colors.neutral['100'],
  },
  primary: {
    light: colors.primary['300'],
    main: colors.primary['500'],
    dark: colors.primary['700'],
  },
  secondary: {
    light: colors.neutral['100'],
    main: colors.neutral['400'],
    dark: colors.neutral['800'],
  },
  success: {
    light: colors.success['300'],
    main: colors.success['500'],
    dark: colors.success['700'],
  },
  warning: {
    light: colors.warning['300'],
    main: colors.warning['500'],
    dark: colors.warning['700'],
  },
  error: {
    light: colors.error['300'],
    main: colors.error['500'],
    dark: colors.error['700'],
  },
} as const satisfies PaletteOptions

const neutralShadow = '0px 1px 2px rgba(16, 24, 40, 0.05)'

function singleColorGradient(color: string) {
  return `linear-gradient(${color}, ${color})`
}

const muiTheme = createTheme({
  shape: {
    borderRadius: 0,
  },
  typography: {
    fontFamily,
    allVariants: { letterSpacing: 0 },
    // Headlines/H1
    h1: {
      color: colors.neutral['800'],
      fontSize: '72px',
      fontWeight: '700',
      lineHeight: '90px',
      letterSpacing: '-1.44px',
    },
    // Headlines/H2
    h2: {
      color: colors.neutral['800'],
      fontSize: '60px',
      fontWeight: '700',
      lineHeight: '72px',
      letterSpacing: '-1.2px',
    },
    // Headlines/H3
    h3: {
      color: colors.neutral['800'],
      fontSize: '44px',
      fontWeight: '700',
      lineHeight: '56px',
      letterSpacing: '-0.88px',
    },
    // Headlines/H4
    h4: {
      color: colors.neutral['800'],
      fontSize: '32px',
      fontWeight: '700',
      lineHeight: '40px',
      letterSpacing: '-0.64px',
    },
    // Headlines/H5
    h5: {
      color: colors.neutral['800'],
      fontSize: '24px',
      fontWeight: '700',
      lineHeight: '32px',
      // letterSpacing: 0,
    },
    // Headlines/H6
    h6: {
      color: colors.neutral['800'],
      fontSize: '20px',
      fontWeight: '700',
      lineHeight: '30px',
      // letterSpacing: 0,
    },
    // Text/Subtitle 1
    subtitle1: {
      color: colors.neutral['800'],
      fontSize: '16px',
      fontWeight: '700',
      lineHeight: '24px',
      // letterSpacing: 0,
    },
    // Text/Subtitle 2
    subtitle2: {
      color: colors.neutral['800'],
      fontSize: '14px',
      fontWeight: '700',
      lineHeight: '20px',
      // letterSpacing: 0,
    },
    // Text/Body 1
    body1: {
      color: colors.neutral['800'],
      fontSize: '16px',
      fontWeight: '500',
      lineHeight: '24px',
    },
    // Text/Body 2
    body2: {
      color: colors.neutral['800'],
      fontSize: '14px',
      fontWeight: '500',
      lineHeight: '20px',
    },
    // Button
    button: {
      color: colors.neutral['600'],
      fontWeight: '500',
      textTransform: 'none',
    },
    // Text/Caption
    caption: {
      color: colors.neutral['800'],
      fontSize: '12px',
      fontWeight: '500',
      lineHeight: '16px',
    },
    // Text/OVERLINE
    overline: {
      color: colors.neutral['800'],
      fontSize: '12px',
      fontWeight: '500',
      lineHeight: '18px',
      letterSpacing: '0.96px',
      textTransform: 'uppercase',
    },
  },
  palette,
  components: {
    MuiTextField: {
      defaultProps: {
        color: 'secondary',
      },
      styleOverrides: {
        root: {
          '&.MuiTextField-root': {
            '& .MuiOutlinedInput-root': {
              border: 'none',
              lineHeight: '1.5em',
              color: colors.neutral['600'],
              paddingRight: 4,
              justifyContent: 'center',
              'input[type="text"]': {
                height: '15px',
              },
              'input[type="email"]': {
                height: '15px',
              },
              'input[type="password"]': {
                height: '15px',
              },
              'input[type="tel"]': {
                height: '15px',
              },
              'input[type="number"]': {
                height: '15px',
              },
              'input[type="date"]': {
                height: '15px',
              },
              'input[type="date-local"]': {
                height: '15px',
              },
              'input[type="search"]': {
                height: '15px',
              },
              'input[type="time"]': {
                height: '15px',
              },
              'input[type="url"]': {
                height: '15px',
              },
              'input[type="week"]': {
                height: '15px',
              },
            },
            'label.Mui-disabled': {
              background: 'inherit',
            },
            '& .MuiInputLabel-root': {
              lineHeight: '16px',
              color: colors.neutral['300'],
              overflow: 'visible',
            },
          },
          '& .MuiOutlinedInput-root': {
            borderRadius: 8,
            boxShadow: neutralShadow,
            border: 'none',
            '&.MuiInputBase-multiline': {
              paddingBottom: '12px',
              paddingTop: '12px',
            },
            '&:hover fieldset': {
              boxShadow: `0 0 0 4px ${colors.primary['100']}`,
              borderRadius: 8,
              border: `1px solid ${palette.primary.main}`,
            },
            legend: {
              background: colors.base.white,
              visibility: 'inherit',
            },
            '&.Mui-focused fieldset': {
              border: `2px solid ${palette.secondary.main}`,
              boxShadow: 'none',
            },
            '&.Mui-disabled': {
              boxShadow: 'none',
              color: colors.neutral['100'],
              background: `${singleColorGradient(palette.secondary.light)}, ${singleColorGradient(
                colors.neutral['50'],
              )}`,
            },
            '&.Mui-error fieldset': {
              border: `1px solid ${palette.error.main}`,
              color: palette.error.main,
              '&.MuiIconButton-root': {
                boxShadow: `0 0 0 4px ${colors.error['100']}`,
                borderRadius: '12px',
                background: '#FEB594', // TODO what is color?
              },
              '&:hover fieldset': {
                boxShadow: 'none',
                border: 'none',
              },
            },
            '&.Mui-error.Mui-focused fieldset': {
              border: `2px solid ${palette.error.main}`,
              boxShadow: 'none',
            },
          },
          padding: 0,
        },
      },
    },
    MuiButton: {
      defaultProps: {
        size: 'medium',
        variant: 'contained',
        color: 'primary',
      },
      styleOverrides: {
        root: {
          borderRadius: '8px',
          fontWeight: 500,
          boxShadow: neutralShadow,
        },
        sizeSmall: {
          // References "Small" button example in Figma
          fontSize: '14px',
          lineHeight: '20px',
          minWidth: '71px',
          minHeight: '36px',
          padding: '8px 14px',
        },
        sizeMedium: {
          // References "Large" button example in Figma
          fontSize: '16px',
          lineHeight: '24px',
          minWidth: '85px',
          minHeight: '44px',
          padding: '10px 18px',
        },
        sizeLarge: {
          // References "2XL" button example in Figma
          fontSize: '18px',
          lineHeight: '28px',
          minWidth: '111px',
          minHeight: '56px',
          padding: '14px 28px',
        },
        containedPrimary: {
          background: singleColorGradient(palette.primary.main), // Default gradient to prevent loading bugs when transitioning to and from hover state
          border: '1px solid transparent',
          ':disabled': {
            color: colors.base.white,
            background: singleColorGradient(colors.primary['200']),
          },
          ':hover': {
            background: `linear-gradient(180deg, ${palette.primary.main} 0%, #D9611E 100%)`,
            boxShadow: '0px 12px 24px -4px rgba(235, 105, 32, 0.28), 0px 4px 6px -2px rgba(235, 105, 32, 0.24)',
          },
        },
        containedSecondary: {
          background: singleColorGradient(colors.base.white), // Default gradient to prevent loading bugs when transitioning to and from hover state
          color: palette.secondary.main,
          border: `1px solid ${palette.secondary.light}`,
          ':disabled': {
            color: palette.secondary.light,
            background: singleColorGradient(colors.base.white),
          },
          ':hover': {
            color: colors.neutral['600'],
            background: `linear-gradient(180deg, ${colors.base.white} 0%, #F8F9FA 100%)`,
            boxShadow: '0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)',
          },
        },
        outlinedPrimary: {
          background: colors.primary['50'],
        },
        text: {
          boxShadow: 'none',
          ':disabled': {
            color: colors.neutral['200'],
          },
        },
        textPrimary: {
          ':hover': {
            textDecoration: `underline ${palette.primary.main}`,
            textUnderlineOffset: '0.3em',
            backgroundColor: 'transparent',
          },
        },
        textSecondary: {
          ':hover': {
            color: colors.neutral['600'],
            textDecoration: `underline ${colors.neutral['600']}`,
            textUnderlineOffset: '0.3em',
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: colors.neutral['200'],
          '& .MuiSvgIcon-root': {
            width: '26.7px',
            height: '26.7px',
          },
          '&:hover': {
            color: palette.primary.dark,
          },
          '&.Mui-checked': {
            color: palette.primary.main,
          },
          '&.MuiCheckbox-indeterminate': {
            color: 'primary',
          },
          '&.Mui-disabled': {
            color: colors.neutral['100'],
            '&::before': {
              content: '""',
              position: 'absolute',
              width: '15.5px',
              height: '15.5px',
              background: colors.neutral['50'],
            },
            '&.MuiCheckbox-indeterminate': {
              color: colors.neutral['100'],
            },
          },
          '&.Mui-error': {
            color: palette.error.main,
            '&:hover': {
              color: palette.error.dark,
            },
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          '&:hover': {
            color: colors.primary['700'],
            '& svg:nth-of-type(2)': {
              color: palette.primary.main,
            },
          },
          marginRight: '4px',
          color: colors.neutral['200'],
          '&.Mui-checked': {
            color: 'primary',
          },
          '&.Mui-disabled': {
            color: colors.neutral['100'],
            '&::before': {
              content: '""',
              position: 'absolute',
              width: '16px',
              height: '16px',
              borderRadius: '50%',
              background: colors.neutral['50'],
            },
          },
          '&.Mui-disabled.Mui-checked': {
            color: colors.neutral['100'],
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          padding: 0,
          width: '44px',
          height: '24px',
          '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
              color: colors.base.white,
              '& + .MuiSwitch-track': {
                backgroundColor: palette.primary.main,
                opacity: 1,
                border: 0,
              },
            },
          },
          '&.Mui-disabled, &.Mui-checked': {
            color: colors.neutral['100'],
            '& .MuiSwitch-thumb': {
              color: colors.base.white,
            },
          },
          '& .MuiSwitch-thumb': {
            borderRadius: '10px',
            width: '20px',
            height: '20px',
            transform: 'translateY(-0.5px)',
            boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
          },
          '& .MuiSwitch-track': {
            borderRadius: 12,
            backgroundColor: palette.secondary.light,
            opacity: 1,
            transition: 'background-color 500ms',
          },
          '&:hover': {
            boxShadow: `0 0 0 4px ${colors.primary['100']}`,
            borderRadius: 12,
            '& .Mui-checked': {
              transform: 'translateX(16px)',
            },
            '.MuiSwitch-track': {
              backgroundColor: colors.neutral['200'],
            },
            '& .MuiSwitch-thumb': {
              transition: '300ms',
              width: '24px',
            },
          },
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          '& .MuiTableCell-head': {
            lineHeight: '1.25rem',
          },
          '& .MuiTableBody-root tr:hover': {
            backgroundColor: '#FEF4EF',
            td: {
              borderColor: colors.primary['500'],
            },
            '&:last-child': {
              td: {
                borderBottom: `1px solid ${colors.primary['500']}`,
                '&:first-of-type': {
                  borderBottomLeftRadius: '16px',
                },
                '&:last-of-type': {
                  borderBottomRightRadius: '16px',
                },
              },
            },
          },
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          tr: {
            td: {
              borderTop: '1px solid transparent',
              '&:first-of-type': {
                borderLeft: '1px solid transparent',
              },
              '&:last-of-type': {
                borderRight: '1px solid transparent',
              },
            },
          },
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
    },
    MuiSelect: {
      defaultProps: {
        color: 'secondary',
        variant: 'outlined',
      },
      styleOverrides: {
        select: {
          color: palette.grey['600'],
          padding: '12px 14px',
        },
        root: {
          borderRadius: '8px',
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderRadius: '8px',
            boxShadow: `0 0 0 4px ${colors.primary['100']}`,
            border: `1px solid ${palette.primary.main}`,
          },
          '&.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderRadius: '8px',
            border: `1px solid ${palette.error.main}`,
          },
          '&.Mui-error.Mui-focused fieldset ': {
            borderRadius: '8px',
            border: `2px solid ${palette.error.main}`,
          },
        },
      } as ComponentsOverrides<Theme>['MuiSelect'] & { root: CSSProperties },
    },
    MuiAutocomplete: {
      styleOverrides: {
        input: {
          color: colors.neutral['600'],
        },
        option: {
          borderRadius: '6px',
          '.MuiTypography-root': {
            color: palette.text.primary,
            fontWeight: '500',
          },
          '&:hover': {
            backgroundColor: `${colors.primary['50']} !important`,
            '.MuiTypography-root': {
              color: colors.primary['600'],
              fontWeight: '500',
            },
          },
          '&[aria-selected="true"]': {
            border: `1px solid ${palette.primary.light}`,
            backgroundColor: `${palette.common.white} !important`,
            '&:hover': {
              backgroundColor: `${colors.primary['50']} !important`,
              '.MuiTypography-root': {
                color: colors.primary['600'],
                fontWeight: '500',
              },
            },
            '.MuiTypography-root': {
              color: palette.grey['600'],
              fontWeight: '500',
            },
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '.MuiTypography-root': {
            color: palette.text.primary,
            fontWeight: '500',
          },
          borderRadius: '6px',
          ':hover': {
            backgroundColor: colors.primary['50'],
            '.MuiTypography-root': {
              color: colors.primary['600'],
              fontWeight: '500',
            },
            '.MuiListItemIcon-root': {
              color: colors.primary['600'],
            },
          },
          '&[aria-selected="true"]': {
            backgroundColor: `${palette.common.white} !important`,
            '&:hover': {
              backgroundColor: `${colors.primary['50']} !important`,
              '.MuiTypography-root': {
                color: colors.primary['600'],
                fontWeight: '500',
              },
            },
            '.MuiTypography-root': {
              color: palette.grey['600'],
              fontWeight: '500',
            },
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        icon: {
          display: 'flex',
          alignItems: 'center',
        },
        root: {
          borderRadius: 0,
          borderWidth: '0 0 1px 0',
        },
        message: {
          display: 'flex',
          alignItems: 'center',
        },
        action: {
          paddingTop: 0,
          alignItems: 'center',
          color: colors.neutral['300'],
        },
        standardSuccess: {
          color: colors.success['600'],
          backgroundColor: colors.success['25'],
          borderColor: colors.success['200'],
          '.MuiAlertTitle-root': {
            color: colors.success['600'], // TODO remove after move #2095 fix from portal theme to mui
          },
          '.MuiAlert-icon': {
            padding: '2px 0',
          },
        },
        standardWarning: {
          color: colors.warning['600'],
          backgroundColor: colors.warning['25'],
          borderColor: colors.warning['200'],
          '.MuiAlertTitle-root': {
            color: colors.warning['600'], // TODO remove after move #2095 fix from portal theme to mui
          },
          '.MuiAlert-icon': {
            padding: '2px 0',
          },
        },
        standardError: {
          color: colors.error['600'],
          backgroundColor: colors.error['25'],
          borderColor: colors.error['200'],
          '.MuiAlertTitle-root': {
            color: colors.error['600'], // TODO remove after move #2095 fix from portal theme to mui
          },
          '.MuiAlert-icon': {
            padding: '2px 0',
          },
        },
      },
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          fontWeight: 700,
          margin: '0 8px',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiDialog: {
      defaultProps: {
        scroll: 'body',
        maxWidth: 'md',
      },
      styleOverrides: {
        paperWidthXs: {
          maxWidth: '320px',
        },
        paperWidthSm: {
          maxWidth: '400px',
        },
        paperWidthMd: {
          maxWidth: '480px',
        },
        paperWidthLg: {
          maxWidth: '640px',
        },
        paperWidthXl: {
          maxWidth: '800px',
        },
        paper: {
          borderRadius: '16px',
        },
      },
    },
    MuiDialogTitle: {
      defaultProps: {
        variant: 'h5',
        component: 'h5',
      },
      styleOverrides: {
        root: {
          fontWeight: 700,
          color: colors.neutral['600'],
          padding: 0,
          margin: '24px 24px 16px',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          overflow: 'visible',
          padding: 0,
          margin: '0 24px',
        },
      },
    },

    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: 0,
          margin: '16px 24px 24px',
          display: 'flex',
          flexDirection: 'row',
          rowGap: '16px',
          columnGap: '8px', // +8px from MUI defaults for not(:first-of-type) = 16px
        },
      },
    },

    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: '4px',
        },
      },
    },

    MuiFormHelperText: {
      styleOverrides: {
        root: {
          margin: '2px 0 12px',
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        input: {
          '&.MuiOutlinedInput-input': {
            paddingRight: '14px',
          },
        },
      },
    },

    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&. MuiInputBase-root': {
            lineHeight: '24px',
          },
          '&.MuiInputLabel-root': {
            color: colors.neutral['300'],
            '&.Mui-disabled': {
              color: colors.neutral['200'],
            },
            '&.Mui-focused, &.Mui-error': {
              color: colors.neutral['300'],
            },
          },
        },
      },
    },

    MuiAccordion: {
      defaultProps: {
        disableGutters: true,
      },
      styleOverrides: {
        root: {
          border: 0,
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        expandIconWrapper: {
          '&.Mui-expanded': {
            transform: 'rotate(90deg)',
          },
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        color: 'primary',
      },
      styleOverrides: {
        root: {
          '.MuiCard-root': {
            boxShadow: '0px 1px 2px rgba(16, 24, 32, 0.05)',
            '& .MuiCardContent-root': {
              'p.MuiTypography-body1': {
                color: colors.neutral['300'],
                fontSize: 12,
                textTransform: 'uppercase',
              },
              borderRadius: '16px',
              padding: '16px',
            },
            '& .MuiCardActions-spacing': {
              paddingTop: 0,
              paddingBottom: 0,
              '.MuiTypography-body1': {
                color: colors.neutral['300'],
                fontSize: 12,
                fontWeight: 500,
              },
            },
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          border: `1px solid ${palette.grey[100]}`,
          borderRadius: '12px',
          backgroundColor: `${palette.grey[75]}`,
          minHeight: '64px',
        },
        indicator: {
          display: 'none',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: '16px',
          fontWeight: 500,
          color: palette.text.primary,
          padding: '16px',
          margin: '4px',
          minHeight: '56px',
          lineHeight: '24px',
          '&:hover': {
            color: palette.primary.main,
          },
          '&.Mui-selected': {
            borderRadius: '8px',
            boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1)',
            background: palette.common.white,
            color: palette.grey['600'],
          },
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          '& .MuiTouchRipple-root .MuiTouchRipple-child': {
            backgroundColor: colors.primary['200'],
          },
          MuiToggleButton: {
            styleOverrides: {
              root: {
                '&.Mui-selected': {
                  background: palette.common.black,
                  '&:hover': {},
                },
              },
            },
          },
        },
      },
    },

    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          border: `1px solid ${palette.secondary.light}`,
          background: colors.neutral['75'],
          padding: '4px',
          color: palette.grey['400'],
          gap: '8px',
          button: {
            minWidth: '79px',
            padding: '6px 12px',
            border: 'none',
            ':hover': {
              background: 'none',
            },
            '&.Mui-selected': {
              borderRadius: '8px',
              boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1)',
              background: palette.common.white,
              color: palette.grey['600'],
              ':hover': {
                background: palette.common.white,
              },
              '&.MuiToggleButtonGroup-grouped': {
                ':not(:last-of-type)': {
                  borderRadius: '8px',
                },
                ':not(:first-of-type)': {
                  borderRadius: '8px',
                },
              },
              '& .MuiToggleButton-root': {
                fontSize: 14,
                fontWeight: 500,
              },
            },
          },
        },
      },
    },
  },
})

muiTheme.typography.h1[muiTheme.breakpoints.down('sm')] = {
  fontSize: '56px',
  lineHeight: '64px',
}

muiTheme.typography.h2[muiTheme.breakpoints.down('sm')] = {
  fontSize: '48px',
  lineHeight: '52px',
}

muiTheme.typography.h3[muiTheme.breakpoints.down('sm')] = {
  fontSize: '40px',
  lineHeight: '48px',
}

muiTheme.typography.h4[muiTheme.breakpoints.down('sm')] = {
  fontSize: '32px',
  lineHeight: '40px',
}

muiTheme.typography.h5[muiTheme.breakpoints.down('sm')] = {
  fontSize: '24px',
  lineHeight: '28px',
}

export default muiTheme
