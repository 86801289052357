import { SurveyQuestionElementBase } from 'survey-react-ui'
import { Serializer } from 'survey-core'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import dayjs, { Dayjs } from 'dayjs'
import DoBModel from './DoBModel'
import css from './MuiDoB.module.css'
import DoBValidator, { MIN_DOB } from './dob-validator'
import FieldError from '../validators/field-error'
import formats from '../formats'
import CustomDatePicker from '../custom-date-picker/CustomDatePicker'

export default class MuiDoBQuestion extends SurveyQuestionElementBase {
  constructor(props: unknown) {
    super(props)
    if (this.question.validators.length === 0) {
      this.questionBase.validators.push(new DoBValidator())
    }
    this.onValueChanged = this.onValueChanged.bind(this)
  }

  get question() {
    return this.questionBase
  }

  onValueChanged(date: Dayjs | null) {
    this.value = date
  }

  get value(): Dayjs | null {
    if (this.state && this.state.date) {
      return this.state.date as Dayjs
    }
    if (!this.questionBase.value) return null
    return formats.parseDate(this.questionBase.value)
  }

  set value(date: Dayjs | null) {
    this.setState({ date }) // we support state to not hide invalid values in date picker
    this.questionBase.value = date && date.isValid() ? date.format(formats.date) : undefined
  }

  protected renderElement(): JSX.Element {
    let errors = new Map<string, FieldError>()
    if (Array.isArray(this.question.errors)) {
      const fieldErrors = this.question.errors.filter((e) => e instanceof FieldError) as FieldError[]
      errors = fieldErrors.reduce((m, e) => {
        if (!m.has(e.fieldName)) m.set(e.fieldName, e)
        return m
      }, errors)
    }

    return (
      <div className={css.container}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <CustomDatePicker
            label="Date of Birth"
            format={formats.date}
            value={this.value}
            onChange={this.onValueChanged}
            openTo="year"
            views={['year', 'month', 'day']}
            maxDate={dayjs()}
            minDate={MIN_DOB}
            helperText={errors.has('dob') ? `${errors.get('dob')?.text}: ${formats.date}` : ' '}
            error={errors.has('dob')}
            onAccept={this.onValueChanged}
          />
        </LocalizationProvider>
      </div>
    )
  }
}

Serializer.addClass(DoBModel.TYPE, [], () => new DoBModel(''), 'question')
