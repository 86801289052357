import { useCallback, useContext, useState } from 'react'
import { runInAction } from 'mobx'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react'
import PortalRightRail, { RightRailItem } from '../../PortalRightRail'
import { usePortalApi } from '../../../api/PortalApiContext'
import CustomSnackbar from '../../../../common/alerts/CustomSnackbar'
import CandidateProfileContext from '../../candidate-profile/context/CandidateProfileContext'
import { SectionIndicator } from '../../placement/PlacementRightRail'
import {
  JOB_SUBMISSION_ANCHOR,
  jobInterviewLimit,
  PLACE_CANDIDATE_ANCHOR,
  useAddInterview,
  useAddPlacement,
  useAddSubmission,
} from '../../../api/hooks/placementHooks'
import { JobDto } from '../../../_generated/portal-api'

function PlacementRightRail({ job }: { job: JobDto }) {
  const { candidate } = useContext(CandidateProfileContext)
  const api = usePortalApi()
  const navigate = useNavigate()
  const [submitting, setSubmitting] = useState(false)
  const archiveJob = useCallback(() => {
    if (job.details) {
      setSubmitting(true)
      api.jobs
        .archiveJob(job.details.id)
        .then(() => {
          runInAction(() => {
            if (job.details) {
              // eslint-disable-next-line no-param-reassign
              job.details.archived = true
            }
          })
          navigate(`/candidates/${candidate.dto.userId}/job-matching`)
          CustomSnackbar.show('Job archived')
        })
        .finally(() => setSubmitting(false))
    }
  }, [api.jobs, candidate.dto.userId, job, navigate])

  const createSubmission = useAddSubmission(job)
  const createInterview = useAddInterview(job)
  const createPlacement = useAddPlacement(job)

  const scrollTo = useCallback(
    (j: JobDto, sectionID: string) => {
      navigate(`/candidates/${candidate.dto.userId}/job-matching/${job.posting?.id}#${sectionID}`, {
        replace: true,
      })
    },
    [candidate.dto.userId, job.posting?.id, navigate],
  )

  const onNewInterviewCreate = useCallback(() => {
    setSubmitting(true)
    if (job.details) {
      createInterview()
        .then((j) => {
          scrollTo(j, `interview${j.details?.interviews.length}`)
        })
        .finally(() => setSubmitting(false))
    } else {
      createInterview()
        .then((j) => scrollTo(j, `interview${j.details?.interviews.length}`))
        .finally(() => setSubmitting(false))
    }
  }, [createInterview, job.details, scrollTo])

  const onSubmitCandidateClick = useCallback(() => {
    if (job.details)
      if (job.details.submission) scrollTo(job, JOB_SUBMISSION_ANCHOR)
      else {
        setSubmitting(true)
        createSubmission()
          .then((j) => scrollTo(j, JOB_SUBMISSION_ANCHOR))
          .finally(() => setSubmitting(false))
      }
    else {
      setSubmitting(true)
      createSubmission()
        .then((j) => scrollTo(j, JOB_SUBMISSION_ANCHOR))
        .finally(() => setSubmitting(false))
    }
  }, [job, scrollTo, createSubmission])

  const onPlaceCandidateClick = useCallback(() => {
    if (job.details) {
      if (job.details.placement) scrollTo(job, PLACE_CANDIDATE_ANCHOR)
      else {
        setSubmitting(true)
        createPlacement()
          .then((j) => scrollTo(j, PLACE_CANDIDATE_ANCHOR))
          .finally(() => setSubmitting(false))
      }
    } else {
      setSubmitting(true)
      createPlacement()
        .then((j) => scrollTo(j, PLACE_CANDIDATE_ANCHOR))
        .finally(() => setSubmitting(false))
    }
  }, [job, scrollTo, createPlacement])

  const submissionBadgeState = () => {
    if (job.details?.submission?.sent) return 'completed'
    if (job.details?.submission?.exportCreated) return 'export_created'
    return 'not_completed'
  }

  return (
    <PortalRightRail title="Placement Actions">
      <RightRailItem
        title="Submit Candidate"
        onClick={onSubmitCandidateClick}
        badge={<SectionIndicator state={submissionBadgeState()} hidden={!job.details?.submission} />}
        disabled={submitting}
      />
      {job.details?.interviews.map((interview, index) => (
        <RightRailItem
          key={interview.id}
          title={job.details?.interviews.length === 1 ? 'Interview' : `Interview ${index + 1}`}
          onClick={() => scrollTo(job, `interview${index + 1}`)}
          badge={<SectionIndicator state={interview.completed ? 'completed' : 'not_completed'} />}
        />
      ))}
      {(job.details?.interviews.length || 0) < jobInterviewLimit && (
        <RightRailItem
          title={!job.details?.interviews.length ? 'Add Interview' : 'Add Another Interview'}
          onClick={onNewInterviewCreate}
          disabled={submitting}
        />
      )}
      <RightRailItem
        title="Place Candidate"
        onClick={onPlaceCandidateClick}
        badge={
          <SectionIndicator
            state={job.details?.placement?.completed ? 'completed' : 'not_completed'}
            hidden={!job.details?.placement}
          />
        }
        disabled={submitting}
      />
      {job.details && <RightRailItem title="View Activity" onClick={() => scrollTo(job, 'job_activity')} />}
      {job.details && !job.details.archived && (
        <RightRailItem title="Archive Job" onClick={archiveJob} disabled={submitting} />
      )}
    </PortalRightRail>
  )
}

export default observer(PlacementRightRail)
