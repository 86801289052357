import { IconProps } from './IconProps'

export function CheckCircle({ width = 20, height = 20, style }: IconProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <path
        d="M18.3327 9.23818V10.0049C18.3317 11.8019 17.7498 13.5504 16.6738 14.9897C15.5978 16.429 14.0854 17.4819 12.3621 17.9914C10.6389 18.501 8.79707 18.4398 7.11141 17.817C5.42575 17.1942 3.98656 16.0433 3.00848 14.5357C2.0304 13.0282 1.56584 11.2449 1.68408 9.45178C1.80232 7.65866 2.49702 5.95179 3.66458 4.58575C4.83214 3.2197 6.41 2.26767 8.16284 1.87164C9.91568 1.47561 11.7496 1.6568 13.391 2.38818M18.3327 3.33341L9.99935 11.6751L7.49935 9.17508"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export function CheckCircleDoubleColor({ width = 24, height = 24, style }: IconProps) {
  return (
    <svg width={width} height={height} style={style} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.12"
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
        fill="currentColor"
      />
      <path
        d="M22 11.0857V12.0057C21.9988 14.1621 21.3005 16.2604 20.0093 17.9875C18.7182 19.7147 16.9033 20.9782 14.8354 21.5896C12.7674 22.201 10.5573 22.1276 8.53447 21.3803C6.51168 20.633 4.78465 19.2518 3.61096 17.4428C2.43727 15.6338 1.87979 13.4938 2.02168 11.342C2.16356 9.19029 2.99721 7.14205 4.39828 5.5028C5.79935 3.86354 7.69279 2.72111 9.79619 2.24587C11.8996 1.77063 14.1003 1.98806 16.07 2.86572M22 4L12 14.01L9 11.01"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
