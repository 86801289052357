import { useSlate } from 'slate-react'
import { IconButtonProps } from '@mui/material/IconButton'
import { SxProps, useEventCallback } from '@mui/material'
import React from 'react'
import { isBlockAlignActive, TextAlign, toggleBlockAlign } from './slate-rte'
import ToolbarButton from './ToolbarButton'

export default function BlockAlignButton({
  align,
  children,
  ...other
}: IconButtonProps & SxProps & { align: TextAlign }) {
  const editor = useSlate()
  const toggled = isBlockAlignActive(editor, align)
  const onMouseDown = useEventCallback(() => {
    toggleBlockAlign(editor, align)
  })
  return (
    <ToolbarButton toggled={toggled} onMouseDown={onMouseDown} {...other}>
      {children}
    </ToolbarButton>
  )
}
