import React from 'react'
import { SurveyQuestionElementBase } from 'survey-react-ui'
import { PageModel, Question, Serializer, SurveyModel } from 'survey-core'
import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import WorkExperienceModel from './WorkExperienceModel'
import type { WorkExperience } from './validator'
import MuiWorkExperienceList from './MuiWorkExperienceList'
import DialogCloseButton from '../../components/dialog/DialogCloseButton'

type State = {
  index?: number
}

export function DialogWrapper({
  children,
  showInDialog,
  onClose,
  title,
}: React.PropsWithChildren & { showInDialog: boolean; onClose: () => void; title: string }) {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (!showInDialog) return <>{children}</>
  return (
    <Dialog open maxWidth="lg" onClose={onClose}>
      <DialogTitle sx={{ paddingLeft: '8px', paddingRight: '32px' }}>
        {title}
        <DialogCloseButton onClick={onClose} />
      </DialogTitle>
      <DialogContent sx={{ minWidth: '480px', padding: '32px', overflowY: 'visible' }}>{children}</DialogContent>
    </Dialog>
  )
}

export default class MuiWorkExperienceQuestion extends SurveyQuestionElementBase {
  constructor(props: unknown) {
    super(props)
    if (!Array.isArray(this.value)) this.question.value = []
    this.state = {
      // eslint-disable-next-line no-nested-ternary
      index: this.value.length === 0 ? (this.mode === 'no-complete' ? undefined : 0) : undefined,
    } as State
    this.question.title = this.title
  }

  get mode(): string {
    const question = this.props.question as Question
    return question.getPropertyValue('mode')
  }

  get title() {
    const { index } = this.state as State
    const isStaffPortal = this.mode === 'no-complete'
    if (isStaffPortal) return 'Additional job experience'
    if (index === 0) return this.question.firstJobTitle
    return this.question.jobListTitle
  }

  get question(): WorkExperienceModel {
    return this.questionBase as WorkExperienceModel
  }

  get value(): WorkExperience[] {
    return this.question.value
  }

  private completePage = () => {
    const { question } = this.props
    const page = question.page as PageModel
    if (page.elements.length === 1) {
      const survey = question.survey as SurveyModel
      if (!survey.nextPage()) {
        survey.completeLastPage()
      }
    }
  }

  override render(): JSX.Element | null {
    setTimeout(() => {
      this.question.title = this.title
    }, 0)
    return super.render()
  }

  protected renderElement(): JSX.Element {
    return (
      <MuiWorkExperienceList
        question={this.question}
        onDelete={(i) => {
          this.value.splice(i, 1)
          this.question.value = this.value
          if (this.value.length === 0) {
            this.setState({ index: 0 })
          } else {
            this.setState({ index: undefined })
          }
        }}
        setIndex={(index: number | undefined) => {
          this.setState({ index })
        }}
      />
    )
  }
}

Serializer.addClass(
  WorkExperienceModel.TYPE,
  [
    {
      name: 'jobListTitle',
      default: "Here is what you've added so far, would you like to add another job?",
      category: 'general',
      visibleIndex: 2, // Place after the Name and Title
    },
    {
      name: 'firstJobTitle',
      default: "Great, let's start by adding your most recent job.",
      category: 'general',
      visibleIndex: 3, // Place after the Name and Title
    },
  ],
  () => new WorkExperienceModel(''),
  'question',
)
