/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface WorkflowStateHistoryDto {
  updatedBy: string
  /** @format date-time */
  timeStamp: string
  oldState?: string
}

export interface PortalUser {
  /**
   * @minLength 0
   * @maxLength 36
   */
  userObjectId: string
  /**
   * @minLength 0
   * @maxLength 100
   */
  fullName?: string
  /**
   * @minLength 0
   * @maxLength 255
   */
  email?: string
}

export interface NoteContentDto {
  /**
   * @minLength 0
   * @maxLength 1000
   */
  title?: string
  content?: string
}

export interface NoteDto {
  /** @format int32 */
  id: number
  /**
   * @minLength 0
   * @maxLength 1000
   */
  title?: string
  content?: string
  createdBy?: string
  updatedBy?: string
  /** @format date-time */
  timeStamp?: string
}

export interface JobSubmission {
  /** @format int32 */
  id: number
  /** @format int32 */
  jobDetailsId: number
  /** @format date-time */
  dateTime?: string
  sent: boolean
  /**
   * @minLength 0
   * @maxLength 45
   */
  contactFirstName?: string
  /**
   * @minLength 0
   * @maxLength 45
   */
  contactLastName?: string
  /**
   * @minLength 0
   * @maxLength 256
   */
  contactEmail?: string
  /**
   * @minLength 0
   * @maxLength 20
   */
  contactPhone?: string
  includeCover: boolean
  includeRecruiterDetails: boolean
  /**
   * @minLength 0
   * @maxLength 36
   */
  recruiter: string
  /**
   * @minLength 0
   * @maxLength 256
   */
  recruiterEmail?: string
  /**
   * @minLength 0
   * @maxLength 20
   */
  recruiterPhone?: string
  includeCandidateEmail: boolean
  /**
   * @minLength 0
   * @maxLength 256
   */
  candidateEmail?: string
  includeCandidatePhone: boolean
  /**
   * @minLength 0
   * @maxLength 20
   */
  candidatePhone?: string
  includeCandidateEducation: boolean
  includeCandidateEmployment: boolean
  includeCandidateBackground: boolean
  additionalDetails?: string
  /**
   * @minLength 0
   * @maxLength 36
   */
  updatedBy: string
  /** @format date-time */
  updatedAt: string
  exportCreated: boolean
}

export interface Placement {
  /** @format int32 */
  id: number
  /** @format int32 */
  jobDetailsId: number
  /**
   * @minLength 0
   * @maxLength 1000
   */
  employer?: string
  /**
   * @minLength 0
   * @maxLength 1000
   */
  title?: string
  /**
   * @minLength 0
   * @maxLength 1000
   */
  careerArea?: string
  salary?: number
  /** @format date-time */
  startDate?: string
  /**
   * @minLength 0
   * @maxLength 100
   */
  shift?: string
  /**
   * @minLength 0
   * @maxLength 45
   */
  supervisorFirstName?: string
  /**
   * @minLength 0
   * @maxLength 45
   */
  supervisorLastName?: string
  /**
   * @minLength 0
   * @maxLength 256
   */
  supervisorEmail?: string
  /**
   * @minLength 0
   * @maxLength 100
   */
  supervisorOfficePhone?: string
  /**
   * @minLength 0
   * @maxLength 100
   */
  supervisorDirectPhone?: string
  /**
   * @minLength 0
   * @maxLength 255
   */
  addressLine1?: string
  /**
   * @minLength 0
   * @maxLength 255
   */
  addressLine2?: string
  /**
   * @minLength 0
   * @maxLength 50
   */
  city?: string
  /**
   * @minLength 0
   * @maxLength 50
   */
  state?: string
  /**
   * @minLength 0
   * @maxLength 10
   */
  zip?: string
  additionalDetails?: string
  placed?: boolean
  archiveAllOther?: boolean
  notPlacedReason?: string
  /**
   * @minLength 0
   * @maxLength 36
   */
  updatedBy: string
  /** @format date-time */
  updatedAt: string
  completed: boolean
}

export interface JobDetailsExportDto {
  candidateName: string
  fileIds: number[]
  html: string
}

export interface JobDetailsFormDto {
  /**
   * @minLength 0
   * @maxLength 1000
   */
  employer?: string
  /**
   * @minLength 0
   * @maxLength 1000
   */
  title?: string
  /**
   * @minLength 0
   * @maxLength 1000
   */
  careerArea?: string
  salary?: number
  /**
   * @minLength 0
   * @maxLength 100
   */
  shift?: string
  /**
   * @minLength 0
   * @maxLength 45
   */
  supervisorFirstName?: string
  /**
   * @minLength 0
   * @maxLength 45
   */
  supervisorLastName?: string
  /**
   * @minLength 0
   * @maxLength 256
   */
  supervisorEmail?: string
  /**
   * @minLength 0
   * @maxLength 100
   */
  supervisorOfficePhone?: string
  /**
   * @minLength 0
   * @maxLength 100
   */
  supervisorDirectPhone?: string
  /**
   * @minLength 0
   * @maxLength 255
   */
  addressLine1?: string
  /**
   * @minLength 0
   * @maxLength 255
   */
  addressLine2?: string
  /**
   * @minLength 0
   * @maxLength 50
   */
  city?: string
  /**
   * @minLength 0
   * @maxLength 50
   */
  state?: string
  /**
   * @minLength 0
   * @maxLength 10
   */
  zip?: string
  additionalDetails?: string
}

export interface Interview {
  /** @format int32 */
  id: number
  /** @format int32 */
  jobDetailsId: number
  setUp: boolean
  completed: boolean
  /** @format date-time */
  interviewedAt?: string
  /**
   * @minLength 0
   * @maxLength 45
   */
  interviewerFirstName?: string
  /**
   * @minLength 0
   * @maxLength 45
   */
  interviewerLastName?: string
  /**
   * @minLength 0
   * @maxLength 256
   */
  interviewerEmail?: string
  /**
   * @minLength 0
   * @maxLength 100
   */
  interviewerPhone?: string
  /** @format date */
  candidateFeedbackDate?: string
  /**
   * @minLength 0
   * @maxLength 1000
   */
  candidateFeedback?: string
  /** @format date */
  interviewerFeedbackDate?: string
  /**
   * @minLength 0
   * @maxLength 1000
   */
  interviewerFeedback?: string
  /**
   * @minLength 0
   * @maxLength 1000
   */
  notes?: string
  /** @format date-time */
  updatedAt: string
  /**
   * @minLength 0
   * @maxLength 36
   */
  updatedBy: string
}

export interface JobDetailsDto {
  /** @format int32 */
  id: number
  /** @format int32 */
  userId: number
  employer?: string
  title?: string
  careerArea?: string
  salary?: number
  shift?: string
  supervisorFirstName?: string
  supervisorLastName?: string
  supervisorEmail?: string
  supervisorOfficePhone?: string
  supervisorDirectPhone?: string
  addressLine1?: string
  addressLine2?: string
  city?: string
  state?: string
  zip?: string
  additionalDetails?: string
  archived: boolean
  interviews: Interview[]
  /** @format date-time */
  timeStamp?: string
  submission?: JobSubmission
  placement?: Placement
  /** @format int32 */
  jobPostingId?: number
}

export interface Attachment {
  /** @format int32 */
  id: number
  /** @format int32 */
  userId: number
  /** @format int32 */
  blobId: number
}

export interface EventDto {
  /** @format int32 */
  id: number
  name?: string
  /** @format date */
  date?: string
  isActive?: boolean
}

export interface EmailContentDto {
  subject?: string
  body?: string
}

export interface CoachingSessionDto {
  /** @format int32 */
  id: number
  /** @format int32 */
  userId: number
  completed: boolean
  answers?: string
  /** @format date-time */
  sessionDate?: string
  /** @format date-time */
  nextSessionDate?: string
  /**
   * @minLength 0
   * @maxLength 36
   */
  updatedBy?: string
  /** @format date-time */
  updatedAt: string
}

export interface NewCandidateDto {
  /**
   * @minLength 0
   * @maxLength 45
   */
  firstName: string
  /**
   * @minLength 0
   * @maxLength 45
   */
  lastName: string
  email?: string
  /** @pattern ^\+1 \([1-9]\d{2}\) \d{3}-\d{4}$| */
  phone?: string
}

export interface CreatedCandidateDto {
  /**
   * @minLength 0
   * @maxLength 45
   */
  firstName: string
  /**
   * @minLength 0
   * @maxLength 45
   */
  lastName: string
  email?: string
  /** @pattern ^\+1 \([1-9]\d{2}\) \d{3}-\d{4}$| */
  phone?: string
  /** @format int32 */
  id: number
}

export interface InterviewDto {
  setUp?: boolean
  completed?: boolean
  /** @format date-time */
  interviewedAt?: string
  /**
   * @minLength 0
   * @maxLength 45
   */
  interviewerFirstName?: string
  /**
   * @minLength 0
   * @maxLength 45
   */
  interviewerLastName?: string
  /**
   * @minLength 0
   * @maxLength 100
   */
  interviewerPhone?: string
  /**
   * @minLength 0
   * @maxLength 256
   */
  interviewerEmail?: string
  /** @format date */
  candidateFeedbackDate?: string
  /**
   * @minLength 0
   * @maxLength 1000
   */
  candidateFeedback?: string
  /** @format date */
  interviewerFeedbackDate?: string
  /**
   * @minLength 0
   * @maxLength 1000
   */
  interviewerFeedback?: string
  /**
   * @minLength 0
   * @maxLength 1000
   */
  notes?: string
}

export interface ArchiveDto {
  /**
   * @minLength 0
   * @maxLength 100
   */
  reason?: string
  note?: string
  /** @format date-time */
  snoozeDate?: string
}

export interface UpdatedCandidateProfileDto {
  /**
   * @minLength 0
   * @maxLength 100
   */
  phone?: string
}

export interface PortalUserDto {
  /**
   * @minLength 0
   * @maxLength 36
   */
  userObjectId: string
  /**
   * @minLength 0
   * @maxLength 100
   */
  fullName?: string
  /**
   * @minLength 0
   * @maxLength 255
   */
  email?: string
  /** @uniqueItems true */
  roles?: string[]
}

export interface JobActivityLog {
  action?: string
  fullName?: string
  /** @format date-time */
  timeStamp?: string
}

export interface JobPostingDto {
  /** @format int32 */
  id: number
  jobTitle?: string
  company?: string
  careerAreas: string[]
  shifts: string[]
  employmentTypes: string[]
  isFavourite: boolean
  isRecommended: boolean
  city?: string
  state?: string
  zipCode?: string
}

export interface JobDto {
  details?: JobDetailsDto
  posting?: JobPosting
  isFeatured?: boolean
}

export interface JobPosting {
  /** @format int32 */
  id: number
  /**
   * @minLength 0
   * @maxLength 30
   */
  company?: string
  /**
   * @minLength 0
   * @maxLength 300
   */
  title?: string
  /**
   * @minLength 0
   * @maxLength 60
   */
  location?: string
  /**
   * @minLength 0
   * @maxLength 60
   */
  city?: string
  /**
   * @minLength 0
   * @maxLength 60
   */
  state?: string
  /**
   * @minLength 0
   * @maxLength 10
   */
  zipCode?: string
  /**
   * @minLength 0
   * @maxLength 400
   */
  category?: string
  /**
   * @minLength 0
   * @maxLength 150
   */
  jobId?: string
  /**
   * @minLength 0
   * @maxLength 60
   */
  employmentType?: string
  /**
   * @minLength 0
   * @maxLength 60
   */
  shift?: string
  /**
   * @minLength 0
   * @maxLength 100
   */
  lineOfBusinessFacilityGroup?: string
  /**
   * @minLength 0
   * @maxLength 65535
   */
  jobDescription?: string
  /**
   * @minLength 0
   * @maxLength 50
   */
  postedDate?: string
  /**
   * @minLength 0
   * @maxLength 40
   */
  payRange?: string
  /**
   * @minLength 0
   * @maxLength 400
   */
  linkToJobPosting?: string
  /**
   * @minLength 0
   * @maxLength 65535
   */
  otherNotesQuestions?: string
  /**
   * @minLength 0
   * @maxLength 100
   */
  jobFunction?: string
  /**
   * @minLength 0
   * @maxLength 50
   */
  payType?: string
  travelRequired?: boolean
  /**
   * @minLength 0
   * @maxLength 65535
   */
  qualifications?: string
  isActive?: boolean
}

export interface FeatureFlag {
  /** @format int32 */
  id: number
  /**
   * @minLength 0
   * @maxLength 250
   */
  name: string
  disabled: boolean
}

export interface RetentionSentEmail {
  /** @format int32 */
  id: number
  /** @format int32 */
  userId: number
  /** @format int32 */
  coachingSessionId: number
  /**
   * @minLength 0
   * @maxLength 100
   */
  subject: string
  body?: string
  /** @format date-time */
  sentDate?: string
}

export interface CandidateDto {
  /** @format int32 */
  userId: number
  /**
   * @minLength 0
   * @maxLength 45
   */
  firstName: string
  /**
   * @minLength 0
   * @maxLength 45
   */
  lastName: string
  /**
   * @minLength 0
   * @maxLength 256
   */
  email: string
  /** @format int32 */
  workflowStateId: number
  /** @format date-time */
  lastActivityDate: string
  /** @format date-time */
  appliedDate: string
  /** @format date-time */
  completedDate?: string
  /**
   * @minLength 0
   * @maxLength 36
   */
  assigneeId?: string
  /** @format date-time */
  archiveSnoozeDate?: string
  resultJsonString?: string
  /**
   * @minLength 0
   * @maxLength 100
   */
  archiveReason?: string
  isDeleted?: boolean
}

export interface SurveyResultDto {
  isSurveyComplete: boolean
  surveyJsonString: string
  resultJsonString?: string
}

export interface JobPostingsInitSearchDto {
  jobPostings: JobPostingDto[]
  /** @format int32 */
  allCount: number
  /** @format int32 */
  featuredCount: number
}

export interface CandidateProfileDto {
  /** @format int32 */
  userId: number
  /**
   * @minLength 0
   * @maxLength 45
   */
  firstName: string
  /**
   * @minLength 0
   * @maxLength 45
   */
  lastName: string
  /**
   * @minLength 0
   * @maxLength 256
   */
  email: string
  /**
   * @minLength 0
   * @maxLength 100
   */
  phone?: string
  /** @format date-time */
  appliedDate: string
  /** @format int32 */
  workflowStateId: number
  /**
   * @minLength 0
   * @maxLength 36
   */
  assigneeId?: string
  resultJsonString?: string
  /**
   * @minLength 0
   * @maxLength 100
   */
  archiveReason?: string
  archiveNote?: string
  /** @format date-time */
  archiveSnoozeDate?: string
  hasDuplicates?: boolean
}

export interface UserDto {
  /** @format int32 */
  id: number
  email?: string
  userObjectId?: string
  isTest: boolean
  /** @format int32 */
  originType?: number
  /** @format int32 */
  eventId?: number
}

export interface ResetStateParams {
  /** @format int32 */
  userId: number
  /** @format int32 */
  workflowDefinitionId: number
}

export interface ResetStateByEmailParams {
  email: string
}

export interface CreateJobParams {
  /** @format int32 */
  jobPostingId?: number
  /** @format int32 */
  userId: number
}

export interface UploadAttachmentPayload {
  /** @format binary */
  file: File
}

export interface CreateEventParams {
  name: string
  /** @format date */
  date: string
}

export interface CandidatesParams {
  /**
   * @format int64
   * @default 0
   */
  timestamp?: number
}

export interface PlaceJobParams {
  placed: boolean
  /** @format int32 */
  jobId: number
}

export type UpdateAnswersPayload = string

export interface AssignParams {
  assigneeUuid?: string
  /** @format int32 */
  userId: number
}

export interface SearchParams {
  /** @default "" */
  query?: string
  /** @format int32 */
  limit: number
  /** @format int32 */
  offset: number
}

export interface GetCountParams {
  /** @default "" */
  query?: string
}

export interface SearchJobPostingsParams {
  /** @default "" */
  query?: string
  /** @format int32 */
  limit: number
  /** @format int32 */
  offset: number
  /** @format int32 */
  userId: number
}

export interface InitSearchJobPostingsParams {
  /** @default "" */
  query?: string
  /** @format int32 */
  limit: number
  /** @format int32 */
  userId: number
}

export interface SearchFeaturedJobPostingsParams {
  /** @default "" */
  query?: string
  /** @format int32 */
  limit: number
  /** @format int32 */
  offset: number
  /** @format int32 */
  userId: number
}

export interface InitSearchFeaturedJobPostingsParams {
  /** @default "" */
  query?: string
  /** @format int32 */
  limit: number
  /** @format int32 */
  userId: number
}

export interface ExistsParams {
  /** User email */
  email: string
}

export type QueryParamsType = Record<string | number, any>
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean
  /** request path */
  path: string
  /** content type of request body */
  type?: ContentType
  /** query params */
  query?: QueryParamsType
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat
  /** request body */
  body?: unknown
  /** base url */
  baseUrl?: string
  /** request cancellation token */
  cancelToken?: CancelToken
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string
  baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void
  customFetch?: typeof fetch
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D
  error: E
}

type CancelToken = Symbol | string | number

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = 'http://localhost:8081'
  private securityData: SecurityDataType | null = null
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker']
  private abortControllers = new Map<CancelToken, AbortController>()
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams)

  private baseApiParams: RequestParams = {
    credentials: 'same-origin',
    headers: {},
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  }

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig)
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data
  }

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key)
    return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key])
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key]
    return value.map((v: any) => this.encodeQueryParam(key, v)).join('&')
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {}
    const keys = Object.keys(query).filter((key) => 'undefined' !== typeof query[key])
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join('&')
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery)
    return queryString ? `?${queryString}` : ''
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === 'object' || typeof input === 'string') ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== 'string' ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key]
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === 'object' && property !== null
            ? JSON.stringify(property)
            : `${property}`,
        )
        return formData
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  }

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    }
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken)
      if (abortController) {
        return abortController.signal
      }
      return void 0
    }

    const abortController = new AbortController()
    this.abortControllers.set(cancelToken, abortController)
    return abortController.signal
  }

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken)

    if (abortController) {
      abortController.abort()
      this.abortControllers.delete(cancelToken)
    }
  }

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {}
    const requestParams = this.mergeRequestParams(params, secureParams)
    const queryString = query && this.toQueryString(query)
    const payloadFormatter = this.contentFormatters[type || ContentType.Json]
    const responseFormat = format || requestParams.format

    return this.customFetch(`${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
      },
      signal: cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal,
      body: typeof body === 'undefined' || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>
      r.data = null as unknown as T
      r.error = null as unknown as E

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data
              } else {
                r.error = data
              }
              return r
            })
            .catch((e) => {
              r.error = e
              return r
            })

      if (cancelToken) {
        this.abortControllers.delete(cancelToken)
      }

      if (!response.ok) throw data
      return data
    })
  }
}

/**
 * @title OpenAPI definition
 * @version v0
 * @baseUrl http://localhost:8081
 */
export class PortalApi<SecurityDataType extends unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  candidates = {
    /**
     * No description
     *
     * @tags Candidates
     * @name AddFeatureJob
     * @summary Add featured job
     * @request PUT:/portal/api/candidates/{userId}/featured-jobs/{jobId}
     * @secure
     */
    addFeatureJob: (userId: number, jobId: number, params: RequestParams = {}) =>
      this.http.request<void, void>({
        path: `/portal/api/candidates/${userId}/featured-jobs/${jobId}`,
        method: 'PUT',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Candidates
     * @name DeleteFeatureJob
     * @summary Delete featured job
     * @request DELETE:/portal/api/candidates/{userId}/featured-jobs/{jobId}
     * @secure
     */
    deleteFeatureJob: (userId: number, jobId: number, params: RequestParams = {}) =>
      this.http.request<void, void>({
        path: `/portal/api/candidates/${userId}/featured-jobs/${jobId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Candidates
     * @name Candidates
     * @summary Get candidates list updated after timestamp
     * @request GET:/portal/api/candidates
     * @secure
     */
    candidates: (query: CandidatesParams, params: RequestParams = {}) =>
      this.http.request<CandidateDto[], void>({
        path: `/portal/api/candidates`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Candidates
     * @name Create
     * @summary Create new candidate record
     * @request POST:/portal/api/candidates
     * @secure
     */
    create: (data: NewCandidateDto, params: RequestParams = {}) =>
      this.http.request<CreatedCandidateDto, CreatedCandidateDto | void>({
        path: `/portal/api/candidates`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Candidates
     * @name MergeDuplicates
     * @summary Merge duplicate
     * @request POST:/portal/api/candidates/{userId}/duplicates/merge
     * @secure
     */
    mergeDuplicates: (userId: number, params: RequestParams = {}) =>
      this.http.request<number, void>({
        path: `/portal/api/candidates/${userId}/duplicates/merge`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Candidates
     * @name IgnoreDuplicates
     * @summary Ignore duplicate
     * @request POST:/portal/api/candidates/{userId}/duplicates/ignore
     * @secure
     */
    ignoreDuplicates: (userId: number, params: RequestParams = {}) =>
      this.http.request<void, void>({
        path: `/portal/api/candidates/${userId}/duplicates/ignore`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Candidates
     * @name UpdateAnswers
     * @summary Update candidate answers
     * @request PATCH:/portal/api/candidates/{workflowStateId}
     * @secure
     */
    updateAnswers: (workflowStateId: number, data: UpdateAnswersPayload, params: RequestParams = {}) =>
      this.http.request<void, void>({
        path: `/portal/api/candidates/${workflowStateId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Text,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Candidates
     * @name UpdateOriginType
     * @summary Update candidate origin type
     * @request PATCH:/portal/api/candidates/{userId}/origin/{originType}
     * @secure
     */
    updateOriginType: (
      userId: number,
      originType: 'STANDARD_APP' | 'EVENT_APP' | 'CHIME' | 'REFERRAL' | 'MANUALLY',
      params: RequestParams = {},
    ) =>
      this.http.request<void, void>({
        path: `/portal/api/candidates/${userId}/origin/${originType}`,
        method: 'PATCH',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Candidates
     * @name UpdateEvent
     * @summary Update the event the candidate went to
     * @request PATCH:/portal/api/candidates/{userId}/event/{eventId}
     * @secure
     */
    updateEvent: (userId: number, eventId: number, params: RequestParams = {}) =>
      this.http.request<void, void>({
        path: `/portal/api/candidates/${userId}/event/${eventId}`,
        method: 'PATCH',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Candidates
     * @name Assign
     * @summary Assign candidate to portal user
     * @request PATCH:/portal/api/candidates/{userId}/assign
     * @secure
     */
    assign: ({ userId, ...query }: AssignParams, params: RequestParams = {}) =>
      this.http.request<void, void>({
        path: `/portal/api/candidates/${userId}/assign`,
        method: 'PATCH',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Candidates
     * @name Archive
     * @summary Archive candidate
     * @request PATCH:/portal/api/candidates/{userId}/archive
     * @secure
     */
    archive: (userId: number, data: ArchiveDto, params: RequestParams = {}) =>
      this.http.request<void, void>({
        path: `/portal/api/candidates/${userId}/archive`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Candidates
     * @name SetTest
     * @summary Mark/unmark candidate as test by ID
     * @request PATCH:/portal/api/candidates/{id}/test/{newValue}
     * @secure
     */
    setTest: (id: number, newValue: boolean, params: RequestParams = {}) =>
      this.http.request<void, void>({
        path: `/portal/api/candidates/${id}/test/${newValue}`,
        method: 'PATCH',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Candidates
     * @name Profile
     * @summary Get candidate profile info
     * @request GET:/portal/api/candidates/profile/{userId}
     * @secure
     */
    profile: (userId: number, params: RequestParams = {}) =>
      this.http.request<CandidateProfileDto, void>({
        path: `/portal/api/candidates/profile/${userId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Candidates
     * @name UpdateProfile
     * @summary Update candidate profile info
     * @request PATCH:/portal/api/candidates/profile/{userId}
     * @secure
     */
    updateProfile: (userId: number, data: UpdatedCandidateProfileDto, params: RequestParams = {}) =>
      this.http.request<void, void>({
        path: `/portal/api/candidates/profile/${userId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Candidates
     * @name Results
     * @summary Get survey results
     * @request GET:/portal/api/candidates/{workflowStateId}/results
     * @secure
     */
    results: (workflowStateId: number, params: RequestParams = {}) =>
      this.http.request<SurveyResultDto, void>({
        path: `/portal/api/candidates/${workflowStateId}/results`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Candidates
     * @name SearchJobPostings
     * @summary Search job postings
     * @request GET:/portal/api/candidates/{userId}/job-postings
     * @secure
     */
    searchJobPostings: ({ userId, ...query }: SearchJobPostingsParams, params: RequestParams = {}) =>
      this.http.request<JobPostingDto[], void>({
        path: `/portal/api/candidates/${userId}/job-postings`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Candidates
     * @name InitSearchJobPostings
     * @summary Initial search request for job postings
     * @request GET:/portal/api/candidates/{userId}/job-postings/init
     * @secure
     */
    initSearchJobPostings: ({ userId, ...query }: InitSearchJobPostingsParams, params: RequestParams = {}) =>
      this.http.request<JobPostingsInitSearchDto, void>({
        path: `/portal/api/candidates/${userId}/job-postings/init`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Candidates
     * @name JobMatching
     * @summary Get job matching details
     * @request GET:/portal/api/candidates/{userId}/job-matchings/{jobPostingId}
     * @secure
     */
    jobMatching: (userId: number, jobPostingId: number, params: RequestParams = {}) =>
      this.http.request<JobDto, JobDto | void>({
        path: `/portal/api/candidates/${userId}/job-matchings/${jobPostingId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Candidates
     * @name SearchFeaturedJobPostings
     * @summary Search featured job postings
     * @request GET:/portal/api/candidates/{userId}/featured-job-postings
     * @secure
     */
    searchFeaturedJobPostings: ({ userId, ...query }: SearchFeaturedJobPostingsParams, params: RequestParams = {}) =>
      this.http.request<JobPostingDto[], void>({
        path: `/portal/api/candidates/${userId}/featured-job-postings`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Candidates
     * @name InitSearchFeaturedJobPostings
     * @summary Initial search request for featured job postings
     * @request GET:/portal/api/candidates/{userId}/featured-job-postings/init
     * @secure
     */
    initSearchFeaturedJobPostings: (
      { userId, ...query }: InitSearchFeaturedJobPostingsParams,
      params: RequestParams = {},
    ) =>
      this.http.request<JobPostingsInitSearchDto, void>({
        path: `/portal/api/candidates/${userId}/featured-job-postings/init`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Candidates
     * @name GetDuplicatesIds
     * @summary Get duplicate ids for provided candidate
     * @request GET:/portal/api/candidates/{userId}/duplicates
     * @secure
     */
    getDuplicatesIds: (userId: number, params: RequestParams = {}) =>
      this.http.request<number[], void>({
        path: `/portal/api/candidates/${userId}/duplicates`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Check if the user already exists with the email address
     *
     * @tags Candidates
     * @name Exists
     * @request GET:/portal/api/candidates/exists
     * @secure
     */
    exists: (query: ExistsParams, params: RequestParams = {}) =>
      this.http.request<boolean, boolean>({
        path: `/portal/api/candidates/exists`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Candidates
     * @name GetAll1
     * @summary Returns list of all candidates with test flag
     * @request GET:/portal/api/candidates/all
     * @secure
     */
    getAll1: (params: RequestParams = {}) =>
      this.http.request<UserDto[], any>({
        path: `/portal/api/candidates/all`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  workflowState = {
    /**
     * No description
     *
     * @tags Workflow State
     * @name GetStateHistory
     * @request POST:/portal/api/workflow-state/{workflowStateId}/history
     * @secure
     */
    getStateHistory: (workflowStateId: number, params: RequestParams = {}) =>
      this.http.request<WorkflowStateHistoryDto[], any>({
        path: `/portal/api/workflow-state/${workflowStateId}/history`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Workflow State
     * @name ResetState
     * @request POST:/portal/api/workflow-state/reset
     * @secure
     */
    resetState: (query: ResetStateParams, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/portal/api/workflow-state/reset`,
        method: 'POST',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Workflow State
     * @name ResetStateByEmail
     * @summary Reset last state of candidate find by email
     * @request POST:/portal/api/workflow-state/reset-by-email
     * @secure
     */
    resetStateByEmail: (query: ResetStateByEmailParams, params: RequestParams = {}) =>
      this.http.request<void, void>({
        path: `/portal/api/workflow-state/reset-by-email`,
        method: 'POST',
        query: query,
        secure: true,
        ...params,
      }),
  }
  portalUsers = {
    /**
     * @description Portal user authentication
     *
     * @tags Portal Users
     * @name Authenticate
     * @request POST:/portal/api/portal-users/authenticate
     * @secure
     */
    authenticate: (params: RequestParams = {}) =>
      this.http.request<PortalUser, PortalUser>({
        path: `/portal/api/portal-users/authenticate`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Portal Users
     * @name Users
     * @summary Get all portal users list
     * @request GET:/portal/api/portal-users/
     * @secure
     */
    users: (params: RequestParams = {}) =>
      this.http.request<PortalUserDto[], void>({
        path: `/portal/api/portal-users/`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  notes = {
    /**
     * No description
     *
     * @tags Notes
     * @name Notes
     * @summary Get all notes for user
     * @request GET:/portal/api/notes/by-user/{userId}
     * @secure
     */
    notes: (userId: number, params: RequestParams = {}) =>
      this.http.request<NoteDto[], void>({
        path: `/portal/api/notes/by-user/${userId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notes
     * @name CreateNote
     * @summary Add new note
     * @request POST:/portal/api/notes/by-user/{userId}
     * @secure
     */
    createNote: (userId: number, data: NoteContentDto, params: RequestParams = {}) =>
      this.http.request<NoteDto, void>({
        path: `/portal/api/notes/by-user/${userId}`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notes
     * @name UpdateNote
     * @summary Update note
     * @request PATCH:/portal/api/notes/{noteId}
     * @secure
     */
    updateNote: (noteId: number, data: NoteContentDto, params: RequestParams = {}) =>
      this.http.request<void, void>({
        path: `/portal/api/notes/${noteId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  }
  jobs = {
    /**
     * No description
     *
     * @tags Jobs
     * @name CreateSubmission
     * @summary Create submission for a job
     * @request POST:/portal/api/job/{jobId}/submission
     * @secure
     */
    createSubmission: (jobId: number, params: RequestParams = {}) =>
      this.http.request<JobSubmission, void>({
        path: `/portal/api/job/${jobId}/submission`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Jobs
     * @name CreatePlacement
     * @summary Create placement for a job
     * @request POST:/portal/api/job/{jobId}/placement
     * @secure
     */
    createPlacement: (jobId: number, params: RequestParams = {}) =>
      this.http.request<Placement, void>({
        path: `/portal/api/job/${jobId}/placement`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Jobs
     * @name UpdateSubmission
     * @summary Update submission for a job
     * @request POST:/portal/api/job/submission
     * @secure
     */
    updateSubmission: (data: JobSubmission, params: RequestParams = {}) =>
      this.http.request<void, void>({
        path: `/portal/api/job/submission`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Jobs
     * @name UpdatePlacement
     * @summary Update placement for a job
     * @request POST:/portal/api/job/placement
     * @secure
     */
    updatePlacement: (data: Placement, params: RequestParams = {}) =>
      this.http.request<void, void>({
        path: `/portal/api/job/placement`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Export data for candidate submission into pdf (Example)
     *
     * @tags Jobs
     * @name ExportCandidateJobInfo
     * @request POST:/portal/api/job/export-submission
     * @secure
     */
    exportCandidateJobInfo: (data: JobDetailsExportDto, params: RequestParams = {}) =>
      this.http.request<File, File>({
        path: `/portal/api/job/export-submission`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Jobs
     * @name CreateJob
     * @summary Add new job
     * @request POST:/portal/api/job/by-user/{userId}/
     * @secure
     */
    createJob: ({ userId, ...query }: CreateJobParams, data: JobDetailsFormDto, params: RequestParams = {}) =>
      this.http.request<JobDetailsDto, void>({
        path: `/portal/api/job/by-user/${userId}/`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Jobs
     * @name UpdateJob
     * @summary Update job
     * @request PATCH:/portal/api/job/{jobId}
     * @secure
     */
    updateJob: (jobId: number, data: JobDetailsFormDto, params: RequestParams = {}) =>
      this.http.request<void, void>({
        path: `/portal/api/job/${jobId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Jobs
     * @name PlaceJob
     * @summary Place job
     * @request PATCH:/portal/api/job/{jobId}/place
     * @secure
     */
    placeJob: ({ jobId, ...query }: PlaceJobParams, params: RequestParams = {}) =>
      this.http.request<void, void>({
        path: `/portal/api/job/${jobId}/place`,
        method: 'PATCH',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Jobs
     * @name ArchiveJob
     * @summary Archive job
     * @request PATCH:/portal/api/job/{jobId}/archive
     * @secure
     */
    archiveJob: (jobId: number, params: RequestParams = {}) =>
      this.http.request<void, void>({
        path: `/portal/api/job/${jobId}/archive`,
        method: 'PATCH',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Jobs
     * @name ArchiveOtherUserJobs
     * @summary Archive all jobs for user
     * @request PATCH:/portal/api/job/{jobId}/archive-others
     * @secure
     */
    archiveOtherUserJobs: (jobId: number, params: RequestParams = {}) =>
      this.http.request<void, void>({
        path: `/portal/api/job/${jobId}/archive-others`,
        method: 'PATCH',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Jobs
     * @name GetActivityLog
     * @summary Get activity log for a job
     * @request GET:/portal/api/job/{jobId}/activity-log
     * @secure
     */
    getActivityLog: (jobId: number, params: RequestParams = {}) =>
      this.http.request<JobActivityLog[], void>({
        path: `/portal/api/job/${jobId}/activity-log`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Jobs
     * @name Search
     * @summary Search jobs
     * @request GET:/portal/api/job/job-posting
     * @secure
     */
    search: (query: SearchParams, params: RequestParams = {}) =>
      this.http.request<JobPostingDto[], void>({
        path: `/portal/api/job/job-posting`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Jobs
     * @name GetJobPosting
     * @summary Get job posting details
     * @request GET:/portal/api/job/job-posting/{jobPostingId}
     * @secure
     */
    getJobPosting: (jobPostingId: number, params: RequestParams = {}) =>
      this.http.request<JobDto, void>({
        path: `/portal/api/job/job-posting/${jobPostingId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Jobs
     * @name GetCount
     * @summary Get jobs count
     * @request GET:/portal/api/job/job-posting/count
     * @secure
     */
    getCount: (query: GetCountParams, params: RequestParams = {}) =>
      this.http.request<number, void>({
        path: `/portal/api/job/job-posting/count`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Jobs
     * @name Jobs
     * @summary Get all jobs for user
     * @request GET:/portal/api/job/by-user/{userId}
     * @secure
     */
    jobs: (userId: number, params: RequestParams = {}) =>
      this.http.request<JobDto[], void>({
        path: `/portal/api/job/by-user/${userId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  interview = {
    /**
     * No description
     *
     * @tags Interview
     * @name CreateInterview
     * @summary Add new interview
     * @request POST:/portal/api/interview/by-job/{jobId}
     * @secure
     */
    createInterview: (jobId: number, params: RequestParams = {}) =>
      this.http.request<Interview, void>({
        path: `/portal/api/interview/by-job/${jobId}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Interview
     * @name PatchInterview
     * @summary Update interview
     * @request PATCH:/portal/api/interview/{id}
     * @secure
     */
    patchInterview: (id: number, data: InterviewDto, params: RequestParams = {}) =>
      this.http.request<void, void>({
        path: `/portal/api/interview/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  }
  files = {
    /**
     * No description
     *
     * @tags Files
     * @name UploadAttachment
     * @summary Upload file to Azure Blob Storage and Save file meta information on DB.
     * @request POST:/portal/api/files/attachment/{userId}
     * @secure
     */
    uploadAttachment: (userId: number, data: UploadAttachmentPayload, params: RequestParams = {}) =>
      this.http.request<Attachment, Attachment>({
        path: `/portal/api/files/attachment/${userId}`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * @description Download attachment file by id
     *
     * @tags Files
     * @name DownloadAttachment
     * @request GET:/portal/api/files/attachment/{attachmentId}
     * @secure
     */
    downloadAttachment: (attachmentId: number, params: RequestParams = {}) =>
      this.http.request<File, File>({
        path: `/portal/api/files/attachment/${attachmentId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),
  }
  events = {
    /**
     * No description
     *
     * @tags Events
     * @name CreateEvent
     * @summary Create new event
     * @request POST:/portal/api/events
     * @secure
     */
    createEvent: (query: CreateEventParams, params: RequestParams = {}) =>
      this.http.request<EventDto, void>({
        path: `/portal/api/events`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Activate/deactivate existing event by id
     *
     * @tags Events
     * @name SetActive
     * @request PATCH:/portal/api/events/{id}/active/{isActive}
     * @secure
     */
    setActive: (id: number, isActive: boolean, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/portal/api/events/${id}/active/${isActive}`,
        method: 'PATCH',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Events
     * @name GetEvents
     * @summary Returns list of all events
     * @request GET:/portal/api/events/all
     * @secure
     */
    getEvents: (params: RequestParams = {}) =>
      this.http.request<EventDto[], void>({
        path: `/portal/api/events/all`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  coachingSession = {
    /**
     * No description
     *
     * @tags Coaching session
     * @name SendEmail
     * @summary Send Coaching Session Details email
     * @request POST:/portal/api/coaching-session/{id}/email
     * @secure
     */
    sendEmail: (id: number, data: EmailContentDto, params: RequestParams = {}) =>
      this.http.request<void, void>({
        path: `/portal/api/coaching-session/${id}/email`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Coaching session
     * @name GetCoachingSessions
     * @summary Get user coaching sessions
     * @request GET:/portal/api/coaching-session/by-user/{userId}
     * @secure
     */
    getCoachingSessions: (userId: number, params: RequestParams = {}) =>
      this.http.request<CoachingSessionDto[], void>({
        path: `/portal/api/coaching-session/by-user/${userId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Coaching session
     * @name CreateSession
     * @summary Add new coaching session
     * @request POST:/portal/api/coaching-session/by-user/{userId}
     * @secure
     */
    createSession: (userId: number, params: RequestParams = {}) =>
      this.http.request<CoachingSessionDto, void>({
        path: `/portal/api/coaching-session/by-user/${userId}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Coaching session
     * @name UpdateSession
     * @summary Update coaching session
     * @request POST:/portal/api/coaching-session/
     * @secure
     */
    updateSession: (data: CoachingSessionDto, params: RequestParams = {}) =>
      this.http.request<void, void>({
        path: `/portal/api/coaching-session/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Coaching session
     * @name GetCoachingSession
     * @summary Get coaching session
     * @request GET:/portal/api/coaching-session/{id}
     * @secure
     */
    getCoachingSession: (id: number, params: RequestParams = {}) =>
      this.http.request<CoachingSessionDto, void>({
        path: `/portal/api/coaching-session/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Coaching session
     * @name CoachingSessionEmails
     * @summary Get emails by coaching session
     * @request GET:/portal/api/coaching-session/{id}/emails
     * @secure
     */
    coachingSessionEmails: (id: number, params: RequestParams = {}) =>
      this.http.request<RetentionSentEmail[], void>({
        path: `/portal/api/coaching-session/${id}/emails`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Coaching session
     * @name Emails
     * @summary Get all user emails
     * @request GET:/portal/api/coaching-session/by-user/{userId}/emails
     * @secure
     */
    emails: (userId: number, params: RequestParams = {}) =>
      this.http.request<RetentionSentEmail[], void>({
        path: `/portal/api/coaching-session/by-user/${userId}/emails`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  featureFlags = {
    /**
     * @description Disable/enable existing feature flag by id
     *
     * @tags Feature Flags
     * @name SetDisabled
     * @request PATCH:/portal/api/feature-flags/{id}/disabled/{newValue}
     * @secure
     */
    setDisabled: (id: number, newValue: boolean, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/portal/api/feature-flags/${id}/disabled/${newValue}`,
        method: 'PATCH',
        secure: true,
        ...params,
      }),

    /**
     * @description Returns all feature flags including disabled
     *
     * @tags Feature Flags
     * @name GetAll
     * @request GET:/portal/api/feature-flags
     * @secure
     */
    getAll: (params: RequestParams = {}) =>
      this.http.request<FeatureFlag[], any>({
        path: `/portal/api/feature-flags`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
}
