import { Box } from '@mui/material'
import { BoxProps } from '@mui/material/Box/Box'
import { useLocation, useNavigate } from 'react-router-dom'
import { useEffect } from 'react'

export default function TabContentContainer({ children, sx, ...other }: BoxProps) {
  const navigate = useNavigate()
  const { pathname, hash } = useLocation()

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (hash) {
        navigate(pathname + hash, { replace: true })
      }
    }, 48)
    return () => clearTimeout(timeout)
  }, [pathname, hash, navigate])

  return (
    <Box sx={{ ...sx, columnGap: '19px', display: 'flex' }} {...other}>
      {children}
    </Box>
  )
}
