import React from 'react'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import { SurveyQuestionCheckboxItem } from 'survey-react-ui'
import { Serializer } from 'survey-core'
import IconButton from '@mui/material/IconButton'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import InfoCircle from '../../icons/InfoCircle'
import DialogCloseButton from '../../components/dialog/DialogCloseButton'
import nop from '../../nop'

class MuiCheckboxItem extends SurveyQuestionCheckboxItem {
  constructor(props: unknown) {
    super(props)
    this.onOpenToolTip = this.onOpenToolTip.bind(this)
    this.onCloseToolTip = this.onCloseToolTip.bind(this)
    this.onSelectToolTip = this.onSelectToolTip.bind(this)
  }

  onOpenToolTip() {
    this.setState({ ...(this.state || {}), toolTipOpened: true })
  }

  onCloseToolTip() {
    this.setState({ ...(this.state || {}), toolTipOpened: false })
  }

  onSelectToolTip() {
    this.handleOnChange({ target: { checked: true } })
    this.onCloseToolTip()
  }

  protected renderCheckbox(isChecked: boolean /* , otherItem: JSX.Element | null */): JSX.Element {
    const id = this.question.getItemId(this.item)
    const label = !this.props.hideCaption ? this.renderLocString(this.item.locText) : ''
    const value = this.item.value !== 'selectall' ? this.item.value : undefined
    const toolTip = this.item.getPropertyValue('toolTip') as string | undefined
    const toolTipSelectLabel = (this.item.getPropertyValue('toolTipSelectLabel') as string) || 'Select'
    const itemClass = this.question.getItemClass(this.item)
    const isOther = this.question.isOtherItem(this.item)

    const onItemChanged = (e: unknown) => {
      if (this.item === this.question.selectAllItem) {
        this.selectAllChanged(e)
      } else {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const { focusOtherComment = undefined } = !isOther ? (this.question as any) : {}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (focusOtherComment) (this.question as any).focusOtherComment = nop
        try {
          this.handleOnChange(e)
        } finally {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          if (focusOtherComment) (this.question as any).focusOtherComment = focusOtherComment
        }
      }
    }

    return (
      <div className={itemClass} role="presentation" style={{ display: 'flex', alignItems: 'start' }}>
        <FormControlLabel
          control={
            <Checkbox
              id={id}
              name={this.question.name}
              value={value}
              onChange={onItemChanged}
              checked={isChecked}
              disabled={!this.question.getItemEnabled(this.item)}
              inputProps={{ 'aria-describedby': this.question.ariaDescribedBy }}
              aria-label={this.question.getAriaItemLabel(this.item)}
              sx={{ marginTop: '-10px' }}
            />
          }
          label={label}
          sx={{ alignItems: 'flex-start' }}
        />
        {toolTip && (
          <>
            <IconButton size="small" color="primary" onClick={this.onOpenToolTip}>
              <InfoCircle />
            </IconButton>
            <Dialog
              open={this.state?.toolTipOpened || false}
              PaperProps={{
                sx: {
                  maxWidth: '608px',
                },
              }}
            >
              <DialogTitle>
                {label}
                <DialogCloseButton onClick={this.onCloseToolTip} />
              </DialogTitle>
              <DialogContent>
                {/* eslint-disable-next-line react/no-danger */}
                <div dangerouslySetInnerHTML={{ __html: toolTip }} />
              </DialogContent>
              <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button onClick={this.onCloseToolTip} color="secondary" fullWidth>
                  Close
                </Button>
                <Button onClick={this.onSelectToolTip} color="primary" fullWidth>
                  {toolTipSelectLabel}
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      </div>
    )
  }
}

Serializer.addProperty('itemvalue', { name: 'toolTip', type: 'string' })
Serializer.addProperty('itemvalue', { name: 'toolTipSelectLabel', type: 'string' })

export default MuiCheckboxItem
