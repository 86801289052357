import { Question, Serializer } from 'survey-core'

export const rates = ['Hourly', 'Yearly'] as const
const labelProperty = { name: 'label', defaultValue: 'Enter Amount' } as const
export type Compensation = {
  rate?: (typeof rates)[number]
  amount?: string
}

export default class CompensationModel extends Question {
  static readonly TYPE = 'compensation'

  // eslint-disable-next-line class-methods-use-this
  getType() {
    return CompensationModel.TYPE
  }

  get label() {
    return this.getPropertyValue(labelProperty.name, labelProperty.defaultValue)
  }

  set label(value: string) {
    this.setPropertyValue(labelProperty.name, value)
  }

  get value() {
    return super.value as Compensation
  }

  set value(compensation: Compensation) {
    super.value = compensation
  }

  isEmpty(): boolean {
    return !this.value || !this.value.amount
  }
}

Serializer.addClass(CompensationModel.TYPE, [labelProperty], () => new CompensationModel(''), 'question')
