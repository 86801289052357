import { feedbackElement, inputFieldElement } from '../../../demo/survey-model'
import { GetChoices } from '../../../demo/survey-types'

export const coachingSession = {
  textUpdateMode: 'onTyping',
  pages: [
    {
      elements: [
        {
          type: 'panel',
          name: 'AccomplishmentPanel',
          titleLocation: 'hidden',
          elements: [
            {
              type: 'paneldynamic',
              name: 'accomplishments',
              title: 'Accomplishments',
              templateElements: [
                {
                  type: 'text',
                  name: 'accomplishment',
                  titleLocation: 'hidden',
                  rows: 1,
                  placeholder: 'Accomplishment',
                },
              ],
              noEntriesText: ' ',
              minPanelCount: 1,
              panelAddText: 'Add another accomplishment',
              allowRemovePanel: false,
            },
          ],
        },
        {
          type: 'panel',
          name: 'Next Steps',
          elements: [
            {
              ...inputFieldElement,
              name: 'actionPlanProgress',
              title: 'Action Plan Progress',
              placeholder: 'Describe Progress',
            },
            {
              type: 'paneldynamic',
              title: 'Action Plan Challenges',
              name: 'challenges',
              templateElements: [
                {
                  type: 'text',
                  name: 'challenge',
                  titleLocation: 'hidden',
                  rows: 1,
                  placeholder: 'Challenge',
                },
              ],
              noEntriesText: ' ',
              minPanelCount: 1,
              panelAddText: 'Add another challenge',
              allowRemovePanel: false,
            },
            {
              type: 'paneldynamic',
              title: 'Resources for Overcoming Action Plan Challenges',
              name: 'resources',
              templateElements: [
                {
                  type: 'text',
                  name: 'resource',
                  titleLocation: 'hidden',
                  rows: 1,
                  placeholder: 'Resource',
                },
              ],
              noEntriesText: ' ',
              minPanelCount: 1,
              panelAddText: 'Add another resource',
              allowRemovePanel: false,
            },
            {
              ...inputFieldElement,
              name: 'nextSteps',
              title: 'Next Steps',
              placeholder: 'Describe Next Steps',
            },
          ],
        },
      ],
    },
  ],
  showQuestionNumbers: 'off',
} as const

export const coachingSessionFeedback = {
  textUpdateMode: 'onTyping',
  pages: [
    {
      elements: [
        {
          ...feedbackElement,
          name: 'additionalDetails',
          placeholder: 'Elaborate on coaching session...',
        },
      ],
    },
  ],
} as const

const professionalGoalsAndAspirations = {
  type: 'panel',
  title: 'Professional Goals and Aspirations',
  elements: [
    {
      ...inputFieldElement,
      name: 'What are you most happy doing for work and why',
      placeholder: 'What are you most happy doing for work and why?',
    },
    {
      ...inputFieldElement,
      titleLocation: 'hidden',
      name: 'What would your dream job be',
      placeholder: 'What would your dream job be?',
    },
    {
      ...inputFieldElement,
      titleLocation: 'hidden',
      name: 'Why does a career in healthcare interest you',
      placeholder: 'Why does a career in healthcare interest you?',
    },
  ],
} as const

const strengthsSkillsAndTalents = {
  type: 'panel',
  elements: [
    {
      type: 'paneldynamic',
      title: 'Strengths, Skills, and Talents',
      description: 'What are the top strengths you currently offer to the healthcare field?',
      name: 'What are the top strengths you currently offer to the healthcare field',
      templateElements: [
        {
          type: 'text',
          name: 'Strength',
          titleLocation: 'hidden',
          placeholder: 'Strength',
        },
      ],
      noEntriesText: ' ',
      minPanelCount: 1,
      panelAddText: 'Add another strength',
      allowRemovePanel: false,
    },
    {
      ...inputFieldElement,
      titleLocation: 'hidden',
      name: 'What would you like to see happen to you in the healthcare field',
      placeholder: 'What would you like to see happen to you in the healthcare field?',
    },
  ],
} as const

const developmentOpportunities = {
  type: 'panel',
  elements: [
    {
      type: 'paneldynamic',
      name: 'What are your biggest obstacles to getting where you want professionally',
      title: 'Development Opportunities',
      description: 'What are your biggest obstacles to getting where you want professionally?',
      templateElements: [
        {
          type: 'text',
          name: 'Obstacle',
          titleLocation: 'hidden',
          rows: 1,
          placeholder: 'Obstacle',
        },
      ],
      noEntriesText: ' ',
      minPanelCount: 1,
      panelAddText: 'Add another obstacle',
      allowRemovePanel: false,
    },
    {
      type: 'paneldynamic',
      name: 'What resources do you need to overcome your obstacles',
      title: '<div style="margin-top: -20px"/>',
      // titleLocation: 'hidden',
      description: 'What resources do you need to overcome your obstacles?',
      templateElements: [
        {
          type: 'text',
          name: 'Resource',
          titleLocation: 'hidden',
          placeholder: 'Resource',
          helperText: 'E.g., Access to transportation, child care...',
        },
      ],
      noEntriesText: ' ',
      minPanelCount: 1,
      panelAddText: 'Add another resource',
      allowRemovePanel: false,
    },
    {
      ...inputFieldElement,
      titleLocation: 'hidden',
      name: 'How will you know',
      placeholder: 'How will you know when you have overcome these obstacles?',
    },
    {
      ...inputFieldElement,
      titleLocation: 'hidden',
      name: 'Overall description of success',
      placeholder: 'What is your overall description of success?',
    },
  ],
} as const

const actionPlan = {
  type: 'panel',
  elements: [
    {
      type: 'paneldynamic',
      title: 'Action Plan',
      description:
        'Identify short-term and long-term goals and the specific actions the candidate must take to achieve their goals.',
      name: 'Action Plan',
      templateElements: [
        {
          type: 'text',
          name: 'Goal',
          title: ' ',
          placeholder: 'Goal',
          helperText: 'E.g., Obtain a job making $16/hr',
        },
        {
          type: 'text',
          name: 'Action',
          titleLocation: 'hidden',
          placeholder: 'Action to Meet Goal',
          helperText: 'E.g., Work with my Career Coaches to create a resume',
        },
        {
          titleLocation: 'hidden',
          type: 'date',
          name: 'Target date',
          label: 'Target Date',
          hideNumber: true,
        },
      ],
      noEntriesText: ' ',
      minPanelCount: 1,
      panelAddText: 'Add another goal',
      allowRemovePanel: false,
    },
  ],
} as const

export const individualDevelopmentPlan = {
  textUpdateMode: 'onTyping',
  pages: [
    {
      elements: [professionalGoalsAndAspirations, strengthsSkillsAndTalents, developmentOpportunities, actionPlan],
    },
  ],
  showQuestionNumbers: 'off',
} as const

export const individualDevelopmentPlanFeedback = {
  textUpdateMode: 'onTyping',
  pages: [
    {
      elements: [
        {
          ...feedbackElement,
          name: 'Individual Development Plan Feedback',
          placeholder: 'Elaborate on development plan...',
        },
      ],
    },
  ],
} as const

type CoachingSessionPanels = (typeof coachingSession.pages)[number]['elements'][number]

type GetCoachingSessionQuestions<P extends CoachingSessionPanels> = P extends { readonly type: 'panel' }
  ? P['elements'][number]
  : P extends { readonly type: 'paneldynamic' }
  ? P['templateElements'][number]
  : never

type CoachingSessionQuestions = GetCoachingSessionQuestions<CoachingSessionPanels>
type IDPQuestions = (typeof individualDevelopmentPlan.pages)[number]['elements'][number]['elements'][number]

type GetSubQuestions<Q extends IDPQuestions | CoachingSessionQuestions> = Q extends { readonly type: 'paneldynamic' }
  ? Q['templateElements'][number]
  : never

type CoachingSessionSubQuestions = GetSubQuestions<CoachingSessionQuestions>
type IDPSubQuestions = GetSubQuestions<IDPQuestions>

type Questions = CoachingSessionQuestions | CoachingSessionSubQuestions | IDPQuestions | IDPSubQuestions

type GetQuestionByName<Q extends Questions, Name extends Q['name']> = Q extends {
  readonly name: Name
}
  ? Q
  : never

type GetAnswerType<Q extends Questions> = Q extends { readonly type: 'boolean' }
  ? boolean
  : Q extends { readonly type: 'checkbox' }
  ? [
      | GetChoices<Q['choices']>
      | (Q extends { readonly showOtherItem: true } ? 'other' : never)
      | (Q extends { readonly showNoneItem: true }
          ? Q extends { readonly noneText: string }
            ? Q['noneText']
            : 'None'
          : never),
    ]
  : Q extends { readonly type: 'radiogroup' }
  ? GetChoices<Q['choices']> | (Q extends { readonly showOtherItem: true } ? 'other' : never)
  : Q extends { readonly type: 'paneldynamic' }
  ? [
      {
        [K2 in Q['templateElements'][number]['name']]?: GetAnswerType<
          GetQuestionByName<Q['templateElements'][number], K2>
        >
      },
    ]
  : Q['type'] extends 'text' | 'date' | 'textarea'
  ? string
  : never

export type IDPAnswers = { [K in IDPQuestions['name']]?: GetAnswerType<GetQuestionByName<IDPQuestions, K>> }
export type CoachingSessionAnswers = {
  [K in CoachingSessionQuestions['name']]?: GetAnswerType<GetQuestionByName<CoachingSessionQuestions, K>>
}
