import { makeAutoObservable } from 'mobx'
import { Dayjs } from 'dayjs'
import Candidate from '../candidate'

function all() {
  return true
}

export default class DateRangeFilter {
  startDate: Dayjs | null = null

  endDate: Dayjs | null = null

  constructor(public readonly name: 'lastActivityDayjs' | 'appliedDayjs') {
    makeAutoObservable(this, undefined, { autoBind: true })
  }

  get predicateFrom() {
    const { startDate } = this
    if (!startDate || !startDate.isValid()) return all
    return (c: Candidate) => {
      const date = c[this.name]
      return startDate.isSame(date) || startDate.isBefore(date)
    }
  }

  get predicateTo() {
    const { endDate } = this
    if (!endDate || !endDate.isValid()) return all
    return (c: Candidate) => {
      const date = c[this.name]
      return endDate.isSame(date) || endDate.isAfter(date)
    }
  }

  clear() {
    this.startDate = null
    this.endDate = null
  }
}
