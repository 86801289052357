import { z } from 'zod'
import dayjs, { Dayjs } from 'dayjs'
import { PHONE_REGEX } from '../../../../common/components/MaskedPhoneInput'
import { ZIP_CODE_REGEX } from '../../../../common/components/MaskedZipCodeInput'
import { stateNamesSet } from '../../../../common/survey/us-address'

export const MAX_TIMESTAMP = dayjs('2038-01-19')

const dateSchema = z
  .instanceof(dayjs as unknown as typeof Dayjs, { message: 'Date is required' })
  .nullable()
  .refine((date) => !!date, { message: 'Date is required' })
  .refine((date) => date?.isValid() && date?.isBefore(MAX_TIMESTAMP), { message: 'Date is invalid' })

const placementSchema = z.object({
  startDate: dateSchema,
  employer: z.string().trim().nonempty('Employer is required'),
  salary: z.string().trim().nonempty('Salary is required'),
  title: z.string().trim().nonempty('Job Title is required'),
  careerArea: z.string().trim().nonempty('Career Area is required'),
  shift: z.string().trim().nonempty('Shift is required').max(100),
  supervisorFirstName: z.string().trim().nonempty('First Name is required').max(45),
  supervisorLastName: z.string().trim().nonempty('Last Name is required').max(45),
  supervisorEmail: z.string().trim().email('Please enter a valid e-mail address').max(256),
  supervisorOfficePhone: z
    .string()
    .trim()
    .nonempty('Phone is required')
    .regex(PHONE_REGEX, 'Enter a valid phone number'),
  supervisorDirectPhone: z
    .string()
    .trim()
    .regex(PHONE_REGEX, 'Enter a valid phone number')
    .or(z.literal(''))
    .nullish()
    .transform((s) => s || ''),
  addressLine1: z.string().trim().nonempty('Address Line 1 is required').max(255),
  addressLine2: z.string().trim().max(255).optional(),
  city: z.string().trim().nonempty('City is required').max(50),
  state: z
    .string()
    .trim()
    .nonempty('State is required')
    .max(50)
    .nullish()
    .refine((value) => value && stateNamesSet.has(value), 'Invalid state'),
  zip: z.string().trim().regex(ZIP_CODE_REGEX, 'Please enter a valid zip code'),
  additionalDetails: z.string().trim().optional(),
  placed: z.boolean().optional(),
  archiveAllOther: z.boolean().optional(),
  notPlacedReason: z.string().trim().optional(),
})

export default placementSchema
