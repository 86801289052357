import { Box, Button } from '@mui/material'
import { PickersActionBarProps } from '@mui/x-date-pickers'
import { useLocaleText } from '@mui/x-date-pickers/internals'

export default function CustomActionBar(props: PickersActionBarProps) {
  const { onCancel } = props
  const localeText = useLocaleText()

  return (
    <Box sx={{ padding: '16px 24px 0', textAlign: 'right' }}>
      <Button size="small" onClick={onCancel} color="secondary">
        {localeText.cancelButtonLabel}
      </Button>
      {/* <Button variant="text" size="small" onClick={onAccept} > */}
      {/*   {localeText.okButtonLabel} */}
      {/* </Button> */}
    </Box>
  )
}
