import React, { useState } from 'react'
import { Box, Divider, Typography } from '@mui/material'
import { colors } from '../../../common/mui-theme'
import MinimizedView from '../placement/tabs/MinimizedView'
import MinimizableSurveyAccordion from '../survey-answers/MinimizableSurveyAccordion'
import { JobDto } from '../../_generated/portal-api'
import { notAvailable } from '../titles'

function renderTextWithNewlines(text?: string) {
  return text?.split('\n').map((line, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <span key={index}>
      {line}
      <br />
    </span>
  ))
}

export function formatArrayValue(value: string | null | undefined): string {
  if (Array.isArray(value)) {
    return value.join(', ')
  }
  return (value ?? '').replace(/[[\]']/g, '')
}

export const getLocationString = (...args: (string | undefined)[]): string =>
  args.filter((value) => value?.trim()).join(', ')

function JobInformation({ job }: { job: JobDto }) {
  const { submission, placement, interviews } = job.details || {}
  const hasPlacementActions = !!(submission || placement || interviews?.length)
  const [expanded, setExpanded] = useState(!hasPlacementActions)

  return (
    <MinimizableSurveyAccordion
      expanded={expanded}
      setExpanded={setExpanded}
      title={job.posting?.title?.trim() || notAvailable}
      subtitle={job.posting?.company?.trim() || notAvailable}
      details={
        job.posting && (
          <>
            <Divider light sx={{ m: '24px 0' }} />
            <Box sx={{ p: '0 24px', display: 'flex', gap: '150px' }}>
              <MinimizedView
                sx={{ p: 0 }}
                items={[
                  { title: 'Career Area', value: formatArrayValue(job.posting.category) || notAvailable },
                  { title: 'Shift', value: formatArrayValue(job.posting.shift) || notAvailable },
                  { title: 'Work Type', value: formatArrayValue(job.posting.employmentType) || notAvailable },
                  {
                    title: 'Location',
                    value: getLocationString(job.posting.city, job.posting.state, job.posting.zipCode) || notAvailable,
                  },
                ]}
              />
              <MinimizedView
                sx={{ p: 0 }}
                items={[
                  { title: 'Job Function', value: formatArrayValue(job.posting.jobFunction) || notAvailable },
                  { title: 'Job ID', value: job.posting.jobId },
                  {
                    title: 'Facility Group',
                    value: formatArrayValue(job.posting.lineOfBusinessFacilityGroup) || notAvailable,
                  },
                ]}
              />
            </Box>
          </>
        )
      }
      collapsedTitle={false}
    >
      <Box sx={{ p: '24px' }}>
        <Typography
          color={colors.neutral['600']}
          sx={{ pb: '16px', fontSize: '18px', fontWeight: '700', lineHeight: '28px' }}
        >
          Overview
        </Typography>
        <Typography variant="body1">
          {job.posting?.jobDescription ? renderTextWithNewlines(job.posting.jobDescription) : notAvailable}
        </Typography>
        <Typography variant="subtitle1" sx={{ p: '16px 0 8px' }}>
          Qualifications
        </Typography>
        <Typography variant="body1">
          {job.posting?.qualifications ? renderTextWithNewlines(job.posting.qualifications) : notAvailable}
        </Typography>
      </Box>
    </MinimizableSurveyAccordion>
  )
}

export default JobInformation
