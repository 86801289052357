import React from 'react'
import type { IconProps } from './IconProps'

export default function KeyboardArrowDown({ width = 24, height = 24, style, ...other }: IconProps) {
  return (
    <svg
      {...other}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path d="M6 9L12 15L18 9" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
