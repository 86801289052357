import { makeAutoObservable } from 'mobx'
import Candidate from './candidate'

export default class AssigneesFilter {
  selection = new Set<string>()

  constructor(
    private readonly source: { result?: Candidate[] | null },
    private readonly predicates: { filterFunctions: Array<(c: Candidate) => boolean> },
  ) {
    makeAutoObservable(this, undefined, { autoBind: true })
  }

  get otherPredicates() {
    return this.predicates.filterFunctions.filter((f) => f !== this.predicate)
  }

  get otherResult() {
    const filters = this.otherPredicates
    if (filters.length === 0) return this.source.result
    return this.source.result?.filter((c) => !filters.some((f) => !f(c)))
  }

  get counts() {
    return (this.otherResult || []).reduce((map, candidate) => {
      if (candidate.dto.assigneeId) {
        map.set(candidate.dto.assigneeId, (map.get(candidate.dto.assigneeId) || 0) + 1)
      }
      return map
    }, new Map<string, number>())
  }

  get predicate() {
    return (c: Candidate) => !!c.dto.assigneeId && this.selection.has(c.dto.assigneeId)
  }

  get empty() {
    return this.selection.size === 0
  }

  clear() {
    this.selection.clear()
  }
}
