import { QuestionTextBase, Serializer } from 'survey-core'
import { ISurvey } from 'base-interfaces'

export default class TextModel extends QuestionTextBase {
  static readonly TYPE = 'text'

  // eslint-disable-next-line class-methods-use-this
  getType(): string {
    return TextModel.TYPE
  }

  /**
   * Specifies whether the comment area automatically increases its height to accomodate multi-line content.
   *
   * Default value: `false` (inherited from `SurveyModel`'s [`autoGrowComment`](https://surveyjs.io/form-library/documentation/surveymodel#autoGrowComment) property)
   * @see allowResize
   */
  public get autoGrow(): boolean {
    return this.getPropertyValue('autoGrow') || (this.survey && this.survey.autoGrowComment)
  }

  public set autoGrow(val: boolean) {
    this.setPropertyValue('autoGrow', val)
  }

  /**
   * Specifies whether to display a resize handle for the comment area.
   *
   * Default value: `true` (inherited from `SurveyModel`'s [`allowResizeComment`](https://surveyjs.io/form-library/documentation/surveymodel#allowResizeComment) property)
   * @see autoGrow
   */
  public get allowResize(): boolean {
    return this.getPropertyValue('allowResize') && !!this.survey?.allowResizeComment
  }

  public set allowResize(val: boolean) {
    this.setPropertyValue('allowResize', val)
  }

  public get resizeStyle() {
    return this.allowResize ? 'both' : 'none'
  }

  get survey(): ISurvey & { allowResizeComment?: boolean } {
    return super.survey
  }

  get helperText() {
    return this.getPropertyValue('helperText', '')
  }

  set helperText(value: string) {
    this.setPropertyValue('helperText', value)
  }
}

Serializer.addClass(
  TextModel.TYPE,
  [
    { name: 'maxLength:number', default: -1 },
    {
      name: 'placeholder',
      alternativeName: 'placeHolder',
      serializationProperty: 'locPlaceholder',
    },
    { name: 'helperText' },
    {
      name: 'textUpdateMode',
      default: 'default',
      choices: ['default', 'onBlur', 'onTyping'],
    },
    { name: 'autoGrow:boolean' },
    { name: 'allowResize:boolean', default: true },
  ],
  () => new TextModel(''),
  'textbase',
)
