import { Box } from '@mui/material'
import React, { useCallback, useContext } from 'react'
import { observer } from 'mobx-react'
import CandidatesContext from '../context/CandidatesContext'
import Eraser from '../../../../common/icons/Eraser'
import SmallButton from '../../../../common/buttons/SmallButton'

const FilterResults = observer(() => {
  const { filter } = useContext(CandidatesContext)
  const count = filter.result?.length || 0
  const message = `${count} Result${count !== 1 ? 's' : ''}`
  return <span>{message}</span>
})

const ClearAll = observer(() => {
  const { filter } = useContext(CandidatesContext)
  const onClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault()
      filter.clear()
    },
    [filter],
  )
  return (
    <SmallButton
      onClick={onClick}
      color="secondary"
      startIcon={<Eraser width={16} height={16} />}
      disabled={filter.empty}
    >
      Clear all
    </SmallButton>
  )
})

function FilterActions() {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontSize: '14px',
        lineHeight: '20px',
      }}
    >
      <FilterResults />
      <ClearAll />
    </Box>
  )
}

export default FilterActions
