import { Box } from '@mui/material'
import React from 'react'
import { colors, palette } from '../../common/mui-theme'

function Container({ children }: { children?: React.ReactNode }) {
  return <Box sx={{ display: 'flex', gap: '24px', alignItems: 'center' }}>{children}</Box>
}

export default function SubNav({ left, right }: { left?: React.ReactNode; right?: React.ReactNode }) {
  return (
    <Box
      sx={{
        backgroundColor: colors.primary['100'],
        color: palette.secondary.main,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '16px 24px 16px 16px',
        gap: '16px',
      }}
    >
      <Container>{left}</Container>
      <Container>{right}</Container>
    </Box>
  )
}

export function Divider() {
  return (
    <Box
      sx={{
        width: '1px',
        height: '16px',
        backgroundColor: colors.primary['200'],
      }}
    />
  )
}
