export default function download(blob: Blob, fileName: string, target?: string) {
  const fileURL = window.URL.createObjectURL(blob)
  const alink = document.createElement('a')
  alink.href = fileURL
  alink.download = fileName
  if (target) alink.target = target
  alink.click()
}

export function getFileNameExtension(fileName: string) {
  const index = fileName.lastIndexOf('.')
  if (index < 0) return ''
  return fileName.substring(index)
}

export function getSeparator(index: number, extensionsSize: number) {
  const hasOxfordComma = extensionsSize > 2
  let separator: string
  if (index === 1) {
    separator = ''
  } else if (index === extensionsSize) {
    separator = hasOxfordComma ? ', or ' : ' or '
  } else {
    separator = ', '
  }
  return separator
}
