import { useSlate } from 'slate-react'
import { IconButtonProps } from '@mui/material/IconButton'
import { SxProps, useEventCallback } from '@mui/material'
import React from 'react'
import { isMarkActive, TextStyle, toggleMark } from './slate-rte'
import ToolbarButton from './ToolbarButton'

export default function MarkButton({
  textStyle,
  children,
  ...other
}: IconButtonProps & SxProps & { textStyle: TextStyle }) {
  const editor = useSlate()
  const toggled = isMarkActive(editor, textStyle)
  const onMouseDown = useEventCallback(() => {
    toggleMark(editor, textStyle)
  })
  return (
    <ToolbarButton toggled={toggled} onMouseDown={onMouseDown} {...other}>
      {children}
    </ToolbarButton>
  )
}
