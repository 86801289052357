import React from 'react'
import { QuestionCheckboxModel } from 'question_checkbox'
import { observer } from 'mobx-react'
import { ItemValue } from 'itemvalue'
import FilterAccordion, { FilterAccordionProps } from '../FilterAccordion'
import CheckboxAnswerFilter from '../../context/answers/checkbox-answer-filter'
import FilterCheckboxOption from './FilterCheckboxOption'

function CheckboxQuestionFilter({
  title,
  question,
  filter,
}: {
  title: FilterAccordionProps['title']
  question: QuestionCheckboxModel
  filter: CheckboxAnswerFilter
}) {
  const choices = (question.choices as Array<ItemValue>).map(({ value, text }) => ({ value, text }))
  if (question.hasNone) choices.push({ value: question.noneItem.value, text: question.noneItem.text })
  if (question.hasOther) choices.push({ value: question.otherItem.value, text: 'Other' })
  return (
    <FilterAccordion title={title} id={question.name} chip={filter.count || undefined}>
      {choices.map(({ value, text }) => (
        <FilterCheckboxOption key={value} name={text} value={value} filter={filter} />
      ))}
    </FilterAccordion>
  )
}

export default observer(CheckboxQuestionFilter)
