import { PublicClientApplication } from '@azure/msal-browser'
import customFetch from '../../common/fetch/custom-fetch'
import { HttpClient, PortalApi } from '../_generated/portal-api'
import appInsight, { SeverityLevel } from '../../common/app-insights'
import msal, { APP_INSIGHT_CUSTOM_PROPS } from './msal'

const client = new HttpClient<PublicClientApplication>({
  baseUrl: import.meta.env.VITE_PORTAL_API,
  securityWorker: async (auth) => {
    if (!auth) return {}
    const scopes = ['openid', 'profile', `api://${import.meta.env.VITE_AD_CLIENT_ID}/access_as_user`] // `api://${import.meta.env.VITE_AD_CLIENT_ID}/access_as_user`
    try {
      await auth.handleRedirectPromise()
    } catch (e) {
      appInsight.trackException(
        {
          exception: e as Error,
          severityLevel: SeverityLevel.Warning,
        },
        APP_INSIGHT_CUSTOM_PROPS,
      )
      await auth.loginRedirect({ scopes })
    }
    const accounts = auth.getAllAccounts()
    const account = accounts.find((a) => a.tenantId === import.meta.env.VITE_AD_TENANT_ID) || null
    if (!account) {
      await auth.loginRedirect({ scopes })
      return {}
    }
    const result = await auth
      .acquireTokenSilent({ account: account || undefined, scopes })
      .then((res) => {
        auth.setActiveAccount(res.account)
        appInsight.trackTrace(
          {
            message: `Token requested for ${account.username}`,
            severityLevel: SeverityLevel.Information,
          },
          APP_INSIGHT_CUSTOM_PROPS,
        )
        return res
      })
      .catch(async (e) => {
        appInsight.trackTrace({ message: e, severityLevel: SeverityLevel.Error }, APP_INSIGHT_CUSTOM_PROPS)
        await auth.loginRedirect({ scopes })
        return { accessToken: null }
      })
    return result.accessToken ? { headers: { Authorization: `Bearer ${result.accessToken}` } } : {}
  },
  customFetch,
})

client.setSecurityData(msal)

const portalApi = new PortalApi(client)

export default portalApi
