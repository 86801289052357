import { z } from 'zod'
import { PHONE_REGEX } from '../../../../common/components/MaskedPhoneInput'
import { ZIP_CODE_REGEX } from '../../../../common/components/MaskedZipCodeInput'
import { stateNamesSet } from '../../../../common/survey/us-address'
import { JobDetailsDto } from '../../../_generated/portal-api'

const jobDetailsSchema = z.object({
  employer: z.string().trim(),
  salary: z
    .number()
    .transform((n) => `${n}`)
    .or(z.string().transform((s) => parseFloat(s))),
  title: z.string().trim(),
  careerArea: z.string().trim(),
  shift: z.string().trim(),
  supervisorFirstName: z.string().trim(),
  supervisorLastName: z.string().trim(),
  supervisorEmail: z.string().trim().email('Please enter a valid e-mail address').optional().or(z.literal('')),
  supervisorOfficePhone: z
    .string()
    .trim()
    .regex(PHONE_REGEX, 'Enter a valid phone number')
    .or(z.literal(''))
    .nullish()
    .transform((s) => s || ''),
  supervisorDirectPhone: z
    .string()
    .trim()
    .regex(PHONE_REGEX, 'Enter a valid phone number')
    .or(z.literal(''))
    .nullish()
    .transform((s) => s || ''),
  addressLine1: z.string().trim().optional(),
  addressLine2: z.string().trim().optional(),
  city: z.string().trim().optional(),
  state: z
    .string()
    .trim()
    .refine((value) => stateNamesSet.has(value), 'Invalid state')
    .or(z.literal(''))
    .optional(),
  zip: z.string().trim().regex(ZIP_CODE_REGEX, 'Please enter a valid zip code').or(z.literal('')),
  additionalDetails: z.null().or(z.string().trim().optional()),
})

export type JobDetailsFields = keyof z.infer<typeof jobDetailsSchema>

export const notCountableJobDetailsFields = ['additionalDetails'] as const satisfies readonly JobDetailsFields[]
type NotCountableJobDetailsFields = (typeof notCountableJobDetailsFields)[number]

export type CountableJobDetailsFields = Exclude<JobDetailsFields, NotCountableJobDetailsFields>

export const countableJobDetailsFields = Object.keys(jobDetailsSchema.shape).filter(
  (key) => !notCountableJobDetailsFields.includes(key as NotCountableJobDetailsFields),
) as readonly CountableJobDetailsFields[]

export const optionalJobDetailsFields = [
  ...notCountableJobDetailsFields,
  'supervisorDirectPhone',
  'addressLine2',
] as const satisfies readonly JobDetailsFields[]

export type OptionalJobDetailsFields = (typeof optionalJobDetailsFields)[number]

export type RequiredJobDetailsFields = Exclude<JobDetailsFields, OptionalJobDetailsFields>

export const requiredJobDetailsFields = Object.keys(jobDetailsSchema.shape).filter(
  (key) => !optionalJobDetailsFields.includes(key as OptionalJobDetailsFields),
) as readonly RequiredJobDetailsFields[]

export function isCompleted(details: JobDetailsDto) {
  const parsed = jobDetailsSchema.safeParse(details)
  if (!parsed.success) {
    const hasErrorInRequiredField = parsed.error.errors.some((e) =>
      requiredJobDetailsFields.includes(e.path[0] as unknown as RequiredJobDetailsFields),
    )
    if (hasErrorInRequiredField) return false
  }
  const hasEmptyRequiredField = requiredJobDetailsFields.some((key) => !details[key])
  return !hasEmptyRequiredField
}

export default jobDetailsSchema
