import {
  Box,
  Card,
  CardContent,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuListProps,
  styled,
  Typography,
} from '@mui/material'
import React, { Children, MouseEventHandler, ReactNode } from 'react'
import { palette } from '../../common/mui-theme'
import ChevronLeft from '../../common/icons/ChevronLeft'

export const StyledMenuItem = styled(MenuItem)(() => ({
  padding: '16px 0',
  display: 'flex',
  columnGap: '8px',
  flexDirection: 'row',
  alignItems: 'center',
  marginLeft: '-7px',
  '.MuiTypography-root': {
    color: palette.text.primary,
  },
  '.MuiListItemIcon-root': {
    minWidth: '24px',
    color: palette.grey['200'],
  },
  '& > svg': {
    width: '0',
    transition: 'width 0.3s',
  },
  '&:hover': {
    backgroundColor: palette.common.white,
    '.MuiTypography-root': {
      color: palette.primary.main,
    },
    '.MuiListItemIcon-root': {
      color: palette.primary.main,
    },
    '& > svg': {
      color: palette.primary.main,
      width: '24px',
    },
  },
  '&.MuiMenuItem-root.Mui-selected': {
    backgroundColor: palette.common.white,
    '.MuiTypography-root': {
      color: palette.grey['600'],
    },
    '.MuiListItemIcon-root': {
      color: palette.grey['600'],
    },
    '& > svg': {
      color: palette.grey['600'],
      width: '24px',
    },
  },
}))

export function RightRailItem({
  icon,
  title,
  badge,
  onClick,
  selected = false,
  disabled = false,
}: {
  icon?: ReactNode
  title: string
  badge?: ReactNode
  onClick: MouseEventHandler<HTMLLIElement>
  selected?: boolean
  disabled?: boolean
}) {
  return (
    <StyledMenuItem onClick={onClick} selected={selected} disabled={disabled}>
      <ChevronLeft />
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      <ListItemText primary={title} sx={{ m: 0 }} />
      {badge && <ListItemIcon>{badge}</ListItemIcon>}
    </StyledMenuItem>
  )
}

export function Total({ title, badge }: { title: string; badge: ReactNode }) {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: palette.grey['50'],
        padding: '16px 24px',
      }}
    >
      <Typography variant="subtitle1" sx={{ color: palette.grey['600'] }}>
        {title}
      </Typography>
      {badge}
    </Box>
  )
}

export default function PortalRightRail({
  children,
  title,
  total,
}: MenuListProps & { title: string; total?: ReactNode }) {
  const arrayChildren = Children.toArray(children)

  for (let i = arrayChildren.length - 1; i > 0; --i) {
    arrayChildren.splice(i, 0, <Divider key={`div_${i}`} sx={{ '&&': { m: '4px 0' } }} />)
  }

  return (
    <Card sx={{ borderRadius: '16px' }}>
      <CardContent
        sx={{
          pb: 0,
          '&:last-child': {
            pb: 0,
          },
        }}
      >
        <Typography variant="h6" sx={{ pb: '17px' }}>
          {title}
        </Typography>
        {arrayChildren}
      </CardContent>
      {total}
    </Card>
  )
}
