import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  Box,
  Chip,
  Collapse,
  Typography,
} from '@mui/material'
import React, { SyntheticEvent, useCallback, useEffect, useRef } from 'react'
import { observer } from 'mobx-react'
import { useLocation } from 'react-router-dom'
import ChevronRight from '../../../common/icons/ChevronRight'
import { colors } from '../../../common/mui-theme'
import nop from '../../../common/nop'
import { getTopOffset } from '../../../common/scroll-offset'

const ellipsisStyle = {
  maxWidth: '300px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}

export type AnswersAccordionProps = AccordionProps & {
  title: string
  subtitle?: string
  details?: React.ReactNode
  expanded: boolean
  setExpanded: (expanded: boolean) => void
  allQuestionCount?: number
  answeredQuestionCount?: number
  headerBadge?: React.ReactNode
  collapsedContent?: React.ReactNode
  collapsedTitle?: boolean
}

function AnswersSectionAccordion({
  children,
  title,
  subtitle,
  details,
  expanded = true,
  allQuestionCount,
  answeredQuestionCount,
  setExpanded,
  headerBadge,
  collapsedContent,
  collapsedTitle = true,
  ...other
}: AnswersAccordionProps) {
  const location = useLocation()
  const accordionRef = useRef<HTMLDivElement>(null)
  const scrollToTop = useCallback(() => {
    if (accordionRef.current) {
      window.scrollTo({ top: getTopOffset(accordionRef.current), behavior: 'smooth' })
    }
  }, [])

  useEffect(() => {
    if (!(other.id && `#${other.id}` === location.hash)) {
      return nop
    }
    const timeout = setTimeout(scrollToTop, 250)
    setExpanded(true)
    return () => clearTimeout(timeout)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, other.id])

  useEffect(() => {
    const y = window.scrollY
    const delay = 16
    let t = 0
    if (expanded) {
      const fixScroll = () => {
        if (window.scrollY > y) {
          window.scrollTo({ top: y })
          t = window.setTimeout(fixScroll, delay)
        }
      }

      t = window.setTimeout(fixScroll, 32)
    } else if (accordionRef.current) {
      const top = getTopOffset(accordionRef.current)
      if (top < y) window.scrollTo({ top })
    }
    return () => clearTimeout(t)
  }, [expanded])

  const onChange = useCallback(
    (event: SyntheticEvent, expandedState: boolean) => setExpanded(expandedState),
    [setExpanded],
  )

  return (
    <Accordion
      {...other}
      sx={{
        ...other.sx,
        '&.MuiPaper-root.MuiPaper-outlined.MuiAccordion-root:first-of-type': { marginTop: 0 },
        '&&': { borderRadius: '16px', border: `1px solid ${colors.neutral[100]}`, margin: '16px 0' },
        '&::before': {
          height: 0,
        },
        '&&.MuiAccordion-root:hover': {
          border: `${colors.primary['500']} 1px solid`,
          borderRadius: '16px',
          boxShadow: '0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)',
        },
      }}
      disableGutters={false}
      expanded={expanded}
      onChange={onChange}
      ref={accordionRef}
    >
      <div>
        <AccordionSummary
          sx={{
            justifyContent: 'left',
            p: '0',
            '.MuiAccordionSummary-content': {
              margin: '24px 0',
              flexDirection: 'column',
            },
            '&.Mui-expanded': {
              borderBottom: `1px solid ${colors.neutral[100]}`,
              minHeight: '48px',
            },
          }}
        >
          <Box sx={{ flexGrow: 1, p: '0 24px' }}>
            <Box sx={{ display: 'flex', justifyContent: collapsedTitle ? 'normal' : 'space-between' }}>
              <Typography variant="h6" sx={collapsedTitle ? { ...ellipsisStyle } : undefined}>
                {title}
              </Typography>
              {!!allQuestionCount && (
                <Chip
                  size="small"
                  label={`${answeredQuestionCount || 0}/${allQuestionCount}`}
                  sx={{ marginLeft: '10px', marginRight: '10px', marginTop: '5px' }}
                />
              )}
              <ChevronRight
                width={20}
                height={20}
                style={{
                  transform: expanded ? 'rotate(90deg) translateX(0.5em)' : 'translateY(0.5em)',
                  transition: 'transform 0.3s ease',
                  minWidth: '20px',
                }}
              />
            </Box>
            {subtitle && (
              <Typography variant="body1" sx={{ color: colors.primary['500'], ...ellipsisStyle }}>
                {subtitle}
              </Typography>
            )}
          </Box>
          {details}
          <div style={{ position: 'absolute', right: '24px' }}>{headerBadge}</div>
        </AccordionSummary>
        {!!collapsedContent && (
          <Collapse in={!expanded}>
            <div style={{ display: expanded ? 'none' : '', borderTop: `1px solid ${colors.neutral[100]}` }}>
              {collapsedContent}
            </div>
          </Collapse>
        )}
      </div>
      <AccordionDetails
        sx={{
          display: 'flex',
          '&&': {
            padding: '0',
          },
          flexDirection: 'column',
          '.sd-body.sd-body--static': {
            maxWidth: 'none',
            margin: '-20px 0',
          },
          '.sd-page': {
            '.sd-row.sd-clearfix': {
              margin: 0,
            },
            '&.sd-body__page': {
              paddingTop: 0,
              margin: '-20px 0',
            },
            ':not(.svc-row)': {
              '.sd-row--multiple > div': {
                padding: 0,
                '.sv-title-actions': { width: '90%' },
                '.sd-comment': { width: '90%' },
              },
              '.sd-row--multiple': { padding: 0, boxShadow: 'none' },
            },
            '.sd-page__row::before': {
              background: 'transparent',
              margin: 0,
              backgroundPosition: 'top',
              backgroundImage: `linear-gradient(to right, #ffffff 80%, ${colors.neutral[200]} 0%)`,
              backgroundSize: '5px 1px',
              backgroundRepeat: 'repeat-x',
            },
            padding: 0,
            '.sd-element': {
              '.MuiTextField-root.sd-input.sd-comment': {
                backgroundColor: 'transparent',
                boxShadow: 'none',
              },
              padding: '20px 0 20px 0',
              '.sd-question__header': {
                padding: 0,
                'h5.sd-title': {
                  paddingBottom: 0,
                  '.sd-question__required-text': {
                    display: 'none',
                  },
                  'span.sv-string-viewer': {
                    padding: 0,
                    color: colors.neutral[600],
                    fontSize: '16px',
                    fontWeight: 700,
                    lineHeight: '24px',
                    textAlign: 'left',
                  },
                },
              },
            },
          },
        }}
      >
        <div style={{ display: expanded ? undefined : 'none' }}>{children}</div>
      </AccordionDetails>
    </Accordion>
  )
}

export default observer(AnswersSectionAccordion)
