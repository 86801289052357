import { debounce, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import { observer } from 'mobx-react'
import React, { useCallback, useEffect } from 'react'
import { action, reaction, runInAction } from 'mobx'
import UserNotes from './context/user-notes'
import TextLabel from '../Label'
import TextValue from '../TextValue'
import NoteUpdatedText from './LastEditedText'
import css from './NoteEditorDialog.module.css'
import NoteEditor from './NoteEditor'
import { usePortalApi } from '../../api/PortalApiContext'
import DialogCloseButton from '../../../common/components/dialog/DialogCloseButton'
import nop from '../../../common/nop'

type Notes = {
  notes: UserNotes
}

function NoteEditorDialogTitle({ notes }: Notes) {
  const note = notes.inEdit
  const closeDialog = useCallback(() => {
    runInAction(() => {
      // eslint-disable-next-line no-param-reassign
      notes.inEdit = null
    })
  }, [notes])

  return (
    <DialogTitle className={css.title}>
      <table>
        <tbody>
          <tr>
            <th>
              <TextLabel>Created by</TextLabel>
            </th>
            <td>
              <TextValue>{note?.dto.createdBy}</TextValue>
            </td>
          </tr>
          <tr>
            <th>
              <TextLabel>Last edited</TextLabel>
            </th>
            <td>
              <TextValue>{note && <NoteUpdatedText note={note} />}</TextValue>
            </td>
          </tr>
        </tbody>
      </table>
      <DialogCloseButton onClick={closeDialog} />
    </DialogTitle>
  )
}

function NoteEditorDialog({ notes }: Notes) {
  const api = usePortalApi()
  const note = notes.inEdit
  const onClose = useCallback(
    () =>
      runInAction(() => {
        // eslint-disable-next-line no-param-reassign
        notes.inEdit = null
      }),
    [notes],
  )

  useEffect(() => {
    if (note === null) return nop
    const updateTimeStamp = action('updateTimeStamp', () => {
      note.dto.timeStamp = new Date().toISOString()
    })
    const create = () =>
      api.notes
        .createNote(notes.userId, { title: note.dto.title, content: note.dto.content })
        .then((r) => r.data)
        .then((dto) =>
          runInAction(() => {
            note.dto.id = dto.id
            note.dto.updatedBy = dto.updatedBy
            note.dto.createdBy = dto.createdBy
            note.dto.timeStamp = dto.timeStamp
          }),
        )
    const disposeTitleUpdate = reaction(
      () => note.dto.title,
      debounce(() => {
        if (note.dto.id) {
          api.notes.updateNote(note.dto.id, { title: note.dto.title }).then(updateTimeStamp)
        } else {
          create()
        }
      }, 5000),
    )
    const disposeContentUpdate = reaction(
      () => note.dto.content,
      debounce(() => {
        if (note.dto.id) {
          api.notes.updateNote(note.dto.id, { content: note.dto.content }).then(updateTimeStamp)
        } else {
          create()
        }
      }, 5000),
    )
    return () => {
      disposeTitleUpdate()
      disposeContentUpdate()
    }
  }, [api.notes, note, notes.userId])

  return (
    <Dialog open={!!note} fullWidth maxWidth="md" onClose={onClose}>
      <NoteEditorDialogTitle notes={notes} />
      <DialogContent className={css.content} sx={{ p: '0', height: '60vh', display: 'flex', alignItems: 'stretch' }}>
        {note && <NoteEditor note={note} />}
      </DialogContent>
      <DialogActions className={css.actions}>
        {/* <Notification /> */}
        <Typography fontSize="14px" lineHeight="22xp">
          All changes saved
        </Typography>
      </DialogActions>
    </Dialog>
  )
}

export default observer(NoteEditorDialog)
