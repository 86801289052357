import { Chip } from '@mui/material'
import React, { useCallback, useContext } from 'react'
import { observer } from 'mobx-react'
import { useNavigate } from 'react-router-dom'
import PortalRightRail, { RightRailItem, Total } from '../PortalRightRail'
import IntakeContext, { IntakeQuestionGroupItem, intakeQuestionGroupList } from './context/IntakeContext'
import { colors } from '../../../common/mui-theme'
import CandidateProfileContext from '../candidate-profile/context/CandidateProfileContext'

const Completion = observer(() => {
  const { allAnsweredQuestionPercentage } = useContext(IntakeContext)
  return (
    <Total
      title="Completion"
      badge={<Chip size="small" label={`${allAnsweredQuestionPercentage}% Complete`} color="primary" />}
    />
  )
})

const IntakeQuestionsRightRailItem = observer(({ item: { title, id } }: { item: IntakeQuestionGroupItem }) => {
  const { candidate } = useContext(CandidateProfileContext)
  const { intakeQuestionGroup } = useContext(IntakeContext)
  const group = intakeQuestionGroup.get(title)

  const navigate = useNavigate()

  const handleClick = useCallback(() => {
    navigate(`/candidates/${candidate?.dto.userId}#${id}`, { replace: true })
  }, [candidate?.dto.userId, id, navigate])

  return (
    <RightRailItem
      title={title}
      onClick={handleClick}
      badge={
        group ? (
          <Chip
            size="small"
            label={`${group.answeredQuestionCount}/${group.allQuestionCount}`}
            sx={{ backgroundColor: colors.neutral['75'] }}
          />
        ) : undefined
      }
    />
  )
})

export default function CandidateQuestionsRightRail() {
  return (
    <PortalRightRail title="Candidate Questions" total={<Completion />}>
      {intakeQuestionGroupList.map((item) => (
        <IntakeQuestionsRightRailItem key={item.title} item={item} />
      ))}
    </PortalRightRail>
  )
}
