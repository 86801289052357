import { observer, Observer } from 'mobx-react'
import React, { useMemo } from 'react'
import DelayedProgressBar from '../../components/DelayedProgressBar'
import PageContentContainer from '../../components/PageContentContainer'
import JobPostingsGrid from '../../components/jobs/grid/JobPostingsGrid'
import JobPostingsGridContext, { JobPostingsGridContextValue } from '../../components/jobs/grid/JobPostingsGridContext'
import { useAdminJobPostings } from '../../components/jobs/context/JobPostingsContext'

function JobsPage() {
  const ctx = useAdminJobPostings()
  const { store } = ctx
  const gridCtx = useMemo(() => new JobPostingsGridContextValue(ctx, true), [ctx])
  return (
    <JobPostingsGridContext.Provider value={gridCtx}>
      <Observer>
        {() =>
          store.isLoading ? (
            <DelayedProgressBar />
          ) : (
            <PageContentContainer>
              <JobPostingsGrid />
            </PageContentContainer>
          )
        }
      </Observer>
    </JobPostingsGridContext.Provider>
  )
}

export default observer(JobsPage)
