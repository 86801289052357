import { SurveyModel } from 'survey-core'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { runInAction, set } from 'mobx'
import { Box } from '@mui/material'
import { observer } from 'mobx-react'
import MuiSurvey from '../../../common/survey/MuiSurvey'
import InnerNote from '../InnerNote'
import MinimizableSurveyAccordion from '../survey-answers/MinimizableSurveyAccordion'
import MinimizedView from '../placement/tabs/MinimizedView'
import IntakeContext, { RetentionQuestionGroupItem } from '../survey/context/IntakeContext'
import CandidateProfileContext from '../candidate-profile/context/CandidateProfileContext'
import SavingBadge from '../SavingBadge'
import { IDPAnswers } from './retention-surveys'

function IndividualDevelopmentPlanSurvey({
  item: { id, title },
  model,
  feedback,
}: {
  item: RetentionQuestionGroupItem
  model: SurveyModel
  feedback: SurveyModel
}) {
  const { candidate } = useContext(CandidateProfileContext)
  const { retentionQuestionGroup } = useContext(IntakeContext)
  const group = retentionQuestionGroup.get(title)
  const [expanded, setExpanded] = useState(true)
  const expand = useCallback(() => setExpanded(true), [])

  useEffect(() => {
    model.setDataCore(candidate.answers)
    feedback.setDataCore(candidate.answers)

    const onValueChanged = (
      sender: SurveyModel,
      options: {
        name: keyof IDPAnswers
        value: IDPAnswers[keyof IDPAnswers]
      },
    ) => {
      runInAction(() => {
        if (group) {
          group.saving = true
          set(candidate.answers, { [options.name]: options.value })
        }
      })
    }

    model.onValueChanged.add(onValueChanged)
    feedback.onValueChanged.add(onValueChanged)
    return () => {
      model.onValueChanged.remove(onValueChanged)
      feedback.onValueChanged.remove(onValueChanged)
    }
  }, [candidate.answers, feedback, group, model])

  const first = (candidate.answers['Action Plan'] || [])[0]
  const { Goal: goal, Action: action, 'Target date': targetDate } = first || {}
  const items = [
    { title: 'Goal', value: goal },
    { title: 'Action to Meet Goal', value: action },
    { title: 'Target Date', value: targetDate },
  ].filter((item) => item.value)

  const collapsedContent = items.length ? <MinimizedView sx={{ p: '24px' }} onEdit={expand} items={items} /> : null

  return (
    <MinimizableSurveyAccordion
      id={id}
      title={title}
      expanded={expanded}
      setExpanded={setExpanded}
      saving={!!group?.saving}
      minimizedHeaderBadge={<SavingBadge saving={!!group?.saving} />}
      collapsedContent={collapsedContent}
    >
      <>
        <Box sx={{ p: '24px' }}>
          <MuiSurvey model={model} hideNavigation />
        </Box>
        <InnerNote title="Staff Feedback">
          <MuiSurvey model={feedback} hideNavigation />
        </InnerNote>
      </>
    </MinimizableSurveyAccordion>
  )
}

export default observer(IndividualDevelopmentPlanSurvey)
