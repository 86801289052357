import { makeAutoObservable, ObservableMap } from 'mobx'
import { createContext } from 'react'
import IntakeQuestionGroup from './IntakeQuestionGroup'

export const intakeQuestionGroupList = [
  {
    id: 'overview-n-goals',
    title: 'Overview & Goals',
  },
  {
    id: 'education-n-experience',
    title: 'Education & Experience',
  },
  {
    id: 'employment-n-preferences',
    title: 'Employment & Preferences',
  },
  {
    id: 'candidate-background',
    title: 'Candidate background',
  },
  {
    id: 'self-identification',
    title: 'Self Identification',
  },
] as const

export const retentionQuestionGroupList = [
  {
    id: 'individual-development-plan',
    title: 'Individual Development Plan',
  },
] as const

export type IntakeQuestionGroupItem = (typeof intakeQuestionGroupList)[number]
export type IntakeQuestionGroupTitle = (typeof intakeQuestionGroupList)[number]['title']

export type RetentionQuestionGroupItem = (typeof retentionQuestionGroupList)[number]
export type RetentionQuestionGroupTitle = (typeof retentionQuestionGroupList)[number]['title']

export class IntakeContextClass {
  intakeQuestionGroup = new ObservableMap<IntakeQuestionGroupTitle, IntakeQuestionGroup>()

  retentionQuestionGroup = new ObservableMap<RetentionQuestionGroupTitle, IntakeQuestionGroup>()

  constructor(public readonly candidateId: number) {
    intakeQuestionGroupList.forEach(({ title }) => {
      this.intakeQuestionGroup.set(title, new IntakeQuestionGroup())
    })
    retentionQuestionGroupList.forEach(({ title }) => {
      this.retentionQuestionGroup.set(title, new IntakeQuestionGroup())
    })
    makeAutoObservable(this, undefined, { autoBind: true })
  }

  get allAnsweredQuestionPercentage() {
    let allQuestionCount = 0
    let allAnsweredQuestionCount = 0

    this.intakeQuestionGroup.forEach((value) => {
      allQuestionCount += value.allQuestionCount
      allAnsweredQuestionCount += value.answeredQuestionCount
    })
    if (allQuestionCount === 0) return 0
    return Math.round((allAnsweredQuestionCount * 100) / allQuestionCount)
  }

  setSaved() {
    this.intakeQuestionGroup.forEach((value) => value.setSaving(false))
    this.retentionQuestionGroup.forEach((value) => value.setSaving(false))
  }
}

export const intakeContext = new IntakeContextClass(0)
const IntakeContext = createContext(intakeContext)

export default IntakeContext
