import React, { useCallback, useContext, useState } from 'react'
import { Button } from '@mui/material'
import AlertsContext from '../../common/alerts/AlertsContext'
import download from '../../common/download-blob'
import File from '../../common/icons/File'
import { HttpResponse } from '../_generated/portal-api'

export default function DownloadButton({
  name,
  downloader,
  disabled,
}: {
  name: string
  downloader: () => Promise<HttpResponse<File, File>>
  disabled?: boolean
}) {
  const [downloading, setDownloading] = useState(false)
  const ctx = useContext(AlertsContext)
  const { addAlert } = ctx // Destructuring here

  const downloadFile = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()
      setDownloading(true)
      downloader()
        .then((r: HttpResponse<File, File>) => r.blob())
        .then((blob: Blob) => download(blob, name, '_blank'))
        .catch(() => {
          addAlert('warning', 'Download File', `Unable to download ${name} file`, 'system', 'dismiss') // Using the destructured property here
        })
        .finally(() => {
          setDownloading(false)
        })
    },
    [downloader, name, addAlert],
  ) // Updated dependencies

  return (
    <Button onClick={downloadFile} aria-label="Export Candidate Job Info" disabled={disabled || downloading}>
      Export Candidate Job Info
    </Button>
  )
}
