import { SurveyValidator, ValidatorResult } from 'survey-core'
import FieldError from './field-error'

export default class RegexFieldValidator<T> extends SurveyValidator {
  constructor(public readonly field: keyof T, public readonly errorMessage: string, public readonly regex: string) {
    super()
  }

  override validate(value: T): ValidatorResult {
    const fieldValue = value[this.field]
    const error =
      typeof fieldValue === 'string' && fieldValue?.match(this.regex)
        ? undefined
        : new FieldError(String(this.field), this.errorMessage)
    return new ValidatorResult(value[this.field], error)
  }
}
