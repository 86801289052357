import XLSX, { WorkSheet } from 'xlsx-js-style'
import template from '../../../resources/candidates_export_template.xlsx?url'
import Candidate from './candidate'

const headerStyle = {
  font: { name: 'Arial', sz: 11 },
  fill: {
    bgColor: {
      rgb: 'FEEDE6',
    },
    fgColor: {
      rgb: 'FEEDE6',
    },
    patternType: 'solid',
  },
  border: {
    top: { style: 'thin', color: { rgb: 'D9D9D9' } },
    bottom: { style: 'thin', color: { rgb: 'D9D9D9' } },
    left: { style: 'thin', color: { rgb: 'D9D9D9' } },
    right: { style: 'thin', color: { rgb: 'D9D9D9' } },
  },
}

const cellStyle = {
  font: { name: 'Arial', sz: 10 },
}

const cellAddrRegex = '^(?<column>[A-Z]+)(?<row>[1-9]\\d*)$'

function formatDate(date: Date) {
  return [
    (date.getMonth() + 1).toString().padStart(2, '0'),
    date.getDate().toString().padStart(2, '0'),
    date.getFullYear(),
  ].join('-')
}

function getHeader(sheet: WorkSheet): string[] {
  return XLSX.utils.sheet_to_json(sheet, { header: 1 }).shift() as string[]
}

function applyStyle(ws: WorkSheet) {
  Object.keys(ws)
    .filter((addr) => addr[0] !== '!')
    .forEach((addr) => {
      // eslint-disable-next-line no-param-reassign
      if (addr.match(cellAddrRegex)?.groups?.row === '1') ws[addr].s = headerStyle
      // eslint-disable-next-line no-param-reassign
      else ws[addr].s = cellStyle
    })
}

function toExportedCandidate(candidate: Candidate, excludeAssignee: boolean) {
  return {
    Candidate: candidate.fullName,
    'Email Address': candidate.dto.email,
    'Staff Assignee': excludeAssignee ? '' : candidate.assigneeLabel,
    Experience: candidate.experience,
    Applied: candidate.applied ? candidate.applied : 'None',
    'Last Activity': candidate.lastActivity ? candidate.lastActivity : 'None',
  }
}

export default async function exportToExcel(candidates: Candidate[], excludeAssignee: boolean) {
  fetch(template)
    .then((response) => response.blob())
    .then((blob) => blob.arrayBuffer())
    .then((arr) => {
      const wb = XLSX.read(arr, {
        type: 'array',
        cellStyles: true,
      })

      const ws = wb.Sheets[wb.SheetNames[0]]
      const header = getHeader(ws)

      if (excludeAssignee && ws['!cols']) {
        ws['!cols'][header.indexOf('Staff Assignee')] = { hidden: true }
      }

      const exportData = candidates.map((c) => toExportedCandidate(c, excludeAssignee))
      XLSX.utils.sheet_add_json(ws, exportData, {
        origin: -1,
        header,
        skipHeader: true,
        dateNF: 'd-mmm-yyyy;@',
        cellDates: true,
      })

      applyStyle(ws)
      XLSX.writeFile(wb, `Candidate Export ${formatDate(new Date())}.xlsx`, { cellStyles: true })
    })
}
