import React from 'react'

import css from './PageContentContainer.module.css'

export default function PageContentContainer({ children }: React.PropsWithChildren) {
  return (
    <div className={css.wrapper}>
      <div className={css.container}>{children}</div>
    </div>
  )
}
