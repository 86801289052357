import React from 'react'
import { PanelModel, Question, QuestionPanelDynamicModel, SurveyModel } from 'survey-core'
import { SurveyPanel } from 'survey-react-ui'

export default class MuiPanelDynamicItem extends SurveyPanel {
  divRef = React.createRef<HTMLDivElement>()

  protected handleRemove = (e: React.FocusEvent) => {
    const isFocusInside =
      this.divRef.current?.contains(e.relatedTarget) || // is inside panel
      e.relatedTarget?.closest('.MuiDialog-root') // or mui dialog was opened, e.g. DatePicker
    if (!isFocusInside) {
      const question = this.panel.parentQuestion
      const panel = this.panel as PanelModel
      const hasValue = panel.questions && panel.questions.some((value: Question) => value?.value)
      if (!hasValue && question.panelCount - question.minPanelCount > 0) {
        question.removePanel(panel)
      }
    }
  }

  private get question(): QuestionPanelDynamicModel {
    return this.props.question
  }

  private get index(): number {
    return this.props.index
  }

  protected getSurvey(): SurveyModel | null {
    return this.question ? (this.question.survey as SurveyModel) : null
  }

  protected showSeparator(index: number): boolean {
    return this.question.isRenderModeList && index < this.question.visiblePanelCount - 1
  }

  public render() {
    const panel = super.render()
    const separator: JSX.Element | null = this.showSeparator(this.index) ? (
      <hr className={this.question.cssClasses.separator} />
    ) : null
    return (
      <>
        <div ref={this.divRef} className={this.question.getPanelWrapperCss()} onBlur={this.handleRemove}>
          {panel}
        </div>
        {separator}
      </>
    )
  }
}
