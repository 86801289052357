import React, { useContext, useEffect, useMemo } from 'react'
import { SurveyModel } from 'survey-core'
import { educationAndExperience, educationAndExperienceFeedback } from '../../../demo/survey-model'
import IntakeSurvey from './IntakeSurvey'
import { enableEditMode } from '../../utils/survey-utils'
import { createOnDownloadFileHandler, createOnUploadFilesHandler } from '../../../common/survey/MuiFileQuestion'
import { usePortalApi } from '../../api/PortalApiContext'
import { intakeQuestionGroupList } from './context/IntakeContext'
import CandidateProfileContext from '../candidate-profile/context/CandidateProfileContext'
import { Attachment } from '../../_generated/portal-api'
import nop from '../../../common/nop'

export default function EducationAndExperienceIntake() {
  const model = useMemo(() => {
    const newModel = new SurveyModel(educationAndExperience)
    enableEditMode(newModel.getAllQuestions(), newModel)
    return newModel
  }, [])

  const feedback = useMemo(() => new SurveyModel(educationAndExperienceFeedback), [])

  const api = usePortalApi()

  const { candidate } = useContext(CandidateProfileContext)

  useEffect(() => {
    if (!candidate?.dto.userId) return nop
    const onUploadFiles = createOnUploadFilesHandler((file) =>
      api.files
        .uploadAttachment(candidate?.dto.userId, { file })
        .then((response) => response.json())
        .then((upload: Attachment) => ({ file, content: { id: upload.id, size: file.size } })),
    )
    const onDownloadFile = createOnDownloadFileHandler((fileId: number) =>
      api.files.downloadAttachment(fileId).then((response) => response.blob()),
    )

    model.onDownloadFile.add(onDownloadFile)
    model.onUploadFiles.add(onUploadFiles)
    return () => {
      model.onDownloadFile.remove(onDownloadFile)
      model.onUploadFiles.remove(onUploadFiles)
    }
  }, [api.files, candidate?.dto?.userId, model])

  return <IntakeSurvey item={intakeQuestionGroupList[1]} model={model} feedback={feedback} />
}
