import { Checkbox, IconProps, ListItemText, MenuItem, SelectProps, TextField } from '@mui/material'
import React, { forwardRef } from 'react'
import { MenuItemProps } from '@mui/material/MenuItem/MenuItem'
import KeyboardArrowDown from '../icons/KeyboardArrowDown'
import Check from '../icons/Check'
import muiTheme, { palette } from '../mui-theme'

function ArrowDownIcon(props: IconProps) {
  return (
    <KeyboardArrowDown
      {...props}
      width={20}
      height={20}
      style={{ pointerEvents: 'none', position: 'absolute', right: '8px' }}
    />
  )
}

export function SelectMenuItem({ children, selected, ...other }: MenuItemProps): JSX.Element {
  return (
    <MenuItem {...other} sx={{ border: selected ? `1px solid ${palette.primary.light}` : 'none' }}>
      <ListItemText primary={children} />
      {selected && <Check style={{ color: muiTheme.palette.primary.main, display: 'flex', marginLeft: 'auto' }} />}
    </MenuItem>
  )
}

export function SelectCheckboxMenuItem({ children, selected, ...other }: MenuItemProps): JSX.Element {
  return (
    <MenuItem {...other} sx={{ p: 0 }}>
      <Checkbox checked={selected} />
      <ListItemText primary={children} />
    </MenuItem>
  )
}

// eslint-disable-next-line react/display-name
const CustomSelect = forwardRef<HTMLElement, SelectProps>(
  ({ children, label, onChange, ...other }: SelectProps, ref) => (
    <TextField select label={label} SelectProps={{ IconComponent: ArrowDownIcon, onChange, ref, ...other }}>
      {children}
    </TextField>
  ),
)
export default CustomSelect
