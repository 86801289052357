import { Box, debounce } from '@mui/material'
import React, { useCallback, useContext, useEffect } from 'react'
import { autorun, runInAction } from 'mobx'
import NoteListCard from './notes/NoteListCard'
import OverviewAndGoalsIntake from './survey/OverviewAndGoalsIntake'
import IntakeContext from './survey/context/IntakeContext'
import { useUpdateApplicationAnswers } from '../api/hooks/candidateHooks'
import EducationAndExperienceIntake from './survey/EducationAndExperience'
import CandidateBackground from './survey/CandidateBackground'
import CandidateQuestionsRightRail from './survey/CandidateQuestionsRightRail'
import SelfIdentificationIntake from './survey/SelfIdentificationIntake'
import EmploymentAndPreferencesIntake from './survey/EmploymentAndPreferences'
import RightRail from './RightRail'
import RightRailContainer from './RightRailContainer'
import CandidateProfileContext from './candidate-profile/context/CandidateProfileContext'
import TabContentContainer from './TabContentContainer'

export default function CandidateIntake() {
  const { candidate } = useContext(CandidateProfileContext)
  const { setSaved } = useContext(IntakeContext)

  const updateProfileAnswers = useUpdateApplicationAnswers(candidate.dto.userId)

  const save = useCallback(
    (json: string) => {
      const updatedProfileAnswers = {
        workflowStateId: candidate.dto.workflowStateId,
        resultJsonString: json,
      }
      updateProfileAnswers.mutateAsync(updatedProfileAnswers).then(() => setSaved())
    },
    [candidate.dto.workflowStateId, setSaved, updateProfileAnswers],
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const saveDebounced = useCallback(debounce(save, 1500), [save])

  useEffect(() => {
    let firstRun = true
    return autorun(() => {
      const json = JSON.stringify(candidate.answers)
      if (firstRun) {
        firstRun = false
      }
      if (candidate.dto.resultJsonString !== json) {
        setTimeout(() => {
          runInAction(() => {
            candidate.dto.resultJsonString = json
          })
          saveDebounced(json)
        }, 0)
      }
    })
  }, [candidate.answers, candidate.dto, saveDebounced])

  return (
    <TabContentContainer>
      <Box sx={{ flex: 1, mb: '40px' }}>
        <OverviewAndGoalsIntake />
        <EducationAndExperienceIntake />
        <EmploymentAndPreferencesIntake />
        <CandidateBackground />
        <SelfIdentificationIntake />
      </Box>

      <RightRail>
        <RightRailContainer>
          <NoteListCard />
          <CandidateQuestionsRightRail />
        </RightRailContainer>
      </RightRail>
    </TabContentContainer>
  )
}
