import React from 'react'
import AnswersSectionAccordion, { AnswersAccordionProps } from './AnswersSectionAccordion'
import SavingBadge from '../SavingBadge'

type ExcludedProps = 'headerBadge'

export type CollapsableSurveyAccordionProps = Omit<AnswersAccordionProps, ExcludedProps> & {
  saving: boolean
}

export default function CollapsableSurveyAccordion({ children, saving, ...props }: CollapsableSurveyAccordionProps) {
  return (
    <AnswersSectionAccordion {...props} headerBadge={<SavingBadge saving={saving} />}>
      {children}
    </AnswersSectionAccordion>
  )
}
