import { makeAutoObservable } from 'mobx'
import Candidate from '../candidate'
import { RadioQuestionName } from '../../../../../demo/survey-model'

export default class RadioAnswerFilter {
  selection = new Set<string>()

  constructor(
    private readonly source: { result?: Candidate[] | null },
    private readonly predicates: { filterFunctions: Array<(c: Candidate) => boolean> },
    public readonly name: RadioQuestionName,
  ) {
    makeAutoObservable(this, undefined, { autoBind: true })
  }

  get predicate() {
    return (c: Candidate) => {
      const v = c.answers[this.name]
      return !!v && this.selection.has(v)
    }
  }

  get count() {
    return this.selection.size
  }

  get otherPredicates() {
    return this.predicates.filterFunctions.filter((f) => f !== this.predicate)
  }

  get otherResult() {
    const filters = this.otherPredicates
    if (filters.length === 0) return this.source.result
    return this.source.result?.filter((c) => !filters.some((f) => !f(c)))
  }

  get counts() {
    return (this.otherResult || []).reduce((map, candidate) => {
      const answer = candidate.answers[this.name]
      if (answer) {
        map.set(answer, (map.get(answer) || 0) + 1)
      }
      return map
    }, new Map<string, number>())
  }

  clear() {
    this.selection.clear()
  }

  get empty() {
    return this.count === 0
  }
}
