import React, { useCallback, useContext } from 'react'
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  TextField,
  Typography,
  useEventCallback,
} from '@mui/material'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { useQueryClient } from 'react-query'
import { Dayjs } from 'dayjs'
import { useNavigate } from 'react-router-dom'
import CustomDatePicker from '../../../../common/survey/custom-date-picker/CustomDatePicker'
import formats from '../../../../common/survey/formats'
import {
  archiveDefaultValues,
  archivedReasons,
  archiveSchema,
  DO_NOT_SNOOZE_TIME_ISO,
  getMinArchiveDate,
  otherReason,
  otherReasonOption,
} from './index'
import useCreateCandidateNote from '../../../api/hooks/noteHooks'
import { useArchiveCandidate } from '../../../api/hooks/candidateHooks'
import CustomSnackbar from '../../../../common/alerts/CustomSnackbar'
import DialogCloseButton from '../../../../common/components/dialog/DialogCloseButton'
import CustomSelect, { SelectMenuItem } from '../../../../common/select/CustomSelect'
import CandidateProfileContext from '../../candidate-profile/context/CandidateProfileContext'

export default function CandidateArchiveDialog({ open, onClose }: { open: boolean; onClose: (event: object) => void }) {
  const {
    register,
    reset,
    setValue,
    handleSubmit,
    watch,
    formState: { errors, isSubmitted, isSubmitting },
  } = useForm<z.infer<typeof archiveSchema>>({
    resolver: zodResolver(archiveSchema),
    defaultValues: archiveDefaultValues,
  })
  const {
    candidate: {
      dto: { userId: candidateId },
    },
  } = useContext(CandidateProfileContext)

  const archiveReason = watch('reason')
  const snoozeCheck = watch('snoozeCheck')
  const snoozeDate = watch('snoozeDate')

  const client = useQueryClient()
  const onSnoozeDateChange = useCallback(
    (data: Dayjs | null) => {
      setValue('snoozeDate', data, { shouldValidate: isSubmitted })
    },
    [isSubmitted, setValue],
  )

  const onSnoozeChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue('snoozeCheck', event.target.checked, { shouldValidate: isSubmitted })
      if (!event.target.checked) {
        onSnoozeDateChange(null)
      }
    },
    [isSubmitted, onSnoozeDateChange, setValue],
  )

  const closeDialog = useCallback(() => {
    onClose(() => false)
    reset(archiveDefaultValues)
  }, [reset, onClose])

  const navigate = useNavigate()
  const createCandidateNote = useCreateCandidateNote(candidateId)
  const archiveCandidate = useArchiveCandidate(candidateId)

  const onSubmit = useEventCallback(
    handleSubmit(async (data) => {
      const { note, snoozeDate: date } = data
      let { reason } = data
      if (reason === otherReasonOption) reason = otherReason
      const parsedSnoozeDate = date ? date.startOf('day').toISOString() : DO_NOT_SNOOZE_TIME_ISO
      const parsedFormData = { reason, note, snoozeDate: parsedSnoozeDate }

      await archiveCandidate.mutateAsync(parsedFormData)
      closeDialog()
      client.invalidateQueries(['candidateProfile', candidateId]).then() // no need to await
      CustomSnackbar.show('Candidate archived')
      navigate(`/candidates`)
      await createCandidateNote.mutateAsync({
        title: `Archived: ${reason}`,
        content: note?.trim() ? JSON.stringify([{ children: [{ text: note }] }]) : '',
      })
    }),
  )

  return (
    <Dialog open={open} onClose={closeDialog}>
      <DialogTitle>
        Archive Candidate
        <DialogCloseButton onClick={closeDialog} />
        <Typography variant="body1">
          Select a reason and add a note below. Notes will be saved to the candidate’s notes.
        </Typography>
      </DialogTitle>
      <DialogContent>
        <FormControl fullWidth>
          <CustomSelect label="Reason*" {...register('reason')} value={archiveReason} error={!!errors.reason}>
            {archivedReasons.map((val) => (
              <SelectMenuItem key={val} value={val}>
                {val}
              </SelectMenuItem>
            ))}
          </CustomSelect>
          <FormHelperText error={!!errors.reason}>{errors.reason?.message || ' '}</FormHelperText>
        </FormControl>
        <TextField
          label="Note"
          {...register('note')}
          sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}
          multiline
          minRows={3}
          maxRows={3}
          helperText=" "
        />
        <FormControl fullWidth>
          <FormControlLabel
            label="Snooze candidate"
            control={<Checkbox {...register('snoozeCheck')} onChange={onSnoozeChange} />}
          />
          {snoozeCheck && (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <CustomDatePicker
                label="Date"
                format={formats.date}
                views={['year', 'month', 'day']}
                minDate={getMinArchiveDate()}
                value={snoozeDate}
                onChange={onSnoozeDateChange}
                onAccept={onSnoozeDateChange}
                error={!!errors.snoozeDate?.message}
                helperText={errors.snoozeDate?.message || ' '}
              />
            </LocalizationProvider>
          )}
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={closeDialog} fullWidth>
          Cancel
        </Button>
        <Button onClick={onSubmit} fullWidth disabled={isSubmitting}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}
