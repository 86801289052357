import { makeAutoObservable } from 'mobx'
import Note from './note'

function byTimeStamp(a: Note, b: Note) {
  return (b.updatedAt?.getTime() || 0) - (a.updatedAt?.getTime() || 0)
}

// function byId(a: Note, b: Note) {
//   if (b.dto.id === 0) return 1
//   if (a.dto.id === 0) return -1
//   return b.dto.id - a.dto.id
// }

export default class UserNotes {
  loaded: Note[] | null = null

  inEdit: Note | null = null

  constructor(public userId: number) {
    makeAutoObservable(this, undefined, { autoBind: true })
  }

  get isLoading() {
    return !this.loaded
  }

  get visible() {
    return this.loaded?.filter((note) => !note.isEmpty).sort(byTimeStamp) || []
  }
}
