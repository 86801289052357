import React, { useCallback } from 'react'
import { Box, FormControlLabel, Radio } from '@mui/material'
import OffenseQuestionModel from './OffenseQuestionModel'

export default function NoConvictions({ question }: { question: OffenseQuestionModel }) {
  const label = 'No convictions'
  const { name, value } = question
  const isChecked = Array.isArray(value) && value.length === 0
  const handleOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      if (checked) {
        // eslint-disable-next-line no-param-reassign
        question.value = []
      }
    },
    [question],
  )

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <FormControlLabel
        control={
          <Radio
            id="no-convictions"
            name={name}
            value={label}
            onChange={handleOnChange}
            checked={isChecked}
            aria-label={label}
            sx={{ marginTop: '-10px' }}
          />
        }
        label={label}
        sx={{ alignItems: 'flex-start', p: '4px 0' }}
      />
    </Box>
  )
}
