import { Button, List, Paper } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { WorkExperience } from './validator'
import css from './MuiWorkExperience.module.css'
import PlusCircle from '../../icons/PlusCircle'
import MuiWorkExperienceEditor from './MuiWorkExperienceEditor'
import WorkExperienceModel from './WorkExperienceModel'
import MuiWorkExperienceListItem from './MuiWorkExperienceListItem'
import { palette } from '../../mui-theme'

type Props = {
  question: WorkExperienceModel
  onDelete: (index: number) => void
  setIndex: (index: number | undefined) => void
}

const defaultValue: WorkExperience = {
  employer: '',
  position: '',
  current: false,
  startDate: '',
  endDate: '',
} as const

function toLexicalOrdered(usaMonthYear: string) {
  const [month, year] = usaMonthYear.trim().split('/')
  return `${year}-${month.length === 1 ? '0' : ''}${month}`
}

function byStartDatesDesc(a: WorkExperience, b: WorkExperience) {
  const aStartDate = toLexicalOrdered(a.startDate)
  const bStartDate = toLexicalOrdered(b.startDate)
  if (aStartDate < bStartDate) return 1
  if (aStartDate > bStartDate) return -1
  return 0
}

export default function MuiWorkExperienceList({ question, onDelete, setIndex }: Props) {
  const list = question.value as WorkExperience[]
  const [newExperience, setNewExperience] = useState<WorkExperience | null>(null)
  const isStaffPortal = question.mode === 'no-complete'
  useEffect(() => {
    setNewExperience(!isStaffPortal && list.length === 0 ? defaultValue : null)
  }, [isStaffPortal, list.length])
  const rerender = useCallback(() => {
    // eslint-disable-next-line no-param-reassign
    question.value = [...list]
  }, [list, question])
  return (
    <div className={css.container}>
      <List sx={{ display: 'flex', flexDirection: 'column', padding: 0 }}>
        {list.map((experience: WorkExperience, index: number) => {
          const key = JSON.stringify(experience) + index
          return (
            <MuiWorkExperienceListItem
              key={key}
              experience={experience}
              onDelete={() => onDelete(index)}
              onUpdate={rerender}
            />
          )
        })}
      </List>
      {!newExperience ? (
        <Button
          onClick={() => {
            setNewExperience(defaultValue)
          }}
          variant="outlined"
          startIcon={<PlusCircle />}
          sx={{ justifyContent: 'flex-start', height: '72px', padding: '24px' }}
        >
          Add{list?.length ? ' Another ' : ' '}Job
        </Button>
      ) : (
        <Paper
          sx={{
            padding: '24px',
            borderRadius: '8px',
            border: `1px solid ${palette.secondary.light}`,
            boxShadow: 'none',
          }}
        >
          <MuiWorkExperienceEditor
            onSave={(newValue) => {
              setNewExperience(null)
              list.push(newValue)
              list.sort(byStartDatesDesc)
              setIndex(undefined)
              rerender()
            }}
            onCancel={() => setNewExperience(null)}
            experience={newExperience}
            showCancel={isStaffPortal || list.length !== 0}
          />
        </Paper>
      )}
    </div>
  )
}
