import React from 'react'

export function FormatBold() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5 9.99998H11.6667C13.5076 9.99998 15 8.5076 15 6.66665C15 4.8257 13.5076 3.33331 11.6667 3.33331H5V9.99998ZM5 9.99998H12.5C14.3409 9.99998 15.8333 11.4924 15.8333 13.3333C15.8333 15.1743 14.3409 16.6666 12.5 16.6666H5V9.99998Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export function FormatItalic() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.8337 3.33331H8.33366M11.667 16.6666H4.16699M12.5003 3.33331L7.50033 16.6666"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export function FormatUnderlined() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.9997 3.33331V9.16665C14.9997 11.9281 12.7611 14.1666 9.99967 14.1666C7.23825 14.1666 4.99967 11.9281 4.99967 9.16665V3.33331M3.33301 17.5H16.6663"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export function FormatAlignLeft() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.3333 8.33333H2.5M16.6667 5H2.5M16.6667 11.6667H2.5M13.3333 15H2.5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export function FormatAlignCenter() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15 8.33333H5M17.5 5H2.5M17.5 11.6667H2.5M15 15H5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export function FormatAlignRight() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.4997 8.33333H6.66634M17.4997 5H3.33301M17.4997 11.6667H3.33301M17.4997 15H6.66634"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export function FormatAlignJustify() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.5 8.33333H2.5M17.5 15H2.5M17.5 5H2.5M17.5 11.6667H2.5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export function FormatListBulleted() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.49967 15L16.6663 15M7.49967 9.99998L16.6663 9.99998M7.49967 4.99998H16.6663M4.16634 4.99998C4.16634 5.2301 3.97979 5.41665 3.74967 5.41665C3.51956 5.41665 3.33301 5.2301 3.33301 4.99998M4.16634 4.99998C4.16634 4.76986 3.97979 4.58331 3.74967 4.58331C3.51956 4.58331 3.33301 4.76986 3.33301 4.99998M4.16634 4.99998H3.33301M4.16634 9.99998C4.16634 10.2301 3.97979 10.4166 3.74967 10.4166C3.51956 10.4166 3.33301 10.2301 3.33301 9.99998M4.16634 9.99998C4.16634 9.76986 3.97979 9.58331 3.74967 9.58331C3.51956 9.58331 3.33301 9.76986 3.33301 9.99998M4.16634 9.99998H3.33301M4.16634 15C4.16634 15.2301 3.97979 15.4166 3.74967 15.4166C3.51956 15.4166 3.33301 15.2301 3.33301 15M4.16634 15C4.16634 14.7699 3.97979 14.5833 3.74967 14.5833C3.51956 14.5833 3.33301 14.7699 3.33301 15M4.16634 15H3.33301"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export function FormatListNumbered() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.49967 15L16.6663 15M7.49967 10L16.6663 10M7.49967 5H16.6663M4.16634 8.33333V5L3.33301 5.66663M4.16634 8.33333H3.33301M4.16634 8.33333L4.99967 8.33329M3.33314 11.6667H4.16627C4.62654 11.6667 4.99964 12.0398 4.99961 12.5001C4.99957 12.9603 4.6265 13.3333 4.16631 13.3333C3.70609 13.3333 3.33301 13.7064 3.33301 14.1666V15H4.99967"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
