import { Question, Serializer } from 'survey-core'

const labelProperty = { name: 'label', defaultValue: 'Date' } as const
export default class DateModel extends Question {
  static readonly TYPE = 'date'

  // eslint-disable-next-line class-methods-use-this
  getType() {
    return DateModel.TYPE
  }

  get savedDate() {
    return this.getPropertyValue('savedDate')
  }

  set savedDate(val) {
    this.setPropertyValue('savedDate', val)
  }

  get label() {
    return this.getPropertyValue(labelProperty.name, labelProperty.defaultValue)
  }

  set label(value: string) {
    this.setPropertyValue(labelProperty.name, value)
  }
}

Serializer.addClass(DateModel.TYPE, [labelProperty], () => new DateModel(''), 'question')
