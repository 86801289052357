import { Box, Button, SxProps, Typography } from '@mui/material'
import React from 'react'
import { observer } from 'mobx-react'
import { colors } from '../../../../common/mui-theme'

type Item = {
  title: string
  value: React.ReactNode
}

function MinimizedView({
  items,
  isEditable,
  onEdit,
  sx,
}: {
  items: Item[]
  isEditable?: boolean
  onEdit?: () => void
  sx?: SxProps
}) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        p: '17px 0 17px 24px',
        ...sx,
      }}
    >
      {items.map(({ title, value }) => (
        <Typography variant="body1" color={colors.neutral['400']} key={title}>
          {title}: <span style={{ fontWeight: '700', wordBreak: 'break-word' }}>{value}</span>
        </Typography>
      ))}
      {isEditable && (
        <Button
          variant="text"
          onClick={onEdit}
          sx={{
            p: '0',
            minHeight: '24px',
            right: '30px',
            bottom: '22px',
            position: 'absolute',
            minWidth: 0,
          }}
        >
          Edit
        </Button>
      )}
    </Box>
  )
}

export default observer(MinimizedView)
