import { Box, debounce } from '@mui/material'
import { observer } from 'mobx-react'
import { useContext, useEffect } from 'react'
import { autorun, runInAction } from 'mobx'
import { useLocation, useNavigate } from 'react-router-dom'
import RightRail from '../RightRail'
import NoteListCard from '../notes/NoteListCard'
import IndividualDevelopmentPlanRetention from './IndividualDevelopmentPlanRetention'
import { useCandidateCoachingSessions, useUpdateApplicationAnswers } from '../../api/hooks/candidateHooks'
import IntakeContext from '../survey/context/IntakeContext'
import RetentionRightRail from './RetentionRightRail'
import RightRailContainer from '../RightRailContainer'
import CandidateProfileContext from '../candidate-profile/context/CandidateProfileContext'
import CoachingSessionRetention from './CoachingSessionRetention'
import CoachingSessionContext from './CoachingSessionContext'

function Retention() {
  const { candidate } = useContext(CandidateProfileContext)
  const { setSaved } = useContext(IntakeContext)
  const updateProfileAnswers = useUpdateApplicationAnswers(candidate.dto.userId)
  const ctx = useContext(CoachingSessionContext)
  const { coachingSessions } = ctx
  const { data: sessions } = useCandidateCoachingSessions(candidate.dto.userId)
  useEffect(() => {
    // instantiate context if not already made
    runInAction(() => {
      ctx.coachingSessions = sessions || []
    })
  }, [ctx, sessions])

  useEffect(() => {
    let firstRun = true
    const save = debounce((json: string) => {
      const updatedProfileAnswers = {
        workflowStateId: candidate.dto.workflowStateId,
        resultJsonString: json,
      }

      updateProfileAnswers.mutateAsync(updatedProfileAnswers).then(() => {
        setSaved()
      })
    }, 1500)

    return autorun(() => {
      const json = JSON.stringify(candidate.answers)
      if (firstRun) {
        firstRun = false
        return
      }
      save(json)
    }, {})
  }, [candidate.answers, candidate.dto.workflowStateId, setSaved, updateProfileAnswers])

  const navigate = useNavigate()
  const { pathname, hash } = useLocation()

  useEffect(() => {
    const timeout = setTimeout(() => {
      navigate(pathname + hash, { replace: true })
    }, 32)
    return () => clearTimeout(timeout)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box sx={{ display: 'flex', gap: '16px', mb: '40px' }}>
      <Box sx={{ flex: 1, width: '100%' }}>
        <IndividualDevelopmentPlanRetention />
        {coachingSessions?.map((session, index) => (
          <CoachingSessionRetention
            key={session.id}
            session={session}
            id={`coaching-session-${index + 1}`}
            title={coachingSessions.length > 1 ? `Coaching Session ${index + 1}` : 'Coaching Session'}
          />
        ))}
      </Box>
      <RightRail>
        <RightRailContainer>
          <NoteListCard />
          <RetentionRightRail />
        </RightRailContainer>
      </RightRail>
    </Box>
  )
}

export default observer(Retention)
