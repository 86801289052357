import { useCallback } from 'react'
import { observer } from 'mobx-react'
import { Box, Button, TextField, Typography, useEventCallback } from '@mui/material'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { useQueryClient } from 'react-query'
import { colors } from '../../../common/mui-theme'
import EmailSentList from './EmailSentList'
import { CoachingSessionDto } from '../../_generated/portal-api'
import { usePortalApi } from '../../api/PortalApiContext'
import DottedDivider from '../DottedDivider'

const emailSchema = z.object({
  emailSubject: z.string().trim().nonempty('Email Subject is required').max(100),
  emailBody: z.string().trim(),
})

function useInvalidateSentList() {
  const client = useQueryClient()
  return useCallback((id: number) => client.invalidateQueries(['coaching-session-email-list', id]), [client])
}

function Email({ coachingSession }: { coachingSession: CoachingSessionDto | undefined }) {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<z.infer<typeof emailSchema>>({
    resolver: zodResolver(emailSchema),
    mode: 'all',
    defaultValues: {
      emailSubject: '',
      emailBody: '',
    },
  })
  const invalidateSentList = useInvalidateSentList()
  const api = usePortalApi()
  const sendEmail = useEventCallback(
    handleSubmit((data) => {
      if (!coachingSession) return
      api.coachingSession
        .sendEmail(coachingSession?.id, { subject: data.emailSubject, body: data.emailBody })
        .then(() => {
          invalidateSentList(coachingSession?.id)
          reset()
        })
    }),
  )

  return coachingSession ? (
    <Box>
      <Typography variant="subtitle1" sx={{ color: colors.neutral[600] }}>
        Email Session Details to Participant
      </Typography>
      <Typography variant="body1">All information above will be sent to the candidate’s email. </Typography>
      <Box sx={{ mt: '16px' }}>
        <TextField
          fullWidth
          required
          label="Email Subject"
          {...register('emailSubject')}
          error={!!errors.emailSubject}
          helperText={errors.emailSubject?.message || ' '}
        />
      </Box>
      <Box>
        <TextField multiline fullWidth rows={4} label="Enter body of email message..." {...register('emailBody')} />
      </Box>
      <Button
        fullWidth
        //      disabled={getFieldState('emailSubject').invalid}
        onClick={sendEmail}
        sx={{ m: '24px 0' }}
      >
        Send to Candidate
      </Button>
      <DottedDivider />
      <EmailSentList coachingSessionId={coachingSession.id} />
    </Box>
  ) : null
}

export default observer(Email)
