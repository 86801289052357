import { SurveyValidator, ValidatorResult } from 'survey-core'
import dayjs from 'dayjs'
import FieldError from '../validators/field-error'
import formats from '../formats'

export const MIN_DOB = dayjs('1900-01-01')

export default class DoBValidator extends SurveyValidator {
  private static getErrorMessage(date: string): string {
    if (!date) return 'Date of Birth is required'
    if (date === 'Invalid Date') return 'Enter a valid Date of Birth'
    const parsed = formats.parseDate(date)
    if (!parsed.isValid()) return 'Enter a valid Date of Birth'
    if (parsed.isAfter(dayjs())) return 'Date of Birth cannot be a future date'
    if (parsed.isBefore(MIN_DOB)) return 'Date of Birth cannot be before 1900 year'
    return ''
  }

  // eslint-disable-next-line class-methods-use-this
  override validate(date: string): ValidatorResult {
    const errorMessage = DoBValidator.getErrorMessage(date)

    const error = errorMessage === '' ? undefined : new FieldError('dob', errorMessage)

    return new ValidatorResult(date, error)
  }
}
