import { makeAutoObservable } from 'mobx'
import Candidate from '../candidate'
import { CheckboxQuestionName } from '../../../../../demo/survey-model'

export default class CheckboxAnswerFilter {
  selection = new Set<string>()

  constructor(
    private readonly source: { result?: Candidate[] | null },
    private readonly predicates: { filterFunctions: Array<(c: Candidate) => boolean> },
    public readonly name: CheckboxQuestionName,
  ) {
    makeAutoObservable(this, undefined, { autoBind: true })
  }

  get otherPredicates() {
    return this.predicates.filterFunctions.filter((f) => f !== this.predicate)
  }

  get otherResult() {
    const filters = this.otherPredicates
    if (filters.length === 0) return this.source.result
    return this.source.result?.filter((c) => !filters.some((f) => !f(c)))
  }

  get predicate() {
    return (c: Candidate) => c.answers[this.name]?.some((v) => this.selection.has(v)) || false
  }

  get count() {
    return this.selection.size
  }

  get counts() {
    return (this.otherResult || []).reduce((map, candidate) => {
      candidate.answers[this.name]?.forEach((answer) => map.set(answer, (map.get(answer) || 0) + 1))
      return map
    }, new Map<string, number>())
  }

  clear() {
    this.selection.clear()
  }

  get empty() {
    return this.count === 0
  }
}
