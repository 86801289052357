import React, { useCallback, useState } from 'react'

import { SurveyQuestionFile } from 'survey-react-ui'
import { Button, Card, CardActions, Checkbox, Divider, IconButton, Link, Typography } from '@mui/material'
import { QuestionFileModel, Serializer, surveyLocalization, SurveyModel } from 'survey-core'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import Cloud from '../icons/Cloud'
import File from '../icons/File'
import Delete from '../icons/Delete'
import CustomCircularProgress from '../components/CustomCircularProgress'
import { colors } from '../mui-theme'
import download, { getFileNameExtension, getSeparator } from '../download-blob'
import { FileQuestionValue } from './file-question-value'

type CustomQuestionFileModel = QuestionFileModel & {
  uploading?: File[]
  maxFiles?: number
  dragAndDrop: boolean
  showCheckboxes: boolean
  hideDelete: boolean
}

function preventDefault<T = Element>(e: React.DragEvent<T>) {
  e.preventDefault()
}

const formatSize = (sizeBytes: number) => {
  const sizeKB = sizeBytes / 1024
  if (sizeKB >= 1024) {
    const sizeMB = sizeKB / 1024
    return `${sizeMB.toFixed(0)} MB`
  }
  if (sizeKB >= 1) {
    return `${sizeKB.toFixed(0)} KB`
  }
  return `${sizeBytes} bytes`
}

const cardActionsStyle = {
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingLeft: '27px',
  paddingRight: '17px',
}

const linkStyles = {
  maxWidth: '45vw',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}

function DownloadButton({
  value,
  question,
  onChange,
}: {
  value: FileQuestionValue
  question: CustomQuestionFileModel
  onChange: (newArray: FileQuestionValue[]) => void
}) {
  const [disabled, setDisabled] = useState(false)
  const [checked, setChecked] = useState(!value.content.excluded)
  const listValue = question.value as FileQuestionValue[]
  const downloadFile = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    if (!disabled) {
      setDisabled(true)
      question.survey.downloadFile(question, question.name, value as never, () => {
        setDisabled(false)
      })
    }
  }
  const onCheckboxClick = useCallback(() => {
    const index = listValue.findIndex((val) => val.content.id === value.content.id)
    if (checked) {
      setChecked(false)
      listValue[index].content.excluded = true
    } else {
      setChecked(true)
      listValue[index].content.excluded = undefined
    }
    onChange(listValue)
  }, [checked, listValue, onChange, value.content.id])
  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '11px', overflowX: 'hidden' }}>
      {question.showCheckboxes && <Checkbox sx={{ p: 0 }} checked={checked} onClick={onCheckboxClick} />}
      <File
        color={colors.primary['500']}
        highlightColor={colors.primary['100']}
        backgroundColor={colors.primary['200']}
      />
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link
          href="#"
          onClick={downloadFile}
          color={colors.neutral['600']}
          role="button"
          underline="hover"
          sx={linkStyles}
        >
          {value.name}
        </Link>
        <Typography variant="caption" color="text.secondary">
          {formatSize(value.content.size)}
        </Typography>
      </div>
    </div>
  )
}

export const ItemTypes = {
  ATTACHMENT: 'attachment',
}

interface DropResult {
  returnVal: string
  index: number
}

function UploadPreview({
  fileValue,
  question,
  index,
  dnd,
  onChange,
}: {
  fileValue: FileQuestionValue
  question: CustomQuestionFileModel
  index: number
  dnd: boolean
  onChange: (newArray: FileQuestionValue[]) => void
}) {
  const listValue = question.value as []
  const [, drag] = useDrag(
    () => ({
      type: ItemTypes.ATTACHMENT,
      item: { index },
      end: (item, monitor) => {
        const dropResult = monitor.getDropResult<DropResult>()
        if (item && dropResult) {
          const trueToIndex = index < dropResult.index ? dropResult.index - 1 : dropResult.index
          const itemToMove = listValue.slice(index, index + 1)
          const templist = listValue.slice(0, index).concat(listValue.slice(index + 1))
          const newList = templist.slice(0, trueToIndex).concat(itemToMove).concat(templist.slice(trueToIndex))
          onChange(newList)
        }
      },
    }),
    [index, listValue],
  )
  const [, drop] = useDrop(
    () => ({
      accept: ItemTypes.ATTACHMENT,
      drop: () => ({ returnVal: fileValue, index }),
    }),
    [index, listValue],
  )
  return (
    <div ref={dnd ? drop : undefined}>
      {index > 0 && <Divider sx={{ borderColor: colors.neutral['100'] }} />}
      <Card
        ref={dnd ? drag : undefined}
        className={question.cssClasses.preview}
        sx={{
          minHeight: '73px',
          backgroundColor: colors.primary['25'],
          border: 'none',
          cursor: dnd ? 'grab' : undefined,
        }}
        elevation={0}
      >
        <CardActions sx={cardActionsStyle}>
          <DownloadButton value={fileValue} question={question} onChange={onChange} />
          {!question.hideDelete && (
            <IconButton
              onClick={() => {
                // eslint-disable-next-line no-param-reassign
                question.removalIndex = index
                question.doRemoveFile(fileValue)
              }}
            >
              <Delete color={colors.primary['500']} />
            </IconButton>
          )}
        </CardActions>
      </Card>
    </div>
  )
}

// TODO use value from editable custom property
// export const MAX_FILES_TO_UPLOAD = 2
export const MAX_FILES_ERROR = 'Only two documents may be uploaded.'
export default class MuiFileQuestion extends SurveyQuestionFile {
  get value(): FileQuestionValue[] | undefined | null {
    return this.question.value as FileQuestionValue[] | undefined | null
  }

  get question() {
    return super.question as CustomQuestionFileModel
  }

  get maxFilesReached() {
    return !!this.question.maxFiles && this.question.maxFile <= (this.value?.length || 0)
  }

  protected renderElement(): JSX.Element {
    const preview = this.renderPreview()
    const fileDecorator = this.renderFileDecorator()
    const isFileUploadDisabled = this.maxFilesReached
    const isReadOnly = this.isDisplayMode || isFileUploadDisabled

    return (
      <div className={this.question.fileRootCss}>
        <input
          type="file"
          disabled={isReadOnly}
          className={this.isDisplayMode ? this.question.getReadOnlyFileCss() : this.question.cssClasses.fileInput}
          id={this.question.inputId}
          ref={(input) => {
            this.setControl(input)
          }}
          style={this.isDisplayMode ? { color: 'transparent' } : undefined}
          onChange={this.isDisplayMode ? undefined : this.question.doChange}
          multiple={this.question.allowMultiple}
          placeholder={this.question.title}
          title={this.question.inputTitle}
          accept={this.question.acceptedTypes}
          tabIndex={-1}
          aria-required={this.question.ariaRequired}
          aria-label={this.question.ariaLabel}
          aria-invalid={this.question.ariaInvalid}
          aria-describedby={this.question.ariaDescribedBy}
        />
        <div>{preview}</div>
        <div
          className={this.question.cssClasses.dragArea}
          onDrop={isReadOnly ? preventDefault : this.question.onDrop}
          onDragOver={isReadOnly ? preventDefault : this.question.onDragOver}
          onDragLeave={isReadOnly ? preventDefault : this.question.onDragLeave}
          onDragEnter={isReadOnly ? preventDefault : this.question.onDragEnter}
        >
          {fileDecorator}
        </div>
      </div>
    )
  }

  protected renderPreview(): JSX.Element | null {
    const files = this.question.uploading
    const hasUploadErrors = this.question.errors.length > 0
    const uploadingPreviews = files?.map((file: File) => (
      <div key={file.name}>
        <Card
          className={this.question.cssClasses.preview}
          sx={{ minHeight: '73px', border: 'none', backgroundColor: hasUploadErrors ? colors.error['25'] : undefined }}
          elevation={0}
        >
          <CardActions sx={cardActionsStyle}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '11px', overflowX: 'hidden' }}>
              <File
                color={hasUploadErrors ? colors.error['600'] : colors.primary['500']}
                highlightColor={hasUploadErrors ? colors.error['50'] : colors.primary['100']}
                backgroundColor={hasUploadErrors ? colors.error['50'] : colors.primary['200']}
              />
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography
                  variant="subtitle2"
                  color={hasUploadErrors ? colors.error['700'] : undefined}
                  sx={linkStyles}
                >
                  {file.name}
                </Typography>
                <Typography variant="caption" color={hasUploadErrors ? colors.error['600'] : 'text.secondary'}>
                  {formatSize(file.size)} – uploading
                </Typography>
                {hasUploadErrors && (
                  <Typography variant="body2" color={colors.error['700']}>
                    Try again
                  </Typography>
                )}
              </div>
            </div>
            {hasUploadErrors ? (
              <IconButton
                onClick={() => {
                  const { question } = this.props
                  const updatedUploading = [...question.uploading]
                  updatedUploading.splice(question.removalIndex, 1)
                  question.uploading = updatedUploading
                  this.forceUpdate()
                }}
              >
                <Delete color={colors.error['700']} />
              </IconButton>
            ) : (
              <CustomCircularProgress
                size={32}
                value={80}
                thickness={3}
                variant="indeterminate"
                backgroundColor={colors.primary['100']}
              />
            )}
          </CardActions>
        </Card>
      </div>
    ))
    const previews = this.value?.map((val, index) => {
      if (!val) return null
      return (
        <UploadPreview
          key={`${this.question.inputId}_${val.content.id}`}
          fileValue={val}
          question={this.question}
          index={index}
          dnd={this.question.dragAndDrop}
          onChange={(newList) => {
            this.question.value = newList
            this.setState({ ...(this.state || {}) })
          }}
        />
      )
    })
    return (
      <div
        style={{ border: this.value?.length ? '1px solid #eaebec' : 'none' }}
        className={this.question.cssClasses.fileList || undefined}
      >
        <DndProvider backend={HTML5Backend}>{previews}</DndProvider>
        {uploadingPreviews}
      </div>
    )
  }

  protected renderFileDecorator(): JSX.Element {
    const isFileUploadDisabled = this.maxFilesReached
    const chooseFile = (
      <Button
        variant="text"
        component="label"
        size="medium"
        htmlFor={this.question.inputId}
        sx={{ minWidth: 'auto', minHeight: 'auto' }}
        color={isFileUploadDisabled ? 'secondary' : 'primary'}
        disabled={isFileUploadDisabled}
      >
        {this.question.chooseButtonCaption}
        <Typography variant="body1" style={isFileUploadDisabled ? { color: colors.neutral['200'] } : undefined}>
          &nbsp;or drag and drop
        </Typography>
      </Button>
    )
    return (
      <div className={isFileUploadDisabled ? 'upload-disabled' : this.question.getFileDecoratorCss()}>
        <Cloud
          strokeColor={isFileUploadDisabled ? colors.neutral['400'] : colors.primary['500']}
          fillBackgroundColor={isFileUploadDisabled ? colors.neutral['200'] : colors.primary['200']}
          strokeBackgroundColor={isFileUploadDisabled ? colors.neutral['100'] : colors.primary['100']}
        />
        <div className={this.question.cssClasses.wrapper}>{chooseFile}</div>
        <span
          className={this.question.cssClasses.dragAreaPlaceholder}
          style={isFileUploadDisabled ? { color: colors.neutral['200'] } : undefined}
        >
          {this.question.dragAreaPlaceholder}
        </span>
      </div>
    )
  }
}

surveyLocalization.locales[surveyLocalization.defaultLocale].fileDragAreaPlaceholder = 'PDF, Word document, or TXT file'
surveyLocalization.locales[surveyLocalization.defaultLocale].chooseFileCaption = 'Click to upload'

export function createOnDownloadFileHandler(downloader: (fileId: number) => Promise<Blob>) {
  return (sender: SurveyModel, options: { fileValue: FileQuestionValue; callback: (status: string) => void }) => {
    downloader(options.fileValue.content.id)
      .then((blob) => download(blob, options.fileValue.name))
      .finally(() => options.callback('loaded'))
  }
}

export function createOnUploadFilesHandler<T>(uploader: (file: File) => Promise<T>) {
  return (
    sender: SurveyModel,
    options: {
      question: CustomQuestionFileModel
      name: string
      files: File[]
      callback: (status: string, data: unknown) => void
    },
  ) => {
    const types = options.question.acceptedTypes as unknown as string[] // .toLowerCase().split(',')
    const extensions = new Set<string>()
    const contentTypes = new Set<string>()
    types.forEach((t) => (t.startsWith('.') ? extensions.add(t.toUpperCase()) : contentTypes.add(t.toUpperCase())))

    const isAcceptable = (file: File) =>
      contentTypes.has(file.type.toUpperCase()) || extensions.has(getFileNameExtension(file.name).toUpperCase())

    const filteredFiles = options.files.filter(isAcceptable)

    const previousCount = options.question.value?.length || 0

    const maxFiles = options.question.maxFiles || 999
    const limit = Math.max(0, maxFiles - previousCount)
    const overflowCount = filteredFiles.length - limit
    if (overflowCount > 0) {
      filteredFiles.splice(limit, overflowCount)
    }
    const fileQuestion = options.question
    fileQuestion.uploading = filteredFiles
    const fileUploads = filteredFiles.map(uploader)
    const allFilesUploaded = Promise.all(fileUploads)
    allFilesUploaded
      .then((files) => {
        fileQuestion.uploading = undefined
        options.callback(files.length > 0 ? 'success' : 'error', files)
        const firstInvalidFile = options.files.find((file) => !isAcceptable(file)) // just first invalid file
        if (firstInvalidFile) {
          let index = 0
          let list = ''
          extensions.forEach((ext) => {
            index++
            list += getSeparator(index, extensions.size) + ext
          })
          options.question.addError(`File type is invalid. Please select a ${list} file.`)
        }
        if (overflowCount > 0) {
          options.question.addError(MAX_FILES_ERROR)
        }
      })
      .catch((e) => {
        // fileUploads.map((p, index) => ({ p, f: filteredFiles[index] })).filter(({ p }) => p.isRejected())
        options.question.addError(`Failed to upload file. ${e.message || ''}`)
        options.callback('error', [])
      })
  }
}

Serializer.addProperty('file', { name: 'maxFiles', type: 'number' })
Serializer.addProperty('file', { name: 'dragAndDrop', type: 'boolean', default: false })
Serializer.addProperty('file', { name: 'showCheckboxes', type: 'boolean', default: false })
Serializer.addProperty('file', { name: 'hideDelete', type: 'boolean', default: false })
