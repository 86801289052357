import React, { useMemo } from 'react'
import { SurveyModel } from 'survey-core'
import { selfIdentification, selfIdentificationFeedback } from '../../../demo/survey-model'
import { enableEditMode } from '../../utils/survey-utils'
import IntakeSurvey from './IntakeSurvey'
import { intakeQuestionGroupList } from './context/IntakeContext'

export default function SelfIdentificationIntake() {
  const model = useMemo(() => {
    const newModel = new SurveyModel(selfIdentification)
    enableEditMode(newModel.getAllQuestions(), newModel)
    return newModel
  }, [])

  const feedback = useMemo(() => new SurveyModel(selfIdentificationFeedback), [])

  return <IntakeSurvey item={intakeQuestionGroupList[4]} model={model} feedback={feedback} />
}
