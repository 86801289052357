import { makeAutoObservable } from 'mobx'
import CandidatesStore, { byFullName } from './candidates-store'
import Candidate from './candidate'
import { getRegEx, limited } from '../../../utils/highlight'

function byStatusAndFullName(a: Candidate, b: Candidate) {
  const result = (a.archived ? 1 : 0) - (b.archived ? 1 : 0)
  return result || byFullName(a, b)
}

export default class CandidatesSearch {
  text = ''

  sortedCopy: Candidate[] = []

  constructor(public readonly store: CandidatesStore) {
    makeAutoObservable(this, { sortedCopy: false }, { autoBind: true })
  }

  get empty() {
    return !this.text
  }

  get regEx() {
    return this.empty ? null : getRegEx(this.text)
  }

  get predicate() {
    const reg = this.regEx
    return reg && ((c: Candidate) => reg.test(c.fullName) || reg.test(c.dto.email))
  }

  private get limitedFilter() {
    return limited(this.predicate, 1000)
  }

  get sorted() {
    if (this.store.candidates) {
      const copy = this.sortedCopy.filter((c) => !c.dto.isDeleted)
      if (this.store.candidates.length > copy.length) {
        const newCandidates = this.store.candidates.slice(copy.length)
        copy.splice(copy.length, 0, ...newCandidates)
      }
      this.sortedCopy = copy.sort(byStatusAndFullName).slice(0)
    }
    return this.sortedCopy
  }

  get options() {
    return this.sorted.filter(this.limitedFilter)
  }

  get result() {
    const p = this.predicate
    return p ? this.store.candidates?.filter(p) : this.store.candidates
  }
}
