import { z } from 'zod'
import dayjs, { Dayjs } from 'dayjs'
import { MAX_TIMESTAMP } from '../placement/tabs/placement-schema'

export const MIN_TIMESTAMP = dayjs('1970-01-01')

const dateSchema = z
  .instanceof(dayjs as unknown as typeof Dayjs, { message: 'Date is required' })
  .nullable()
  .refine((date) => !date || (date.isValid() && date.isBefore(MAX_TIMESTAMP) && date.isAfter(MIN_TIMESTAMP)), {
    message: 'Date is invalid',
  })

const coachingSessionSchema = z.object({
  completed: z.boolean(),
  sessionDate: dateSchema,
  nextSessionDate: dateSchema,
})

export default coachingSessionSchema

export type CoachingSessionSchema = z.infer<typeof coachingSessionSchema>
