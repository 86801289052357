import { Alert, AlertTitle } from '@mui/material'

export default function CrashAlert() {
  return (
    <Alert severity="error">
      <AlertTitle>Error</AlertTitle>
      Sorry, an unexpected error has occurred. We are working on it. Please try to&nbsp;
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a href={window.location.toString()}>reload</a>&nbsp;this page later.
    </Alert>
  )
}
