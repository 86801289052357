import React, { useMemo } from 'react'
import { SurveyModel } from 'survey-core'
import { employmentAndPreferences, employmentAndPreferencesFeedback } from '../../../demo/survey-model'
import IntakeSurvey from './IntakeSurvey'
import { enableEditMode } from '../../utils/survey-utils'
import { intakeQuestionGroupList } from './context/IntakeContext'

export default function EmploymentAndPreferencesIntake() {
  const model = useMemo(() => {
    const newModel = new SurveyModel(employmentAndPreferences)
    enableEditMode(newModel.getAllQuestions(), newModel)

    newModel.widthMode = 'static'
    return newModel
  }, [])
  const feedback = useMemo(() => new SurveyModel(employmentAndPreferencesFeedback), [])

  return <IntakeSurvey item={intakeQuestionGroupList[2]} model={model} feedback={feedback} />
}
