import { createContext, useContext } from 'react'
import portalApi from './portal'

const PortalApiContext = createContext(portalApi)

export function usePortalApi() {
  return useContext(PortalApiContext)
}

export default PortalApiContext
