import { useFocused } from 'slate-react'
import { IconButtonProps } from '@mui/material/IconButton'
import { SxProps, useEventCallback } from '@mui/material'
import React from 'react'
import ToggleIconButton from '../ToggleIconButton'

export default function ToolbarButton({
  toggled,
  children,
  onMouseDown,
  ...other
}: IconButtonProps & SxProps & { toggled: boolean }) {
  const focused = useFocused()
  const onMouseDownHandler = useEventCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    e.preventDefault()
    if (onMouseDown) onMouseDown(e)
  })
  return (
    <ToggleIconButton toggled={toggled} onMouseDown={onMouseDownHandler} {...other} disabled={!focused}>
      {children}
    </ToggleIconButton>
  )
}
