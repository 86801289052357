import IconButton, { IconButtonProps } from '@mui/material/IconButton'
import React from 'react'
import Close from '../../icons/Close'

type DialogCloseButtonProps = Omit<IconButtonProps, 'children' | 'color'>
export default function DialogCloseButton(props: DialogCloseButtonProps) {
  const { sx, onClick, ...other } = props
  return (
    <IconButton
      {...other}
      aria-label="close"
      color="secondary"
      sx={{
        ...sx,
        position: 'absolute',
        top: 12,
        right: 12,
        minWidth: 0,
        minHeight: 0,
        opacity: 0.4,
        zIndex: 10,
        '&:hover': { opacity: 1 },
      }}
      onClick={onClick}
    >
      <Close />
    </IconButton>
  )
}
