import { observer } from 'mobx-react'
import React, { useCallback } from 'react'
import { runInAction } from 'mobx'
import { Checkbox, Chip, FormControlLabel } from '@mui/material'
import CheckboxAnswerFilter from '../../context/answers/checkbox-answer-filter'
import RadioAnswerFilter from '../../context/answers/radio-answer-filter'

function FilterCheckboxOption({
  name,
  value,
  filter,
}: {
  name: string
  value: string
  filter: CheckboxAnswerFilter | RadioAnswerFilter
}) {
  const checked = filter.selection.has(value)
  const count = filter.counts.get(value)
  const disabled = !count && !checked
  const toggle = useCallback(() => {
    if (checked) runInAction(() => filter.selection.delete(value))
    else runInAction(() => filter.selection.add(value))
  }, [checked, filter.selection, value])

  return (
    <FormControlLabel
      control={<Checkbox checked={checked} onChange={toggle} disabled={disabled} />}
      label={
        <>
          {name} {!!count && <Chip size="small" label={count} />}
        </>
      }
    />
  )
}

export default observer(FilterCheckboxOption)
