import { Navigate, Route, Routes } from 'react-router-dom'
import { Box } from '@mui/material'
import React from 'react'
import AlertsContext, { alertsContext } from '../common/alerts/AlertsContext'
import CandidatesPage from './pages/CandidatesPage'
import Header from './components/Header'
import CandidatesContext, { candidatesContext } from './components/candidates/context/CandidatesContext'
import CandidateProfilePage from './pages/CandidateProfilePage'
import PortalUserContext, { portalUserContextValue } from './components/portal-user/context/PortalUserContext'
import FeatureFlagsContext, { featureFlags } from './feature-flags/FeatureFlagsContext'
import CustomSnackbar from '../common/alerts/CustomSnackbar'
import useScrollHighlighter from '../common/scroll-highlighter'
import DuplicateCandidatesPage from './pages/DuplicateCandidatesPage'
import JobRouter from './pages/job/JobRouter'

function App() {
  useScrollHighlighter()
  return (
    <Box sx={{ minWidth: '1024px' }}>
      <AlertsContext.Provider value={alertsContext}>
        <PortalUserContext.Provider value={portalUserContextValue}>
          <FeatureFlagsContext.Provider value={featureFlags}>
            <CandidatesContext.Provider value={candidatesContext}>
              <Routes>
                <Route path="/jobs/*" element={null} />
                <Route path="*" element={<Header />} />
              </Routes>
              <Routes>
                <Route path="/candidates" element={<CandidatesPage />} />
                <Route path="/duplicate-candidates/:id" element={<DuplicateCandidatesPage />} />
                <Route path="/candidates/:id/*" element={<CandidateProfilePage />} />
                <Route path="/jobs/*" element={<JobRouter />} />
                <Route path="*" element={<Navigate to="/candidates" />} />
              </Routes>
              <CustomSnackbar />
            </CandidatesContext.Provider>
          </FeatureFlagsContext.Provider>
        </PortalUserContext.Provider>
      </AlertsContext.Provider>
    </Box>
  )
}

export default App
