import React, { useContext, useEffect } from 'react'
import { SurveyModel } from 'survey-core'
import { runInAction, set } from 'mobx'
import { observer } from 'mobx-react'
import { Box } from '@mui/material'
import MuiSurvey from '../../../common/survey/MuiSurvey'
import IntakeContext, { IntakeQuestionGroupItem } from './context/IntakeContext'
import { Answers } from '../../../demo/survey-model'
import IntakeSurveyAccordion from '../survey-answers/IntakeSurveyAccordion'
import InnerNote from '../InnerNote'
import CandidateProfileContext from '../candidate-profile/context/CandidateProfileContext'

const calculateAnsweredQuestionsCount = (model: SurveyModel) =>
  model.getAllQuestions(true).reduce((count, q) => (q.isAnswered ? count + 1 : count), 0)
const getAllQuestionsCount = (model: SurveyModel) => model.getAllQuestions(true).length

function IntakeSurvey({
  item: { id, title },
  model,
  feedback,
}: {
  item: IntakeQuestionGroupItem
  model: SurveyModel
  feedback: SurveyModel
}) {
  const { candidate } = useContext(CandidateProfileContext)
  const { intakeQuestionGroup } = useContext(IntakeContext)
  const group = intakeQuestionGroup.get(title)

  useEffect(() => {
    model.setDataCore(candidate.answers)
    feedback.setDataCore(candidate.answers)
    if (group) {
      runInAction(() => {
        group.allQuestionCount = getAllQuestionsCount(model)
        group.answeredQuestionCount = calculateAnsweredQuestionsCount(model)
      })
    }
  }, [candidate.answers, feedback, group, model])

  useEffect(() => {
    const onValueChanged = (
      sender: SurveyModel,
      options: {
        name: keyof Answers
        value: Answers[keyof Answers]
      },
    ) => {
      runInAction(() => {
        if (group) {
          group.saving = true
          group.answeredQuestionCount = calculateAnsweredQuestionsCount(model)
          set(candidate.answers, { [options.name]: options.value })
        }
      })
    }

    model.onValueChanged.add(onValueChanged)
    feedback.onValueChanged.add(onValueChanged)
    return () => {
      model.onValueChanged.remove(onValueChanged)
      feedback.onValueChanged.remove(onValueChanged)
    }
  }, [candidate.answers, feedback, group, model])

  return (
    <IntakeSurveyAccordion title={title} id={id}>
      <Box sx={{ p: '24px' }}>
        <MuiSurvey model={model} hideNavigation />
      </Box>
      <InnerNote title="Staff Feedback">
        <MuiSurvey model={feedback} hideNavigation />
      </InnerNote>
    </IntakeSurveyAccordion>
  )
}

export default observer(IntakeSurvey)
