import { makeAutoObservable, ObservableMap } from 'mobx'
import { PortalUserDto } from '../../../_generated/portal-api'

function byFullName(a: PortalUserDto, b: PortalUserDto) {
  return (a.fullName || '').localeCompare(b.fullName || '', undefined, { sensitivity: 'base' })
}

const lastNameCache = new Map<PortalUserDto, string>()

function getLastName(user: PortalUserDto) {
  let lastName = lastNameCache.get(user)
  if (lastName) return lastName
  const fullName = user.fullName?.trim() || ''
  const lastIndexOfSpace = fullName.trim()?.lastIndexOf(' ') || -1
  lastName = (lastIndexOfSpace > 0 ? fullName.substring(lastIndexOfSpace + 1) : fullName).toLowerCase()
  lastNameCache.set(user, lastName)
  return lastName
}

function byLastName(a: PortalUserDto, b: PortalUserDto) {
  return getLastName(a).localeCompare(getLastName(b)) || byFullName(a, b)
}

export default class PortalUserStore {
  indexed = new ObservableMap<string, PortalUserDto>()

  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true })
  }

  getPortalUser(uuid?: string) {
    if (!uuid) return undefined
    return this.indexed.get(uuid)
  }

  get assignees() {
    return [...this.indexed.values()].filter((v) => v.roles?.find((role) => role === 'Assignee')).sort(byLastName)
  }

  get all() {
    return [...this.indexed.values()].sort(byLastName)
  }

  get indexedAssignees() {
    return new Map<string, PortalUserDto>(this.assignees.map((dto) => [dto.userObjectId, dto]))
  }
}
