import React, { ReactNode, useCallback, useContext } from 'react'
import { Chip, ListItemIcon, ListItemText } from '@mui/material'
import { observer } from 'mobx-react'
import { runInAction } from 'mobx'
import PortalRightRail, { StyledMenuItem } from '../../PortalRightRail'
import { colors } from '../../../../common/mui-theme'
import All from '../../../../common/icons/All'
import ChevronLeft from '../../../../common/icons/ChevronLeft'
import JobPostingsContext, { JobPostingFilter } from '../context/JobPostingsContext'
import { BookmarkCheckDuo } from '../../../../common/icons/BookmarkCheck'

// import Star from '../../../common/icons/Star'

export function JobMatchingRightRailItem({
  icon,
  title,
  itemCount,
  filter,
}: {
  icon: ReactNode
  title: string
  itemCount: number
  filter: JobPostingFilter
}) {
  const { setFilter } = useContext(JobPostingsContext)

  const applyFilter = useCallback(
    () =>
      runInAction(() => {
        setFilter(filter)
      }),
    [filter, setFilter],
  )

  return (
    <StyledMenuItem onClick={applyFilter}>
      <ChevronLeft />
      <ListItemIcon sx={{ minWidth: '0', m: 'auto' }}>{icon}</ListItemIcon>
      <ListItemText primary={title} />
      <ListItemIcon>
        <Chip size="small" label={itemCount} sx={{ backgroundColor: colors.neutral['75'] }} />
      </ListItemIcon>
    </StyledMenuItem>
  )
}

function JobMatchingFilterRightRail() {
  const { allCount, favouriteCount } = useContext(JobPostingsContext)

  return (
    <PortalRightRail title="Filter">
      <JobMatchingRightRailItem
        icon={<BookmarkCheckDuo />}
        itemCount={favouriteCount}
        filter="#favourite"
        title="Favorites"
      />
      {/* <JobMatchingRightRailItem icon={<Star />} itemCount={14} filter="#recommended" title="Recommended Jobs" /> */}
      <JobMatchingRightRailItem icon={<All />} itemCount={allCount} filter="#all" title="All Jobs" />
    </PortalRightRail>
  )
}

export default observer(JobMatchingFilterRightRail)
