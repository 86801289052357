import IconButton, { IconButtonProps } from '@mui/material/IconButton'
import React from 'react'
import { SxProps } from '@mui/material'

export default function ToggleIconButton({
  toggled,
  children,
  sx,
  ...other
}: IconButtonProps & SxProps & { toggled?: boolean }) {
  return (
    <IconButton sx={{ ...sx, opacity: toggled ? 1 : 0.5 }} {...other}>
      {children}
    </IconButton>
  )
}
