import React from 'react'
import Saved from '../../common/icons/Saved'
import { colors } from '../../common/mui-theme'
import AccordionHeaderBadge from './AccordionHeaderBadge'

export default function SavingBadge({ saving }: { saving: boolean }) {
  return (
    <AccordionHeaderBadge variant="neutral">
      {saving ? (
        'Saving'
      ) : (
        <>
          All changes saved <Saved width={20} height={20} style={{ color: colors.neutral[300] }} />
        </>
      )}
    </AccordionHeaderBadge>
  )
}
