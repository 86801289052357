import { IconProps } from './IconProps'

export default function Certificate({ width = 24, height = 24, style }: IconProps) {
  return (
    <svg width={width} height={height} style={style} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.12"
        d="M14.9779 16.3663C14.9925 16.2462 15 16.124 15 16C15 14.3431 13.6569 13 12 13C10.3431 13 9 14.3431 9 16C9 16.1166 9.00665 16.2316 9.01958 16.3447L6 19.3642L8.82843 22.1926L12.0108 19.0103L15.1931 22.1926L18.0215 19.3641L15.0021 16.3447L14.9779 16.3663Z"
        fill="currentColor"
      />
      <path
        d="M6.5 20H5C3.89543 20 3 19.1046 3 18V4C3 2.89543 3.89543 2 5 2H19C20.1046 2 21 2.89543 21 4V18C21 19.1046 20.1046 20 19 20H17.5M12 19C13.6569 19 15 17.6569 15 16C15 14.3431 13.6569 13 12 13C10.3431 13 9 14.3431 9 16C9 17.6569 10.3431 19 12 19ZM12 19L12.0214 18.9998L8.82867 22.1926L6.00024 19.3641L9.01965 16.3447M12 19L15.1928 22.1926L18.0212 19.3641L15.0018 16.3447M9 6H15M7 9.5H17"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
