import { useMutation, useQueryClient } from 'react-query'
import { NoteContentDto } from '../../_generated/portal-api'
import { usePortalApi } from '../PortalApiContext'

export default function useCreateCandidateNote(id: number) {
  const queryClient = useQueryClient()
  const api = usePortalApi()
  return useMutation({
    mutationFn: (noteContent: NoteContentDto) => api.notes.createNote(id, noteContent),
    onSuccess: () => queryClient.invalidateQueries(['user-notes', id]),
  })
}
