import React from 'react'
import { Typography } from '@mui/material'
import { observer } from 'mobx-react'
import { JobDetailsDto } from '../../_generated/portal-api'
import PlacementCard from './PlacementCard'
import { colors } from '../../../common/mui-theme'

function JobStatusesRender({ title, jobs }: { title: string; jobs: JobDetailsDto[] }) {
  return jobs.length > 0 ? (
    <>
      <Typography variant="h6" sx={{ color: colors.neutral['600'] }}>
        {title}
      </Typography>
      {jobs.map((job) => (
        <div key={job.id}>
          <PlacementCard job={job} />
        </div>
      ))}
    </>
  ) : null
}

export default observer(JobStatusesRender)
