import React, { useContext, useEffect } from 'react'
import { Observer, observer } from 'mobx-react'
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom'
import { Box, Link } from '@mui/material'
import DelayedProgressBar from '../components/DelayedProgressBar'
import NotFoundPage from './NotFoundPage'
import PageContentContainer from '../components/PageContentContainer'
import Profile from '../components/Profile'
import { useCandidateProfile } from '../api/hooks/candidateHooks'
import CandidateProfile from '../components/candidates/profile/candidate-profile'
import AlertsContext from '../../common/alerts/AlertsContext'
import ProfileTabs from '../components/ProfileTabs'
import SubNav, { Divider } from '../components/SubNav'
import { ArrowBackward } from '../../common/icons/Arrows'
import AssignDropdown from '../components/AssignDropdown'
import CandidateProfileContext, {
  useCandidateProfileContextValue,
} from '../components/candidate-profile/context/CandidateProfileContext'

export function BackToCandidates({ text = 'Back to Candidates' }: { text?: string }) {
  return (
    <Link
      component={RouterLink}
      underline="hover"
      to="/candidates"
      sx={{ display: 'flex', alignItems: 'center', gap: '28px' }}
      color="secondary"
    >
      <ArrowBackward />
      {text}
    </Link>
  )
}

function StaffAssignee() {
  const { candidate } = useContext(CandidateProfileContext)

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
      Staff Assignee:
      <Observer>
        {() => <AssignDropdown userId={candidate.dto.userId} assigneeId={candidate.dto.assigneeId} />}
      </Observer>
    </Box>
  )
}

function CandidateProfileContextProvider({
  candidate,
  children,
}: {
  candidate: CandidateProfile
  children: React.ReactNode
}) {
  const candidateProfileContext = useCandidateProfileContextValue(candidate)

  return <CandidateProfileContext.Provider value={candidateProfileContext}>{children}</CandidateProfileContext.Provider>
}

function CandidateProfilePage() {
  const { id = '-1' } = useParams()

  const { isLoading, error, data: candidate } = useCandidateProfile(+id)

  const { addAlert, removeAlerts } = useContext(AlertsContext)

  const location = useLocation()

  useEffect(() => {
    if (!isLoading && location.search === '?merged') {
      addAlert('success', 'Candidate entries merged successfully!', null, 'page', 'dismiss')
    }

    return () => removeAlerts((alert) => alert.title === 'Candidate entries merged successfully!')
  }, [addAlert, isLoading, location.search, removeAlerts])

  if (isLoading) return <DelayedProgressBar />

  if (error || !candidate) return <NotFoundPage />

  return (
    <CandidateProfileContextProvider candidate={candidate}>
      <SubNav
        left={
          <>
            <BackToCandidates />
            <Divider />
            <StaffAssignee />
          </>
        }
      />
      <PageContentContainer>
        <Box sx={{ mb: '24px' }}>
          <Profile />
        </Box>
        <ProfileTabs />
      </PageContentContainer>
    </CandidateProfileContextProvider>
  )
}

export default observer(CandidateProfilePage)
