import React, { useContext } from 'react'
import { observer } from 'mobx-react'
import JobDetailsAccordion from './JobDetailsAccordion'
import { useSelectedJob } from '../../../api/hooks/placementHooks'
import InterviewAccordion from './InterviewAccordion'
import ActivityLog from './ActivityLog'
import JobSubmissionAccordion from '../submission/JobSubmissionAccordion'
import PlaceCandidateAccordion from './PlaceCandidateAccordion'
import CandidateProfileContext from '../../candidate-profile/context/CandidateProfileContext'
import { WithRequired } from '../../../../common/type-utils'
import { JobDetailsDto, JobDto } from '../../../_generated/portal-api'
import JobInformation from '../../jobs/JobInformation'

function JobDetails() {
  const { candidate } = useContext(CandidateProfileContext)
  const { job } = useSelectedJob()
  return (
    <>
      {job?.posting ? <JobInformation job={job} /> : job?.details && <JobDetailsAccordion details={job.details} />}
      {candidate && job?.details?.submission && (
        <JobSubmissionAccordion
          candidate={candidate}
          details={job.details as WithRequired<JobDetailsDto, 'submission'>}
        />
      )}
      {job?.details?.interviews.map((interview, index) => (
        <InterviewAccordion
          key={interview.id}
          title={job?.details?.interviews.length === 1 ? 'Interview' : `Interview ${index + 1}`}
          interview={interview}
          id={`interview${index + 1}`}
        />
      ))}
      {job?.details?.placement && <PlaceCandidateAccordion job={job as WithRequired<JobDto, 'details'>} />}
      {job?.details && <ActivityLog details={job.details} />}
    </>
  )
}

export default observer(JobDetails)
