import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import '../common/global.css'
import './portal-app.css'
import { ThemeProvider } from '@mui/material/styles'
import { BrowserRouter } from 'react-router-dom'
import { AppInsightsContext, AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js'
import { QueryClient, QueryClientProvider } from 'react-query'
import { MsalProvider } from '@azure/msal-react'
import App from './App'
import appInsight from '../common/app-insights'
import msal from './api/msal'
import portalTheme from './portal-theme'
import PortalApiContext from './api/PortalApiContext'
import portalApi from './api/portal'
import ErrorPage from './pages/ErrorPage'

const container = document.getElementById('root') || document.body
const root = createRoot(container)
const queryClient = new QueryClient({ defaultOptions: { queries: { refetchOnWindowFocus: false } } })

root.render(
  <StrictMode>
    <BrowserRouter basename={import.meta.env.BASE_URL}>
      <ThemeProvider theme={portalTheme}>
        <AppInsightsContext.Provider value={appInsight}>
          <AppInsightsErrorBoundary appInsights={appInsight} onError={ErrorPage}>
            <MsalProvider instance={msal}>
              <QueryClientProvider client={queryClient}>
                <PortalApiContext.Provider value={portalApi}>
                  <App />
                </PortalApiContext.Provider>
              </QueryClientProvider>
            </MsalProvider>
          </AppInsightsErrorBoundary>
        </AppInsightsContext.Provider>
      </ThemeProvider>
    </BrowserRouter>
  </StrictMode>,
)
