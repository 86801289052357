import { alertsContext } from '../alerts/AlertsContext'
import { appInsightsApi, SeverityLevel } from '../app-insights'

const serverIsUnreachable = 'Server is unreachable'

export default function customFetch(...fetchParams: Parameters<typeof fetch>) {
  return fetch(...fetchParams)
    .then((response) => {
      if (response.ok) {
        // clean up server unreachable errors
        alertsContext.removeAlerts((alert) => alert.title === serverIsUnreachable)
        return Promise.resolve(response)
      }
      appInsightsApi.trackException({
        severityLevel: SeverityLevel.Error,
        exception: new Error(`Bad response code: ${response.status} - ${response.statusText}`),
        properties: { ...fetchParams, Component: 'FETCH' },
      })
      alertsContext.addAlert('error', 'Server Error', 'Sorry, an unexpected error has occurred.', 'system', 'dismiss')
      return Promise.reject(response)
    })
    .catch((reason) => {
      alertsContext.addAlert(
        'error',
        serverIsUnreachable,
        window.navigator.onLine ? 'Please retry later' : 'Please check your Internet connection.',
        'system',
        'dismiss',
      )
      appInsightsApi.trackException({
        severityLevel: SeverityLevel.Error,
        exception: reason,
        properties: { ...fetchParams, Component: 'FETCH' },
      })
      return Promise.reject(reason)
    })
}
