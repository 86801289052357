import { Box, Card, CardContent, CardHeader, Chip, Collapse, Typography } from '@mui/material'
import { useQuery } from 'react-query'
import IconButton from '@mui/material/IconButton'
import React, { MouseEventHandler, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { reaction, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import { Editable, Slate, withReact } from 'slate-react'
import { createEditor } from 'slate'
import { ChevronRight } from '@mui/icons-material'
import Add from '../../../common/icons/Add'
import Note from './context/note'
import UserNotes from './context/user-notes'
import InnerCard from './InnerCard'
import NoteEditor from './NoteEditorDialog'
import PortalUserContext from '../portal-user/context/PortalUserContext'
import CustomLeaf from '../rte/CustomLeaf'
import CustomBlock from '../rte/CustomBlock'
import LastEditedText from './LastEditedText'
import { usePortalApi } from '../../api/PortalApiContext'
import { colors } from '../../../common/mui-theme'
import CandidateProfileContext from '../candidate-profile/context/CandidateProfileContext'

type Notes = {
  notes: UserNotes
}

const AddNote = observer(({ notes, onClick }: Notes & { onClick: MouseEventHandler }) => (
  <IconButton aria-label="Add Note" disabled={notes.isLoading} onClick={onClick} sx={{ padding: '0px 8px 0px 8px' }}>
    <Add />
  </IconButton>
))

const NoteCard = observer(({ notes, note, collapsed }: Notes & { note: Note; collapsed?: boolean }) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(
    () =>
      reaction(
        () => note.dto.id,
        (newId, oldId) => {
          if (oldId === 0 && newId > 0 && ref.current) {
            ref.current.scrollIntoView()
          }
        },
      ),
    [note],
  )

  const onClick = useCallback(
    () =>
      runInAction(() => {
        // eslint-disable-next-line no-param-reassign
        notes.inEdit = note
      }),
    [note, notes],
  )

  const value = useMemo(
    () => (note.dto.content ? JSON.parse(note.dto.content) : [{ children: [{ text: '' }] }]),
    [note.dto.content],
  )
  const editor = useMemo(() => withReact(createEditor()), [])
  editor.children = value

  const title = note.dto.title?.trim() || '[Untitled]'

  return (
    <InnerCard
      ref={ref}
      title={title}
      onClick={onClick}
      actions={
        <Typography>
          <LastEditedText note={note} />
        </Typography>
      }
    >
      {!collapsed && (
        <Slate editor={editor} value={value}>
          <Editable readOnly renderLeaf={CustomLeaf} renderElement={CustomBlock} style={{ overflowWrap: 'anywhere' }} />
        </Slate>
      )}
    </InnerCard>
  )
})

function NoteListCard() {
  const { candidate } = useContext(CandidateProfileContext)
  const { user } = useContext(PortalUserContext)
  const api = usePortalApi()
  const notes = useMemo(() => new UserNotes(candidate.dto.userId), [candidate.dto.userId])
  useQuery(
    ['user-notes', candidate.dto.userId],
    () => api.notes.notes(candidate.dto.userId).then((r) => r.data.map((dto) => new Note(dto))),
    {
      onSuccess: (data) =>
        runInAction(() => {
          notes.loaded = data
        }),
    },
  )

  const addNote = useCallback(
    () =>
      runInAction(() => {
        const note = new Note({
          id: 0,
          title: '',
          content: '',
          timeStamp: new Date().toISOString(),
          createdBy: user?.fullName,
          updatedBy: user?.fullName,
        })
        notes.inEdit = note
        notes.loaded?.unshift(note)
      }),
    [notes, user?.fullName],
  )

  const [collapsed, setCollapsed] = useState(true)
  const onClick = useCallback(() => setCollapsed((s) => !s), [])
  const [first, ...others] = notes.visible || []

  return (
    <Card
      sx={{
        borderRadius: '16px',
        background: colors.primary['25'],
        '.MuiCard-root': {
          borderRadius: '8px',
        },
      }}
    >
      {/* Title */}
      <CardHeader
        sx={{ borderBottom: '0px', padding: '16px 16px 16px 24px', background: 'inherit' }}
        title={
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              fontSize: '24px',
              fontFamily: 'Satoshi',
              fontStyle: 'normal',
              fontWeight: '700',
              lineHeight: '32px',
            }}
          >
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <Box onClick={onClick} sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              Notes{' '}
              {!!notes.visible.length && (
                <Chip
                  label={notes.visible.length}
                  sx={{
                    color: colors.primary['600'],
                    textAlign: 'center',
                    background: colors.primary['200'],
                    borderRadius: '16px',
                    height: '20px',
                    minWidth: '20px',
                    padding: '1px 6px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    '& .MuiChip-label': {
                      color: colors.primary['600'],
                      textAlign: 'center',
                      fontSize: '12px',
                      fontStyle: 'normal',
                      fontWeight: '500',
                      lineHeight: '16px',
                      display: 'flex',
                      flexDirection: 'column',
                      flex: '1 0 0',
                      padding: '0px',
                    },
                  }}
                />
              )}
              {others.length > 0 && (
                <ChevronRight style={{ transform: collapsed ? undefined : 'rotate(90deg)', cursor: 'pointer' }} />
              )}
            </Box>
            <AddNote notes={notes} onClick={addNote} />
          </Box>
        }
      />
      <CardContent sx={{ padding: '0px 24px 20px 24px' }}>
        <Box sx={{ overflowY: 'auto', maxHeight: '624px' }}>
          {notes.visible.length === 0 && (
            <InnerCard title="Add a Note" onClick={addNote}>
              Click here to add your first note
            </InnerCard>
          )}
          {/* Show one latest note card */}
          {notes.visible.length > 0 && <NoteCard collapsed={collapsed} key={first.dto.id} notes={notes} note={first} />}
          {/* Show other note cards */}
          <Collapse in={!collapsed}>
            {others.length > 0 &&
              (collapsed ? [] : others).map((note) => <NoteCard key={note.dto.id} notes={notes} note={note} />)}
          </Collapse>
        </Box>
      </CardContent>
      <NoteEditor notes={notes} />
    </Card>
  )
}

export default observer(NoteListCard)
