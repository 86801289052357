import { Button, Tooltip } from '@mui/material'
import { useCallback, useContext, useState } from 'react'
import { observer } from 'mobx-react'
import Export from '../../../common/icons/Export'
import CandidatesGridContext from './grid/CandidatesGridContext'
import exportToExcel from './context/candidate-export'
import CandidateCreator from './create/CandidateCreator'
import CandidateContentBox from './CandidateContentBox'
import { useFeatureFlags } from '../../feature-flags/FeatureFlagsContext'
import FilterToggle from './filter/FilterToggle'

function CandidatesGridActions() {
  const { enabled } = useFeatureFlags()
  const excludeAssignee = enabled.has('EXCLUDE_ASSIGNEE_COLUMN')
  const { isEmpty, candidates } = useContext(CandidatesGridContext)
  const [exporting, setExporting] = useState(false)
  const handleExport = useCallback(() => {
    setExporting(true)
    exportToExcel(candidates, excludeAssignee).finally(() => setExporting(false))
  }, [candidates, excludeAssignee])
  const exportCandidates = !enabled.has('EXCLUDE_EXPORT_CANDIDATES')
  const createCandidate = !enabled.has('EXCLUDE_PROFILE_EDIT')
  if (!exportCandidates && !createCandidate) return null
  return (
    <CandidateContentBox sx={{ gap: '16px' }}>
      <FilterToggle />
      <div style={{ flex: 1 }} />
      {createCandidate && <CandidateCreator />}
      {exportCandidates && (
        <Tooltip title="Export current view of candidates" placement="bottom">
          <Button
            size="large"
            color="secondary"
            onClick={handleExport}
            startIcon={<Export />}
            disabled={isEmpty || exporting}
          >
            Export
          </Button>
        </Tooltip>
      )}
    </CandidateContentBox>
  )
}

export default observer(CandidatesGridActions)
