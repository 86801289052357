import React, { useCallback, useContext, useState } from 'react'
import { runInAction } from 'mobx'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react'
import { Chip } from '@mui/material'
import PortalRightRail, { RightRailItem } from '../PortalRightRail'
import {
  JOB_SUBMISSION_ANCHOR,
  jobInterviewLimit,
  PLACE_CANDIDATE_ANCHOR,
  useAddInterview,
  useAddPlacement,
  useAddSubmission,
  useSelectedJob,
} from '../../api/hooks/placementHooks'
import { usePortalApi } from '../../api/PortalApiContext'
import CustomSnackbar from '../../../common/alerts/CustomSnackbar'
import { CheckCircleDoubleColor } from '../../../common/icons/CheckCircle'
import { colors, palette } from '../../../common/mui-theme'
import CandidateProfileContext from '../candidate-profile/context/CandidateProfileContext'
import { JobDto } from '../../_generated/portal-api'

export function SectionIndicator({ state, hidden }: { state: string; hidden?: boolean }) {
  if (hidden) return null
  switch (state) {
    case 'completed':
      return <CheckCircleDoubleColor width={24} height={24} style={{ display: 'flex', color: colors.success['400'] }} />
    case 'export_created':
      return (
        <Chip
          sx={{ display: 'flex', lineHeight: '16px', backgroundColor: palette.primary.main, color: colors.base.white }}
          size="small"
          label="Export Created"
        />
      )
    case 'not_completed':
      return <Chip sx={{ display: 'flex', lineHeight: '16px' }} size="small" label="Not Completed" color="error" />
    default:
      return null
  }
}

function PlacementRightRail() {
  const { candidate } = useContext(CandidateProfileContext)
  const { loading, job } = useSelectedJob()
  const api = usePortalApi()
  const navigate = useNavigate()
  const [submitting, setSubmitting] = useState(false)

  const archiveJob = useCallback(() => {
    if (job?.details) {
      const { details } = job
      setSubmitting(true)
      api.jobs
        .archiveJob(details.id)
        .then(() => {
          runInAction(() => {
            details.archived = true
          })
          // invalidateActivityLog(job.id) - navigation happened no need reload it now
          navigate(`/candidates/${candidate.dto.userId}/placement`)
          CustomSnackbar.show('Job archived')
        })
        .finally(() => setSubmitting(false))
    }
  }, [api.jobs, candidate.dto.userId, job, navigate])

  const createSubmission = useAddSubmission(job)
  const createInterview = useAddInterview(job)
  const createPlacement = useAddPlacement(job)

  const scrollTo = useCallback(
    (j: JobDto, sectionID: string) => {
      const anchor = sectionID ? `#${sectionID}` : ''
      navigate(`/candidates/${candidate.dto.userId}/placement/${j.details?.id}${anchor}`, {
        replace: !!anchor,
      })
    },
    [candidate.dto.userId, navigate],
  )

  const onNewInterviewCreate = useCallback(() => {
    setSubmitting(true)
    if (job?.details) {
      createInterview()
        .then((j) => scrollTo(j, `interview${j.details?.interviews.length}`))
        .finally(() => setSubmitting(false))
    } else {
      createInterview()
        .then((j) => scrollTo(j, `interview${j.details?.interviews.length}`))
        .finally(() => setSubmitting(false))
    }
  }, [createInterview, job, scrollTo])

  const onSubmitCandidateClick = useCallback(() => {
    if (job?.details)
      if (job.details.submission) scrollTo(job, JOB_SUBMISSION_ANCHOR)
      else {
        setSubmitting(true)
        createSubmission()
          .then((j) => scrollTo(j, JOB_SUBMISSION_ANCHOR))
          .finally(() => setSubmitting(false))
      }
    else {
      setSubmitting(true)
      createSubmission()
        .then((j) => scrollTo(j, JOB_SUBMISSION_ANCHOR))
        .finally(() => setSubmitting(false))
    }
  }, [job, scrollTo, createSubmission])

  const onPlaceCandidateClick = useCallback(() => {
    if (job?.details)
      if (job.details.placement) scrollTo(job, PLACE_CANDIDATE_ANCHOR)
      else {
        setSubmitting(true)
        createPlacement()
          .then((j) => scrollTo(j, PLACE_CANDIDATE_ANCHOR))
          .finally(() => setSubmitting(false))
      }
    else {
      setSubmitting(true)
      createPlacement()
        .then((j) => scrollTo(j, PLACE_CANDIDATE_ANCHOR))
        .finally(() => setSubmitting(false))
    }
  }, [job, scrollTo, createPlacement])

  const submissionBadgeState = () => {
    if (job?.details?.submission?.sent) return 'completed'
    if (job?.details?.submission?.exportCreated) return 'export_created'
    return 'not_completed'
  }

  if (loading) return null
  return (
    <PortalRightRail title="Placement Actions">
      <RightRailItem
        title="Submit Candidate"
        onClick={onSubmitCandidateClick}
        badge={<SectionIndicator state={submissionBadgeState()} hidden={!job || !job.details?.submission} />}
        disabled={submitting}
      />
      {job?.details &&
        job.details?.interviews.map((interview, index) => (
          <RightRailItem
            key={interview.id}
            title={job.details?.interviews.length === 1 ? 'Interview' : `Interview ${index + 1}`}
            onClick={() => scrollTo(job, `interview${index + 1}`)}
            badge={<SectionIndicator state={interview.completed ? 'completed' : 'not_completed'} />}
          />
        ))}
      {(!job?.details || job.details.interviews.length < jobInterviewLimit) && (
        <RightRailItem
          title={!job?.details?.interviews.length ? 'Add Interview' : 'Add Another Interview'}
          onClick={onNewInterviewCreate}
          disabled={submitting}
        />
      )}
      <RightRailItem
        title="Place Candidate"
        onClick={onPlaceCandidateClick}
        badge={
          <SectionIndicator
            state={job?.details?.placement?.completed ? 'completed' : 'not_completed'}
            hidden={!job?.details?.placement}
          />
        }
        disabled={submitting}
      />
      {job && <RightRailItem title="View Activity" onClick={() => scrollTo(job, 'job_activity')} />}
      {job && !job.details?.archived && (
        <RightRailItem title="Archive Job" onClick={archiveJob} disabled={submitting} />
      )}
    </PortalRightRail>
  )
}

export default observer(PlacementRightRail)
