import { makeAutoObservable } from 'mobx'
import { createContext, RefObject } from 'react'
import { TableVirtuosoHandle } from 'react-virtuoso'
import { candidatesContext, CandidatesContextValue } from '../context/CandidatesContext'
import Candidate from '../context/candidate'
import { byFullName } from '../context/candidates-store'

type Sort<T> = {
  desc: boolean
  compare: (a: T, b: T) => number
}

export type Column<T> = {
  sort?: Sort<T>
  width?: string
}

const AppliedDateSort: Sort<Candidate> = {
  desc: false,
  compare: (a, b) => (a.applied?.getTime() || 0) - (b.applied?.getTime() || 0),
}

export const Columns = {
  fullName: {
    sort: {
      desc: false,
      compare: byFullName,
    },
  } as Column<Candidate>,
  staffAssignee: {
    sort: {
      desc: false,
      compare: (a, b) => a.assigneeFullNameLowerCased.localeCompare(b.assigneeFullNameLowerCased),
    },
    width: '20%',
  } as Column<Candidate>,
  experience: {
    sort: {
      desc: false,
      compare: (a, b) => a.experience.localeCompare(b.experience),
    },
    width: '15%',
  } as Column<Candidate>,
  appliedDate: {
    sort: AppliedDateSort,
    width: '15%',
  } as Column<Candidate>,
  lastActivityDate: {
    sort: {
      desc: true,
      compare: (a, b) => (a.lastActivity?.getTime() || 0) - (b.lastActivity?.getTime() || 0),
    },
    width: '18%',
  } as Column<Candidate>,
}

export class CandidatesGridContextValue {
  columns = Columns

  sort = AppliedDateSort

  virtuoso: RefObject<TableVirtuosoHandle> | null = null

  highlightedCandidateId: number | null = null

  constructor(public readonly ctx: CandidatesContextValue, public readonly withFilter = true) {
    makeAutoObservable(this, undefined, { autoBind: true })
    if (this.columns.appliedDate.sort) {
      this.sort = this.columns.appliedDate.sort
    }
  }

  get candidates() {
    const result = (
      (this.withFilter ? this.ctx.filter.result?.slice() : this.ctx.store.candidates?.slice()) || []
    ).sort(this.sort.compare)
    if (this.sort.desc) return result.reverse()
    return result
  }

  get isEmpty() {
    return !this.ctx.filter.result?.length
  }

  setVirtuoso(virtuoso: RefObject<TableVirtuosoHandle>) {
    this.virtuoso = virtuoso
  }
}

export const gridContextValue = new CandidatesGridContextValue(candidatesContext)

const CandidatesGridContext = createContext<CandidatesGridContextValue>(gridContextValue)

export default CandidatesGridContext
