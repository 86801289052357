import React, { useCallback, useEffect, useState } from 'react'
import {
  Box,
  Checkbox,
  debounce,
  FormControlLabel,
  FormGroup,
  Grid,
  styled,
  TextField,
  Typography,
} from '@mui/material'
import { z } from 'zod'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { observer, Observer } from 'mobx-react'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs, { Dayjs } from 'dayjs'
import { runInAction } from 'mobx'
import MinimizableSurveyAccordion, {
  MinimizableSurveyAccordionProps,
} from '../../survey-answers/MinimizableSurveyAccordion'
import MaskedPhoneInput, { PHONE_REGEX } from '../../../../common/components/MaskedPhoneInput'
import CustomDatePicker from '../../../../common/survey/custom-date-picker/CustomDatePicker'
import formats from '../../../../common/survey/formats'
import { Interview } from '../../../_generated/portal-api'
import { usePortalApi } from '../../../api/PortalApiContext'
import CustomDateTimePicker from '../../../../common/survey/custom-date-time-picker/CustomDateTimePicker'
import Check from '../../../../common/icons/Check'
import Close from '../../../../common/icons/Close'
import { colors } from '../../../../common/mui-theme'
import InnerNote from '../../InnerNote'
import MinimizedView from './MinimizedView'
import AccordionHeaderBadge from '../../AccordionHeaderBadge'
import { useInvalidateActivityLog } from '../../../api/hooks/placementHooks'
import { MAX_TIMESTAMP } from './placement-schema'
import { notEnteredYet } from '../../titles'

const DottedDivider = styled('div')(() => ({
  margin: '1px 0 36px',
  height: '1px',
  background: 'transparent',
  backgroundPosition: 'top',
  backgroundImage: `linear-gradient(to right, ${colors.base.white} 80%, ${colors.neutral['200']} 0%)`,
  backgroundSize: '5px 1px',
  backgroundRepeat: 'repeat-x',
}))

const dateSchema = z
  .instanceof(dayjs as unknown as typeof Dayjs, { message: 'Date is required' })
  .nullable()
  .refine((date) => !date || (date?.isValid() && date?.isBefore(MAX_TIMESTAMP)), { message: 'Date is invalid' })

const interviewTrackableSchema = z.object({
  interviewedAt: dateSchema,
  interviewerFirstName: z.string().trim(),
  interviewerLastName: z.string().trim(),
  interviewerEmail: z.string().trim().email('Please enter a valid e-mail address').optional().or(z.literal('')),
  interviewerPhone: z
    .string()
    .trim()
    .regex(PHONE_REGEX, 'Enter a valid phone number')
    .optional()
    .or(z.literal(''))
    .transform((s) => s || ''),
  candidateFeedbackDate: dateSchema,
  candidateFeedback: z.string().trim(),
  interviewerFeedbackDate: dateSchema,
  interviewerFeedback: z.string().trim(),
  notes: z.string().trim(),
})

const interviewSchema = z.object({
  setUp: z.boolean(),
  completed: z.boolean(),
  ...interviewTrackableSchema.shape,
})

const trackableQuestions = Object.keys(interviewTrackableSchema.shape) as Array<
  keyof z.infer<typeof interviewTrackableSchema>
>
const trackableQuestionCount = trackableQuestions.length

type InterviewAccordionProp = Pick<MinimizableSurveyAccordionProps, 'title' | 'id'> & {
  interview: Interview
}

type FormDate = Dayjs | null | undefined
const dateFormat = (date: FormDate, template?: string) =>
  !date || !date.isValid() || date.isAfter(MAX_TIMESTAMP) ? undefined : date.format(template)
const parseDate = (date: string | undefined) => {
  if (!date) return null
  const result = dayjs(date)
  return result.isValid() ? result : null
}

const SolidDivider = styled('div')(() => ({
  margin: '17px 0 34px',
  borderBottom: `1px solid ${colors.neutral['200']}`,
}))

const controlledFiledOptions = { shouldValidate: true, shouldDirty: true }

function InterviewAccordion({ title, id, interview }: InterviewAccordionProp) {
  const [saving, setSaving] = useState(false)
  const [expanded, setExpanded] = useState(true)
  const expand = useCallback(() => setExpanded(true), [])
  const {
    register,
    getFieldState,
    control,
    watch,
    getValues,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<z.infer<typeof interviewSchema>>({
    resolver: zodResolver(interviewSchema),
    mode: 'all',
    defaultValues: {
      completed: interview.completed === null ? undefined : interview.completed,
      setUp: interview.setUp === null ? undefined : interview.setUp,
      candidateFeedback: interview.candidateFeedback || '',
      notes: interview.notes || '',
      interviewerFirstName: interview.interviewerFirstName || '',
      interviewerLastName: interview.interviewerLastName || '',
      interviewerFeedback: interview.interviewerFeedback || '',
      interviewerPhone: interview.interviewerPhone || '',
      interviewerEmail: interview.interviewerEmail || '',
      candidateFeedbackDate: parseDate(interview.candidateFeedbackDate),
      interviewerFeedbackDate: parseDate(interview.interviewerFeedbackDate),
      interviewedAt: parseDate(interview.interviewedAt),
    },
  })

  const [interviewedAt, candidateFeedbackDate, interviewerFeedbackDate, setUp, completed] = watch([
    'interviewedAt',
    'candidateFeedbackDate',
    'interviewerFeedbackDate',
    'setUp',
    'completed',
  ])

  const api = usePortalApi()
  const invalidateActivityLog = useInvalidateActivityLog()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const saveInterview = useCallback(
    debounce(() => {
      api.interview.patchInterview(interview.id, interview).then(() => {
        setSaving(false)
      })
    }, 1500),
    [api.interview, interview.id],
  )

  useEffect(() => {
    const { unsubscribe } = watch((value, info) => {
      const i = interview
      setSaving(true)
      runInAction(() => {
        switch (info.name) {
          case undefined:
            break
          case 'candidateFeedbackDate':
          case 'interviewerFeedbackDate':
            i[info.name] = dateFormat(value[info.name] as FormDate, 'YYYY-MM-DD')
            break
          case 'interviewedAt':
            i.interviewedAt = dateFormat(value.interviewedAt as FormDate)
            break
          case 'setUp':
          case 'completed':
            i[info.name] = !!value[info.name]
            if (value[info.name]) invalidateActivityLog(interview.jobDetailsId)
            break
          case 'interviewerFirstName':
          case 'interviewerLastName':
          case 'interviewerEmail':
          case 'interviewerPhone':
          case 'candidateFeedback':
          case 'interviewerFeedback':
          case 'notes':
            i[info.name] = value[info.name] || undefined
            break
          default:
            break
        }
      })
      saveInterview()
    })

    return unsubscribe
  }, [interview, invalidateActivityLog, saveInterview, watch])

  useEffect(() => {
    trigger(['interviewerEmail', 'interviewerPhone']).then()
  }, [trigger])

  const getAnsweredCount = useCallback(
    () => trackableQuestions.filter((key) => !!getValues(key) && !getFieldState(key).invalid).length,
    [getFieldState, getValues],
  )

  const onInterviewDateTimeChange = useCallback(
    (data: Dayjs | null) => {
      setValue('interviewedAt', data, controlledFiledOptions)
    },
    [setValue],
  )

  const onCandidateFeedbackDateChange = useCallback(
    (data: Dayjs | null) => {
      setValue('candidateFeedbackDate', data, controlledFiledOptions)
    },
    [setValue],
  )

  const onInterviewerFeedbackDateChange = useCallback(
    (data: Dayjs | null) => {
      setValue('interviewerFeedbackDate', data, controlledFiledOptions)
    },
    [setValue],
  )

  const getSubmissionContact = useCallback(() => {
    const { interviewerFirstName, interviewerLastName, interviewerEmail, interviewerPhone: phone } = getValues()
    const interviewerFullName = [interviewerFirstName, interviewerLastName].filter(Boolean).join(' ')

    return [interviewerFullName, interviewerEmail, phone].filter(Boolean).join(', ')
  }, [getValues])

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MinimizableSurveyAccordion
        id={id}
        title={title}
        saving={saving}
        expanded={expanded}
        setExpanded={setExpanded}
        allQuestionCount={trackableQuestionCount}
        answeredQuestionCount={getAnsweredCount()}
        minimizedHeaderBadge={
          <AccordionHeaderBadge variant={completed || setUp ? 'info' : 'error'}>
            <>
              {(completed && 'Interview Completed') || (setUp && 'Interview Set up') || 'Not Completed'}
              {completed || setUp ? <Check width={16} height={16} /> : <Close width={16} height={16} />}
            </>
          </AccordionHeaderBadge>
        }
        collapsedContent={
          <MinimizedView
            isEditable
            onEdit={expand}
            items={[
              { title: 'Interview Date', value: dateFormat(interviewedAt, formats.dateTime) || notEnteredYet },
              { title: 'Submission Contact', value: getSubmissionContact() || notEnteredYet },
            ]}
          />
        }
      >
        <Observer>
          {() => (
            <>
              <Box sx={{ p: '22px' }}>
                <Typography variant="subtitle1" sx={{ mt: 0, mb: '2px' }}>
                  Confirm Interview Steps
                </Typography>

                <Box sx={{ display: 'flex', gap: '16px' }}>
                  <FormGroup sx={{ flexDirection: 'row' }}>
                    <Controller
                      name="setUp"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          control={<Checkbox {...field} checked={field.value || false} />}
                          label="Interview set up"
                        />
                      )}
                    />

                    <Controller
                      name="completed"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          control={<Checkbox {...field} checked={field.value || false} />}
                          label="Interview completed"
                        />
                      )}
                    />
                  </FormGroup>
                </Box>

                <SolidDivider />

                <Box sx={{ display: 'flex', gap: '16px' }}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                    <CustomDateTimePicker
                      label="Interview Date"
                      format={formats.dateTime}
                      views={['year', 'month', 'day', 'hours', 'minutes']}
                      maxDate={MAX_TIMESTAMP}
                      value={interviewedAt}
                      onChange={onInterviewDateTimeChange}
                      onAccept={onInterviewDateTimeChange}
                      error={!!errors.interviewedAt?.message}
                      helperText={errors.interviewedAt?.message || ' '}
                      fullWidth
                    />
                  </Box>
                </Box>

                <Typography variant="body1" sx={{ my: '14px' }}>
                  Interview Contact Details
                </Typography>

                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      sx={{ mb: '5px' }}
                      label="First Name"
                      fullWidth
                      {...register('interviewerFirstName')}
                      inputProps={{ maxLength: 45 }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Last Name"
                      fullWidth
                      {...register('interviewerLastName')}
                      inputProps={{ maxLength: 45 }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Email"
                      fullWidth
                      {...register('interviewerEmail')}
                      error={!!errors.interviewerEmail}
                      helperText={errors.interviewerEmail?.message || ' '}
                      inputProps={{ maxLength: 255 }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      control={control}
                      name="interviewerPhone"
                      render={({ field }) => (
                        <TextField
                          label="Phone"
                          {...field}
                          onInput={field.onChange}
                          error={!!errors.interviewerPhone}
                          helperText={errors.interviewerPhone?.message || ' '}
                          InputProps={{
                            inputComponent: MaskedPhoneInput,
                          }}
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                </Grid>

                <DottedDivider />

                <Box sx={{ display: 'flex', gap: '16px' }}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                    <CustomDatePicker
                      label="Feedback Date"
                      format={formats.date}
                      views={['year', 'month', 'day']}
                      value={candidateFeedbackDate}
                      maxDate={MAX_TIMESTAMP}
                      onChange={onCandidateFeedbackDateChange}
                      onAccept={onCandidateFeedbackDateChange}
                      error={!!errors.candidateFeedbackDate?.message}
                      helperText={errors.candidateFeedbackDate?.message || ' '}
                      fullWidth
                    />
                  </Box>
                </Box>

                <Typography variant="body1" sx={{ mb: '7px' }}>
                  Interview Feedback from Candidate
                </Typography>

                <TextField
                  multiline
                  fullWidth
                  rows={4}
                  placeholder="Elaborate on any additional interview information..."
                  {...register('candidateFeedback')}
                  inputProps={{ maxLength: 1000 }}
                />

                <Box sx={{ display: 'flex', gap: '16px', mt: '37px' }}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                    <CustomDatePicker
                      label="Feedback Date"
                      format={formats.date}
                      views={['year', 'month', 'day']}
                      maxDate={MAX_TIMESTAMP}
                      value={interviewerFeedbackDate}
                      onChange={onInterviewerFeedbackDateChange}
                      onAccept={onInterviewerFeedbackDateChange}
                      error={!!errors.interviewerFeedbackDate?.message}
                      helperText={errors.interviewerFeedbackDate?.message || ' '}
                      fullWidth
                    />
                  </Box>
                </Box>

                <Typography variant="body1" sx={{ mb: '7px' }}>
                  Interview Feedback from Interviewer
                </Typography>

                <TextField
                  multiline
                  fullWidth
                  rows={4}
                  placeholder="Elaborate on any additional interview information..."
                  {...register('interviewerFeedback')}
                  inputProps={{ maxLength: 1000 }}
                />
              </Box>
              <InnerNote title="Additional Interview Details">
                <TextField
                  multiline
                  fullWidth
                  rows={4}
                  placeholder="Elaborate on any additional interview information..."
                  {...register('notes')}
                  inputProps={{ maxLength: 1000 }}
                />
              </InnerNote>
            </>
          )}
        </Observer>
      </MinimizableSurveyAccordion>
    </LocalizationProvider>
  )
}

export default observer(InterviewAccordion)
