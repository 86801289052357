import React from 'react'
import { SurveyQuestionPanelDynamic } from 'survey-react-ui'
import MuiPanelDynamicItem from './MuiPanelDynamicItem'

export default class MuiPanelDynamic extends SurveyQuestionPanelDynamic {
  protected renderElement(): JSX.Element {
    const panels: Array<JSX.Element> = []
    if (this.question.isRenderModeList) {
      for (let i = 0; i < this.question.panels.length; i++) {
        const panel = this.question.panels[i]
        panels.push(
          <MuiPanelDynamicItem
            key={panel.id}
            element={panel}
            question={this.question}
            index={i}
            cssClasses={this.question.cssClasses}
            isDisplayMode={this.isDisplayMode}
            creator={this.creator}
          />,
        )
      }
    } else if (this.question.currentPanel != null) {
      const panel = this.question.currentPanel
      panels.push(
        <MuiPanelDynamicItem
          key={this.question.currentIndex}
          element={panel}
          question={this.question}
          index={this.question.currentIndex}
          cssClasses={this.question.cssClasses}
          isDisplayMode={this.isDisplayMode}
          creator={this.creator}
        />,
      )
    }
    const navTop: JSX.Element | null = this.question.isProgressTopShowing ? this.renderNavigator() : null
    const navBottom: JSX.Element | null = this.question.isProgressBottomShowing ? this.renderNavigator() : null

    //   const style: any = {}
    const navV2: JSX.Element | null = this.renderNavigatorV2()
    const noEntriesPlaceholder = this.renderPlaceholder()
    return (
      <div className={this.question.cssClasses.root}>
        {noEntriesPlaceholder}
        {navTop}
        {panels}
        {navBottom}
        {navV2}
      </div>
    )
  }
}
