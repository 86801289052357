import { createContext, useCallback, useContext, useEffect } from 'react'
import { PublicClientApplication } from '@azure/msal-browser'
import { autorun, makeAutoObservable, observable, runInAction } from 'mobx'
import { debounce } from '@mui/material'
import JobPostingStore from './job-posting-store'
import { InitSearchJobPostingsParams, PortalApi } from '../../../_generated/portal-api'
import CandidateProfileContext from '../../candidate-profile/context/CandidateProfileContext'
import portalApi from '../../../api/portal'

const batchSize = 50

function hash(userId: number, newQuery: string, filter: JobPostingFilter) {
  return `${userId}-${filter}-${newQuery}`
}

export class JobPostingsContextValue {
  store = new JobPostingStore()

  query: string | null = null

  filter: JobPostingFilter | null = null

  gridScrollTop: number | undefined = undefined

  gridItemIndex = 0

  allCount = 0

  favouriteCount = 0

  hash = ''

  debouncedUserQuery: InitSearchJobPostingsParams | null = null

  readonly setDebouncedUserQuery = debounce(
    (q: InitSearchJobPostingsParams) =>
      runInAction(() => {
        this.debouncedUserQuery = q
      }),
    500,
  )

  debouncedAdminQuery: string | null = null

  setDebouncedAdminQuery = debounce(
    (q: string) =>
      runInAction(() => {
        this.debouncedAdminQuery = q
      }),
    500,
  )

  constructor(public readonly api: PortalApi<PublicClientApplication>) {
    makeAutoObservable(
      this,
      {
        debouncedUserQuery: observable.ref,
        setDebouncedUserQuery: observable.ref,
        setDebouncedAdminQuery: observable.ref,
      },
      { autoBind: true },
    )
  }

  get initApi() {
    return this.filter === '#favourite'
      ? this.api.candidates.initSearchFeaturedJobPostings
      : this.api.candidates.initSearchJobPostings
  }

  loadUserJobs() {
    if (this.debouncedUserQuery) {
      this.initApi(this.debouncedUserQuery)
        .then((response) => response.data)
        .then((data) => {
          this.store.init(data.jobPostings)
          runInAction(() => {
            this.allCount = data.allCount
            this.favouriteCount = data.featuredCount
          })
        })
    }
  }

  initUserJobs(userId: number, newQuery: string, filter: JobPostingFilter) {
    const newHash = hash(userId, newQuery, filter)
    if (this.hash !== newHash) {
      runInAction(() => {
        this.hash = newHash
        this.query = newQuery
        this.filter = filter
      })
      this.setDebouncedUserQuery({
        userId,
        limit: batchSize,
        query: newQuery,
      })
    }
  }

  loadMoreUserJobs(userId: number, lastIndex: number) {
    this.api.candidates[(this.filter === '#favourite' && 'searchFeaturedJobPostings') || 'searchJobPostings']({
      userId,
      limit: batchSize,
      offset: lastIndex + 1,
      query: this.query || '',
    })
      .then((response) => response.data)
      .then((jobs) => this.store.update(jobs))
  }

  initAdminJobs(newQuery: string) {
    this.query = newQuery
    this.setDebouncedAdminQuery(newQuery)
  }

  loadAdminJobs() {
    if (this.debouncedAdminQuery === null) return
    this.api.jobs
      .search({ limit: batchSize, offset: 0, query: this.debouncedAdminQuery })
      .then((response) => response.data)
      .then((jobs) => this.store.init(jobs))

    this.api.jobs
      .getCount({ query: this.debouncedAdminQuery })
      .then((response) => response.data)
      .then((count) => this.store.updateChipCount(count))
  }

  loadMoreAdminJobs(lastIndex: number) {
    this.api.jobs
      .search({ limit: batchSize, offset: lastIndex + 1, query: this.query || '' })
      .then((response) => response.data)
      .then((jobs) => this.store.update(jobs))
  }

  increaseFavouriteCount() {
    this.favouriteCount += 1
  }

  decreaseFavouriteCount() {
    this.favouriteCount -= 1
  }

  setFilter(filter: JobPostingFilter) {
    this.filter = filter
  }

  setQuery(query: string) {
    runInAction(() => {
      this.query = query
    })
  }

  saveGridPosition(scrollTop: number | undefined, itemIndex: number) {
    runInAction(() => {
      this.gridScrollTop = scrollTop
      this.gridItemIndex = itemIndex
    })
  }
}

export const jobPostingsContextDefaultValue = new JobPostingsContextValue(portalApi)
const JobPostingsContext = createContext(jobPostingsContextDefaultValue)

export const jobPostingFilters = ['#all', '#favourite', '#recommended'] as const
export type JobPostingFilter = (typeof jobPostingFilters)[number]

export function useUserJobPostings(newQuery: string, filter: JobPostingFilter, userId: number) {
  const ctx = useContext(JobPostingsContext)
  useEffect(() => ctx.initUserJobs(userId, newQuery, filter), [ctx, filter, newQuery, userId])
  useEffect(() => autorun(() => ctx.loadUserJobs()), [ctx])
  return ctx
}

export function useMoreUserJobPostings() {
  const ctx = useContext(JobPostingsContext)
  const { candidate } = useContext(CandidateProfileContext)
  return useCallback(
    (lastIndex: number) => ctx.loadMoreUserJobs(candidate.dto.userId, lastIndex),
    [candidate.dto.userId, ctx],
  )
}

export function useAdminJobPostings() {
  const ctx = useContext(JobPostingsContext)
  const { query, initAdminJobs, loadAdminJobs } = ctx
  useEffect(() => initAdminJobs(query || ''), [initAdminJobs, query])
  useEffect(() => autorun(() => loadAdminJobs()), [loadAdminJobs])
  return ctx
}

export function useMoreAdminJobPostings() {
  const ctx = useContext(JobPostingsContext)
  return useCallback((lastIndex: number) => ctx.loadMoreAdminJobs(lastIndex), [ctx])
}

export default JobPostingsContext
