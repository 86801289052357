import { observer } from 'mobx-react'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import Note from './context/note'
import nop from '../../../common/nop'

function LastEditedText({ note }: { note: Note }) {
  const date = dayjs(note.updatedAt)
  const now = dayjs()
  const isToday = date.isSame(now, 'date')
  const [, setState] = useState(false)
  useEffect(() => {
    if (isToday) {
      const timeOut = setTimeout(() => setState((s) => !s), now.endOf('day').valueOf() - date.valueOf())
      return () => clearTimeout(timeOut)
    }
    return nop
  }, [date, isToday, now])
  return (
    <span>
      {date.format(isToday ? 'h:mm a' : 'MMM D, YYYY')} by {note.dto.updatedBy}
    </span>
  )
}

export default observer(LastEditedText)
