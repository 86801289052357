import React from 'react'
import { SurveyElementBase, SurveyQuestionElementBase } from 'survey-react-ui'
import Button from '@mui/material/Button'
import { PageModel, Question, SurveyModel } from 'survey-core'
import { FormControlLabel, Radio } from '@mui/material'
import css from './MuiBoolean.module.css'

export default class MuiBoolean extends SurveyQuestionElementBase {
  get mode(): string {
    const question = this.props.question as Question
    return question.getPropertyValue('mode')
  }

  private onChange(value: boolean) {
    const { question } = this.props
    question.booleanValue = value
    if (this.mode === 'radio') return
    const page = question.page as PageModel
    if (page.elements.length === 1) {
      const survey = question.survey as SurveyModel
      if (!survey.nextPage()) {
        survey.completeLastPage()
      }
    }
  }

  protected renderElement(): JSX.Element | null {
    const { question } = this.props
    const { cssClasses } = question
    const itemClass = question.getCheckboxItemCss()
    const description = question.canRenderLabelDescription
      ? SurveyElementBase.renderQuestionDescription(question)
      : null
    const controls = this.mode === 'radio' ? this.renderRadioGroup() : this.renderButtons()
    return (
      <div className={cssClasses.rootCheckbox}>
        <div className={itemClass}>
          <div className={css.container}>{controls}</div>
          {description}
        </div>
      </div>
    )
  }

  protected renderButtons() {
    const { question } = this.props
    const { booleanValue } = question
    const disabled = this.props.isDisplayMode || question?.isInputReadOnly
    return (
      <>
        <input
          type="hidden"
          name={question.name}
          id={question.inputId}
          value={booleanValue === null ? '' : booleanValue}
        />
        <Button
          value="true"
          radioGroup={question.name}
          fullWidth
          onClick={() => this.onChange(true)}
          disabled={disabled}
        >
          {this.renderLocString(question.locLabelTrue)}
        </Button>
        <Button
          value="false"
          radioGroup={question.name}
          fullWidth
          onClick={() => this.onChange(false)}
          disabled={disabled}
        >
          {this.renderLocString(question.locLabelFalse)}
        </Button>
      </>
    )
  }

  protected renderRadioGroup() {
    const { question } = this.props
    const disabled = this.props.isDisplayMode || question?.isInputReadOnly
    return (
      <>
        <FormControlLabel
          control={
            <Radio
              name={question.name}
              value="true"
              onChange={() => this.onChange(true)}
              checked={question.booleanValue === true}
              radioGroup={question.name}
              disabled={disabled}
              inputProps={{ 'aria-describedby': question.ariaDescribedBy }}
              sx={{ marginTop: '-10px' }}
            />
          }
          label={this.renderLocString(question.locLabelTrue)}
          sx={{ alignItems: 'flex-start' }}
        />
        <FormControlLabel
          control={
            <Radio
              name={question.name}
              value="false"
              onChange={() => this.onChange(false)}
              checked={question.booleanValue === false}
              radioGroup={question.name}
              disabled={disabled}
              inputProps={{ 'aria-describedby': question.ariaDescribedBy }}
              sx={{ marginTop: '-10px' }}
            />
          }
          label={this.renderLocString(question.locLabelFalse)}
          sx={{ alignItems: 'flex-start' }}
        />
      </>
    )
  }
}
