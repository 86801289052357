import { useCallback, useEffect, useState } from 'react'
import { Box, Checkbox, debounce, FormControlLabel, Typography } from '@mui/material'
import { SurveyModel } from 'survey-core'
import dayjs, { Dayjs } from 'dayjs'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { runInAction } from 'mobx'
import CustomDatePicker from '../../../common/survey/custom-date-picker/CustomDatePicker'
import formats from '../../../common/survey/formats'
import MinimizableSurveyAccordion from '../survey-answers/MinimizableSurveyAccordion'
import AccordionHeaderBadge from '../AccordionHeaderBadge'
import Check from '../../../common/icons/Check'
import InnerNote from '../InnerNote'
import MuiSurvey from '../../../common/survey/MuiSurvey'
import { colors } from '../../../common/mui-theme'
import MinimizedView from '../placement/tabs/MinimizedView'
import { usePortalApi } from '../../api/PortalApiContext'
import { CoachingSessionDto } from '../../_generated/portal-api'
import coachingSessionSchema, { CoachingSessionSchema, MIN_TIMESTAMP } from './coaching-session-schema'
import { CoachingSessionAnswers } from './retention-surveys'
import DottedDivider from '../DottedDivider'
import Email from './Email'
import { MAX_TIMESTAMP } from '../placement/tabs/placement-schema'
import { notEnteredYet } from '../titles'

type Accomplishment = {
  Accomplishment: string
}

type Challenge = {
  Challenge: string
}

type Resource = {
  Resource: string
}

type SessionAnswers = {
  accomplishments?: Array<Accomplishment>
  actionPlanProgress?: string
  challenges?: Array<Challenge>
  resources?: Array<Resource>
  nextSteps?: string
  additionalDetails?: string
}

function getDefaultValue(session: CoachingSessionDto): CoachingSessionSchema {
  const { sessionDate, nextSessionDate, completed } = session
  return {
    sessionDate: sessionDate ? dayjs(sessionDate) : null,
    nextSessionDate: nextSessionDate ? dayjs(nextSessionDate) : null,
    completed,
  }
}

function format(date: Dayjs | null) {
  if (!date || !date.isValid()) return notEnteredYet
  return date.format(formats.date)
}

export default function CoachingSessionAccordion({
  id,
  title,
  model,
  feedback,
  session,
}: {
  id: string
  title: string
  model: SurveyModel
  feedback: SurveyModel
  session: CoachingSessionDto
}) {
  const [expanded, setExpanded] = useState(true)
  const expand = useCallback(() => setExpanded(true), [])
  const [, setRender] = useState(0)
  const [saving, setSaving] = useState(false)
  const api = usePortalApi()

  const setDataCores = useCallback(() => {
    if (session.answers) {
      const answers = JSON.parse(session.answers) as SessionAnswers
      model.setDataCore({
        accomplishments: answers.accomplishments,
        actionPlanProgress: answers.actionPlanProgress,
        challenges: answers.challenges,
        resources: answers.resources,
        nextSteps: answers.nextSteps,
      })
      feedback.setDataCore({ additionalDetails: answers.additionalDetails })
      setRender((r) => r + 1)
    }
  }, [feedback, model, session])

  useEffect(() => setDataCores(), [setDataCores])

  const { register, watch, control } = useForm<CoachingSessionSchema>({
    resolver: zodResolver(coachingSessionSchema),
    mode: 'all',
    defaultValues: getDefaultValue(session),
  })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const saveSession = useCallback(
    debounce(() => {
      api.coachingSession.updateSession(session).then(() => setSaving(false))
    }, 1500),
    [api, session],
  )

  useEffect(() => {
    const surveyAnswers: SessionAnswers = JSON.parse(session.answers || '{}')
    const onSurveyValueChanged = (
      sender: SurveyModel,
      options: { name: keyof CoachingSessionAnswers; value: CoachingSessionAnswers[keyof CoachingSessionAnswers] },
    ) => {
      setSaving(true)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      surveyAnswers[options.name] = options.value as any
      // eslint-disable-next-line no-param-reassign
      session.answers = JSON.stringify(surveyAnswers)
      saveSession()
    }

    const subscription = watch((value, info) => {
      const s = session
      if (!info.name) return
      setSaving(true)
      runInAction(() => {
        const key = info.name as keyof CoachingSessionSchema
        if (key !== 'completed') {
          const dt = value[key] as Dayjs | null
          s[key] =
            dt && dt.isValid() && dt.isBefore(MAX_TIMESTAMP) && dt.isAfter(MIN_TIMESTAMP) ? dt.format() : undefined
          return
        }
        if (Object.hasOwn(s, key)) {
          const v = value[key]
          s[key] = !!v
        }
      })

      saveSession()
    })

    model.onValueChanged.add(onSurveyValueChanged)
    feedback.onValueChanged.add(onSurveyValueChanged)
    return () => {
      model.onValueChanged.remove(onSurveyValueChanged)
      feedback.onValueChanged.remove(onSurveyValueChanged)
      subscription.unsubscribe()
    }
  }, [feedback, model, saveSession, session, watch])

  return (
    <MinimizableSurveyAccordion
      id={id}
      title={title}
      saving={saving}
      expanded={expanded}
      setExpanded={setExpanded}
      minimizedHeaderBadge={
        session.completed ? (
          <AccordionHeaderBadge variant="info">
            Session Completed
            <Check width={16} height={16} />
          </AccordionHeaderBadge>
        ) : (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <></>
        )
      }
      collapsedContent={
        <Controller
          render={({ field: sessionDate }) => (
            <Controller
              render={({ field: nextSessionDate }) => (
                <MinimizedView
                  sx={{ p: '24px' }}
                  onEdit={expand}
                  items={[
                    { title: 'Session Date', value: format(sessionDate.value) },
                    { title: 'Date of Next Session', value: format(nextSessionDate.value) },
                  ]}
                />
              )}
              name="nextSessionDate"
              control={control}
            />
          )}
          name="sessionDate"
          control={control}
        />
      }
    >
      <>
        <Box sx={{ p: '24px', display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px', marginBottom: '24px' }}>
            <Typography variant="subtitle1" color={colors.neutral['600']}>
              Session Outcome
            </Typography>
            <FormControlLabel
              control={<Checkbox checked={session.completed} {...register('completed')} />}
              label="Candidate Completed Session"
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Controller
                render={({ field: { ref, ...other }, formState: { errors } }) => (
                  <CustomDatePicker
                    label="Session Date"
                    {...other}
                    inputRef={ref}
                    onAccept={other.onChange}
                    error={!!errors.sessionDate}
                    helperText={errors.sessionDate?.message || ' '}
                    maxDate={MAX_TIMESTAMP}
                    minDate={MIN_TIMESTAMP}
                    format={formats.date}
                  />
                )}
                name="sessionDate"
                control={control}
              />
            </LocalizationProvider>
          </Box>
          <DottedDivider />
          <MuiSurvey model={model} hideNavigation />
          <Box sx={{ height: '36px' }} />
          <Box>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Controller
                render={({ field: { ref, ...other }, formState: { errors } }) => (
                  <CustomDatePicker
                    label="Date of Next Session"
                    {...other}
                    inputRef={ref}
                    maxDate={MAX_TIMESTAMP}
                    minDate={MIN_TIMESTAMP}
                    onAccept={other.onChange}
                    format={formats.date}
                    error={!!errors.nextSessionDate}
                    helperText={errors.nextSessionDate?.message || ' '}
                  />
                )}
                name="nextSessionDate"
                control={control}
              />
            </LocalizationProvider>
          </Box>
          <DottedDivider />
          <Email coachingSession={session} />
        </Box>
        <InnerNote title="Staff Feedback">
          <MuiSurvey model={feedback} hideNavigation />
        </InnerNote>
      </>
    </MinimizableSurveyAccordion>
  )
}
