import { Box, Divider, Toolbar, useEventCallback } from '@mui/material'
import { Editable, Slate, withReact } from 'slate-react'
import React, { useCallback, useContext, useMemo } from 'react'
import { withHistory } from 'slate-history'
import { createEditor, Descendant, Editor, Node as SlateNode } from 'slate'
import isHotkey from 'is-hotkey'
import { runInAction } from 'mobx'
import {
  FormatAlignCenter,
  FormatAlignJustify,
  FormatAlignLeft,
  FormatAlignRight,
  FormatBold,
  FormatItalic,
  FormatListBulleted,
  FormatListNumbered,
  FormatUnderlined,
} from '../../../common/icons/TextFormat'
import css from './NoteEditorDialog.module.css'
import Note from './context/note'
import NoteTitleEditor from './NoteTitleEditor'
import { MARK_HOTKEYS, toggleMark, withHtml } from '../rte/slate-rte'
import MarkButton from '../rte/MarkButton'
import BlockTypeButton from '../rte/BlockTypeButton'
import CustomLeaf from '../rte/CustomLeaf'
import CustomBlock from '../rte/CustomBlock'
import BlockAlignButton from '../rte/BlockAlignButton'
import PortalUserContext from '../portal-user/context/PortalUserContext'

function ToolbarDivider() {
  return <Divider orientation="vertical" sx={{ height: '60%' }} />
}

export default function NoteEditor({ note }: { note: Note }) {
  const { user } = useContext(PortalUserContext)

  const initialValue = useMemo(
    () => (note.dto.content ? JSON.parse(note.dto.content) : [{ children: [{ text: '' }] }]),
    [note],
  )
  const editor = useMemo(() => withHtml(withReact(withHistory(createEditor()))), [])
  const onKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      // eslint-disable-next-line no-restricted-syntax
      for (const key in MARK_HOTKEYS) {
        if (isHotkey(key, e)) {
          e.preventDefault()
          const mark = MARK_HOTKEYS[key as keyof typeof MARK_HOTKEYS]
          toggleMark(editor, mark)
        }
      }
      if (isHotkey('shift+enter', e)) {
        e.preventDefault()
        Editor.insertText(editor, '\n')
      }
    },
    [editor],
  )

  const onChange = useEventCallback((value: Descendant[]) => {
    const changed = editor.operations.some((op) => op.type !== 'set_selection')
    if (!changed) return
    const text = value.map((d) => SlateNode.string(d).trim()).join()
    runInAction(() => {
      const { dto } = note
      dto.content = text ? JSON.stringify(value) : ''
      dto.updatedBy = user?.fullName
      dto.timeStamp = new Date().toISOString()
    })
  })

  return (
    <Slate editor={editor} value={initialValue} onChange={onChange}>
      <Box sx={{ display: 'flex', flex: '1', flexFlow: 'column', alignItems: 'stretch' }}>
        <Toolbar className={css.toolbar} variant="dense">
          <MarkButton textStyle="bold">
            <FormatBold />
          </MarkButton>
          <MarkButton textStyle="italic">
            <FormatItalic />
          </MarkButton>
          <MarkButton textStyle="underline">
            <FormatUnderlined />
          </MarkButton>
          <ToolbarDivider />
          <BlockAlignButton align="left">
            <FormatAlignLeft />
          </BlockAlignButton>
          <BlockAlignButton align="center">
            <FormatAlignCenter />
          </BlockAlignButton>
          <BlockAlignButton align="right">
            <FormatAlignRight />
          </BlockAlignButton>
          <BlockAlignButton align="justify">
            <FormatAlignJustify />
          </BlockAlignButton>
          <ToolbarDivider />
          <BlockTypeButton blockType="bulleted-list">
            <FormatListBulleted />
          </BlockTypeButton>
          <BlockTypeButton blockType="numbered-list">
            <FormatListNumbered />
          </BlockTypeButton>
        </Toolbar>
        <Box sx={{ flex: '1 1 auto', overflow: 'auto', p: '16px 24px' }}>
          <NoteTitleEditor note={note} contentEditor={editor} />
          <Editable
            placeholder="Start typing"
            style={{
              flex: '1',
              fontSize: '16px',
              lineHeight: '160%',
              overflowWrap: 'anywhere',
            }}
            onKeyDown={onKeyDown}
            renderLeaf={CustomLeaf}
            renderElement={CustomBlock}
          />
        </Box>
      </Box>
    </Slate>
  )
}
