import { observer } from 'mobx-react'
import { Badge } from '@mui/material'
import React from 'react'
import { colors } from '../../common/mui-theme'

type AccordionHeaderBadgeProps = {
  variant: 'error' | 'info' | 'neutral'
  children: React.ReactNode
}

function AccordionHeaderBadge({ variant, children }: AccordionHeaderBadgeProps) {
  return (
    <Badge
      color="secondary"
      className={`AccordionHeaderBadge-${variant}`}
      sx={{
        display: 'flex',
        alignItems: 'center',
        height: '32px',
        padding: '6px 8px 6px 12px',
        borderRadius: '8px',
        gap: '8px',
        '&.AccordionHeaderBadge-neutral': {
          color: colors.neutral[300],
          backgroundColor: colors.neutral[75],
        },
        '&.AccordionHeaderBadge-info': {
          color: colors.base.white,
          backgroundColor: colors.primary[500],
        },
        '&.AccordionHeaderBadge-error': {
          color: colors.primary[100],
          backgroundColor: colors.error[500],
        },
      }}
    >
      {children}
    </Badge>
  )
}

export default observer(AccordionHeaderBadge)
