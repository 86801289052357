import React, { useCallback, useEffect, useState } from 'react'
import { Box, debounce, TextField, Typography } from '@mui/material'
import { z } from 'zod'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { observer } from 'mobx-react'
import { runInAction } from 'mobx'
import jobDetailsSchema, { countableJobDetailsFields, isCompleted } from './job-details-schema'
import MaskedPhoneInput from '../../../../common/components/MaskedPhoneInput'
import CustomAutocomplete from '../../../../common/select/CustomAutocomplete'
import { stateNames, states } from '../../../../common/survey/us-address'
import MaskedZipCodeInput from '../../../../common/components/MaskedZipCodeInput'
import { colors } from '../../../../common/mui-theme'
import Check from '../../../../common/icons/Check'
import Close from '../../../../common/icons/Close'
import MinimizableSurveyAccordion from '../../survey-answers/MinimizableSurveyAccordion'
import AccordionHeaderBadge from '../../AccordionHeaderBadge'
import { JobDetailsDto } from '../../../_generated/portal-api'
import AmountInput from '../../../../common/components/AmountInput'
import { usePortalApi } from '../../../api/PortalApiContext'
import InnerNote from '../../InnerNote'
import { JOB_DETAILS_ANCHOR, useInvalidateActivityLog } from '../../../api/hooks/placementHooks'
import MinimizedView from './MinimizedView'
import { getLocationString } from '../../jobs/JobInformation'
import { notEnteredYet } from '../../titles'

const flexContainerStyles = {
  display: 'flex',
  gap: '16px',
}

function JobDetailsAccordion({ details }: { details: JobDetailsDto }) {
  const api = usePortalApi()
  const [saving, setSaving] = useState(false)
  const completed = isCompleted(details)
  const { submission, placement, interviews } = details
  const hasPlacementActions = !!(submission || placement || interviews?.length)
  const [expanded, setExpanded] = useState(!(hasPlacementActions && completed))
  const expand = useCallback(() => setExpanded(true), [])
  const {
    control,
    register,
    formState: { errors },
    getFieldState,
    getValues,
    watch,
  } = useForm<z.infer<typeof jobDetailsSchema>>({
    resolver: zodResolver(jobDetailsSchema),
    mode: 'all',
    defaultValues: {
      employer: details.employer ?? '',
      salary: !!details.salary || details.salary === 0 ? `${details.salary}` : '',
      title: details.title ?? '',
      careerArea: details.careerArea ?? '',
      shift: details.shift ?? '',
      supervisorFirstName: details.supervisorFirstName ?? '',
      supervisorLastName: details.supervisorLastName ?? '',
      supervisorEmail: details.supervisorEmail ?? '',
      supervisorOfficePhone: details.supervisorOfficePhone ?? '',
      supervisorDirectPhone: details.supervisorDirectPhone ?? '',
      addressLine1: details.addressLine1 ?? '',
      addressLine2: details.addressLine2 ?? '',
      city: details.city ?? '',
      state: details.state ?? '',
      zip: details.zip ?? '',
      additionalDetails: details.additionalDetails ?? '',
    },
  })

  const [state, setState] = useState(details.state ?? '')
  const invalidateActivityLog = useInvalidateActivityLog()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const saveJobDetails = useCallback(
    debounce(() => {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      api.jobs.updateJob(details.id, details).then(() => setSaving(false))
    }, 1500),
    [api, details],
  )

  useEffect(() => {
    const { unsubscribe } = watch((value, info) => {
      const j = details
      setSaving(true)
      runInAction(() => {
        switch (info.name) {
          case 'salary':
            j.salary = typeof value.salary === 'string' ? parseFloat(value.salary) : value.salary || undefined
            break
          case undefined:
            break
          default:
            if (!j[info.name] && value[info.name]) invalidateActivityLog(details.id)
            j[info.name] = value[info.name] || undefined
            break
        }
      })
      saveJobDetails()
    })

    return unsubscribe
  }, [invalidateActivityLog, details, saveJobDetails, watch])

  const title = !expanded ? getValues('title') || 'Enter Job Title' : 'Job/Company Details'
  const subtitle = !expanded ? getValues('employer') || 'Enter Company Name' : undefined
  const answerCount = countableJobDetailsFields.filter((key) => !!getValues(key) && !getFieldState(key).invalid).length

  return (
    <MinimizableSurveyAccordion
      id={JOB_DETAILS_ANCHOR}
      title={title}
      subtitle={subtitle}
      saving={saving}
      allQuestionCount={countableJobDetailsFields.length}
      answeredQuestionCount={answerCount}
      expanded={expanded}
      setExpanded={setExpanded}
      minimizedHeaderBadge={
        <AccordionHeaderBadge variant={completed ? 'info' : 'error'}>
          {completed ? 'Details Completed' : 'Not Completed'}
          {completed ? <Check width={16} height={16} /> : <Close width={16} height={16} />}
        </AccordionHeaderBadge>
      }
      collapsedContent={
        <MinimizedView
          sx={{ p: '24px' }}
          isEditable
          onEdit={expand}
          items={[
            { title: 'Career Area', value: getValues('careerArea') || notEnteredYet },
            { title: 'Shift', value: getValues('shift') || notEnteredYet },
            {
              title: 'Location',
              value:
                getLocationString(
                  getValues('addressLine1'),
                  getValues('addressLine2'),
                  getValues('city'),
                  getValues('state'),
                  getValues('zip'),
                ) || notEnteredYet,
            },
          ]}
        />
      }
    >
      <>
        <Box sx={{ p: '24px', borderBottom: `1px solid ${colors.neutral['100']}` }}>
          <Box sx={flexContainerStyles}>
            <TextField
              label="Employer"
              helperText=" "
              fullWidth
              {...register('employer')}
              inputProps={{ maxLength: 1000 }}
            />
            <Controller
              render={({ field }) => <AmountInput label="Salary" helperText=" " fullWidth {...field} />}
              name="salary"
              control={control}
            />
          </Box>
          <TextField
            label="Job Title"
            helperText=" "
            sx={{ width: '50%', pr: '8px' }}
            {...register('title')}
            inputProps={{ maxLength: 1000 }}
          />
          <Box sx={flexContainerStyles}>
            <TextField
              label="Career Area"
              helperText=" "
              fullWidth
              {...register('careerArea')}
              value={getValues('careerArea')}
              inputProps={{ maxLength: 1000 }}
            />
            <TextField
              label="Shift"
              helperText=" "
              fullWidth
              {...register('shift')}
              value={getValues('shift')}
              inputProps={{ maxLength: 100 }}
            />
          </Box>
          <Typography variant="body1" sx={{ mb: '16px', color: colors.neutral['400'] }}>
            Supervisor Contact Details
          </Typography>
          <Box sx={flexContainerStyles}>
            <TextField
              label="First Name"
              {...register('supervisorFirstName')}
              helperText=" "
              fullWidth
              inputProps={{ maxLength: 45 }}
            />
            <TextField
              label="Last Name"
              {...register('supervisorLastName')}
              helperText=" "
              fullWidth
              inputProps={{ maxLength: 45 }}
            />
          </Box>
          <Box sx={flexContainerStyles}>
            <TextField
              label="Email Address"
              {...register('supervisorEmail')}
              error={!!errors.supervisorEmail}
              helperText={errors.supervisorEmail?.message || ' '}
              fullWidth
              inputProps={{ maxLength: 255 }}
            />
            <Controller
              render={({ field }) => (
                <TextField
                  label="Office Phone"
                  {...field}
                  onInput={field.onChange}
                  error={!!errors.supervisorOfficePhone}
                  helperText={errors.supervisorOfficePhone?.message || ' '}
                  InputProps={{
                    inputComponent: MaskedPhoneInput,
                  }}
                  fullWidth
                />
              )}
              name="supervisorOfficePhone"
              control={control}
            />
          </Box>
          <Controller
            render={({ field }) => (
              <TextField
                label="Direct Phone"
                {...field}
                onInput={field.onChange}
                error={!!errors.supervisorDirectPhone}
                helperText={errors.supervisorDirectPhone?.message || ' '}
                InputProps={{
                  inputComponent: MaskedPhoneInput,
                }}
                sx={{ width: '50%', pr: '8px' }}
              />
            )}
            name="supervisorDirectPhone"
            control={control}
          />
          <Typography variant="body1" sx={{ mb: '16px', color: colors.neutral['400'] }}>
            Location of Employment
          </Typography>
          <TextField
            label="Address line 1"
            {...register('addressLine1')}
            helperText=" "
            fullWidth
            inputProps={{ maxLength: 255 }}
          />
          <TextField
            label="Address line 2"
            {...register('addressLine2')}
            helperText=" "
            fullWidth
            inputProps={{ maxLength: 255 }}
          />
          <Box
            sx={{
              ...flexContainerStyles,
            }}
          >
            <TextField label="City" {...register('city')} helperText=" " fullWidth inputProps={{ maxLength: 50 }} />
            <Controller
              render={({ field }) => (
                <CustomAutocomplete
                  fullWidth
                  disablePortal
                  autoComplete
                  autoSelect
                  onChange={(e, v) => field.onChange(v)}
                  value={field.value || null}
                  inputValue={state}
                  onInputChange={(e, v) => setState(v)}
                  options={stateNames}
                  isOptionEqualToValue={(option, value) => option === value || states.get(value) === option}
                  sx={{ border: 'none' }}
                  ListboxProps={{
                    style: {
                      maxHeight: 190,
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      label="State"
                      ref={field.ref}
                      onBlur={field.onBlur}
                      {...params}
                      InputProps={{ ...params.InputProps }}
                    />
                  )}
                />
              )}
              name="state"
              control={control}
            />
            <Controller
              render={({ field: { value, ...other } }) => (
                <TextField
                  label="Zip Code"
                  {...other}
                  value={value || ''}
                  onInput={other.onChange}
                  error={!!errors.zip}
                  helperText={errors.zip?.message || ' '}
                  fullWidth
                  InputProps={{
                    inputComponent: MaskedZipCodeInput,
                  }}
                />
              )}
              name="zip"
              control={control}
            />
          </Box>
        </Box>
        <InnerNote title="Additional Job/Company Details">
          <TextField
            placeholder="Elaborate on any additional placement information..."
            multiline
            fullWidth
            rows={5}
            {...register('additionalDetails')}
            inputProps={{ maxLength: 1000 }}
          />
        </InnerNote>
      </>
    </MinimizableSurveyAccordion>
  )
}

export default observer(JobDetailsAccordion)
