import React, { useCallback, useContext, useMemo } from 'react'
import { ListItemText } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import { observer } from 'mobx-react'
import { runInAction } from 'mobx'
import PortalRightRail, { StyledMenuItem } from '../../PortalRightRail'
import BookmarkPlus from '../../../../common/icons/BookmarkPlus'
import { palette } from '../../../../common/mui-theme'
import CandidateProfileContext from '../../candidate-profile/context/CandidateProfileContext'
import { JobDto } from '../../../_generated/portal-api'
import { usePortalApi } from '../../../api/PortalApiContext'
import JobPostingsContext from '../context/JobPostingsContext'
import BookmarkFilledCheck from '../../../../common/icons/BookmarkFilledCheck'

function FavoriteRightRail({ job }: { job: JobDto }) {
  const api = usePortalApi()
  const { candidate } = useContext(CandidateProfileContext)
  const {
    increaseFavouriteCount,
    decreaseFavouriteCount,
    store: { indexed },
  } = useContext(JobPostingsContext)

  const selectedJob = useMemo(() => (job.posting ? indexed.get(job.posting.id) : null), [indexed, job.posting])

  const toggleFavourite = useCallback(() => {
    api.candidates[job.isFeatured ? 'deleteFeatureJob' : 'addFeatureJob'](
      candidate.dto.userId,
      job.posting?.id as number,
    ).then(() => {
      runInAction(() => {
        // eslint-disable-next-line no-param-reassign
        job.isFeatured = !job.isFeatured
        if (selectedJob) {
          selectedJob.dto.isFavourite = !selectedJob.dto.isFavourite

          if (selectedJob.dto.isFavourite) {
            increaseFavouriteCount()
          } else {
            decreaseFavouriteCount()
          }
        }
      })
    })
  }, [api.candidates, job, candidate.dto.userId, selectedJob, increaseFavouriteCount, decreaseFavouriteCount])

  return (
    <PortalRightRail title="Job Matching Actions">
      <StyledMenuItem onClick={toggleFavourite} sx={{ marginLeft: 0 }}>
        <ListItemText primary="Favorite" />
        <IconButton>
          {selectedJob?.dto.isFavourite || job.isFeatured ? (
            <BookmarkFilledCheck
              style={{ minWidth: '0', margin: 'auto', color: palette.primary.main, stroke: palette.common.white }}
            />
          ) : (
            <BookmarkPlus style={{ minWidth: '0', margin: 'auto', color: palette.grey['200'] }} />
          )}
        </IconButton>
      </StyledMenuItem>
    </PortalRightRail>
  )
}

export default observer(FavoriteRightRail)
