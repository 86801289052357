import { observer } from 'mobx-react'
import React, { useCallback, useContext } from 'react'
import { Alert as MuiAlert, AlertTitle, Collapse, List } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import { TransitionGroup } from 'react-transition-group'
import { runInAction } from 'mobx'
import AlertsContext, { Alert } from './AlertsContext'
import Close from '../icons/Close'
import css from './Alerts.module.css'
import AlertSuccess from '../icons/AlertSuccess'
import AlertWarning from '../icons/AlertWarning'
import AlertError from '../icons/AlertError'
import ActionableAlert from './ActionableAlert'

function ClosableAlert({ alert, onClose }: { alert: Alert; onClose: (alert: Alert) => void }) {
  return (
    <MuiAlert
      severity={alert.severity}
      iconMapping={{
        success: <AlertSuccess />,
        error: <AlertError />,
        warning: <AlertWarning />,
      }}
      action={
        <IconButton aria-label="close" color="inherit" size="small" onClick={() => onClose(alert)}>
          <Close />
        </IconButton>
      }
    >
      <AlertTitle className={css.alertTitle}>{alert.title}</AlertTitle>
      {alert.message ? <span className={css.alertDescription}>{alert.message}</span> : null}
    </MuiAlert>
  )
}

function Alerts() {
  const { alerts } = useContext(AlertsContext)

  const removeAlert = useCallback(
    (alert: Alert) => {
      const index = alerts.indexOf(alert)
      if (index >= 0) {
        runInAction(() => alerts.splice(index, 1))
      }
    },
    [alerts],
  )

  const onClose = useCallback((alert: Alert) => alert.cancelAction(() => removeAlert(alert)), [removeAlert])
  const onConfirm = useCallback((alert: Alert) => alert.confirmAction(() => removeAlert(alert)), [removeAlert])

  return (
    <List sx={{ padding: 0, zIndex: 2 }}>
      <TransitionGroup>
        {alerts.map((alert) => (
          <Collapse key={alert.timestamp}>
            {alert.type === 'dismiss' ? (
              <ClosableAlert alert={alert} onClose={onClose} />
            ) : (
              <ActionableAlert alert={alert} onClose={onClose} onClick={onConfirm} />
            )}
          </Collapse>
        ))}
      </TransitionGroup>
    </List>
  )
}

export default observer(Alerts)
