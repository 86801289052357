import { useMatch } from 'react-router-dom'
import { useCallback, useContext } from 'react'
import { observable, runInAction } from 'mobx'
import { useQuery, useQueryClient } from 'react-query'
import PlacementContext from '../../components/placement/context/PlacementContext'
import { usePortalApi } from '../PortalApiContext'
import { JobDetailsDto, JobDto } from '../../_generated/portal-api'
import CandidateProfileContext from '../../components/candidate-profile/context/CandidateProfileContext'

export const PLACE_CANDIDATE_ANCHOR = 'job_placement'
export const JOB_SUBMISSION_ANCHOR = 'job_submission'
export const JOB_DETAILS_ANCHOR = 'job_details'

const activityLog = 'activity-log'

export function useInvalidateActivityLog() {
  const client = useQueryClient()
  return useCallback(
    (jobId: number, delayInMillis = 1600) =>
      setTimeout(() => client.invalidateQueries([activityLog, jobId]), delayInMillis),
    [client],
  )
}

export function useJobActivityLog(job: JobDetailsDto) {
  const api = usePortalApi()
  return useQuery([activityLog, job.id], () => api.jobs.getActivityLog(job.id).then((r) => r.data))
}

export function useSelectedJob() {
  const { candidate } = useContext(CandidateProfileContext)
  const { loading, jobs } = useContext(PlacementContext)
  const { jobId } = useMatch(`/candidates/${candidate.dto.userId}/placement/:jobId`)?.params || {}
  const id = jobId ? +jobId : 0
  const job = jobs?.find((j) => j.details?.id === id)
  return { loading, job }
}

export function useAddNewJob(job?: JobDto) {
  const { candidate } = useContext(CandidateProfileContext)
  const { jobs } = useContext(PlacementContext)
  const api = usePortalApi()
  return useCallback(
    (jobPostingId?: number) => {
      const j = job || observable({} as JobDto)
      if (j.details) return Promise.resolve(j)
      return api.jobs
        .createJob(
          {
            userId: candidate.dto.userId,
            jobPostingId: !jobPostingId ? undefined : jobPostingId,
          },
          {},
        )
        .then((r) => {
          runInAction(() => {
            j.details = r.data
            jobs?.push(j)
          })
          return j
        })
    },
    [api.jobs, candidate.dto.userId, job, jobs],
  )
}

export function useAddSubmission(job?: JobDto) {
  const api = usePortalApi()
  const onJobAdded = useAddNewJob(job)
  const invalidateActivityLog = useInvalidateActivityLog()
  const addSubmission = useCallback(
    (j: JobDto) => {
      const { details } = j
      if (!details) throw new Error('Job details not provided')
      return api.jobs.createSubmission(details.id, {}).then((r) => {
        runInAction(() => {
          details.submission = r.data
        })
        invalidateActivityLog(details.id)
        return j
      })
    },
    [api.jobs, invalidateActivityLog],
  )
  return useCallback(
    () => (job?.details ? addSubmission(job) : onJobAdded(job?.posting?.id).then(addSubmission)),
    [addSubmission, job, onJobAdded],
  )
}

export function useAddInterview(job?: JobDto) {
  const onJobAdded = useAddNewJob(job)
  const api = usePortalApi()
  const invalidateActivityLog = useInvalidateActivityLog()
  const addNewInterview = useCallback(
    (j: JobDto) => {
      const { details } = j
      if (!details) throw new Error('Job details not provided')
      return api.interview.createInterview(details.id).then((r) => {
        runInAction(() => {
          details.interviews.push(r.data)
        })
        invalidateActivityLog(details.id)
        return j
      })
    },
    [api.interview, invalidateActivityLog],
  )
  return useCallback(
    () => (job?.details ? addNewInterview(job) : onJobAdded(job?.posting?.id).then(addNewInterview)),
    [addNewInterview, job, onJobAdded],
  )
}

export const jobInterviewLimit = 30

export function useAddPlacement(job?: JobDto) {
  const api = usePortalApi()
  const invalidateActivityLog = useInvalidateActivityLog()
  const onJobAdded = useAddNewJob(job)
  const addPlacement = useCallback(
    (j: JobDto) => {
      const { details } = j
      if (!details) throw new Error('Job details not provided')
      return api.jobs.createPlacement(details.id).then((r) => {
        runInAction(() => {
          details.placement = r.data
        })
        invalidateActivityLog(details.id)
        return j
      })
    },
    [api.jobs, invalidateActivityLog],
  )
  return useCallback(
    () => (job?.details ? addPlacement(job) : onJobAdded(job?.posting?.id).then(addPlacement)),
    [addPlacement, job, onJobAdded],
  )
}
