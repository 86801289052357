import { Question } from 'survey-core'

export default class DoBModel extends Question {
  static readonly TYPE = 'dob'

  // eslint-disable-next-line class-methods-use-this
  getType() {
    return DoBModel.TYPE
  }

  get savedDate() {
    return this.getPropertyValue('savedDate')
  }

  set savedDate(val) {
    this.setPropertyValue('savedDate', val)
  }
}
