import React from 'react'
import { SurveyQuestionElementBase } from 'survey-react-ui'
import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import CompensationModel, { Compensation, rates } from './CompensationModel'
import css from './MuiCompensation.module.css'
import AmountInput from '../../components/AmountInput'
import { colors } from '../../mui-theme'

function RateToggle({
  value,
  onChange,
}: {
  value: Compensation['rate']
  onChange: (event: React.MouseEvent<HTMLElement>, value: Compensation['rate']) => void
}) {
  return (
    <ToggleButtonGroup size="small" sx={{ marginRight: 'auto' }} value={value} exclusive onChange={onChange}>
      {rates.map((rate) => (
        <ToggleButton key={rate} value={rate}>
          {rate}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  )
}

export default class MuiCompensationQuestion extends SurveyQuestionElementBase {
  constructor(props: unknown) {
    super(props)
    this.onRateChanged = this.onRateChanged.bind(this)
    this.onAmountChanged = this.onAmountChanged.bind(this)
  }

  get question(): CompensationModel {
    return this.questionBase as CompensationModel
  }

  get value() {
    return this.question.value
  }

  set value(value: Compensation) {
    this.question.value = value
  }

  onRateChanged(_event: React.MouseEvent<HTMLElement>, value: Compensation['rate']) {
    if (value) {
      this.value = { rate: value, amount: undefined }
    }
  }

  onAmountChanged(event: React.ChangeEvent<HTMLInputElement>) {
    const rate = this.value?.rate || rates[0]
    const { value } = event.target
    this.value = { rate, amount: value }
  }

  protected renderElement(): JSX.Element {
    const { rate = rates[0], amount = '' } = this.value || {}
    return (
      <div className={css.container}>
        <RateToggle value={rate} onChange={this.onRateChanged} />
        <AmountInput
          value={amount}
          onChange={this.onAmountChanged}
          label={this.question.label}
          sx={{
            maxWidth: '240px',
            minHeight: '48px',
            padding: '0px',
            color: colors.neutral['300'],
          }}
        />
      </div>
    )
  }
}
