import { Observer } from 'mobx-react'
import CandidatesGrid from '../components/candidates/grid/CandidatesGrid'
import CandidatesGridContext, { gridContextValue } from '../components/candidates/grid/CandidatesGridContext'
import DelayedProgressBar from '../components/DelayedProgressBar'
import PageContentContainer from '../components/PageContentContainer'
import CandidatesGridActions from '../components/candidates/CandidatesGridActions'
import { useCandidates } from '../components/candidates/context/CandidatesContext'

export default function CandidatesPage() {
  const { store } = useCandidates()
  return (
    <CandidatesGridContext.Provider value={gridContextValue}>
      <Observer>
        {() =>
          store.isLoading ? (
            <DelayedProgressBar />
          ) : (
            <PageContentContainer>
              <CandidatesGridActions />
              <CandidatesGrid />
            </PageContentContainer>
          )
        }
      </Observer>
    </CandidatesGridContext.Provider>
  )
}
