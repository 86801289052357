import { IconButton, useEventCallback, useTheme } from '@mui/material'
import {
  DateTimeValidationError,
  DesktopDateTimePicker,
  DesktopDateTimePickerProps,
  StaticDateTimePicker,
} from '@mui/x-date-pickers'
import dayjs, { Dayjs } from 'dayjs'

import { FieldChangeHandlerContext, PickersModalDialog } from '@mui/x-date-pickers/internals'
import React, { useCallback, useState } from 'react'
import { TextFieldProps } from '@mui/material/TextField/TextField'
import Calendar from '../../icons/Calendar'
import Close from '../../icons/Close'
import { colors, palette } from '../../mui-theme'
import formats from '../formats'
import CustomActionBar from './CustomActionBar'

export default function CustomDateTimePicker({
  value,
  openTo,
  views,
  onChange,
  format = formats.monthNameDate,
  minDate,
  maxDate,
  className,
  helperText,
  error,
  fullWidth,
  onAccept,
  ...other
}: DesktopDateTimePickerProps<Dayjs> & Pick<TextFieldProps, 'helperText' | 'error' | 'fullWidth'>) {
  const theme = useTheme()
  const [pickerOpen, setPickerOpen] = useState(false)
  const [pickerValue, setPickerValue] = useState<Dayjs | null>(null)

  const closePicker = useCallback(() => setPickerOpen(false), [])

  const onPickerOpen = useEventCallback(() => {
    setPickerValue(value || null)
    setPickerOpen(true)
  })

  const [focused, setFocused] = useState(false)
  const onFocus = useCallback(() => setFocused(true), [])
  const onBlur = useCallback(() => setFocused(false), [])
  const shrink = focused || value !== null
  // fix for bug with passing InputProps to DataPicker https://github.com/mui/mui-x/issues/8322
  const { InputProps, inputProps, ...textFieldProps } = theme?.components?.MuiTextField?.defaultProps || {}
  const { style, ...otherInputProps } = inputProps || {}
  const accept = useEventCallback((v: Dayjs | null) => {
    if (onAccept) onAccept(v)
    closePicker()
  })
  const reset = useEventCallback(() => accept(null))
  const acceptPicked = useEventCallback(() => accept(pickerValue))
  const acceptToday = useEventCallback(() => accept(dayjs()))

  const change = useCallback(
    (v: Dayjs | null, context: FieldChangeHandlerContext<DateTimeValidationError>) => {
      if (onChange) onChange(v, context)
    },
    [onChange],
  )

  return (
    <div className={className}>
      <DesktopDateTimePicker
        {...other}
        value={value}
        slots={{
          openPickerIcon: Calendar,
        }}
        openTo={openTo}
        open={false}
        onOpen={onPickerOpen}
        views={views}
        onChange={change}
        format={format}
        minDate={minDate}
        maxDate={maxDate}
        slotProps={{
          textField: {
            ...textFieldProps,
            helperText,
            error,
            fullWidth,
            InputProps: {
              ...InputProps,
              endAdornment: value && (
                <IconButton onClick={reset} sx={{ position: 'absolute', right: '8px' }}>
                  <Close />
                </IconButton>
              ),
            },
            InputLabelProps: {
              shrink,
            },
            inputProps: {
              ...otherInputProps,
              style: shrink ? style : { ...style, opacity: 0 },
            },
            onFocus,
            onBlur,
            sx: {
              '& .MuiInputLabel-root:not(.MuiInputLabel-shrink)': {
                marginLeft: '32px',
              },
            },
          },
          inputAdornment: {
            position: 'start',
          },
        }}
      />
      <PickersModalDialog
        open={pickerOpen}
        onClose={closePicker}
        onAccept={acceptPicked}
        onSetToday={acceptToday}
        onDismiss={closePicker}
        onCancel={closePicker}
        onClear={closePicker}
        onOpen={onPickerOpen}
        slotProps={{
          mobilePaper: {
            sx: {
              borderRadius: '8px',
              padding: '8px 0 16px',
              '&>.MuiDialogContent-root': {
                padding: 0,
              },
            },
          },
        }}
      >
        <StaticDateTimePicker
          displayStaticWrapperAs="desktop"
          value={pickerValue}
          openTo={openTo}
          minDate={minDate}
          maxDate={maxDate}
          views={views}
          showDaysOutsideCurrentMonth
          slots={{ actionBar: CustomActionBar }}
          slotProps={{
            layout: {
              sx: {
                margin: '0 -24px',
                display: 'block',
                '.MuiDateCalendar-root': {
                  overflow: 'visible',
                },
                '.MuiClock-root': {
                  marginTop: '40px',
                  '.MuiTimeClock-arrowSwitcher': {
                    top: 0,
                  },
                },
                '.MuiDayCalendar-header': {
                  '& .MuiDayCalendar-weekDayLabel': {
                    color: colors.neutral['400'],
                    fontSize: '14px',
                    '&:nth-of-type(1):after': {
                      content: '"u"',
                      display: 'inline-block',
                    },
                    '&:nth-of-type(2):after': {
                      content: '"o"',
                      display: 'inline-block',
                    },
                    '&:nth-of-type(3):after': {
                      content: '"u"',
                      display: 'inline-block',
                    },
                    '&:nth-of-type(4):after': {
                      content: '"e"',
                      display: 'inline-block',
                    },
                    '&:nth-of-type(5):after': {
                      content: '"h"',
                      display: 'inline-block',
                    },
                    '&:nth-of-type(6):after': {
                      content: '"r"',
                      display: 'inline-block',
                    },
                    '&:nth-of-type(7):after': {
                      content: '"a"',
                      display: 'inline-block',
                    },
                  },
                },
                '.MuiPickersArrowSwitcher-spacer': {
                  width: '232px',
                },
                '.MuiPickersCalendarHeader-root': {
                  display: 'flex',
                  alignItems: 'center',
                  justifyItems: 'space-between',
                  order: 0,
                  paddingRight: '16px',
                  paddingLeft: '0',
                  position: 'relative',
                },
                '.MuiPickersCalendarHeader-switchViewButton': {
                  visibility: 'hidden',
                },
                '.MuiPickersCalendarHeader-label': {
                  textAlign: 'center',
                },
                '.MuiPickersDay-today': {
                  border: '1px solid transparent',
                  ':not(.Mui-selected)': {
                    border: '1px solid transparent',
                  },
                  ':before': {
                    display: 'inline-block',
                    content: '"\u2022"',
                    position: 'absolute',
                    bottom: '-2px',
                    left: 0,
                    textAlign: 'center',
                    width: '100%',
                    fontSize: '16px',
                    color: palette.primary.main,
                  },
                  '&.Mui-selected': {
                    ':before': {
                      color: colors.primary['600'],
                    },
                    ':hover:before': {
                      color: palette.primary.main,
                    },
                  },
                },
                '.MuiPickersYear-yearButton': {
                  fontWeight: 500,
                  '&:focus': {
                    backgroundColor: 'inherit',
                    border: `1px solid ${palette.primary.light}`,
                  },
                  '&:hover': {
                    color: palette.primary.main,
                    backgroundColor: colors.primary['200'],
                  },
                  '&.Mui-selected': {
                    '&:hover': {
                      color: colors.base.white,
                      backgroundColor: colors.primary['600'],
                    },
                    '&:focus': {
                      backgroundColor: palette.primary.main,
                      '&:hover': {
                        backgroundColor: colors.primary['600'],
                      },
                    },
                  },
                },
                '.MuiPickersMonth-monthButton': {
                  fontWeight: 500,
                  '&:focus': {
                    backgroundColor: 'inherit',
                    border: `1px solid ${palette.primary.light}`,
                  },
                  '&:hover': {
                    color: palette.primary.main,
                    backgroundColor: colors.primary['200'],
                  },
                  '&.Mui-selected': {
                    '&:hover': {
                      color: colors.base.white,
                      backgroundColor: colors.primary['600'],
                    },
                    '&:focus': {
                      backgroundColor: palette.primary.main,
                      '&:hover': {
                        backgroundColor: colors.primary['600'],
                      },
                    },
                  },
                },
                '& .MuiPickersDay-root': {
                  fontSize: '14px',
                  lineHeight: '20px',
                  border: '1px solid transparent',
                  ':hover': {
                    color: palette.primary.main,
                    backgroundColor: colors.primary['200'],
                  },
                  '&.Mui-selected': {
                    backgroundColor: palette.primary.main,
                    ':focus': {
                      backgroundColor: palette.primary.main,
                    },
                    ':hover': {
                      color: colors.base.white,
                      backgroundColor: colors.primary['600'],
                      ':focus': {
                        backgroundColor: colors.primary['600'],
                      },
                    },
                  },
                  ':focus': {
                    backgroundColor: 'inherit',
                    border: `1px solid ${palette.primary.light}`,
                    ':hover': {
                      backgroundColor: colors.primary['200'],
                    },
                  },
                },
                '.MuiPickersCalendarHeader-labelContainer': {
                  fontSize: '16px',
                  lineHeight: '24px',
                  fontWeight: 700,
                },
                '.MuiPickersCalendarHeader-labelContainer > .MuiPickersFadeTransitionGroup-root': {
                  position: 'absolute',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  left: 0,
                  right: 0,
                },
              },
            },
          }}
          onChange={setPickerValue}
          onAccept={accept}
          autoFocus
          onClose={closePicker}
        />
      </PickersModalDialog>
    </div>
  )
}
