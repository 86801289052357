import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { ReactPlugin } from '@microsoft/applicationinsights-react-js'

// https://learn.microsoft.com/en-us/azure/azure-monitor/app/javascript-react-plugin#use-react-hooks
export const enum SeverityLevel {
  Verbose = 0,
  Information = 1,
  Warning = 2,
  Error = 3,
  Critical = 4,
}

const appInsight = new ReactPlugin()
export const appInsightsApi = new ApplicationInsights({
  config: {
    instrumentationKey: import.meta.env.VITE_APP_INSIGHTS_KEY,
    connectionString: import.meta.env.VITE_APP_INSIGHTS_STRING,
    extensions: [appInsight],
    enableAutoRouteTracking: true,
  },
})
appInsightsApi.loadAppInsights()

export default appInsight
