import { useSlate } from 'slate-react'
import { IconButtonProps } from '@mui/material/IconButton'
import { SxProps, useEventCallback } from '@mui/material'
import React from 'react'
import ToolbarButton from './ToolbarButton'
import { BlockType, isBlockTypeActive, toggleBlockType } from './slate-rte'

export default function BlockTypeButton({
  blockType,
  children,
  ...other
}: IconButtonProps & SxProps & { blockType: BlockType }) {
  const editor = useSlate()
  const toggled = isBlockTypeActive(editor, blockType)
  const onMouseDown = useEventCallback(() => {
    toggleBlockType(editor, blockType)
  })
  return (
    <ToolbarButton toggled={toggled} onMouseDown={onMouseDown} {...other}>
      {children}
    </ToolbarButton>
  )
}
