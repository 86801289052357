import { SurveyModel } from 'survey-core'
import { useContext, useEffect } from 'react'
import { runInAction, set } from 'mobx'
import { createOnDownloadFileHandler, createOnUploadFilesHandler } from '../../common/survey/MuiFileQuestion'
import { Attachment } from '../_generated/portal-api'
import { Answers } from '../../demo/survey-model'
import MuiSurvey from '../../common/survey/MuiSurvey'
import { usePortalApi } from '../api/PortalApiContext'
import CandidateProfileContext from './candidate-profile/context/CandidateProfileContext'
import nop from '../../common/nop'

export default function SurveyWithFileQuestions({ model }: { model: SurveyModel }) {
  const api = usePortalApi()
  const { candidate } = useContext(CandidateProfileContext)

  useEffect(() => {
    if (!candidate.dto.userId) return nop
    const { userId } = candidate.dto
    const onUploadFiles = createOnUploadFilesHandler((file) =>
      api.files
        .uploadAttachment(userId, { file })
        .then((response) => response.json())
        .then((upload: Attachment) => ({ file, content: { id: upload.id, size: file.size } })),
    )
    const onDownloadFile = createOnDownloadFileHandler((fileId) =>
      api.files.downloadAttachment(fileId).then((response) => response.blob()),
    )

    model.onDownloadFile.add(onDownloadFile)
    model.onUploadFiles.add(onUploadFiles)
    return () => {
      model.onDownloadFile.remove(onDownloadFile)
      model.onUploadFiles.remove(onUploadFiles)
    }
  }, [api.files, candidate.dto, model])

  useEffect(() => {
    model.setDataCore(candidate.answers)
    const onValueChanged = (
      sender: SurveyModel,
      options: {
        name: keyof Answers
        value: Answers[keyof Answers]
      },
    ) => {
      runInAction(() => {
        set(candidate.answers, { [options.name]: options.value })
      })
    }

    model.onValueChanged.add(onValueChanged)
    return () => {
      model.onValueChanged.remove(onValueChanged)
    }
  }, [candidate.answers, model])

  return <MuiSurvey model={model} hideNavigation />
}
