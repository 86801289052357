import { BaseEditor, Editor, Element as SlateElement, Node as SlateNode, Text, Transforms } from 'slate'
import { ReactEditor } from 'slate-react'
import { jsx } from 'slate-hyperscript'

export type TextStyle = 'bold' | 'italic' | 'underline'
type TextStyleMark = Partial<Record<TextStyle, boolean>>
export const MARK_HOTKEYS: Record<string, TextStyle> = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
} as const

type ListType = 'numbered-list' | 'bulleted-list'
const LIST_TYPES = new Set<string>(['numbered-list', 'bulleted-list'])
export type BlockType = ListType
export type TextAlign = 'left' | 'center' | 'right' | 'justify'

const BLOCK_TAGS = new Map<string, (el: Node) => { type: BlockType | 'paragraph' | 'list-item' }>([
  ['LI', () => ({ type: 'list-item' })],
  ['OL', () => ({ type: 'numbered-list' })],
  ['P', () => ({ type: 'paragraph' })],
  ['H1', () => ({ type: 'paragraph' })],
  ['H2', () => ({ type: 'paragraph' })],
  ['H3', () => ({ type: 'paragraph' })],
  ['H4', () => ({ type: 'paragraph' })],
  ['H5', () => ({ type: 'paragraph' })],
  ['H6', () => ({ type: 'paragraph' })],
  ['UL', () => ({ type: 'bulleted-list' })],
])

// CODE: () => ({ code: true }),
// DEL: () => ({ strikethrough: true }),
// S: () => ({ strikethrough: true }),
type StyleAttr = { [key in TextStyle]?: boolean }
const TEXT_STYLE_TAGS = new Map<string, (el: Node) => StyleAttr>([
  ['EM', () => ({ italic: true })],
  ['I', () => ({ italic: true })],
  ['STRONG', () => ({ bold: true })],
  // ['B', () => ({ bold: true })], // Google Docs uses B in wierd way
  ['U', () => ({ underline: true })],
])

export function isMarkActive(editor: BaseEditor, format: TextStyle) {
  const marks = Editor.marks(editor) as TextStyleMark
  return marks ? marks[format] === true : false
}

export function toggleMark(editor: BaseEditor, format: TextStyle) {
  const isActive = isMarkActive(editor, format)
  if (isActive) Editor.removeMark(editor, format)
  else Editor.addMark(editor, format, true)
}

export function isBlockAlignActive(editor: BaseEditor, align: TextAlign) {
  const { selection } = editor
  if (!selection) return false
  const generator = Editor.nodes(editor, {
    at: Editor.unhangRange(editor, selection),
    match: (node) =>
      !Editor.isEditor(node) &&
      SlateElement.isElement(node) &&
      (node as unknown as { align?: TextAlign }).align === align,
  })
  return !!generator.next().value
}

export function isBlockTypeActive(editor: BaseEditor, format: BlockType) {
  const { selection } = editor
  if (!selection) return false
  const generator = Editor.nodes(editor, {
    at: Editor.unhangRange(editor, selection),
    match: (node) =>
      !Editor.isEditor(node) &&
      SlateElement.isElement(node) &&
      (node as unknown as { type?: BlockType }).type === format,
  })
  return !!generator.next().value
}

export function toggleBlockAlign(editor: BaseEditor, align: TextAlign) {
  const isActive = isBlockAlignActive(editor, align)
  const newProps = { align: isActive ? undefined : align }
  Transforms.setNodes(editor, newProps as Partial<Node>)
}

export function toggleBlockType(editor: BaseEditor, blockType: BlockType) {
  const isActive = isBlockTypeActive(editor, blockType)
  Transforms.unwrapNodes(editor, {
    match: (node) =>
      !Editor.isEditor(node) &&
      SlateElement.isElement(node) &&
      LIST_TYPES.has((node as unknown as { type: BlockType }).type),
    split: true,
  })

  const isList = LIST_TYPES.has(blockType)
  // eslint-disable-next-line no-nested-ternary
  const newProps = { type: isActive ? 'paragraph' : isList ? 'list-item' : blockType }
  Transforms.setNodes(editor, newProps as Partial<Node>)
  if (!isActive && isList) {
    const block = { type: blockType, children: [] }
    Transforms.wrapNodes(editor, block)
  }
}

const NewLineRegEx = /[\n\r]/g

function parseDOMElement(el: Node): SlateNode[] {
  if (el.nodeType === 3) {
    // return el.textContent
    let text = el.textContent?.replaceAll(NewLineRegEx, '') || ''
    if (el.parentElement?.nodeName === 'DIV') text += '\n'
    return text ? [{ text }] : []
  }
  if (el.nodeType !== 1) {
    // return null
    return []
  }
  if (el.nodeName === 'BR') {
    return [{ text: '\n' }]
  }

  let children = Array.from(el.childNodes).map(parseDOMElement).flat()
  if (children.length === 0) {
    children = [{ text: '' }]
  }
  if (el.nodeName === 'BODY') return jsx('fragment', {}, children)
  const block = BLOCK_TAGS.get(el.nodeName)
  if (block) return [jsx('element', block(el), children)]

  const style = TEXT_STYLE_TAGS.get(el.nodeName)
  if (style) {
    const attrs = style(el)
    if (children.some((child) => !Text.isText(child))) {
      children = children.reduce((acc: SlateNode[], cur: SlateNode) => {
        if (Text.isText(cur)) {
          acc.push(cur)
        } else {
          const texts = Array.from(SlateNode.texts(cur))
          acc.push(...texts.map((t) => ({ text: `${t[0].text}\n` })))
        }
        return acc
      }, [])
    }
    return children.map((child) => jsx('text', attrs, child))
  }
  return children
}

export function withHtml(editor: ReactEditor) {
  const { insertData } = editor
  // eslint-disable-next-line no-param-reassign
  editor.insertData = (data) => {
    const html = data.getData('text/html')
    if (html) {
      const parsed = new DOMParser().parseFromString(html, 'text/html')
      const fragment = parseDOMElement(parsed.body)
      Transforms.insertFragment(editor, fragment)
      return
    }
    insertData(data)
  }
  return editor
}
