import { useMemo } from 'react'
import { SurveyModel } from 'survey-core'
import IndividualDevelopmentPlanSurvey from './IndividualDevelopmentPlanSurvey'
import { retentionQuestionGroupList } from '../survey/context/IntakeContext'
import { individualDevelopmentPlan, individualDevelopmentPlanFeedback } from './retention-surveys'

export default function IndividualDevelopmentPlanRetention() {
  const model = useMemo(() => new SurveyModel(individualDevelopmentPlan), [])
  const feedback = useMemo(() => new SurveyModel(individualDevelopmentPlanFeedback), [])

  return <IndividualDevelopmentPlanSurvey item={retentionQuestionGroupList[0]} model={model} feedback={feedback} />
}
