import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import {
  Avatar,
  Box,
  Grid,
  IconButton,
  Link,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Tooltip,
  Typography,
} from '@mui/material'
import { observer, Observer } from 'mobx-react'
import MoreVert from '../../common/icons/MoreVert'
import Archive from '../../common/icons/Archive'
import DownloadLink from './DownloadLink'
import Label from './Label'
import TextValue from './TextValue'
import { formatAddress, formatDate, removeBlanks } from '../utils/format'
import { usePortalApi } from '../api/PortalApiContext'
import { useFeatureFlags } from '../feature-flags/FeatureFlagsContext'
import { useArchiveCandidate } from '../api/hooks/candidateHooks'
import CandidateEditor from './candidates/edit/CandidateEditor'
import CandidateArchiveDialog from './candidates/archive/CandidateArchiveDialog'
import { hasSnoozeTime, TIME_OUT_LIMIT_IN_MILLIS } from './candidates/archive'
import CustomSnackbar from '../../common/alerts/CustomSnackbar'
import CandidateEditSnoozeDialog from './candidates/archive/CandidateEditSnoozeDialog'
import { colors, palette } from '../../common/mui-theme'
import DuplicateAlert from './DuplicateAlert'
import CandidateProfileContext from './candidate-profile/context/CandidateProfileContext'

function isOther(option: string) {
  return option === 'other' || option === 'Other'
}

function notOther(option: string) {
  return !isOther(option)
}

function Profile() {
  const { candidate } = useContext(CandidateProfileContext)
  const { fullName, dto, answers, snoozeDate, archived } = candidate
  const featureFlags = useFeatureFlags()
  const api = usePortalApi()
  const firstNameLetter = useMemo(() => (fullName || dto.email).charAt(0).toUpperCase(), [dto.email, fullName])
  const appliedDate = useMemo(() => formatDate(dto.appliedDate), [dto.appliedDate])

  const address = formatAddress(answers['Mailing address'])
  const downloadAttachment = useCallback((fileId: number) => api.files.downloadAttachment(fileId), [api.files])

  const surveyAttachmentsEl = answers.Attachments?.map((a) => (
    <DownloadLink key={a.content.id} {...a} icon downloader={() => downloadAttachment(a.content.id)} />
  ))
  const placementAttachmentsEl = answers['Offer Letter']?.map((a) => (
    <DownloadLink key={a.content.id} {...a} icon downloader={() => downloadAttachment(a.content.id)} />
  ))
  const nonSubmissionAttachments = (answers.Attachments || []).concat(answers['Offer Letter'] || [])
  const uniqueSubmissionAttachments = answers['Submission Attachments']?.map((value) => {
    if (!nonSubmissionAttachments.find((val) => val.content.id === value.content.id)) return value
    return undefined
  })
  const submissionAttachmentsEl = uniqueSubmissionAttachments?.map(
    (a) =>
      a && (
        <DownloadLink
          key={`submission${a.content.id}`}
          {...a}
          icon
          downloader={() => downloadAttachment(a.content.id)}
        />
      ),
  )
  const interests = (answers['Career areas'] || []) as string[]
  const otherInterests = (answers['Career areas-Comment'] || '') as string

  const hasOther = interests.some(isOther)
  const interestToShow = removeBlanks(...interests)
    .filter(notOther)
    .join(', ')

  useEffect(() => {
    if (archived && snoozeDate) {
      const millis = snoozeDate.diff() + 1
      if (millis < TIME_OUT_LIMIT_IN_MILLIS) {
        const timeout = setTimeout(candidate.updateCurrentTime, millis)
        return () => clearTimeout(timeout)
      }
    }
    return undefined
  }, [archived, candidate, snoozeDate])

  const [anchorMenu, setAnchorMenu] = React.useState<null | HTMLElement>(null)
  const openMenu = useCallback((event: React.MouseEvent<HTMLElement>) => setAnchorMenu(event.currentTarget), [])
  const handleClose = useCallback(() => setAnchorMenu(null), [])

  const [editorOpened, setEditorOpened] = useState(false)
  const openEditor = useCallback(() => setEditorOpened(true), [])

  const [archiverOpened, setArchiverOpened] = useState(false)
  const openArchiver = useCallback(() => setArchiverOpened(true), [])

  const [snoozeEditorOpened, setSnoozeEditorOpened] = useState(false)
  const openSnoozeEditor = useCallback(() => setSnoozeEditorOpened(true), [])

  const archiveCandidate = useArchiveCandidate(dto.userId)
  const unarchiveCandidate = useCallback(() => {
    // keep reason / note as a hint to archive user again
    // const parsedFormData: ArchiveDto = { reason: dto.archiveReason, note: dto.archiveNote, snoozeDate: undefined }
    archiveCandidate.mutateAsync({}).then(() => {
      CustomSnackbar.show('Candidate unarchived')
    })
  }, [archiveCandidate])

  const closeCandidateEditor = useCallback(() => setEditorOpened(false), [])
  const closeArchiver = useCallback(() => setArchiverOpened(false), [])
  const closeSnoozeEditor = useCallback(() => setSnoozeEditorOpened(false), [])

  return (
    <Paper sx={{ display: 'flex', p: '24px', position: 'relative', borderRadius: '16px' }}>
      {dto.hasDuplicates ? <DuplicateAlert /> : null}
      <Avatar
        sx={{
          width: '56px',
          height: '56px',
          bgcolor: colors.primary['200'],
          color: palette.primary.main,
        }}
      >
        {firstNameLetter}
      </Avatar>
      <Grid container spacing={2} sx={{ pl: '18px' }}>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box>
            <Typography variant="h4" paddingTop="2px">
              {fullName || dto.email}
            </Typography>
            <Typography variant="body2" color="secondary">
              <Link
                color="secondary"
                href={fullName ? `mailto:${fullName}<${dto.email}>` : `mailto:${dto.email}`}
                underline="hover"
              >
                {dto.email}
              </Link>
              &nbsp;·&nbsp;{appliedDate}
            </Typography>
          </Box>
          {archived && (
            <Box
              sx={{
                display: 'flex',
                flexShrink: 0,
                alignItems: 'flex-start',
                paddingTop: '8px',
              }}
            >
              <Archive style={{ marginRight: '10px' }} />
              <Typography variant="body2" color="secondary">
                {dto.archiveReason}
                {hasSnoozeTime(snoozeDate) ? ` · Snoozed until ${snoozeDate?.format('MM/DD/YYYY')}` : ''}
              </Typography>
            </Box>
          )}
        </Grid>
        <Grid item xs={3}>
          <Label>Job Interests</Label>
          <TextValue>
            {interestToShow}
            {interestToShow?.length > 0 && hasOther ? ', ' : ''}
            {hasOther && (
              <Observer>
                {() =>
                  featureFlags.enabled.has('EXCLUDE_PROFILE_EDIT') ? (
                    <>{otherInterests.trim()}</>
                  ) : (
                    <Tooltip title="View additional job interests" arrow>
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <Link component="button" underline="none" sx={{ mb: '1px' }} onClick={openEditor}>
                        <Typography variant="body2">Other</Typography>
                      </Link>
                    </Tooltip>
                  )
                }
              </Observer>
            )}
          </TextValue>
        </Grid>
        <Grid item xs={3} sx={{ wordBreak: 'break-all' }}>
          <Label>Attachments</Label>
          {surveyAttachmentsEl}
          {placementAttachmentsEl}
          {submissionAttachmentsEl}
        </Grid>
        <Grid item xs={3}>
          <Label>Phone Number</Label>
          <TextValue>{dto.phone}</TextValue>
        </Grid>
        <Grid item xs={3}>
          <Label>Address</Label>
          <TextValue>{address}</TextValue>
        </Grid>
      </Grid>
      <Observer>
        {() => (
          <Box>
            <IconButton
              id="profile-menu-button"
              sx={{
                width: '40px',
                height: '40px',
                '&:hover': { backgroundColor: '#FEEDE6' },
                backgroundColor: anchorMenu ? '#FEEDE6' : 'white',
              }}
              onClick={openMenu}
            >
              <MoreVert />
            </IconButton>
            <Menu
              id="profile-menu"
              aria-labelledby="profile-menu-button"
              anchorEl={anchorMenu}
              open={!!anchorMenu}
              onClose={handleClose}
              onClick={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              PaperProps={{
                sx: {
                  minWidth: '178px',
                },
              }}
            >
              {!featureFlags.enabled.has('EXCLUDE_PROFILE_EDIT') && (
                <MenuItem onClick={openEditor}>
                  <ListItemText>Edit profile</ListItemText>
                </MenuItem>
              )}
              {!archived ? (
                <MenuItem onClick={openArchiver}>
                  <ListItemText>Archive</ListItemText>
                </MenuItem>
              ) : (
                <>
                  <MenuItem onClick={unarchiveCandidate}>
                    <ListItemText>Unarchive</ListItemText>
                  </MenuItem>
                  <MenuItem onClick={openSnoozeEditor}>
                    <ListItemText>Snooze Settings</ListItemText>
                  </MenuItem>
                </>
              )}
            </Menu>
          </Box>
        )}
      </Observer>
      {editorOpened && <CandidateEditor candidate={candidate} open onClose={closeCandidateEditor} />}
      {archiverOpened && <CandidateArchiveDialog open onClose={closeArchiver} />}
      {snoozeEditorOpened && <CandidateEditSnoozeDialog open onClose={closeSnoozeEditor} />}
    </Paper>
  )
}

export default observer(Profile)
