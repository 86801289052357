import { makeAutoObservable, runInAction } from 'mobx'
import { useQuery } from 'react-query'
import { createContext, useContext } from 'react'
import { PortalUser } from '../../../_generated/portal-api'
import PortalUserStore from './portal-user-store'
import { usePortalApi } from '../../../api/PortalApiContext'

export class PortalUserContextClass {
  store: PortalUserStore

  user: PortalUser | null = null

  constructor() {
    this.store = new PortalUserStore()
    makeAutoObservable(this, undefined, { autoBind: true })
  }
}

export const portalUserContextValue = new PortalUserContextClass()
const PortalUserContext = createContext(portalUserContextValue)
export default PortalUserContext

export function usePortalUsers() {
  const api = usePortalApi()
  const { store } = useContext(PortalUserContext)
  useQuery('portalUsers', () =>
    api.portalUsers
      .users()
      .then((r) => r.data)
      .then((users) => runInAction(() => users.forEach((user) => store.indexed.set(user.userObjectId, user)))),
  )
  return store
}
