import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useContext } from 'react'
import { runInAction } from 'mobx'
import { ArchiveDto, AssignParams, CandidateProfileDto, NewCandidateDto } from '../../_generated/portal-api'
import CandidatesContext, { useCandidates } from '../../components/candidates/context/CandidatesContext'
import { usePortalApi } from '../PortalApiContext'
import CandidateProfile from '../../components/candidates/profile/candidate-profile'

export function useCandidateProfile(id: number) {
  const api = usePortalApi()
  return useQuery(['candidateProfile', id], () =>
    api.candidates.profile(id).then((r: { data: CandidateProfileDto }) => new CandidateProfile(r.data)),
  )
}

export function useUpdateCandidateProfile(id: number) {
  const queryClient = useQueryClient()
  const api = usePortalApi()
  return useMutation({
    mutationFn: (profile: CandidateProfileDto) => api.candidates.updateProfile(id, profile),
    onSuccess: () => queryClient.invalidateQueries(['candidateProfile', id]),
  })
}

export function useUpdateCandidateAssignee(id: number) {
  const { store } = useCandidates()
  const queryClient = useQueryClient()
  const api = usePortalApi()
  return useMutation({
    mutationFn: (assignParams: AssignParams) => api.candidates.assign(assignParams),
    onSuccess: (data, assignParams) => {
      queryClient.invalidateQueries(['candidateProfile', id])
      const candidate = store.indexed.get(id)
      if (candidate)
        runInAction(() => {
          candidate.dto.assigneeId = assignParams.assigneeUuid
        })
    },
  })
}

export function useUpdateApplicationAnswers(candidateId: number) {
  const api = usePortalApi()
  const { store } = useCandidates()
  return useMutation({
    mutationFn: ({
      workflowStateId,
      resultJsonString,
    }: Pick<CandidateProfileDto, 'workflowStateId' | 'resultJsonString'>) =>
      api.candidates.updateAnswers(workflowStateId, resultJsonString || '{}'),
    onSuccess: (data, result) => {
      const candidate = store.indexed.get(candidateId)
      if (candidate)
        runInAction(() => {
          candidate.dto.resultJsonString = result.resultJsonString
        })
    }, // queryClient.invalidateQueries(['candidateProfile', candidateId])
  })
}

export function useCreateNewCandidate() {
  const api = usePortalApi()
  const ctx = useContext(CandidatesContext)
  return useMutation({
    mutationFn: (profile: NewCandidateDto) => api.candidates.create(profile),
    onSuccess: () => {
      ctx.load(api)
    },
  })
}

export function useEmailExistenceCheck(email?: string | null) {
  const api = usePortalApi()
  const lowerCased = (email || '').toLowerCase()
  return useQuery(['check-email', lowerCased], () =>
    email ? api.candidates.exists({ email: lowerCased }).then((r) => r.data) : Promise.resolve(false),
  )
}

export function useArchiveCandidate(id: number) {
  const { store } = useCandidates()
  const queryClient = useQueryClient()
  const api = usePortalApi()
  return useMutation({
    mutationFn: (archiveData: ArchiveDto) => api.candidates.archive(id, archiveData),
    onSuccess: (response, archiveData) => {
      const candidate = store.indexed.get(id)
      if (candidate) {
        runInAction(() => {
          candidate.dto.archiveSnoozeDate = archiveData.snoozeDate
          candidate.dto.archiveReason = archiveData.reason
        })
      }
      queryClient.invalidateQueries(['candidateProfile', id])
    },
  })
}

export function useDuplicateIds(id: number) {
  const api = usePortalApi()

  return useQuery(['duplicate-ids', id], () =>
    id > 0 ? api.candidates.getDuplicatesIds(id).then((r) => r.data) : Promise.resolve([]),
  )
}

export function useIgnoreDuplicates(id: number) {
  const api = usePortalApi()
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: () => api.candidates.ignoreDuplicates(id),
    onSuccess: () => {
      queryClient.invalidateQueries(['candidateProfile', id])
    },
  })
}

export function useMergeDuplicates(id: number) {
  const api = usePortalApi()
  const ctx = useContext(CandidatesContext)
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: () => api.candidates.mergeDuplicates(id),
    onSuccess: (r) => {
      queryClient.invalidateQueries(['candidateProfile', r.data]).then()
      queryClient.invalidateQueries(`jobs/${r.data}`).then()
      queryClient.invalidateQueries(['coaching-sessions', r.data]).then()
      ctx.load(api)
    },
  })
}

export function useCandidateCoachingSessions(id: number) {
  const api = usePortalApi()

  return useQuery(['coaching-sessions', id], () =>
    id > 0 ? api.coachingSession.getCoachingSessions(id).then((r) => r.data) : Promise.resolve([]),
  )
}
