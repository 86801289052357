import { RenderElementProps } from 'slate-react'
import { BaseElement } from 'slate'
import { BlockType, TextAlign } from './slate-rte'

type CustomElement = BaseElement & {
  align?: TextAlign
  type?: BlockType | 'list-item'
}

export default function CustomBlock({ attributes, children, element }: RenderElementProps) {
  const e = element as CustomElement
  const style = { textAlign: e.align }
  switch (e.type) {
    case 'bulleted-list':
      return (
        <ul style={style} {...attributes}>
          {children}
        </ul>
      )
    case 'numbered-list':
      return (
        <ol style={style} {...attributes}>
          {children}
        </ol>
      )
    case 'list-item':
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      )
    default:
      return (
        <p style={style} {...attributes}>
          {children}
        </p>
      )
  }
}
