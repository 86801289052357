import React, { useContext, useEffect } from 'react'
import { Box } from '@mui/material'
import { observer } from 'mobx-react'
import { useQuery } from 'react-query'
import { observable, runInAction } from 'mobx'
import RightRail from '../../RightRail'
import RightRailContainer from '../../RightRailContainer'
import NoteListCard from '../../notes/NoteListCard'
import JobInformation from '../JobInformation'
import ActivityLog from '../../placement/tabs/ActivityLog'
import CandidateProfileContext from '../../candidate-profile/context/CandidateProfileContext'
import JobSubmissionAccordion from '../../placement/submission/JobSubmissionAccordion'
import InterviewAccordion from '../../placement/tabs/InterviewAccordion'
import PlaceCandidateAccordion from '../../placement/tabs/PlaceCandidateAccordion'
import { usePortalApi } from '../../../api/PortalApiContext'
import FavoriteRightRail from './FavoriteRightRail'
import PlacementRightRail from './PlacementRightRail'
import { WithRequired } from '../../../../common/type-utils'
import { JobDetailsDto, JobDto } from '../../../_generated/portal-api'
import BackToJobPostingsGrid from './BackToJobPostingsGrid'
import PlacementContext from '../../placement/context/PlacementContext'

function JobMatchingDetails({ jobPostingId }: { jobPostingId: number }) {
  const { candidate } = useContext(CandidateProfileContext)
  const { jobs } = useContext(PlacementContext)
  const api = usePortalApi()
  const { data: job } = useQuery(['jobDetails', candidate.dto.userId, jobPostingId], async () =>
    api.candidates.jobMatching(candidate.dto.userId, jobPostingId).then((r) => observable(r.data)),
  )

  useEffect(() => {
    if (jobs && job) {
      const index = jobs.findIndex((j) => j.posting?.id === job.posting?.id)
      if (index >= 0) {
        runInAction(() => {
          jobs[index] = job
        })
      }
    }
  }, [job, jobs])

  return (
    <>
      {job && <BackToJobPostingsGrid />}
      {job && (
        <Box sx={{ display: 'flex', gap: '16px' }}>
          <Box sx={{ flex: 1, width: '100%' }}>
            <JobInformation job={job} />
            {candidate && job.details?.submission && (
              <JobSubmissionAccordion
                candidate={candidate}
                details={job?.details as WithRequired<JobDetailsDto, 'submission'>}
              />
            )}
            {job.details?.interviews?.map((interview, index) => (
              <InterviewAccordion
                key={interview.id}
                title={job?.details?.interviews.length === 1 ? 'Interview' : `Interview ${index + 1}`}
                interview={interview}
                id={`interview${index + 1}`}
              />
            ))}
            {job.details?.placement && <PlaceCandidateAccordion job={job as WithRequired<JobDto, 'details'>} />}
            {job.details && <ActivityLog details={job.details} />}
          </Box>
          <RightRail>
            <RightRailContainer>
              <NoteListCard />
              {job && <FavoriteRightRail job={job} />}
              {job && <PlacementRightRail job={job} />}
            </RightRailContainer>
          </RightRail>
        </Box>
      )}
    </>
  )
}

export default observer(JobMatchingDetails)
