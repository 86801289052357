import React from 'react'
import { observer } from 'mobx-react'
import { ItemValue } from 'itemvalue'
import { QuestionRadiogroupModel } from 'question_radiogroup'
import FilterAccordion, { FilterAccordionProps } from '../FilterAccordion'
import RadioAnswerFilter from '../../context/answers/radio-answer-filter'
import FilterCheckboxOption from './FilterCheckboxOption'

function RadioQuestionFilter({
  title,
  question,
  filter,
}: {
  title: FilterAccordionProps['title']
  question: QuestionRadiogroupModel
  filter: RadioAnswerFilter
}) {
  const choices = (question.choices as Array<ItemValue>).map(({ value, text }) => ({ value, text }))
  if (question.hasOther) choices.push({ value: question.otherItem.value, text: 'Other' })
  return (
    <FilterAccordion title={title} id={question.name} chip={filter.count || undefined}>
      {choices.map(({ value, text }) => (
        <FilterCheckboxOption key={value} name={text} value={value} filter={filter} />
      ))}
    </FilterAccordion>
  )
}

export default observer(RadioQuestionFilter)
