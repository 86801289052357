import MuiCommentItem from './MuiCommentItem'

export default class MuiOtherValueItem extends MuiCommentItem {
  protected onCommentChange(event: unknown): void {
    this.props.question.onOtherValueChange(event)
  }

  protected onCommentInput(event: unknown): void {
    this.props.question.onOtherValueInput(event)
  }

  protected getComment(): string {
    return this.props.question.otherValue
  }

  protected getId(): string {
    return this.props.question.otherId
  }

  protected getPlaceholder(): string {
    return this.props.question.otherPlaceholder
  }
}
