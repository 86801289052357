import React, { MouseEventHandler, useCallback, useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import CandidateProfileContext from '../../candidate-profile/context/CandidateProfileContext'
import BackToJob from '../BackToJob'

function BackToJobPostingsGrid() {
  const { candidate } = useContext(CandidateProfileContext)
  const navigate = useNavigate()
  const { state } = useLocation()

  const customNavigation = useCallback<MouseEventHandler>(
    (e) => {
      if (state && state.fromJobPostingGrid) {
        navigate(-1)
        e.preventDefault()
      }
    },
    [navigate, state],
  )

  return (
    <BackToJob to={`/candidates/${candidate.dto.userId}/job-matching`} onClick={customNavigation}>
      Back to Job Matching
    </BackToJob>
  )
}

export default BackToJobPostingsGrid
