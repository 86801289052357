import {
  Box,
  Checkbox,
  debounce,
  FormControl,
  FormControlLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  SxProps,
  TextField,
  Typography,
} from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers'
import React, { CSSProperties, useCallback, useEffect, useMemo, useState } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { runInAction } from 'mobx'
import { observer, Observer } from 'mobx-react'
import { SurveyModel } from 'survey-core'
import CustomDatePicker from '../../../../common/survey/custom-date-picker/CustomDatePicker'
import css from './JobSubmission.module.css'
import CandidateProfile from '../../candidates/profile/candidate-profile'
import { usePortalUsers } from '../../portal-user/context/PortalUserContext'
import { FixedIcon, menuProps } from '../../AssignDropdown'
import { JobDetailsDto, JobDetailsExportDto, JobSubmission, PortalUserDto } from '../../../_generated/portal-api'
import formats from '../../../../common/survey/formats'
import MinimizableSurveyAccordion from '../../survey-answers/MinimizableSurveyAccordion'
import AccordionHeaderBadge from '../../AccordionHeaderBadge'
import Close from '../../../../common/icons/Close'
import { usePortalApi } from '../../../api/PortalApiContext'
import MaskedPhoneInput from '../../../../common/components/MaskedPhoneInput'
import InnerNote from '../../InnerNote'
import SubmissionUpload from './SubmissionUpload'
import Check from '../../../../common/icons/Check'
import { JOB_SUBMISSION_ANCHOR, useInvalidateActivityLog } from '../../../api/hooks/placementHooks'
import { FileQuestionValue } from '../../../../common/survey/file-question-value'
import DownloadButton from '../../DownloadButton'
import { candidateBackground, educationAndExperience, employmentAndPreferences } from '../../../../demo/survey-model'
import ExportIntakeSection from './ExportIntakeSection'
import { formatDate } from '../../../utils/format'
import HealthCareerLab from '../../../../common/icons/HealthCareerLab'
import { MAX_TIMESTAMP } from '../tabs/placement-schema'
import { colors } from '../../../../common/mui-theme'
import submissionSchema, {
  BoolFields,
  DateFields,
  dateFieldsSet,
  SameFields,
  SubmissionSchema,
  UuidFields,
  uuidFieldsSet,
} from './job-submission-schema'
import JobSubmissionComputed from './job-submission-computed'
import MinimizedView from '../tabs/MinimizedView'
import { WithRequired } from '../../../../common/type-utils'
import { notEnteredYet } from '../../titles'

function getDefaultValue(submission: JobSubmission): SubmissionSchema {
  const { dateTime, ...other } = submission
  return {
    dateTime: dateTime ? dayjs(dateTime) : null,
    ...other,
  }
}

const controlledFiledOptions = { shouldValidate: true, shouldDirty: true }

export function Divider({ sx }: { sx?: SxProps }) {
  return (
    <Box
      sx={{
        ...sx,
        height: '1px',
        backgroundColor: colors.neutral['200'],
      }}
    />
  )
}

const trackedQuestions = ['dateTime', 'contactFirstName', 'contactLastName', 'contactEmail', 'contactPhone'] as const

function hasAnswersForExport(survey: SurveyModel): boolean {
  return survey.getAllQuestions(true).some((question) => {
    if (question.getType() === 'file') return false // we should skip file questions
    if (question.getType() === 'offense') return !!question.value // empty array here means - no offense selected
    return Array.isArray(question.value) ? !!question.value.length : !!question.value
  })
}

const firstSectionStyle: CSSProperties = { borderTop: '1px solid rgba(224, 224, 224, 1)' }

function JobSubmissionAccordion({
  candidate,
  details,
}: {
  candidate: CandidateProfile
  details: WithRequired<JobDetailsDto, 'submission'>
}) {
  const { submission } = details
  const computedValues = useMemo(() => new JobSubmissionComputed(submission), [submission])
  const backgroundSurvey = useMemo(() => new SurveyModel(candidateBackground), [])
  const educationSurvey = useMemo(() => new SurveyModel(educationAndExperience), [])
  const employmentSurvey = useMemo(() => new SurveyModel(employmentAndPreferences), [])

  const [isBackgroundDisabled, setBackgroundDisabled] = useState(true)
  const [isEducationDisabled, setEducationDisabled] = useState(true)
  const [isEmploymentDisabled, setEmploymentDisabled] = useState(true)

  const isBackgroundIncluded = submission.includeCandidateBackground && !isBackgroundDisabled
  const isEducationIncluded = submission.includeCandidateEducation && !isEducationDisabled
  const isEmploymentIncluded = submission.includeCandidateEmployment && !isEmploymentDisabled

  useEffect(() => {
    backgroundSurvey.setDataCore(candidate.answers)
    educationSurvey.setDataCore(candidate.answers)
    employmentSurvey.setDataCore(candidate.answers)
    setBackgroundDisabled(!hasAnswersForExport(backgroundSurvey))
    setEducationDisabled(!hasAnswersForExport(educationSurvey))
    setEmploymentDisabled(!hasAnswersForExport(employmentSurvey))
  }, [candidate.answers, backgroundSurvey, educationSurvey, employmentSurvey])

  const api = usePortalApi()
  const store = usePortalUsers()
  const invalidateActivityLog = useInvalidateActivityLog()
  const [saving, setSaving] = useState(false)
  const [expanded, setExpanded] = useState(true)
  const expand = useCallback(() => setExpanded(true), [])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const saveJobDetails = useCallback(
    debounce(() => {
      api.jobs.updateSubmission(submission).then(() => setSaving(false))
    }, 1500),
    [api, submission],
  )

  const {
    control,
    register,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm<SubmissionSchema>({
    resolver: zodResolver(submissionSchema),
    mode: 'all',
    defaultValues: getDefaultValue(submission),
  })

  useEffect(() => {
    const subscription = watch((value, info) => {
      const s = submission
      setSaving(true)
      runInAction(() => {
        if (!info.name) return
        const key = info.name as keyof SubmissionSchema
        if (dateFieldsSet.has(key as DateFields)) {
          const dt = value[key] as Dayjs | null
          s[key as DateFields] = dt && dt.isValid() && dt.isBefore(MAX_TIMESTAMP) ? dt.format() : undefined
          return
        }
        if (uuidFieldsSet.has(key as UuidFields)) {
          const uuid = value[key as UuidFields]
          if (uuid && uuid.length === 36) {
            s[key as UuidFields] = uuid
          }
          return
        }

        if (Object.hasOwn(s, key)) {
          const v = value[key]
          if (typeof v === 'boolean') {
            s[key as BoolFields] = v
          } else if (typeof v === 'string' || v === undefined) {
            const k = key as Exclude<SameFields, BoolFields>
            s[k] = v
          }
        }
      })
      saveJobDetails()
      if (info.name === 'sent' && value.sent) invalidateActivityLog(submission.jobDetailsId)
    })

    return () => subscription.unsubscribe()
  }, [submission, saveJobDetails, watch, invalidateActivityLog])

  const dateTime = watch('dateTime')
  const onDateTimeChanged = useCallback(
    (d: Dayjs | null) => {
      setValue('dateTime', d, controlledFiledOptions)
    },
    [setValue],
  )

  const onRecruiterChanged = useCallback(
    (e: SelectChangeEvent) => {
      const uuid = e.target.value
      setValue('recruiter', uuid)
      const recruiter = store.indexed.get(uuid)
      setValue('recruiterEmail', recruiter?.email || '')
    },
    [setValue, store.indexed],
  )

  const answeredCount = trackedQuestions.filter((name) => !!getValues(name) && !errors[name]).length
  const downloadAttachment = useCallback(
    async (jobInfo: JobDetailsExportDto) => {
      const response = await api.jobs.exportCandidateJobInfo(jobInfo)
      if (!getValues('exportCreated')) {
        setValue('exportCreated', true)
        saveJobDetails()
      }
      return response
    },
    [api.jobs, getValues, saveJobDetails, setValue],
  )

  const isCandidateEmailInvalid = computedValues.isCandidateEmailEmpty || !!errors.candidateEmail?.message
  const isCandidateEmailIncluded = submission.includeCandidateEmail && !isCandidateEmailInvalid

  const isCandidatePhoneInvalid = computedValues.isCandidatePhoneEmpty || !!errors.candidatePhone?.message
  const isCandidatePhoneIncluded = submission.includeCandidatePhone && !isCandidatePhoneInvalid

  const exportContainerHTML = (fileQuestionValues: FileQuestionValue[], excludePdf: boolean): JobDetailsExportDto => {
    const fileIds = fileQuestionValues.map((file: FileQuestionValue) => file.content.id)
    const container = excludePdf ? null : document.getElementById('exportContainerId')
    return {
      candidateName: candidate.fullName,
      fileIds,
      html: container?.outerHTML || '',
    }
  }

  const appliedDate = useMemo(() => formatDate(candidate.dto.appliedDate), [candidate.dto.appliedDate])

  const recruiter = store.indexedAssignees.get(submission.recruiter)
  const isRecruiterInvalid = !recruiter
  const isRecruiterIncluded = submission.includeRecruiterDetails && !isRecruiterInvalid

  return (
    <MinimizableSurveyAccordion
      id={JOB_SUBMISSION_ANCHOR}
      title="Job Submission"
      saving={saving}
      allQuestionCount={trackedQuestions.length}
      answeredQuestionCount={answeredCount}
      expanded={expanded}
      setExpanded={setExpanded}
      minimizedHeaderBadge={
        <AccordionHeaderBadge variant={submission.sent || submission.exportCreated ? 'info' : 'error'}>
          {
            // eslint-disable-next-line no-nested-ternary
            submission.sent ? 'Candidate Submitted' : submission.exportCreated ? 'Export Created' : 'Not Completed'
          }
          {submission.sent ? <Check width={16} height={16} /> : <Close width={16} height={16} />}
        </AccordionHeaderBadge>
      }
      collapsedContent={
        <MinimizedView
          isEditable
          onEdit={expand}
          items={[
            {
              title: 'Submission Date',
              value: submission.dateTime ? dayjs(submission.dateTime).format(formats.date) : notEnteredYet,
            },
            { title: 'Submission Contact', value: computedValues.contactSummary },
          ]}
        />
      }
    >
      <>
        <div className={css.paddedInnerSection}>
          <div>
            <Typography variant="subtitle1" color={colors.neutral['600']} sx={{ mb: '24px' }}>
              Confirm Job Submission
            </Typography>
            <div className={css.flexRow}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <CustomDatePicker
                  label="Submission Date"
                  value={dateTime}
                  maxDate={MAX_TIMESTAMP}
                  onChange={onDateTimeChanged}
                  onAccept={onDateTimeChanged}
                  format={formats.date}
                  error={!!errors.dateTime}
                  helperText={errors.dateTime?.message || ' '}
                  fullWidth
                />
              </LocalizationProvider>
            </div>
            <Observer>
              {() => (
                <FormControlLabel
                  control={<Checkbox checked={submission.sent} {...register('sent')} />}
                  label="Submission sent to employer"
                />
              )}
            </Observer>
          </div>
          <Divider sx={{ m: '15px 0 24px' }} />
          <div>
            <Typography variant="subtitle1" color={colors.neutral['600']}>
              Job Submission Details
            </Typography>
            <div className={css.flexColumn}>
              <Typography variant="subtitle1" color={colors.neutral['600']} sx={{ mb: '16px' }}>
                Job Contact Details
              </Typography>
              <div className={css.flexRow}>
                <TextField
                  label="First Name"
                  {...register('contactFirstName')}
                  fullWidth
                  helperText=" "
                  inputProps={{ maxLength: 45 }}
                />
                <TextField
                  label="Last Name"
                  {...register('contactLastName')}
                  fullWidth
                  helperText=" "
                  inputProps={{ maxLength: 45 }}
                />
              </div>
              <div className={css.flexRow}>
                <TextField
                  label="Email Address"
                  {...register('contactEmail')}
                  error={!!errors.contactEmail}
                  helperText={errors.contactEmail?.message || ' '}
                  fullWidth
                  inputProps={{ maxLength: 255 }}
                />
                <Observer>
                  {() => {
                    const { onChange, ref, ...other } = register('contactPhone')
                    return (
                      <TextField
                        label="Office Phone"
                        {...other}
                        inputRef={ref}
                        onInput={onChange}
                        onChange={onChange}
                        value={submission.contactPhone || ''}
                        error={!!errors.contactPhone}
                        helperText={errors.contactPhone?.message || ' '}
                        InputProps={{
                          inputComponent: MaskedPhoneInput,
                        }}
                        fullWidth
                      />
                    )
                  }}
                </Observer>
              </div>
            </div>
          </div>
          <Divider sx={{ mb: '24px' }} />
          <div className={css.innerContainer}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px', mb: '15px' }}>
              <Typography variant="h6" color={colors.neutral['600']}>
                Export Candidate Info
              </Typography>
              <Typography variant="body1">Select the items you would like to include</Typography>
            </Box>
            <FormControlLabel
              control={
                <Observer>
                  {() => <Checkbox checked={submission.includeCover} {...register('includeCover')} />}
                </Observer>
              }
              label="Include Cover Page and Branding"
            />
            <div className={css.flexColumn}>
              <Typography variant="subtitle1" color={colors.neutral['600']} sx={{ mb: '16px' }}>
                Recruiter Details
              </Typography>
              <div className={css.recruiterRow}>
                <FormControl fullWidth sx={{ marginBottom: '30px' }}>
                  <Observer>
                    {() => (
                      <Select
                        value={submission.recruiter}
                        color="secondary"
                        IconComponent={FixedIcon}
                        displayEmpty={false}
                        renderValue={(value) => store.indexed.get(value)?.fullName || 'Not Assigned'}
                        MenuProps={menuProps}
                        onChange={onRecruiterChanged}
                      >
                        {/* <MenuItem value="">
                      <ListItemText primary="Not Assigned" />
                      {candidate.dto.assigneeId === '' && <Typography id="dot">{'\u2022'}</Typography>}
                    </MenuItem> */}
                        {store.all.map((variant: PortalUserDto) => (
                          <MenuItem key={variant.userObjectId} value={variant.userObjectId}>
                            <ListItemText primary={variant.fullName} />
                            {candidate.dto.assigneeId === variant.userObjectId && (
                              <Typography id="dot">{'\u2022'}</Typography>
                            )}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  </Observer>
                </FormControl>
                <Controller
                  render={({ field }) => (
                    <TextField
                      label="Recruiter Email"
                      {...field}
                      error={!!errors.recruiterEmail}
                      helperText={errors.recruiterEmail?.message || ' '}
                      fullWidth
                    />
                  )}
                  name="recruiterEmail"
                  control={control}
                />

                <Observer>
                  {() => (
                    <TextField
                      label="Recruiter Phone"
                      {...register('recruiterPhone')}
                      value={submission.recruiterPhone || ''}
                      onInput={register('recruiterPhone').onChange}
                      error={!!errors.recruiterPhone}
                      helperText={errors.recruiterPhone?.message || ' '}
                      className={css.recruiterPhone}
                      InputProps={{
                        inputComponent: MaskedPhoneInput,
                      }}
                      fullWidth
                    />
                  )}
                </Observer>
              </div>
            </div>
            <FormControlLabel
              control={
                <Observer>
                  {() => (
                    <Checkbox
                      disabled={isRecruiterInvalid}
                      checked={isRecruiterIncluded}
                      {...register('includeRecruiterDetails')}
                    />
                  )}
                </Observer>
              }
              label="Include Recruiter Details"
            />
            <div className={css.flexColumn}>
              <Typography variant="subtitle1" color={colors.neutral['600']} sx={{ mb: '16px' }}>
                Candidate Details
              </Typography>
              <div className={css.flexRow}>
                <TextField
                  label="Candidate Email"
                  {...register('candidateEmail')}
                  error={!!errors.candidateEmail}
                  helperText={errors.candidateEmail?.message || ' '}
                  fullWidth
                />
                <Observer>
                  {() => {
                    const props = register('candidatePhone')
                    return (
                      <TextField
                        label="Candidate Phone"
                        {...props}
                        value={submission.candidatePhone || ''}
                        onInput={props.onChange}
                        error={!!errors.candidatePhone}
                        helperText={errors.candidatePhone?.message || ' '}
                        InputProps={{
                          inputComponent: MaskedPhoneInput,
                        }}
                        fullWidth
                      />
                    )
                  }}
                </Observer>
              </div>
            </div>
            <div className={css.flexRow}>
              <FormControlLabel
                control={
                  <Observer>
                    {() => (
                      <Checkbox
                        disabled={isCandidateEmailInvalid}
                        checked={isCandidateEmailIncluded}
                        {...register('includeCandidateEmail')}
                      />
                    )}
                  </Observer>
                }
                label="Include Candidate Email"
                sx={{ flex: '50%' }}
              />
              <FormControlLabel
                control={
                  <Observer>
                    {() => (
                      <Checkbox
                        disabled={isCandidatePhoneInvalid}
                        checked={isCandidatePhoneIncluded}
                        {...register('includeCandidatePhone')}
                      />
                    )}
                  </Observer>
                }
                label="Include Candidate Phone"
                sx={{ flex: '50%' }}
              />
            </div>
            <div className={css.flexRow}>
              <FormControlLabel
                control={
                  <Observer>
                    {() => (
                      <Checkbox
                        disabled={isEducationDisabled}
                        checked={isEducationIncluded}
                        {...register('includeCandidateEducation')}
                      />
                    )}
                  </Observer>
                }
                label="Candidate Education & Experience"
                sx={{ flex: '50%' }}
              />
              <FormControlLabel
                control={
                  <Observer>
                    {() => (
                      <Checkbox
                        disabled={isEmploymentDisabled}
                        checked={isEmploymentIncluded}
                        {...register('includeCandidateEmployment')}
                      />
                    )}
                  </Observer>
                }
                label="Candidate Employment & Preferences"
                sx={{ flex: '50%' }}
              />
            </div>
            <FormControlLabel
              control={
                <Observer>
                  {() => (
                    <Checkbox
                      disabled={isBackgroundDisabled}
                      checked={isBackgroundIncluded}
                      {...register('includeCandidateBackground')}
                    />
                  )}
                </Observer>
              }
              label="Candidate Background"
            />
            <div className={css.flexColumn} style={{ marginBottom: '24px' }}>
              <SubmissionUpload />
            </div>
          </div>
          <Observer>
            {() => {
              const pdfIsEmpty = !(
                isRecruiterIncluded ||
                isCandidateEmailIncluded ||
                isCandidatePhoneIncluded ||
                isEducationIncluded ||
                isEmploymentIncluded ||
                isBackgroundIncluded
              )
              const files = candidate.answers['Submission Attachments']?.filter((file) => !file.content.excluded) || []
              const archiveName = `${candidate.fullName || candidate.dto.email}${
                (details.title || '').trim() && ` - ${details.title?.trim()}`
              }.zip`
              return (
                <DownloadButton
                  name={archiveName}
                  disabled={files.length === 0 && pdfIsEmpty}
                  downloader={() => downloadAttachment(exportContainerHTML(files, pdfIsEmpty))}
                />
              )
            }}
          </Observer>

          <Observer>
            {() => {
              const title = details.title ? `${candidate.fullName} for ${details.title}` : candidate.fullName
              return (
                <div id="exportContainerId" className={css.hidden}>
                  {submission.includeCover && (
                    <div
                      style={{
                        marginTop: '4.8in',
                        marginBottom: '5.5in',
                        textAlign: 'center',
                        alignContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <p style={{ textAlign: 'center' }}>
                        <HealthCareerLab height={124} width={253} />
                      </p>
                      <p
                        style={{
                          fontFamily: 'Satoshi',
                          fontSize: '24px',
                          fontStyle: 'normal',
                          fontWeight: '700',
                          lineHeight: '32px',
                        }}
                      >
                        {title}
                      </p>
                    </div>
                  )}
                  <table width="100%">
                    <thead>
                      <tr>
                        <th>
                          {submission.includeCover && (
                            <p style={{ textAlign: 'center', display: 'table-header-group' }}>
                              <HealthCareerLab height={64} width={130} />
                            </p>
                          )}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <p
                            style={{
                              fontSize: '20px',
                              fontWeight: '700',
                              lineHeight: '30px',
                              color: '#000',
                              fontFamily: 'Satoshi',
                              fontStyle: 'normal',
                            }}
                          >
                            {title}
                          </p>
                          {isCandidateEmailIncluded && (
                            <div
                              style={{
                                color: colors.neutral['400'],
                                fontSize: '16px',
                                fontWeight: '500',
                                lineHeight: '24px',
                              }}
                            >
                              <Typography variant="body2" color="secondary">
                                {`${submission.candidateEmail} · ${appliedDate}`}
                              </Typography>
                            </div>
                          )}
                          {isCandidatePhoneIncluded && <p>{submission.candidatePhone}</p>}

                          {isRecruiterIncluded && (
                            <div style={{ borderTop: '1px solid rgba(224, 224, 224, 1)' }}>
                              <div style={{ fontSize: '20px' }}>
                                <Typography>Recruiter Details</Typography>
                              </div>
                              <p>
                                {[
                                  recruiter.fullName,
                                  errors.recruiterEmail?.message ? '' : submission.recruiterEmail,
                                  errors.recruiterPhone?.message ? '' : submission.recruiterPhone,
                                ]
                                  .map((s) => s?.trim())
                                  .filter(Boolean)
                                  .join(', ') || 'Not Assigned'}
                              </p>
                            </div>
                          )}

                          {isEducationIncluded && (
                            <ExportIntakeSection
                              model={educationSurvey}
                              title="Education & Experience"
                              style={firstSectionStyle}
                            />
                          )}
                          {isEmploymentIncluded && (
                            <ExportIntakeSection
                              model={employmentSurvey}
                              title="Employment & Preferences"
                              style={!isEducationIncluded ? firstSectionStyle : undefined}
                            />
                          )}
                          {isBackgroundIncluded && (
                            <ExportIntakeSection
                              model={backgroundSurvey}
                              title="Candidate Background"
                              style={!isEducationIncluded && !isEmploymentIncluded ? firstSectionStyle : undefined}
                            />
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )
            }}
          </Observer>
        </div>
        <Box sx={{ borderTop: '1px solid #EAEBEC' }}>
          <InnerNote title="Additional Submission Details">
            <TextField
              placeholder="Elaborate on any additional submission information...."
              multiline
              fullWidth
              rows={5}
              {...register('additionalDetails')}
              inputProps={{ maxLength: 1000 }}
            />
          </InnerNote>
        </Box>
      </>
    </MinimizableSurveyAccordion>
  )
}

export default observer(JobSubmissionAccordion)
