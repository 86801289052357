import { Box, Divider, Paper } from '@mui/material'
import { ForwardedRef, forwardRef, useContext } from 'react'
import FilterActions from './FilterActions'
import StaffAssignee from './sections/StaffAssignee'
import { getCheckboxQuestion, getRadioQuestion } from '../../../../demo/survey-model'
import CheckboxQuestionFilter from './sections/CheckboxQuestionFilter'
import CandidatesContext from '../context/CandidatesContext'
import RadioQuestionFilter from './sections/RadioQuestionFilter'
import Archived from './sections/Archived'
import DateFilter from './sections/DateFilter'
import { palette } from '../../../../common/mui-theme'

type FilterPanelProps = {
  width?: number
  height?: number
}

// eslint-disable-next-line react/display-name
const FilterPanel = forwardRef(({ width, height }: FilterPanelProps, ref: ForwardedRef<HTMLDivElement>) => {
  const { filter } = useContext(CandidatesContext)
  const applyingReasons = getCheckboxQuestion('Why you are here')
  const education = getRadioQuestion('Highest degree')
  const interests = getCheckboxQuestion('Career areas')
  const training = getCheckboxQuestion('Training Program Interest')
  return (
    <Paper
      ref={ref}
      variant="outlined"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width,
        height,
        background: palette.secondary.light,
        borderRadius: '16px',
      }}
    >
      <Box
        sx={{
          height: 16,
          background: 'white',
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
        }}
      />
      <Box
        sx={{
          flex: 1,
          background: 'white',
          overflowY: 'auto',
        }}
      >
        <StaffAssignee />
        <Divider />
        <DateFilter filter={filter.appliedDate} title="Applied" id="applied" />
        <Divider />
        <DateFilter filter={filter.lastActivityDate} title="Last Activity" id="last-activity" />
        <Divider />
        {applyingReasons && (
          <>
            <CheckboxQuestionFilter
              title="Reason for Applying"
              question={applyingReasons}
              filter={filter.applyingReason}
            />
            <Divider />
          </>
        )}
        {education && (
          <>
            <RadioQuestionFilter title="Education" question={education} filter={filter.education} />
            <Divider />
          </>
        )}
        {interests && (
          <>
            <CheckboxQuestionFilter title="Area of Interest" question={interests} filter={filter.interests} />
            <Divider />
          </>
        )}
        {training && (
          <>
            <CheckboxQuestionFilter title="Training" question={training} filter={filter.training} />
            <Divider />
          </>
        )}
        <Archived />
      </Box>
      <Box sx={{ borderRadius: 16, padding: '22px 24px' }}>
        <FilterActions />
      </Box>
    </Paper>
  )
})

export default FilterPanel
