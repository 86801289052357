import { Button } from '@mui/material'
import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import AlertsContext, { Alert } from '../../common/alerts/AlertsContext'
import { useIgnoreDuplicates, useMergeDuplicates } from '../api/hooks/candidateHooks'
import { CandidateProfileDto } from '../_generated/portal-api'
import CandidateProfileContext from './candidate-profile/context/CandidateProfileContext'
import nop from '../../common/nop'

function DuplicateAlertContent({ dto }: { dto: CandidateProfileDto }) {
  const navigate = useNavigate()

  const viewDuplicates = useCallback(() => {
    navigate(`/duplicate-candidates/${dto.userId}`)
  }, [dto.userId, navigate])

  return (
    <Button variant="text" size="small" sx={{ padding: 0 }} onClick={viewDuplicates}>
      Review Duplicate Entries
    </Button>
  )
}

const isDuplicate = (alert: Alert) => alert.title === 'Duplicate candidate detected'

export default function DuplicateAlert() {
  const { addAlert, removeAlerts, hasAlert } = useContext(AlertsContext)
  const { candidate } = useContext(CandidateProfileContext)
  const { dto } = candidate

  const navigate = useNavigate()
  const ignoreDuplicates = useIgnoreDuplicates(dto.userId)
  const mergeDuplicates = useMergeDuplicates(dto.userId)

  const onIgnore = useCallback(() => {
    ignoreDuplicates.mutateAsync().then(nop)
  }, [ignoreDuplicates])

  const onMerge = useCallback(
    (removeAlert: () => void) => {
      mergeDuplicates.mutateAsync().then((value) => {
        removeAlert()
        navigate(`/candidates/${value.data}?merged`)
      })
    },
    [mergeDuplicates, navigate],
  )

  const content = useMemo(() => <DuplicateAlertContent dto={dto} />, [dto])

  useEffect(() => {
    if (dto.hasDuplicates) {
      if (!hasAlert(isDuplicate))
        addAlert('warning', 'Duplicate candidate detected', content, 'page', 'action', onIgnore, onMerge)
    } else {
      removeAlerts(isDuplicate)
    }
  }, [addAlert, content, dto.hasDuplicates, hasAlert, onIgnore, onMerge, removeAlerts])

  useEffect(() => () => removeAlerts(isDuplicate), [removeAlerts])

  return null
}
