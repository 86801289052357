import { Observer } from 'mobx-react'
import React, { useMemo } from 'react'
import AutoSizer from 'react-virtualized-auto-sizer'
import { Box } from '@mui/material'
import { useParams } from 'react-router-dom'
import CandidatesGridContext, { CandidatesGridContextValue } from '../components/candidates/grid/CandidatesGridContext'
import DelayedProgressBar from '../components/DelayedProgressBar'
import { CandidatesContextValue, useCandidates } from '../components/candidates/context/CandidatesContext'
import FullHeight from '../components/FullHeight'
import CandidateContentBox from '../components/candidates/CandidateContentBox'
import { CandidatesGridVirtuoso } from '../components/candidates/grid/CandidatesGrid'
import PageContentContainer from '../components/PageContentContainer'
import { BackToCandidates } from './CandidateProfilePage'
import { useDuplicateIds } from '../api/hooks/candidateHooks'
import PortalUserStore from '../components/portal-user/context/portal-user-store'

function DuplicateCandidatesPageContent({
  candidateId,
  duplicateIds,
}: {
  candidateId: number
  duplicateIds: number[]
}) {
  const { store } = useCandidates()

  const gridContextValue = useMemo(() => {
    const candidatesContextValue = new CandidatesContextValue(new PortalUserStore())

    if (duplicateIds?.length) {
      const duplicateIdsSet = new Set<number>(duplicateIds)
      duplicateIdsSet.add(candidateId)

      candidatesContextValue.store.update(
        store.candidates?.filter((c) => duplicateIdsSet.has(c.dto.userId)).map((c) => c.dto) || [],
      )
    }

    return new CandidatesGridContextValue(candidatesContextValue, false)
  }, [candidateId, duplicateIds, store.candidates])

  return (
    <CandidatesGridContext.Provider value={gridContextValue}>
      <PageContentContainer>
        <BackToCandidates text="Back to All Candidates" />
        <Box sx={{ display: 'flex', width: '100%', overflow: 'hidden' }}>
          <FullHeight bottomMargin={24} style={{ display: 'flex', marginTop: '16px', flex: 1 }}>
            <CandidateContentBox>
              <AutoSizer>{({ height, width }) => <CandidatesGridVirtuoso width={width} height={height} />}</AutoSizer>
            </CandidateContentBox>
          </FullHeight>
        </Box>
      </PageContentContainer>
    </CandidatesGridContext.Provider>
  )
}
export default function DuplicateCandidatesPage() {
  const { store } = useCandidates()
  const { id = '-1' } = useParams()
  const { data: duplicateIds } = useDuplicateIds(+id)

  return (
    <Observer>
      {() =>
        store.isLoading || !duplicateIds ? (
          <DelayedProgressBar />
        ) : (
          <DuplicateCandidatesPageContent duplicateIds={duplicateIds || []} candidateId={+id} />
        )
      }
    </Observer>
  )
}
