import { SurveyQuestionElementBase } from 'survey-react-ui'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { Dayjs } from 'dayjs'
import formats from './formats'
import CustomDatePicker from './custom-date-picker/CustomDatePicker'
import DateModel from './DateModel'

export default class MuiDate extends SurveyQuestionElementBase {
  constructor(props: unknown) {
    super(props)
    this.onValueChanged = this.onValueChanged.bind(this)
  }

  get question(): DateModel {
    return this.questionBase as DateModel
  }

  onValueChanged(date: Dayjs | null) {
    this.value = date
  }

  get value(): Dayjs | null {
    if (this.state?.date) {
      return this.state.date as Dayjs
    }
    if (!this.questionBase.value) return null
    return formats.parseDate(this.questionBase.value)
  }

  set value(date: Dayjs | null) {
    this.setState({ date }) // we support state to not hide invalid values in date picker
    this.questionBase.value = date?.isValid() ? date.format(formats.date) : undefined
  }

  protected renderElement(): JSX.Element {
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <CustomDatePicker
          label={this.question.label}
          format={formats.date}
          value={this.value}
          onChange={this.onValueChanged}
          views={['year', 'month', 'day']}
          onAccept={this.onValueChanged}
        />
      </LocalizationProvider>
    )
  }
}
