import { Box, Card, CardContent, Typography } from '@mui/material'
import React, { useCallback, useContext } from 'react'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import { colors, palette } from '../../../common/mui-theme'
import { ArrowRightNoCenter } from '../../../common/icons/Arrows'
import LapsedDays from './LapsedTimeText'
import { JobDetailsDto } from '../../_generated/portal-api'
import CandidateProfileContext from '../candidate-profile/context/CandidateProfileContext'
import { getStatus } from './tabs/archived-job'

function FormattedDate({ date }: { date?: Date }) {
  return <span>{date ? dayjs(date).format('MMM D, YYYY') : 'unknown'}</span>
}

function getFurthestDate(job: JobDetailsDto): Date | undefined {
  if (job.placement) return (job.placement.startDate && new Date(job.placement.startDate)) || undefined
  if (job.interviews.length > 0) {
    // max interview date or undefined
    return job.interviews.reduce((d, interview) => {
      if (!interview.interviewedAt) return d
      const d2 = new Date(interview.interviewedAt)
      return d && d.getTime() > d2.getTime() ? d : d2
    }, undefined as Date | undefined)
  }
  return (job.submission?.dateTime && new Date(job.submission.dateTime)) || undefined
}

export default function PlacementCard({ job }: { job: JobDetailsDto }) {
  const navigate = useNavigate()
  const { candidate } = useContext(CandidateProfileContext)
  const to = `/candidates/${candidate?.dto.userId}/placement/${job.id}` // TODO #${JOB_DETAILS_ANCHOR}
  const handleClick = useCallback(() => navigate(to), [to, navigate])
  const companyName = job.employer?.trim() || job.placement?.employer?.trim() || 'Enter Company Name'
  const jobTitle = job.title?.trim() || job.placement?.title?.trim() || 'Enter Job Title'
  const isCompleteData = jobTitle !== 'Enter Job Title' && companyName !== 'Enter Company Name'

  const placementCardDateStyle = {
    fontSize: '16px',
    lineHeight: '24px',
    color: isCompleteData ? colors.neutral['300'] : colors.neutral['200'],
    background: 'inherit',
  }

  const ellipsisStyle = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  }

  const renderDetails = () => {
    if (job.archived) {
      return (
        <>
          <Typography
            variant="body2"
            sx={{
              fontSize: '18px',
              fontWeight: 700,
              lineHeight: '28px',
              color: isCompleteData ? palette.primary.main : colors.neutral['200'],
            }}
          >
            {getStatus(job)}
          </Typography>
          <Typography variant="body2" sx={placementCardDateStyle}>
            <FormattedDate date={(job.timeStamp && new Date(job.timeStamp)) || undefined} />
          </Typography>
        </>
      )
    }

    const date = isCompleteData ? getFurthestDate(job) : undefined
    return (
      <>
        <Typography variant="body2" paddingTop="2px" sx={placementCardDateStyle}>
          <FormattedDate date={date} />
        </Typography>
        {date && (
          <Typography variant="body2" paddingTop="2px" sx={placementCardDateStyle}>
            <LapsedDays date={date} />
          </Typography>
        )}
      </>
    )
  }

  return (
    <Card
      onClick={handleClick}
      sx={{
        border: `1px solid ${palette.secondary.light}`,
        borderRadius: '20px',
        boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
        backgroundColor: job.archived ? colors.neutral['75'] : colors.base.white,
        margin: '17px 0',
        '&:hover': {
          cursor: 'pointer',
          borderColor: colors.primary['500'],
          boxShadow: '0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)',
        },
      }}
    >
      <CardContent
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '16px 24px',
          '&:last-child': {
            pb: '16px',
          },
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', maxWidth: '300px' }}>
          <Typography
            variant="h6"
            sx={{
              color: isCompleteData ? colors.neutral['600'] : colors.neutral['200'],
              ...ellipsisStyle,
            }}
          >
            {jobTitle}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: isCompleteData ? palette.primary.main : colors.neutral['200'],
              ...ellipsisStyle,
            }}
          >
            {companyName}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', textAlign: 'right', alignItems: 'center', gap: '21px' }}>
          <Box sx={{ marginRight: '22px' }}>{renderDetails()}</Box>
          <Box>
            <ArrowRightNoCenter />
          </Box>
        </Box>
      </CardContent>
    </Card>
  )
}
