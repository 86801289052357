import { makeAutoObservable } from 'mobx'
import CandidatesSearch from './candidates-search'
import Candidate from './candidate'
import CheckboxAnswerFilter from './answers/checkbox-answer-filter'
import RadioAnswerFilter from './answers/radio-answer-filter'
import ArchivedCandidatesFilter from './archived-candidates-filter'
import AssigneesFilter from './assignees-filter'
import DateRangeFilter from './answers/date-range-filter'

export default class CandidatesFilter {
  opened = false

  archived: ArchivedCandidatesFilter

  assignees: AssigneesFilter

  applyingReason: CheckboxAnswerFilter

  education: RadioAnswerFilter

  interests: CheckboxAnswerFilter

  training: CheckboxAnswerFilter

  appliedDate: DateRangeFilter

  lastActivityDate: DateRangeFilter

  expanded?: string

  constructor(public readonly search: CandidatesSearch) {
    this.archived = new ArchivedCandidatesFilter(search, this)
    this.assignees = new AssigneesFilter(this.archived, this)
    this.applyingReason = new CheckboxAnswerFilter(this.archived, this, 'Why you are here')
    this.education = new RadioAnswerFilter(this.archived, this, 'Highest degree')
    this.interests = new CheckboxAnswerFilter(this.archived, this, 'Career areas')
    this.training = new CheckboxAnswerFilter(this.archived, this, 'Training Program Interest')
    this.appliedDate = new DateRangeFilter('appliedDayjs')
    this.lastActivityDate = new DateRangeFilter('lastActivityDayjs')
    makeAutoObservable(this, undefined, { autoBind: true })
  }

  get source() {
    if (this.archived.empty) {
      return this.search.empty ? this.search.result?.filter((c) => !c.archived) : this.search.result
    }
    return this.search.result?.filter((c) => c.archived)
  }

  toggle() {
    this.opened = !this.opened
  }

  clear() {
    this.appliedDate.clear()
    this.lastActivityDate.clear()
    this.assignees.clear()
    this.applyingReason.clear()
    this.education.clear()
    this.interests.clear()
    this.training.clear()
    this.archived.clear()
    this.expanded = undefined
    this.opened = false
  }

  get filterFunctions() {
    const result: Array<(c: Candidate) => boolean> = []

    if (!this.assignees.empty) {
      result.push(this.assignees.predicate)
    }

    if (this.appliedDate.startDate) {
      result.push(this.appliedDate.predicateFrom)
    }
    if (this.appliedDate.endDate) {
      result.push(this.appliedDate.predicateTo)
    }
    if (this.lastActivityDate.startDate) {
      result.push(this.lastActivityDate.predicateFrom)
    }
    if (this.lastActivityDate.endDate) {
      result.push(this.lastActivityDate.predicateTo)
    }
    if (!this.applyingReason.empty) {
      result.push(this.applyingReason.predicate)
    }
    if (!this.education.empty) {
      result.push(this.education.predicate)
    }
    if (!this.interests.empty) {
      result.push(this.interests.predicate)
    }
    if (!this.training.empty) {
      result.push(this.training.predicate)
    }
    if (!this.archived.empty) {
      result.push(this.archived.predicate)
    }
    return result
  }

  get resultFilterFunction() {
    const fs = this.filterFunctions
    return fs.length ? (c: Candidate) => !fs.some((f) => !f(c)) : undefined
  }

  get result() {
    const filter = this.resultFilterFunction
    return filter ? this.archived.result?.filter(filter) : this.archived.result
  }

  get empty() {
    return !this.filterFunctions.length
  }
}
