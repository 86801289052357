import { useCallback, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react'
import { runInAction } from 'mobx'
import PortalRightRail, { RightRailItem } from '../PortalRightRail'
import { CheckCircleDoubleColor } from '../../../common/icons/CheckCircle'
import { colors } from '../../../common/mui-theme'
import { usePortalApi } from '../../api/PortalApiContext'
import CoachingSessionContext from './CoachingSessionContext'
import CandidateProfileContext from '../candidate-profile/context/CandidateProfileContext'

const COACHING_SESSIONS_LIMIT = 30

function RetentionRightRail() {
  const { candidate } = useContext(CandidateProfileContext)
  const navigate = useNavigate()
  const api = usePortalApi()
  const { coachingSessions } = useContext(CoachingSessionContext)

  const scrollTo = useCallback(
    (sectionID: string) => {
      navigate(`/candidates/${candidate.dto.userId}/retention#${sectionID}`, {
        replace: true,
      })
    },
    [candidate.dto.userId, navigate],
  )

  const onAddCoachingSession = () => {
    api.coachingSession
      .createSession(candidate.dto.userId)
      .then((r) => {
        runInAction(() => {
          coachingSessions.push(r.data)
        })
      })
      .then(() => {
        scrollTo(`coaching-session-${coachingSessions.length}`)
      })
  }

  return (
    <PortalRightRail title="Retention Actions">
      <RightRailItem title="Individual Development Plan" onClick={() => scrollTo('individual-development-plan')} />
      {coachingSessions.map((session, index) => (
        <RightRailItem
          key={session.id}
          title={coachingSessions.length === 1 ? 'Coaching Session' : `Coaching Session ${index + 1}`}
          onClick={() => scrollTo(`coaching-session-${index + 1}`)}
          badge={
            <CheckCircleDoubleColor
              width={24}
              height={24}
              style={session.completed ? { display: 'flex', color: colors.success['400'] } : { display: 'none' }}
            />
          }
        />
      ))}
      {coachingSessions.length < COACHING_SESSIONS_LIMIT && (
        <RightRailItem
          title={!coachingSessions.length ? 'Add Coaching Session' : 'Add Another Coaching Session'}
          onClick={onAddCoachingSession}
        />
      )}
    </PortalRightRail>
  )
}

export default observer(RetentionRightRail)
