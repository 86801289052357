import { SurveyQuestionElementBase } from 'survey-react-ui'
import { Box, Button, Collapse } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import OffenseQuestionModel from './OffenseQuestionModel'
import PlusCircle from '../../icons/PlusCircle'
import OffenseItem from './OffenseItem'
import NoConvictions from './NoConvictions'
import Trash from '../../icons/Trash'
import { colors } from '../../mui-theme'

function DottedHr() {
  return (
    <Box
      sx={{
        height: '1px',
        borderStyle: 'none',
        m: '16px 0',
        backgroundImage: `linear-gradient(to right, ${colors.neutral['200']} 25%, transparent 0%)`,
        backgroundPosition: 'bottom',
        backgroundSize: '4px 1px',
        backgroundRepeat: 'repeat-x',
      }}
    />
  )
}

function AddOffense({ question }: { question: OffenseQuestionModel }) {
  const [mode, setMode] = useState<'button' | 'form'>('button')
  const onAdd = useCallback(() => setMode('form'), [])
  useEffect(() => setMode('button'), [question.value?.length])
  if (mode === 'button')
    return (
      <Button variant="text" onClick={onAdd} startIcon={<PlusCircle />} sx={{ pl: '2px' }}>
        Add Additional Offence
      </Button>
    )
  return (
    <>
      <OffenseItem question={question} type="Felony" index={question.value.length} />
      <OffenseItem question={question} type="Crime" index={question.value.length} />
    </>
  )
}

function AdditionalOffense({ question, index }: { question: OffenseQuestionModel; index: number }) {
  const remove = useCallback(() => {
    const copy = [...question.value]
    copy.splice(index, 1)
    // eslint-disable-next-line no-param-reassign
    question.value = copy
  }, [index, question])
  return (
    <>
      <DottedHr />
      <OffenseItem question={question} type="Felony" index={index} />
      <OffenseItem question={question} type="Crime" index={index} />
      <Button variant="outlined" startIcon={<Trash />} color="secondary" onClick={remove}>
        Remove Offense
      </Button>
    </>
  )
}

export default class MuiOffenseQuestion extends SurveyQuestionElementBase {
  get question() {
    return super.questionBase as OffenseQuestionModel
  }

  protected renderElement(): JSX.Element | null {
    const answers = this.question.value || []
    const additional = this.question.value?.slice(1) || []
    const addMore = answers.length > 0 && !answers.some((offense) => !offense.type)

    return (
      <>
        <NoConvictions question={this.question} />
        <OffenseItem question={this.question} type="Felony" index={0} />
        <OffenseItem question={this.question} type="Crime" index={0} />
        {additional.map((offense, index) => {
          const i = index + 1
          const key = `${offense?.type}_${offense?.year}_${i}`
          return <AdditionalOffense key={key} question={this.question} index={i} />
        })}
        <Collapse in={addMore}>
          {addMore && (
            <>
              <DottedHr />
              <AddOffense question={this.question} />
            </>
          )}
        </Collapse>
      </>
    )
  }
}
