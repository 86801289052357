import * as React from 'react'
import { CircularProgress } from '@mui/material'

type CircularProgressVariant = 'determinate' | 'indeterminate'

interface CustomCircularProgressProps {
  backgroundColor?: string
  size?: number
  thickness?: number
  variant?: CircularProgressVariant
  value?: number
}

function CustomCircularProgress({ backgroundColor, size, thickness, variant, value }: CustomCircularProgressProps) {
  return (
    <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: backgroundColor,
          animationDuration: '550ms',
          position: 'absolute',
          left: 0,
        }}
        size={size}
        thickness={thickness}
        value={100}
      />
      <CircularProgress variant={variant} size={size} thickness={thickness} value={value} />
    </div>
  )
}

export default CustomCircularProgress
