import { IconProps } from './IconProps'

function BookmarkFilledCheck({ width = 24, height = 24, style }: IconProps) {
  return (
    <svg width={width} height={height} style={style} viewBox="0 0 22 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.08398 8.05C1.08398 5.66978 1.08398 4.47967 1.54721 3.57054C1.95467 2.77085 2.60484 2.12068 3.40452 1.71322C4.31365 1.25 5.50376 1.25 7.88398 1.25H14.1173C16.4975 1.25 17.6877 1.25 18.5968 1.71322C19.3965 2.12068 20.0466 2.77085 20.4541 3.57054C20.9173 4.47967 20.9173 5.66978 20.9173 8.05V26.75L11.0007 21.0833L1.08398 26.75V8.05Z"
        fill="currentColor"
      />
      <path
        d="M6.75065 11.875L9.58398 14.7083L15.959 8.33333M20.9173 26.75V8.05C20.9173 5.66978 20.9173 4.47967 20.4541 3.57054C20.0466 2.77085 19.3965 2.12068 18.5968 1.71322C17.6877 1.25 16.4975 1.25 14.1173 1.25H7.88398C5.50376 1.25 4.31365 1.25 3.40452 1.71322C2.60484 2.12068 1.95467 2.77085 1.54721 3.57054C1.08398 4.47967 1.08398 5.66978 1.08398 8.05V26.75L11.0007 21.0833L20.9173 26.75Z"
        stroke="#FCF8F5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default BookmarkFilledCheck
