import { createContext, useContext, useEffect } from 'react'
import { PublicClientApplication } from '@azure/msal-browser'
import { ReactPlugin, useAppInsightsContext } from '@microsoft/applicationinsights-react-js'
import CandidatesStore from './candidates-store'
import CandidatesSearch from './candidates-search'
import { usePortalApi } from '../../../api/PortalApiContext'
import { PortalApi } from '../../../_generated/portal-api'
import { SeverityLevel } from '../../../../common/app-insights'
import { portalUserContextValue } from '../../portal-user/context/PortalUserContext'
import PortalUserStore from '../../portal-user/context/portal-user-store'
import CandidatesFilter from './candidates-filter'

export class CandidatesContextValue {
  store: CandidatesStore

  search: CandidatesSearch

  filter: CandidatesFilter

  constructor(private readonly portalUsers: PortalUserStore) {
    this.store = new CandidatesStore(portalUsers)
    this.search = new CandidatesSearch(this.store)
    this.filter = new CandidatesFilter(this.search)
  }

  load(portalApi: PortalApi<PublicClientApplication>) {
    portalApi.candidates
      .candidates({ timestamp: this.store.latestActivityTimestamp })
      .then((response) => response.data)
      .then((candidates) => this.store.update(candidates))
  }
}

export const candidatesContext = new CandidatesContextValue(portalUserContextValue.store)

const CandidatesContext = createContext(candidatesContext)

const loaders: Array<{
  ctx: CandidatesContextValue
  api: PortalApi<PublicClientApplication>
  count: number
  dispose: () => void
}> = []

function registerReaction(ctx: CandidatesContextValue, portalApi: PortalApi<PublicClientApplication>) {
  const existing = loaders.find((loader) => loader.api === portalApi && loader.ctx === ctx)
  if (!existing) {
    ctx.load(portalApi)
    const interval = setInterval(() => ctx.load(portalApi), 30000)
    loaders.push({ ctx, api: portalApi, count: 1, dispose: () => clearInterval(interval) })
  } else {
    existing.count++
  }
}

function unregisterReaction(ctx: CandidatesContextValue, api: PortalApi<PublicClientApplication>, logger: ReactPlugin) {
  const index = loaders.findIndex((loader) => loader.api === api && loader.ctx === ctx)
  if (index >= 0) {
    const loader = loaders[index]
    loader.count--
    if (loader.count === 0) {
      loader.dispose()
      loaders.splice(index, 1)
    }
  } else {
    logger.trackTrace({
      severityLevel: SeverityLevel.Error,
      iKey: 'candidates-loader',
      message: 'Unexpected registration called for not registered API & Context',
    })
  }
}

export function useCandidates() {
  const logger = useAppInsightsContext()
  const ctx = useContext(CandidatesContext)
  const api = usePortalApi()
  useEffect(() => {
    registerReaction(ctx, api)
    return () => unregisterReaction(ctx, api, logger)
  }, [ctx, api, logger])
  return ctx
}

export default CandidatesContext
