import { Tab, Tabs } from '@mui/material'
import { Link, useMatch } from 'react-router-dom'
import React, { useContext, useEffect, useMemo } from 'react'
import { useQuery } from 'react-query'
import { runInAction } from 'mobx'
import UserCircle from '../../common/icons/Users'
import MarkerPin from '../../common/icons/MarkerPin'
import UsersLeft from '../../common/icons/UsersLeft'
import CandidateIntake from './CandidateIntake'
import TabContent from './TabContent'
import Placement from './placement/Placement'
import PlacementContext, { CandidatePlacement } from './placement/context/PlacementContext'
import { usePortalApi } from '../api/PortalApiContext'
import IntakeContext, { IntakeContextClass } from './survey/context/IntakeContext'
import CandidateProfileContext from './candidate-profile/context/CandidateProfileContext'
import Retention from './retention/Retention'
import Dataflow from '../../common/icons/Dataflow'
import JobMatchingContent from './jobs/job-matching/JobMatchingContent'

export default function ProfileTabs() {
  const defaultTab = useMatch('/candidates/:id')
  const jobMatchingTab = useMatch('/candidates/:id/job-matching/*')
  const placementTab = useMatch('/candidates/:id/placement/*')
  const retentionTab = useMatch('/candidates/:id/retention/*')

  const { candidate } = useContext(CandidateProfileContext)

  const links = useMemo(
    () => [
      `/candidates/${candidate.dto.userId}`,
      `/candidates/${candidate.dto.userId}/job-matching`,
      `/candidates/${candidate.dto.userId}/placement`,
      `/candidates/${candidate.dto.userId}/retention`,
    ],
    [candidate.dto.userId],
  )

  const selectedTab = useMemo(
    () => [defaultTab, jobMatchingTab, placementTab, retentionTab].findIndex((p) => p !== null) || 0,
    [defaultTab, jobMatchingTab, placementTab, retentionTab],
  )

  const placement = useMemo(() => new CandidatePlacement(candidate.dto.userId), [candidate.dto.userId])
  const intakeContext = useMemo(() => new IntakeContextClass(candidate.dto.userId), [candidate.dto.userId])
  const api = usePortalApi()
  const { data } = useQuery(`jobs/${candidate.dto.userId}`, () =>
    api.jobs.jobs(candidate.dto.userId).then((r) => r.data),
  )

  useEffect(() => {
    runInAction(() => {
      placement.jobs = data
    })
  }, [data, placement])

  return (
    <>
      <Tabs value={selectedTab} sx={{ mb: '24px', '& .MuiTab-labelIcon': { gap: '8px' } }}>
        <Tab component={Link} label="Candidate Intake" to={links[0]} icon={<UserCircle />} iconPosition="start" />
        <Tab component={Link} label="Job Matching" to={links[1]} icon={<Dataflow />} iconPosition="start" />
        <Tab component={Link} label="Placement" to={links[2]} icon={<MarkerPin />} iconPosition="start" />
        <Tab component={Link} label="Retention" to={links[3]} icon={<UsersLeft />} iconPosition="start" />
      </Tabs>
      <TabContent hidden={!defaultTab}>
        <IntakeContext.Provider value={intakeContext}>
          <CandidateIntake />
        </IntakeContext.Provider>
      </TabContent>
      <TabContent hidden={!jobMatchingTab}>
        <PlacementContext.Provider value={placement}>
          <JobMatchingContent />
        </PlacementContext.Provider>
      </TabContent>
      <TabContent hidden={!placementTab}>
        <PlacementContext.Provider value={placement}>
          <Placement />
        </PlacementContext.Provider>
      </TabContent>
      <TabContent hidden={!retentionTab}>
        <Retention />
      </TabContent>
    </>
  )
}
