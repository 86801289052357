import React, { useEffect, useRef } from 'react'
import { Box, debounce, Divider, Paper, Typography } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { autorun, toJS } from 'mobx'
import { colors, palette } from '../../../../common/mui-theme'
import { useInvalidateActivityLog, useJobActivityLog } from '../../../api/hooks/placementHooks'
import { JobActivityLog, JobDetailsDto } from '../../../_generated/portal-api'
import { formatDate } from '../../../utils/format'
import nop from '../../../../common/nop'

const activityStyles = {
  maxWidth: '200px',
  width: '100%',
  wordWrap: 'break-word',
}

function ActivityLogItem({ log }: { log: JobActivityLog }) {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        gap: '32px',
        p: '16px 16px 16px 24px',
      }}
    >
      <Typography variant="body1" sx={{ color: colors.neutral['600'], ...activityStyles }}>
        {log.action}
      </Typography>
      <Typography
        variant="body1"
        sx={{
          textAlign: 'center',
          color: colors.neutral['300'],
          ...activityStyles,
        }}
      >
        {log.fullName}
      </Typography>
      <Typography
        variant="body1"
        sx={{
          textAlign: 'right',
          color: colors.neutral['300'],
          ...activityStyles,
        }}
      >
        {log.timeStamp && formatDate(log.timeStamp)}
      </Typography>
    </Box>
  )
}

function ActivityLog({ details }: { details: JobDetailsDto }) {
  const location = useLocation()
  const ref = useRef<HTMLDivElement | null>(null)
  const { data } = useJobActivityLog(details)
  const invalidateActivityLog = useInvalidateActivityLog()
  useEffect(() => {
    const invalidate = debounce(() => invalidateActivityLog(details.id), 10000)
    const interval = setInterval(() => invalidate(), 15000)
    const disposer = autorun(() => {
      toJS(details)
      invalidate()
    })

    return () => {
      clearInterval(interval)
      invalidate.clear()
      disposer()
    }
  }, [invalidateActivityLog, details])

  useEffect(() => {
    if (location.hash === '#job_activity' && ref.current) {
      invalidateActivityLog(details.id)
      const timeout = setTimeout(() => {
        const header = document.getElementsByTagName('header')[0]

        window.scrollTo({
          top: ref.current ? ref.current.offsetTop - header.offsetHeight - 8 : 0,
          behavior: 'smooth',
        })
      }, 250)
      return () => clearTimeout(timeout)
    }

    return nop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, invalidateActivityLog, details])

  return data?.length ? (
    <Paper sx={{ m: '35px 0 55px', borderRadius: '16px' }} ref={ref}>
      <Typography variant="h6" sx={{ p: '24px' }}>
        Activity
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          p: '16px 16px 16px 24px',
          backgroundColor: colors.primary['50'],
          borderTop: `1px solid ${palette.secondary.light}`,
          borderBottom: `1px solid ${palette.secondary.light}`,
        }}
      >
        <Typography variant="body1">Action</Typography>
        <Typography variant="body1">User</Typography>
        <Typography variant="body1">Date</Typography>
      </Box>
      {data.map((log, index) => (
        <Box key={`${log.timeStamp} ${log.action}`}>
          <ActivityLogItem log={log} />
          {index !== data.length - 1 && <Divider light />}
        </Box>
      ))}
    </Paper>
  ) : null
}

export default ActivityLog
