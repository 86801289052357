import React, { PropsWithChildren } from 'react'
import { Typography } from '@mui/material'

export default function TextValue({ children }: PropsWithChildren) {
  return (
    <Typography variant="body2" style={{ wordWrap: 'break-word' }}>
      {children}
    </Typography>
  )
}
