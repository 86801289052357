import { JobDetailsDto } from '../../../_generated/portal-api'

// eslint-disable-next-line import/prefer-default-export
export function getStatus(job: JobDetailsDto) {
  const { submission, placement, interviews } = job

  if (placement) {
    if (placement?.completed) return placement.placed ? 'Candidate Placed' : 'Candidate Not Placed'
    return 'Placement Added'
  }

  if (interviews.length > 0) {
    if (interviews.some((i) => i.completed)) return 'Interview Completed'
    if (interviews.some((i) => i.setUp)) return 'Interview Set Up'
    return 'Interview Added'
  }

  // eslint-disable-next-line no-nested-ternary
  return submission?.sent
    ? 'Candidate Submitted'
    : submission?.exportCreated
    ? 'Export Created'
    : 'Job Submission Added'
}
