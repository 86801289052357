import { Box, BoxProps } from '@mui/material'

export default function CandidateContentBox(props: BoxProps) {
  const { children, sx, ...others } = props
  return (
    <Box sx={{ ...sx, display: 'flex', flex: '1' }} {...others}>
      {children}
    </Box>
  )
}
