import { makeAutoObservable, ObservableMap } from 'mobx'
import dayjs from 'dayjs'
import Candidate from './candidate'
import { CandidateDto } from '../../../_generated/portal-api'
import PortalUserStore from '../../portal-user/context/portal-user-store'

export function byFullName(a: Candidate, b: Candidate) {
  return a.lastFirstNameUpperCased.localeCompare(b.lastFirstNameUpperCased)
}

export default class CandidatesStore {
  currentTime = dayjs()

  latestActivityTimestamp = 0

  /**
   * Candidates indexed by userId
   */
  indexed: ObservableMap<number, Candidate> = new ObservableMap<number, Candidate>()

  candidates: Candidate[] | null = null

  constructor(readonly portalUsers: PortalUserStore) {
    makeAutoObservable(this, undefined, { autoBind: true })
  }

  get isLoading() {
    return this.candidates === null
  }

  update(candidates: Array<CandidateDto>) {
    this.currentTime = dayjs()

    if (candidates.length === 0) {
      if (this.candidates === null) {
        this.candidates = []
      }
      return
    }

    if (candidates[0].lastActivityDate) {
      const timestamp = new Date(candidates[0].lastActivityDate).getTime()
      if (timestamp > this.latestActivityTimestamp) this.latestActivityTimestamp = timestamp
    }

    if (this.candidates === null) {
      // initial load
      const observables = candidates.filter((dto) => !dto.isDeleted).map((dto) => new Candidate(dto, this))
      observables.forEach((candidate) => this.indexed.set(candidate.dto.userId, candidate))
      this.candidates = observables
      // for (let i = 0; i < 100000; i++) this.candidates.push(...observables)
      // // debug update
      // {
      //   let updateCount = 0
      //   setInterval(
      //     () =>
      //       runInAction(() => {
      //         this.candidates![0].dto.firstName = `Updated${updateCount++}`
      //       }),
      //     5000,
      //   )
      // }
      // // debug push
      // {
      //   let pushCount = 0
      //   setInterval(
      //     () =>
      //       runInAction(() => {
      //         this.candidates![0].dto.lastName = `Push${pushCount++}`
      //         this.candidates?.push(this.candidates[0])
      //       }),
      //     7000,
      //   )
      // }
      return
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const dto of candidates) {
      const existing = this.indexed.get(dto.userId)

      if (dto.isDeleted) {
        if (existing) {
          existing.dto.isDeleted = true
          this.indexed.delete(dto.userId)
          this.candidates.splice(
            this.candidates.findIndex((c) => c.dto.userId === dto.userId),
            1,
          )
        }
      } else if (existing) {
        existing.assign(dto)
      } else {
        const candidate = new Candidate(dto, this)
        this.indexed.set(dto.userId, candidate)
        this.candidates.push(candidate)
      }
    }
  }
}
