import { Question, SurveyModel } from 'survey-core'
import React, { CSSProperties, useContext } from 'react'
import { observer } from 'mobx-react'
import { QuestionRadiogroupModel } from 'question_radiogroup'
import { ItemValue } from 'itemvalue'
import { Answers } from '../../../../demo/survey-model'
import { OffenceAnswer } from '../../../../common/survey/offense/OffenseQuestionModel'
import CandidateProfileContext from '../../candidate-profile/context/CandidateProfileContext'
import { colors } from '../../../../common/mui-theme'
import WorkExperienceModel from '../../../../common/survey/work-experience/WorkExperienceModel'

function BorderCoverElement({ children }: React.PropsWithChildren) {
  return <div style={{ paddingBottom: '24px', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>{children}</div>
}

function ExportIntakeSection({ model, title, style }: { model: SurveyModel; title: string; style?: CSSProperties }) {
  const { candidate } = useContext(CandidateProfileContext)

  const questions = model.getAllQuestions()
  // eslint-disable-next-line consistent-return
  const radioGroupAnswer = (q: Question) => {
    const selectedOption = (q as QuestionRadiogroupModel).choices?.find((choice: ItemValue) => choice.value === q.value)
    return (
      <p style={{ marginBottom: '0px' }}>
        {/* Additional details in radio */}
        {selectedOption?.subQuestion && candidate?.answers[selectedOption?.subQuestion as keyof Answers]}
        {/* Other value in radio */}
        {q.otherValue}
      </p>
    )
  }

  function compensationQuestion(q: Question) {
    return (
      q.value?.amount && (
        <BorderCoverElement>
          <h3>{q.name}</h3>
          <p
            style={{
              marginBottom: '0px',
              color: colors.neutral['600'],
              fontFamily: 'Satoshi',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '24px',
            }}
          >
            ${q.value.amount} {q.value.rate}
          </p>
        </BorderCoverElement>
      )
    )
  }

  function workExperienceQuestion(q: Question) {
    return (
      <BorderCoverElement>
        <h3
          style={{
            color: colors.neutral['600'],
            fontFamily: 'Satoshi',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: '700',
            lineHeight: '24px',
          }}
        >
          Additional job experience
        </h3>
        {q.value.map((a: WorkExperienceModel, index: number) => (
          <p
            style={{
              marginBottom: '0px',
              color: colors.neutral['600'],
              fontFamily: 'Satoshi',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '24px',
            }}
            // eslint-disable-next-line react/no-array-index-key
            key={a.employer + a.position + index}
          >
            <b>{a.position}</b> <br />
            <span>{a.employer}</span>
            <br />
            <span
              style={{
                fontSize: '14px',
                fontWeight: '500',
                lineHeight: '20px',
                fontFamily: 'Satoshi',
                letterSpacing: 0,
                color: colors.neutral['300'],
              }}
            >
              {a.startDate} {a.endDate}
            </span>
          </p>
        ))}
      </BorderCoverElement>
    )
  }

  function radioGroupQuestion(q: Question) {
    return (
      <BorderCoverElement>
        <h3
          style={{
            color: colors.neutral['600'],
            fontFamily: 'Satoshi',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: '700',
            lineHeight: '24px',
          }}
        >
          {q.title.replace(/"/g, '')}
        </h3>
        <p
          style={{
            marginBottom: '0px',
            color: colors.neutral['600'],
            fontFamily: 'Satoshi',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '24px',
          }}
        >
          {candidate?.answers[q.name as keyof Answers] as string}
        </p>
        {radioGroupAnswer(q)}
      </BorderCoverElement>
    )
  }

  function offenseQuestion(q: Question) {
    const answers = candidate?.answers[q.name as keyof Answers] as OffenceAnswer
    if (!answers?.length) return null
    return (
      <BorderCoverElement>
        <h3
          style={{
            color: colors.neutral['600'],
            fontFamily: 'Satoshi',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: '700',
            lineHeight: '24px',
          }}
        >
          {q.title.replace(/"/g, '')}
        </h3>

        {answers.map((a, index) => (
          <p
            style={{
              marginBottom: '0px',
              color: colors.neutral['600'],
              fontFamily: 'Satoshi',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '24px',
            }}
            // eslint-disable-next-line react/no-array-index-key
            key={a.year + a.type + index}
          >
            {a.type} {a.year && ` - ${a.year}`}
          </p>
        ))}
        {radioGroupAnswer(q)}
      </BorderCoverElement>
    )
  }

  function checkboxQuestion(q: Question) {
    const otherValue = q.value.find((v: string) => v === 'other') ? `Other: ${q.comment}` : ''
    const checkboxAnswers = q.value.filter((v: string) => v !== 'other')
    const resultValue = otherValue ? [...checkboxAnswers, otherValue].join(', ') : checkboxAnswers.join(', ')

    if (!q.value?.length) return null

    return (
      <BorderCoverElement>
        <h3
          style={{
            color: colors.neutral['600'],
            fontFamily: 'Satoshi',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: '700',
            lineHeight: '24px',
          }}
        >
          {q.title}
        </h3>
        {resultValue}
      </BorderCoverElement>
    )
  }

  return (
    <div style={{ ...style }}>
      <h2
        style={{
          color: colors.neutral['400'],
          fontFamily: 'Satoshi',
          fontSize: '20px',
          fontStyle: 'normal',
          fontWeight: '700',
          lineHeight: '30px',
        }}
      >
        {title}
      </h2>

      {candidate &&
        questions
          .filter(
            (q) =>
              candidate?.answers[q.name as keyof Answers] != null &&
              q.getType() !== 'textarea' &&
              q.getType() !== 'file',
          )
          .map((q) => {
            const qType = q.getType()

            return (
              <div key={q.name}>
                {qType === 'compensation' && compensationQuestion(q)}
                {qType === 'radiogroup' && radioGroupQuestion(q)}
                {qType === 'checkbox' && checkboxQuestion(q)}
                {qType === 'offense' && offenseQuestion(q)}
                {qType === 'work-experience' && workExperienceQuestion(q)}
              </div>
            )
          })}
    </div>
  )
}

export default observer(ExportIntakeSection)
