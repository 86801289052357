import { makeAutoObservable } from 'mobx'
import { JobSubmission } from '../../../_generated/portal-api'
import { notEnteredYet } from '../../titles'

export default class JobSubmissionComputed {
  constructor(public submission: JobSubmission) {
    makeAutoObservable(this, undefined, { autoBind: true })
  }

  get isCandidateEmailEmpty() {
    return !this.submission.candidateEmail?.trim()
  }

  get isCandidatePhoneEmpty() {
    return !this.submission.candidatePhone?.trim()
  }

  get contactFullName() {
    return [this.submission.contactFirstName, this.submission.contactLastName].filter(Boolean).join(' ')
  }

  get contactSummary() {
    return (
      [this.contactFullName, this.submission.contactEmail, this.submission.contactPhone].filter(Boolean).join(', ') ||
      notEnteredYet
    )
  }
}
