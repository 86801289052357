import React, { useContext } from 'react'
import { observer } from 'mobx-react'
import PlacementContext from '../context/PlacementContext'
import GetStarted from './GetStarted'
import { JobDetailsDto } from '../../../_generated/portal-api'
import JobStatusesRender from '../JobStatusesRender'

const sortByTime = (jobsArray: JobDetailsDto[]) =>
  jobsArray.sort((a, b) => {
    const timeA = a.timeStamp ? new Date(a.timeStamp).getTime() : 0
    const timeB = b.timeStamp ? new Date(b.timeStamp).getTime() : 0
    return timeB - timeA
  })

class JobCategories {
  placed: JobDetailsDto[] = []

  interviewing: JobDetailsDto[] = []

  submission: JobDetailsDto[] = []

  archived: JobDetailsDto[] = []

  unclassified: JobDetailsDto[] = []

  push(job?: JobDetailsDto) {
    if (job) {
      this.getArrayFor(job).push(job)
    }
    return this
  }

  private getArrayFor(job: JobDetailsDto) {
    if (job.archived) return this.archived
    if (job.placement) return this.placed
    if (job.interviews && job.interviews.length > 0) return this.interviewing
    if (job.submission) return this.submission
    return this.unclassified
  }

  sortJobs() {
    sortByTime(this.placed)
    sortByTime(this.interviewing)
    sortByTime(this.submission)
    sortByTime(this.archived)
  }
}

function CandidateJobs() {
  const { jobs } = useContext(PlacementContext)
  if (!jobs) return null
  if (!jobs.length) return <GetStarted />
  const categories = jobs.reduce((c, job) => c.push(job.details), new JobCategories())
  categories.sortJobs()

  return (
    <>
      <JobStatusesRender title="Placed/Active" jobs={categories.placed} />
      <JobStatusesRender title="Interviewing" jobs={categories.interviewing} />
      <JobStatusesRender title="Submission" jobs={categories.submission} />
      <JobStatusesRender title="Archived" jobs={categories.archived} />
    </>
  )
}

export default observer(CandidateJobs)
