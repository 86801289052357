import { makeAutoObservable } from 'mobx'

export default class IntakeQuestionGroup {
  saving = false

  allQuestionCount = 0

  answeredQuestionCount = 0

  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true })
  }

  setSaving(saving: boolean) {
    this.saving = saving
  }
}
