import { Box, Typography } from '@mui/material'
import React from 'react'
import { colors } from '../../common/mui-theme'
import BackgroundPattern from '../../common/components/BackgroundPattern'
import Feedback from '../../common/icons/Feedback'

function InnerNote({ title, children }: { title: React.ReactNode; children: React.ReactNode }) {
  return (
    <Box
      sx={{
        p: '24px',
        borderRadius: '0 0 16px 16px',
        backgroundColor: colors.neutral['50'],
        overflow: 'hidden',
        position: 'relative',
        '.MuiOutlinedInput-root': { backgroundColor: colors.base.white },
      }}
    >
      <BackgroundPattern color={colors.neutral['100']} />
      <Typography
        variant="h6"
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          pb: '8px',
          color: colors.neutral['400'],
          position: 'relative',
        }}
      >
        <Feedback /> {title}
      </Typography>
      {children}
    </Box>
  )
}

export default InnerNote
