import { AppBar, Box, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Toolbar, Typography } from '@mui/material'
import { Route, Routes, useNavigate, useParams } from 'react-router-dom'
import React, { useCallback, useContext } from 'react'
import { useQuery } from 'react-query'
import { runInAction } from 'mobx'
import { Observer, observer } from 'mobx-react'
import CandidatesSearch from './candidates/CandidatesSearch'
import Alerts from '../../common/alerts/Alerts'
import PortalUserContext from './portal-user/context/PortalUserContext'
import { usePortalApi } from '../api/PortalApiContext'
import { useCandidateProfile } from '../api/hooks/candidateHooks'
import { colors, palette } from '../../common/mui-theme'
import MenuIcon from '../../common/icons/MenuIcon'
import UserCircle from '../../common/icons/Users'
import Dataflow from '../../common/icons/Dataflow'
import Close from '../../common/icons/Close'
import JobsSearch from './jobs/JobsSearch'

export function Title({ children = '' }: { children?: string }) {
  return (
    <Box
      sx={{
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      }}
    >
      {children}
    </Box>
  )
}

function CandidateProfileTitle() {
  const { id = '-1' } = useParams()

  const { data: candidate } = useCandidateProfile(+id)

  return <Observer>{() => <Title>{candidate && (candidate.fullName || candidate.dto.email || '')}</Title>}</Observer>
}

export function HeaderMenu() {
  const navigate = useNavigate()

  const [anchorMenu, setAnchorMenu] = React.useState<null | HTMLElement>(null)
  const openMenu = useCallback((event: React.MouseEvent<HTMLElement>) => setAnchorMenu(event.currentTarget), [])
  const handleClose = useCallback(() => setAnchorMenu(null), [])

  const openJobs = useCallback(() => {
    navigate(`/jobs`)
  }, [navigate])

  const openCandidates = useCallback(() => {
    navigate(`/candidates`)
  }, [navigate])

  return (
    <Box>
      <IconButton
        id="header-menu-button"
        onClick={openMenu}
        sx={{
          mr: '16px',
          '&:hover': { backgroundColor: '#FEEDE6' },
        }}
      >
        {anchorMenu ? (
          <Close style={{ color: palette.text.primary }} />
        ) : (
          <MenuIcon style={{ color: palette.text.primary }} />
        )}
      </IconButton>
      <Menu
        id="header-menu"
        aria-labelledby="header-menu-button"
        anchorEl={anchorMenu}
        open={!!anchorMenu}
        onClose={handleClose}
        onClick={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          sx: {
            p: '10px 8px',
            minWidth: '200px',
            borderRadius: '8px',
          },
        }}
      >
        <MenuItem onClick={openCandidates} disabled={false}>
          <ListItemIcon>
            <UserCircle />
          </ListItemIcon>
          <ListItemText>Candidates</ListItemText>
        </MenuItem>

        <MenuItem onClick={openJobs}>
          <ListItemIcon>
            <Dataflow />
          </ListItemIcon>
          <ListItemText>Jobs</ListItemText>
        </MenuItem>
      </Menu>
    </Box>
  )
}

function Header() {
  const context = useContext(PortalUserContext)
  const api = usePortalApi()
  useQuery('portalUser', () => api.portalUsers.authenticate().then((r) => r.data), {
    onSuccess: (user) =>
      runInAction(() => {
        context.user = user
      }),
  })
  return (
    <Box sx={{ position: 'sticky', zIndex: 1100, top: 0 }}>
      <AppBar
        position="relative"
        color="transparent"
        sx={{ backgroundColor: colors.base.white, paddingTop: '4px', paddingBottom: '4px' }}
      >
        <Toolbar sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', columnGap: '8px' }}>
          <Box sx={{ display: 'flex' }}>
            <HeaderMenu />
            <Typography variant="h5" component="div" alignSelf="center" sx={{ maxWidth: '30vw' }}>
              <Routes>
                <Route path="/candidates" element={<Title>Candidates</Title>} />
                <Route path="/duplicate-candidates/:id" element={<Title>Duplicate Candidates</Title>} />
                <Route path="/candidates/:id/*" element={<CandidateProfileTitle />} />
                <Route path="/jobs/*" element={<Title>Jobs</Title>} />
              </Routes>
            </Typography>
          </Box>
          <Box>
            <Routes>
              <Route path="/candidates" element={<CandidatesSearch />} />
              <Route path="/duplicate-candidates/:id" element={<CandidatesSearch />} />
              <Route path="/candidates/*" element={<CandidatesSearch />} />
              <Route path="/jobs" element={<JobsSearch />} />
              <Route path="/jobs/*" element={null} />
            </Routes>
          </Box>
        </Toolbar>
      </AppBar>
      <Alerts />
    </Box>
  )
}

export default observer(Header)
