import { Snackbar } from '@mui/material'
import { ReactNode, useEffect } from 'react'
import { makeAutoObservable, observable, reaction, runInAction } from 'mobx'
import { Observer } from 'mobx-react'
import css from './CustomSnackbar.module.css'

class SnackMessage {
  message?: ReactNode

  expiredAt = 0

  constructor() {
    makeAutoObservable(this, { message: observable.ref })
  }

  get shown() {
    return !!this.message && this.expiredAt > new Date().getTime()
  }

  get autoHideDuration() {
    return this.expiredAt - new Date().getTime()
  }
}

const model = new SnackMessage()

function CustomSnackbar() {
  useEffect(
    () =>
      reaction(
        () => model.expiredAt - new Date().getTime(),
        (inMillis) => {
          if (inMillis > 0) setTimeout(CustomSnackbar.hide, inMillis)
        },
      ),
    [],
  )

  return (
    <Observer>
      {() => (
        <Snackbar
          open={model.shown}
          autoHideDuration={model.autoHideDuration}
          onClose={CustomSnackbar.hide}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          message={model.message}
          className={css.customSnackbar}
        />
      )}
    </Observer>
  )
}

CustomSnackbar.show = (message: ReactNode, autoHideInMillis = 10000) => {
  runInAction(() => {
    model.message = message
    model.expiredAt = new Date().getTime() + autoHideInMillis
  })
}

CustomSnackbar.hide = () => {
  runInAction(() => {
    model.message = undefined
    model.expiredAt = 0
  })
}

export default CustomSnackbar
