import { useContext, useEffect, useMemo } from 'react'
import { action, autorun, computed } from 'mobx'
import { observer } from 'mobx-react'
import { SurveyModel } from 'survey-core'
import { submissionAttachments } from '../../../../demo/survey-model'
import CandidateProfileContext from '../../candidate-profile/context/CandidateProfileContext'
import { FileQuestionValue } from '../../../../common/survey/file-question-value'
import SurveyWithFileQuestions from '../../SurveyWithFileQuestions'

function addNewValuesTo(target: FileQuestionValue[], fromSource: FileQuestionValue[]) {
  fromSource.forEach((value) => {
    if (!target.find((val) => val.content.id === value.content.id)) target.push(value)
  })
}

function SubmissionUpload() {
  const { candidate } = useContext(CandidateProfileContext)
  const model = useMemo(() => new SurveyModel(submissionAttachments), [])

  const all = useMemo(
    () =>
      computed(() => {
        if (!candidate) return []
        const result = (candidate.answers['Submission Attachments'] || []).slice()
        addNewValuesTo(result, candidate.answers.Attachments || [])
        addNewValuesTo(result, candidate.answers['Offer Letter'] || [])
        return result
      }),
    [candidate],
  )

  useEffect(
    () =>
      autorun(() => {
        const target = candidate.answers['Submission Attachments']
        const allAttachments = all.get()
        if (!target) {
          setTimeout(
            action(() => {
              candidate.answers['Submission Attachments'] = allAttachments
              model.setDataCore(candidate.answers)
            }),
            0,
          )
        } else {
          setTimeout(
            action(() => {
              addNewValuesTo(target, allAttachments)
              model.setDataCore(candidate.answers)
            }),
            0,
          )
        }
      }),
    [all, candidate, model],
  )

  return <SurveyWithFileQuestions model={model} />
}

export default observer(SubmissionUpload)
