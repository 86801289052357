import React from 'react'
import TextField from '@mui/material/TextField'
import { SurveyQuestionElementBase } from 'survey-react-ui'
import { Serializer } from 'survey-core'
import { Grid } from '@mui/material'
import UsaAddressGroupModel from './UsaAddressGroupModel'
import RequiredFieldValidator from '../validators/required-field-validator'
import ZipCodeValidator from '../validators/us-address/zip-code-validator'
import FieldError from '../validators/field-error'
import RegexFieldValidator from '../validators/regex-field-validator'
import MaskedZipCodeInput from '../../components/MaskedZipCodeInput'
import { stateNames, states, UsaAddress } from './index'
import CustomAutocomplete from '../../select/CustomAutocomplete'

export default class MuiUsaAddressQuestion extends SurveyQuestionElementBase {
  constructor(props: unknown) {
    super(props)

    if (!this.value) {
      this.value = { addressLine1: '', addressLine2: '', city: '', stateName: '', zipCode: '' }
    }

    this.state = { stateInput: this.value.stateName, ...(this.state || {}) }

    if (this.questionBase.validators.length === 0) {
      this.questionBase.validators.push(
        new RequiredFieldValidator<UsaAddressGroupModel>('addressLine1', 'Street Address or PO Box is required'),
      )
      this.questionBase.validators.push(
        new RegexFieldValidator<UsaAddressGroupModel>(
          'addressLine1',
          'A valid Street Address or PO Box is required',
          '^.*[a-zA-Z]+.*$',
        ),
      )
      this.questionBase.validators.push(new RequiredFieldValidator<UsaAddressGroupModel>('city', 'City is required'))
      this.questionBase.validators.push(
        new RegexFieldValidator<UsaAddressGroupModel>('city', 'A valid City is required', '^.*[a-zA-Z]+.*$'),
      )
      this.questionBase.validators.push(
        new RequiredFieldValidator<UsaAddressGroupModel>('stateName', 'State is required'),
      )
      this.questionBase.validators.push(
        new RequiredFieldValidator<UsaAddressGroupModel>('zipCode', 'Zip Code is required'),
      )
      this.questionBase.validators.push(new ZipCodeValidator('Enter a valid Zip Code'))
    }
  }

  get value(): UsaAddress {
    return this.questionBase?.value
  }

  set value(v) {
    this.questionBase.value = v
    this.forceUpdate()
  }

  private onInputChange(fieldName: keyof UsaAddress, value: string) {
    const v = this.value
    v[fieldName] = value
    this.value = v // required to update the value in the survey answers
  }

  protected renderElement(): JSX.Element {
    let errors = new Map<string, FieldError>()
    if (Array.isArray(this.questionBase.errors)) {
      const fieldErrors = this.questionBase.errors.filter((e) => e instanceof FieldError) as FieldError[]
      errors = fieldErrors.reduce((m, e) => {
        if (!m.has(e.fieldName)) m.set(e.fieldName, e)
        return m
      }, errors)
    }
    return (
      <Grid container columnSpacing={2} sx={{ maxWidth: { sm: 416 } }}>
        <Grid item xs={12}>
          <TextField
            //  required
            id="addressLine1"
            name="addressLine1"
            label="Address line 1"
            value={this.value.addressLine1 || ''}
            fullWidth
            onChange={(e) => this.onInputChange('addressLine1', e.target.value)}
            required
            error={errors.has('addressLine1')}
            helperText={errors.get('addressLine1')?.text || ' '}
            inputProps={{ maxLength: 255 }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="addressLine2"
            name="addressLine2"
            label="Address line 2"
            value={this.value.addressLine2 || ''}
            fullWidth
            onChange={(e) => this.onInputChange('addressLine2', e.target.value)}
            helperText={' '}
            inputProps={{ maxLength: 255 }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="city"
            name="city"
            label="City"
            value={this.value.city || ''}
            fullWidth
            onChange={(e) => this.onInputChange('city', e.target.value)}
            error={errors.has('city')}
            helperText={errors.get('city')?.text || ' '}
            inputProps={{ maxLength: 50 }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomAutocomplete
            disablePortal
            id="stateName"
            value={this.value.stateName || null}
            onChange={(e, value) => this.onInputChange('stateName', value as string)}
            inputValue={this.state.stateInput}
            onInputChange={(e, value) => this.setState({ ...this.state, stateInput: value })}
            options={stateNames}
            isOptionEqualToValue={(option, value) => option === value || states.get(value) === option}
            ListboxProps={{
              style: {
                maxHeight: 190,
              },
            }}
            renderInput={(params) => {
              const { inputProps = {}, ...other } = params
              inputProps.maxLength = 50
              return (
                <TextField
                  label="State"
                  error={errors.has('stateName')}
                  helperText={errors.get('stateName')?.text || ' '}
                  inputProps={inputProps}
                  required
                  {...other}
                />
              )
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="zipCode"
            name="zipCode"
            label="Zip Code"
            value={this.value.zipCode || ''}
            fullWidth
            onChange={(e) => this.onInputChange('zipCode', e.target.value)}
            onInput={(e) => this.onInputChange('zipCode', (e.target as HTMLInputElement).value)}
            error={errors.has('zipCode')}
            helperText={errors.get('zipCode')?.text || ' '}
            InputProps={{
              inputComponent: MaskedZipCodeInput,
            }}
          />
        </Grid>
      </Grid>
    )
  }
}

Serializer.addClass(UsaAddressGroupModel.TYPE, [], () => new UsaAddressGroupModel(''), 'question')
