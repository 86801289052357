import Button from '@mui/material/Button'
import { SurveyQuestionPanelDynamicAddButton } from 'survey-react-ui'
import React from 'react'
import { Question } from 'survey-core'
import PlusCircle from '../../icons/PlusCircle'

class MuiAddButton extends SurveyQuestionPanelDynamicAddButton {
  customHandleClick = () => {
    const panel = this.question.addPanelUI()
    setTimeout(() => {
      const all = Array.from(document.getElementById(panel.id)?.getElementsByTagName('*') || [])
      const focusable = all.find((e: Element) => {
        if ((e as HTMLElement).tabIndex < 0) return false
        switch (e.tagName) {
          case 'A':
            return !!(e as HTMLAnchorElement).href
          case 'INPUT':
            return (e as HTMLInputElement).type !== 'hidden' && !(e as HTMLInputElement).disabled
          case 'SELECT':
          case 'TEXTAREA':
          case 'BUTTON':
            return !(e as HTMLSelectElement | HTMLTextAreaElement | HTMLButtonElement).disabled
          default:
            return false
        }
      })
      ;(focusable as unknown as { focus: () => void } | null)?.focus()
    }, 16)
  }

  protected renderElement(): JSX.Element {
    const hasEmpty = this.question.panels.some((panel) => !panel.questions.some((value: Question) => !value.isEmpty()))
    return (
      <Button
        variant="text"
        size="small"
        startIcon={<PlusCircle />}
        onClick={this.customHandleClick}
        sx={{ pl: '2px', pt: 0 }}
        disabled={hasEmpty}
      >
        {this.question.panelAddText}
      </Button>
    )
  }
}

export default MuiAddButton
