import React, { createContext } from 'react'
import { AlertColor } from '@mui/material'
import { makeAutoObservable, observable } from 'mobx'

type AlertLevel = 'system' | 'page'
type AlertType = 'dismiss' | 'action'

export type Alert = {
  timestamp: number
  severity: AlertColor
  title: React.ReactNode
  message: React.ReactNode
  level: AlertLevel
  type: AlertType
  cancelAction: (removeAlert: () => void) => void
  confirmAction: (removeAlert: () => void) => void
}

const defaultAlertAction = (removeAlert: () => void) => removeAlert()

class AlertsContextValue {
  alerts = observable.array<Alert>([], { deep: false, name: 'alerts' })

  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true })
  }

  hasAlert = (predicate: (alert: Alert) => boolean) => this.alerts.findIndex((alert) => predicate(alert)) !== -1

  addAlert = (
    severity: AlertColor,
    title: React.ReactNode,
    message: React.ReactNode,
    level: AlertLevel,
    type: AlertType,
    cancelAction: (removeAlert: () => void) => void = defaultAlertAction,
    confirmAction: (removeAlert: () => void) => void = defaultAlertAction,
  ) => {
    const alert = {
      timestamp: new Date().getTime(),
      severity,
      title,
      message,
      level,
      type,
      cancelAction,
      confirmAction,
    }
    const index = this.alerts.findIndex((v) => v.severity === severity && v.title === title && v.message === message)
    if (index !== -1) {
      this.alerts[index] = alert
    } else {
      this.alerts.push(alert)
    }
    this.alerts.sort((a, b) => {
      if (a.level < b.level) return 1
      if (a.level > b.level) return -1
      return 0
    })
    return alert
  }

  /**
   * Removes alerts in-place
   * @param predicate
   */
  removeAlerts = (predicate: (alert: Alert) => boolean) => {
    for (let i = this.alerts.length - 1; i >= 0; i--)
      if (predicate(this.alerts[i])) {
        this.alerts.splice(i, 1)
      }
  }
}

export const alertsContext = new AlertsContextValue()

const AlertsContext = createContext<AlertsContextValue>(alertsContext)

export default AlertsContext
