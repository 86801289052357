import { createTheme } from '@mui/material'
import muiTheme, { colors } from '../common/mui-theme'

// TODO move fix to common theme
const colorFix = createTheme({
  ...muiTheme,
  typography: {
    ...muiTheme.typography,
    // Headlines/H1
    h1: {
      ...muiTheme.typography?.h1,
      color: colors.neutral['600'],
    },
    // Headlines/H2
    h2: {
      ...muiTheme.typography?.h2,
      color: colors.neutral['600'],
    },
    // Headlines/H3
    h3: {
      ...muiTheme.typography?.h3,
      color: colors.neutral['600'],
    },
    // Headlines/H4
    h4: {
      ...muiTheme.typography?.h4,
      color: colors.neutral['600'],
    },
    // Headlines/H5
    h5: {
      ...muiTheme.typography?.h5,
      color: colors.neutral['600'],
    },
    // Headlines/H6
    h6: {
      ...muiTheme.typography?.h6,
      color: colors.neutral['600'],
    },
    // Text/Subtitle 1
    subtitle1: {
      ...muiTheme.typography?.subtitle1,
      color: undefined,
    },
    // Text/Subtitle 2
    subtitle2: {
      ...muiTheme.typography?.subtitle2,
      color: undefined,
    },
    // Text/Body 1
    body1: {
      ...muiTheme.typography?.body1,
      color: undefined,
    },
    // Text/Body 2
    body2: {
      ...muiTheme.typography?.body2,
      color: undefined,
    },
    // Button
    button: {
      ...muiTheme.typography?.button,
      color: undefined,
    },
    // Text/Caption
    caption: {
      ...muiTheme.typography?.caption,
      color: undefined,
    },
    // Text/OVERLINE
    overline: {
      ...muiTheme.typography?.overline,
      color: undefined,
    },
  },
  components: {
    ...muiTheme.components,
    MuiFormControlLabel: {
      ...muiTheme.components?.MuiFormControlLabel,
      styleOverrides: {
        ...muiTheme.components?.MuiFormControlLabel?.styleOverrides,
        root: {
          ...(muiTheme.components?.MuiFormControlLabel?.styleOverrides?.root as object),
          color: colors.neutral['600'],
        },
      },
    },
  },
})

const portalTheme = createTheme({
  ...colorFix,
  components: {
    ...colorFix.components,
    MuiPaper: {
      ...muiTheme.components?.MuiPaper,
      defaultProps: {
        ...muiTheme.components?.MuiPaper?.defaultProps,
        variant: 'outlined',
      },
      styleOverrides: {
        ...muiTheme.components?.MuiPaper?.styleOverrides,
        outlined: {
          ...(muiTheme.components?.MuiPaper?.styleOverrides?.outlined as object),
          border: '1px solid rgba(14, 20, 37, 0.1)',
        },
      },
    },
    MuiCardHeader: {
      ...muiTheme.components?.MuiCardHeader,
      styleOverrides: {
        ...muiTheme.components?.MuiCardHeader?.styleOverrides,
        root: {
          background: '#FFF',
          borderBottom: '1px solid rgba(14, 20, 37, 0.1)',
          padding: '24px 24px 16px',
        },
        title: {
          fontSize: '18px',
          lineHeight: '160%',
        },
      },
    },
    MuiCardContent: {
      ...muiTheme.components?.MuiCardContent,
      styleOverrides: {
        ...muiTheme.components?.MuiCardContent?.styleOverrides,
        root: {
          ...(muiTheme.components?.MuiCardContent?.styleOverrides?.root as object),
          padding: '24px',
        },
      },
    },

    MuiMenuItem: {
      ...muiTheme.components?.MuiMenuItem,
      styleOverrides: {
        ...muiTheme.components?.MuiMenuItem?.styleOverrides,
        root: {
          ...(muiTheme.components?.MuiMenuItem?.styleOverrides?.root as object),
          fontSize: '16px',
        },
      },
    },
  },
})

export default portalTheme
