import { Editable, ReactEditor, Slate, withReact } from 'slate-react'
import { createEditor, Descendant, Node as SlateNode, RemoveTextOperation, Transforms } from 'slate'
import React, { useCallback, useContext, useMemo } from 'react'
import { withHistory } from 'slate-history'
import { runInAction } from 'mobx'
import { useEventCallback } from '@mui/material'
import Note from './context/note'
import PortalUserContext from '../portal-user/context/PortalUserContext'

type Child = Descendant & { children: [{ text: string }] }

function withLimit(editor: ReactEditor, limit: number) {
  const { apply } = editor
  // eslint-disable-next-line no-param-reassign
  editor.apply = (operation) => {
    apply(operation)
    if (editor.children.length > 0) {
      const root = editor.children[0] as Child
      if (root.children.length > 0) {
        const { text } = root.children[0]
        if (text.length > limit) {
          apply({
            type: 'remove_text',
            path: [0, 0],
            offset: limit,
            text: text.substring(limit),
          } as RemoveTextOperation)
        }
      }
    }
  }

  return editor
}

function withSingleLine(editor: ReactEditor) {
  const { normalizeNode } = editor
  // eslint-disable-next-line no-param-reassign
  editor.normalizeNode = ([node, path]) => {
    if (path.length === 0) {
      if (editor.children.length > 1) {
        Transforms.mergeNodes(editor)
      }
    }
    return normalizeNode([node, path])
  }
  return editor
}

export default function NoteTitleEditor({ note, contentEditor }: { note: Note; contentEditor: ReactEditor }) {
  const { user } = useContext(PortalUserContext)
  const initialValue = useMemo(() => [{ children: [{ text: note.dto.title || '' }] }], [note])
  const editor = useMemo(() => withLimit(withSingleLine(withReact(withHistory(createEditor()))), 1000), [])
  const onChange = useEventCallback((value: Descendant[]) => {
    const changed = editor.operations.some((op) => op.type !== 'set_selection')
    if (!changed) return
    const text = value.map((d) => SlateNode.string(d)).join()
    // prettier-ignore
    runInAction(() => {
      const { dto } = note;
      dto.title = text;
      dto.updatedBy = user?.fullName;
      dto.timeStamp = new Date().toISOString();
    });
  })

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key === 'Enter') {
        e.preventDefault()
        ReactEditor.focus(contentEditor)
      }
    },
    [contentEditor],
  )

  return (
    <Slate editor={editor} value={initialValue} onChange={onChange}>
      <Editable
        autoFocus
        placeholder="Title"
        style={{ fontSize: '20px', fontWeight: 500, lineHeight: '28px', overflowWrap: 'anywhere' }}
        onKeyDown={onKeyDown}
      />
    </Slate>
  )
}
