import { makeAutoObservable } from 'mobx'
import { createContext } from 'react'
import { JobDto } from '../../../_generated/portal-api'

export class CandidatePlacement {
  jobs?: JobDto[] = undefined

  constructor(public readonly candidateId: number) {
    makeAutoObservable(this, undefined, { autoBind: true })
  }

  get loading() {
    return !this.jobs
  }
}

const PlacementContext = createContext(new CandidatePlacement(0))

export default PlacementContext
