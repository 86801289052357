import { CSSProperties } from 'react'

export default function Eraser({
  width = 24,
  height = 24,
  style,
}: {
  width?: number
  height?: number
  style?: CSSProperties
}) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <path
        opacity="0.12"
        d="M11 6.00004L12.6059 4.39415C13.7939 3.2061 14.388 2.61207 15.0729 2.38951C15.6755 2.19373 16.3245 2.19373 16.9271 2.38951C17.612 2.61207 18.2061 3.2061 19.3941 4.39415L19.6059 4.60592C20.7939 5.79397 21.388 6.388 21.6105 7.07298C21.8063 7.67551 21.8063 8.32456 21.6105 8.92709C21.388 9.61207 20.7939 10.2061 19.6059 11.3941L18 13L11 6.00004Z"
        fill="currentColor"
      />
      <path
        d="M18 13L11 6.00004M21 21H8.00004M10.9373 20.0628L19.6059 11.3941C20.794 10.2061 21.388 9.61207 21.6106 8.92709C21.8063 8.32456 21.8063 7.67551 21.6106 7.07298C21.388 6.388 20.794 5.79397 19.6059 4.60592L19.3941 4.39415C18.2061 3.2061 17.6121 2.61207 16.9271 2.38951C16.3246 2.19373 15.6755 2.19373 15.073 2.38951C14.388 2.61207 13.794 3.2061 12.6059 4.39415L4.39415 12.6059C3.2061 13.794 2.61207 14.388 2.38951 15.073C2.19373 15.6755 2.19373 16.3246 2.38951 16.9271C2.61207 17.6121 3.2061 18.2061 4.39415 19.3941L5.06278 20.0628C5.40868 20.4087 5.58163 20.5816 5.78347 20.7053C5.96241 20.815 6.1575 20.8958 6.36157 20.9448C6.59175 21 6.83634 21 7.32552 21H8.67455C9.16373 21 9.40832 21 9.6385 20.9448C9.84257 20.8958 10.0377 20.815 10.2166 20.7053C10.4184 20.5816 10.5914 20.4087 10.9373 20.0628Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
