import React, { useCallback, useState } from 'react'
import { InputAdornment, TextField } from '@mui/material'
import { TextFieldProps } from '@mui/material/TextField/TextField'
import MaskedAmountInput from './MaskedAmountInput'

// eslint-disable-next-line react/display-name
const AmountInput = React.forwardRef<HTMLInputElement, TextFieldProps>(
  ({ value, onChange, onFocus, onBlur, sx, ...props }: TextFieldProps, ref) => {
    const [focused, setFocused] = useState(false)
    const innerOnFocus = useCallback(
      (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFocused(true)
        if (onFocus) onFocus(e)
      },
      [onFocus],
    )
    const innerOnBlur = useCallback(
      (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFocused(false)
        if (onBlur) onBlur(e)
      },
      [onBlur],
    )
    const shrink = focused || !!value
    return (
      <TextField
        inputRef={ref}
        value={value}
        sx={{
          ...sx,
          '& .MuiInputLabel-root:not(.MuiInputLabel-shrink)': {
            marginLeft: '16px',
          },
        }}
        InputProps={{
          inputComponent: MaskedAmountInput,
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
        onChange={onChange}
        onInput={onChange as React.FormEventHandler<HTMLDivElement>}
        onFocus={innerOnFocus}
        onBlur={innerOnBlur}
        InputLabelProps={{ shrink }}
        {...props}
      />
    )
  },
)

export default AmountInput
