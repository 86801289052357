import { useMemo } from 'react'
import { SurveyModel } from 'survey-core'
import { coachingSession, coachingSessionFeedback } from './retention-surveys'
import CoachingSessionAccordion from './CoachingSessionAccordion'
import { CoachingSessionDto } from '../../_generated/portal-api'

export default function CoachingSessionRetention({
  session,
  id,
  title,
}: {
  session: CoachingSessionDto
  id: string
  title: string
}) {
  const model = useMemo(() => new SurveyModel(coachingSession), [])
  const feedback = useMemo(() => new SurveyModel(coachingSessionFeedback), [])
  return <CoachingSessionAccordion id={id} title={title} model={model} feedback={feedback} session={session} />
}
