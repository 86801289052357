import { IconButton, InputAdornment, styled } from '@mui/material'
import TextField from '@mui/material/TextField'
import React, { useCallback, useContext } from 'react'
import { observer } from 'mobx-react'
import Search from '../../../common/icons/Search'
import JobPostingsContext from './context/JobPostingsContext'
import Close from '../../../common/icons/Close'

const StyledTextField = styled(TextField)(() => ({
  button: {
    display: 'none',
  },
  '&:hover button, & .Mui-focused button': {
    display: 'flex',
  },
}))

function JobsSearch() {
  const { query, setQuery } = useContext(JobPostingsContext)
  const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => setQuery(event.target.value), [setQuery])
  const handleClearClick = useCallback(() => setQuery(''), [setQuery])

  return (
    <StyledTextField
      fullWidth
      placeholder="Search by job title, company, ZIP Code, and more..."
      size="small"
      value={query || ''}
      onChange={onChange}
      inputProps={{
        style: {
          padding: '2.5px 4px 2.5px 6px',
          textOverflow: 'ellipsis',
        },
        maxLength: 1000,
      }}
      InputProps={{
        style: {
          padding: '10px 5px 10px 12px',
        },
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleClearClick}>
              <Close width={16} height={16} />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  )
}

export default observer(JobsSearch)
