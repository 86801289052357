import React, { useContext } from 'react'
import { Link, Typography } from '@mui/material'
import AlertsContext from '../../common/alerts/AlertsContext'
import download from '../../common/download-blob'
import FileAttachment from '../../common/icons/FileAttachment'
import { HttpResponse } from '../_generated/portal-api'
import { FileQuestionValue } from '../../common/survey/file-question-value'

export default function DownloadLink({
  content,
  name,
  type,
  icon = false,
  downloader,
}: FileQuestionValue & { icon?: boolean; downloader: (fileId: number) => Promise<HttpResponse<File, File>> }) {
  const ctx = useContext(AlertsContext)
  const downloadFile = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    downloader(content.id)
      .then((r: HttpResponse<File, File>) => r.blob())
      .then((blob: Blob) => download(blob, name, type === 'application/pdf' ? '_blank' : '_self'))
      .catch(() => {
        // eslint-disable-next-line react/destructuring-assignment
        ctx.addAlert(
          'warning',
          'Download File',
          `Unable to download ${name} (id:${content.id}) file`,
          'system',
          'dismiss',
        )
      })
  }

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <Link href="#" underline="hover" sx={{ display: 'flex', my: '3px', gap: '11px' }} onClick={downloadFile}>
      {icon ? <FileAttachment width={18} height={20} style={{ flex: '0 0 18px' }} /> : null}
      <Typography variant="body2" sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
        {name}
      </Typography>
    </Link>
  )
}
