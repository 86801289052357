import React from 'react'

export default function UploadFileEnabled({
  fillBackgroundColor,
  strokeBackgroundColor,
  strokeColor,
}: {
  fillBackgroundColor: string
  strokeBackgroundColor: string
  strokeColor: string
}) {
  return (
    <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="3" y="3" width="40" height="40" rx="20" fill={fillBackgroundColor} />
      <path
        d="M19.6666 26.3333L23 23M23 23L26.3333 26.3333M23 23V30.5M29.6666 26.9524C30.6845 26.1117 31.3333 24.8399 31.3333 23.4167C31.3333 20.8854 29.2813 18.8333 26.75 18.8333C26.5679 18.8333 26.3975 18.7383 26.3051 18.5814C25.2183 16.7374 23.212 15.5 20.9166 15.5C17.4648 15.5 14.6666 18.2982 14.6666 21.75C14.6666 23.4718 15.3628 25.0309 16.4891 26.1613"
        stroke={strokeColor}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x="3" y="3" width="40" height="40" rx="20" stroke={strokeBackgroundColor} strokeWidth="6" />
    </svg>
  )
}
