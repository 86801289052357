import React, { useCallback } from 'react'
import { Box, Button } from '@mui/material'
import AnswersSectionAccordion, { AnswersAccordionProps } from './AnswersSectionAccordion'
import SavingBadge from '../SavingBadge'
import { colors } from '../../../common/mui-theme'
import ChevronRight from '../../../common/icons/ChevronRight'

type ExcludedProps = 'headerBadge'

export type MinimizableSurveyAccordionProps = Omit<AnswersAccordionProps, ExcludedProps> & {
  saving?: boolean
  minimizedHeaderBadge?: React.ReactElement
}

export function MinimizeButton({ onClick }: { onClick: () => void }) {
  return (
    <Box
      onClick={onClick}
      sx={{
        padding: '17px 0',
        borderTop: `1px solid ${colors.neutral['100']}`,
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
      }}
    >
      <Button variant="text" color="secondary" onClick={onClick} fullWidth>
        Minimize
        <ChevronRight width={20} height={20} style={{ transform: 'rotate(-90deg)' }} />
      </Button>
    </Box>
  )
}

export default function MinimizableSurveyAccordion({
  expanded,
  saving,
  minimizedHeaderBadge,
  setExpanded,
  children,
  ...props
}: MinimizableSurveyAccordionProps) {
  const collapse = useCallback(() => setExpanded(false), [setExpanded])
  return (
    <AnswersSectionAccordion
      {...props}
      expanded={expanded}
      setExpanded={setExpanded}
      headerBadge={!expanded ? minimizedHeaderBadge : saving !== undefined && <SavingBadge saving={saving} />}
    >
      {children}
      <MinimizeButton onClick={collapse} />
    </AnswersSectionAccordion>
  )
}
