import React, { useEffect, useState } from 'react'
import { ElementFactory, SurveyError, SurveyModel } from 'survey-core'
import { ReactElementFactory, ReactQuestionFactory, Survey } from 'survey-react-ui'
import { QuestionCheckboxBase } from 'question_baseselect'
import { Box } from '@mui/material'
import MuiCheckboxItem from './items/MuiCheckboxItem'
import MuiCheckbox from './MuiCheckbox'
import './theme.css'
import MuiBoolean from './MuiBoolean'
import MuiActionButton from './items/MuiActionButton'
import MuiRadioItem from './items/MuiRadioItem'
import MuiRadioGroup from './MuiRadioGroup'
import MuiUsaAddressQuestion from './us-address/MuiUsaAddressQuestion'
import WorkExperienceModel from './work-experience/WorkExperienceModel'
import MuiWorkExperienceQuestion from './work-experience/MuiWorkExperienceQuestion'
import MuiCompensationQuestion from './compensation/MuiCompensationQuestion'
import MuiFileQuestion from './MuiFileQuestion'
import FieldError from './validators/field-error'
import UsaAddressGroupModel from './us-address/UsaAddressGroupModel'
import DoBModel from './dob/DoBModel'
import MuiDoBQuestion from './dob/MuiDoBQuestion'
import CompensationModel from './compensation/CompensationModel'
import OffenseQuestionModel from './offense/OffenseQuestionModel'
import MuiOffenseQuestion from './offense/MuiOffenseQuestion'
import TextareaModel from './textarea/TextareaModel'
import MuiTextarea from './textarea/MuiTextarea'
import TextModel from './TextModel'
import MuiText from './MuiText'
import MuiAddButton from './panel-dynamic/MuiAddButton'
import MuiRemoveButton from './panel-dynamic/MuiRemoveButton'
import DateModel from './DateModel'
import MuiDate from './MuiDate'
import MuiPanelDynamic from './panel-dynamic/MuiPanelDynamic'

ReactElementFactory.Instance.registerElement('survey-checkbox-item', (props) =>
  React.createElement(MuiCheckboxItem, props),
)
ReactElementFactory.Instance.registerElement('survey-radiogroup-item', (props) =>
  React.createElement(MuiRadioItem, props),
)
ReactElementFactory.Instance.registerElement('sv-nav-btn', (props) => React.createElement(MuiActionButton, props))
ElementFactory.Instance.registerElement(UsaAddressGroupModel.TYPE, (name) => new UsaAddressGroupModel(name))
ElementFactory.Instance.registerElement(WorkExperienceModel.TYPE, (name) => new WorkExperienceModel(name))
ElementFactory.Instance.registerElement(DoBModel.TYPE, (name) => new DoBModel(name))

ReactQuestionFactory.Instance.registerQuestion('checkbox', (props) => React.createElement(MuiCheckbox, props))
ReactQuestionFactory.Instance.registerQuestion('radiogroup', (props) => React.createElement(MuiRadioGroup, props))
ReactQuestionFactory.Instance.registerQuestion('boolean', (props) => React.createElement(MuiBoolean, props))
ReactQuestionFactory.Instance.registerQuestion(UsaAddressGroupModel.TYPE, (props) =>
  React.createElement(MuiUsaAddressQuestion, props),
)
ReactQuestionFactory.Instance.registerQuestion(WorkExperienceModel.TYPE, (props) =>
  React.createElement(MuiWorkExperienceQuestion, props),
)
ReactQuestionFactory.Instance.registerQuestion(DoBModel.TYPE, (props) => React.createElement(MuiDoBQuestion, props))

ReactQuestionFactory.Instance.registerQuestion('file', (props) => React.createElement(MuiFileQuestion, props))

// ReactQuestionFactory.Instance.registerQuestion('comment', (props) => React.createElement(MuiComment, props))
ReactQuestionFactory.Instance.registerQuestion(TextareaModel.TYPE, (props) => React.createElement(MuiTextarea, props))
ElementFactory.Instance.registerElement(TextareaModel.TYPE, (name) => new TextareaModel(name))

ReactQuestionFactory.Instance.registerQuestion(TextModel.TYPE, (props) => React.createElement(MuiText, props))
ElementFactory.Instance.registerElement(TextModel.TYPE, (name) => new TextModel(name))

ReactQuestionFactory.Instance.registerQuestion(DateModel.TYPE, (props) => React.createElement(MuiDate, props))
ElementFactory.Instance.registerElement(DateModel.TYPE, (name) => new DateModel(name))

ReactQuestionFactory.Instance.registerQuestion('paneldynamic', (props) => React.createElement(MuiPanelDynamic, props))

ReactElementFactory.Instance.registerElement('sv-paneldynamic-add-btn', (props) =>
  React.createElement(MuiAddButton, props),
)
ReactElementFactory.Instance.registerElement('sv-paneldynamic-remove-btn', (props) =>
  React.createElement(MuiRemoveButton, props),
)

ReactQuestionFactory.Instance.registerQuestion(CompensationModel.TYPE, (props) =>
  React.createElement(MuiCompensationQuestion, props),
)
ElementFactory.Instance.registerElement(CompensationModel.TYPE, (name) => new CompensationModel(name))

ReactQuestionFactory.Instance.registerQuestion(OffenseQuestionModel.TYPE, (props) =>
  React.createElement(MuiOffenseQuestion, props),
)

ElementFactory.Instance.registerElement(OffenseQuestionModel.TYPE, (name) => new OffenseQuestionModel(name))

export type MuiSurveyProps = {
  model: SurveyModel
  hideNavigation?: boolean
}

function applyFixedConfiguration(model: SurveyModel) {
  const m = model
  m.widthMode = 'auto'
  // model.sendResultOnPageNext = true // onPartialSend doesn't work with
  m.questionErrorLocation = 'bottom' // 'top'
  m.focusOnFirstError = true
  // fix None values
  m.getAllQuestions()
    .filter((q) => q.getType() === 'checkbox' && (q as QuestionCheckboxBase).hasNone)
    .forEach((q) => {
      const qc = q as QuestionCheckboxBase
      qc.noneItem.value = qc.noneItem.text
    })
  return model
}

class CustomSurvey extends Survey {
  override renderError(key: string, error: SurveyError, cssClasses: unknown): JSX.Element {
    if (error instanceof FieldError) return <span key={key} />
    return super.renderError(key, error, cssClasses)
  }

  // eslint-disable-next-line class-methods-use-this
  override renderNavigation(navPosition: string): JSX.Element | null {
    if (this.props.hideNavigation) return null
    return super.renderNavigation(navPosition)
  }
}

const hiddenStyle = { display: 'none' } as const

function MuiSurvey({ model, hideNavigation = false }: MuiSurveyProps) {
  const [sx, setSx] = useState<typeof hiddenStyle | undefined>(hiddenStyle)
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const markdownHandler = (survey: SurveyModel, options: any) => {
      // eslint-disable-next-line no-param-reassign
      options.html = options.text
    }
    model.onTextMarkdown.add(markdownHandler)

    setTimeout(() => setSx(undefined), 16) // fix auto-scroll
    return () => model.onTextMarkdown.remove(markdownHandler)
  }, [model])

  return (
    <Box sx={sx}>
      <CustomSurvey model={applyFixedConfiguration(model)} hideNavigation={hideNavigation} />
    </Box>
  )
}

export default MuiSurvey
