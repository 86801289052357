import { AppBar, Box, Toolbar, Typography } from '@mui/material'
import { Route, Routes } from 'react-router-dom'
import React, { useContext } from 'react'
import { useQuery } from 'react-query'
import { runInAction } from 'mobx'
import { observer } from 'mobx-react'
import Alerts from '../../common/alerts/Alerts'
import PortalUserContext from './portal-user/context/PortalUserContext'
import { usePortalApi } from '../api/PortalApiContext'
import { colors } from '../../common/mui-theme'
import JobsSearch from './jobs/JobsSearch'
import { HeaderMenu } from './Header'

function Title({ children = '' }: { children?: string }) {
  return (
    <Box
      sx={{
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      }}
    >
      {children}
    </Box>
  )
}

function JobsHeader() {
  const context = useContext(PortalUserContext)
  const api = usePortalApi()
  useQuery('portalUser', () => api.portalUsers.authenticate().then((r) => r.data), {
    onSuccess: (user) =>
      runInAction(() => {
        context.user = user
      }),
  })
  return (
    <Box sx={{ position: 'sticky', zIndex: 1100, top: 0 }}>
      <AppBar
        position="relative"
        color="transparent"
        sx={{ backgroundColor: colors.base.white, paddingTop: '4px', paddingBottom: '4px' }}
      >
        <Toolbar sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', columnGap: '8px' }}>
          <Box sx={{ display: 'flex' }}>
            <HeaderMenu />
            <Typography variant="h5" component="div" alignSelf="center" sx={{ maxWidth: '30vw' }}>
              <Routes>
                <Route path="*" element={<Title>Jobs</Title>} />
              </Routes>
            </Typography>
          </Box>
          <Box>
            <Routes>
              <Route path="/" element={<JobsSearch />} />
              <Route path="/*" element={null} />
            </Routes>
          </Box>
        </Toolbar>
      </AppBar>
      <Alerts />
    </Box>
  )
}

export default observer(JobsHeader)
