import React from 'react'
import { SurveyQuestionRadiogroup } from 'survey-react-ui'
import MuiOtherValueItem from './items/MuiOtherValueItem'

class MuiRadioGroup extends SurveyQuestionRadiogroup {
  constructor(props: unknown) {
    super(props)
    this.props.question.creator = this.creator
  }

  protected renderOther(): JSX.Element {
    const { cssClasses } = this.question
    return (
      <div className="form-group">
        <MuiOtherValueItem
          question={this.question}
          otherCss={cssClasses.other}
          cssClasses={cssClasses}
          isDisplayMode={this.isDisplayMode}
        />
      </div>
    )
  }
}

export default MuiRadioGroup
