import dayjs, { Dayjs } from 'dayjs'
import React, { useCallback, useMemo, useState } from 'react'
import { Box, Collapse, FormControlLabel, Radio } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import OffenseQuestionModel, { OffenseType } from './OffenseQuestionModel'
import CustomDatePicker from '../custom-date-picker/CustomDatePicker'

class YearOnlyAdapterDayjs extends AdapterDayjs {
  constructor() {
    super()
    const old = this.parse
    this.parse = (value: string, format: string) => {
      const year = +value
      return Number.isNaN(year) ? old(value, format) : dayjs(new Date(year, 0, 1))
    }
  }
}

export default function OffenseItem({
  question,
  type,
  index,
}: {
  question: OffenseQuestionModel
  type: OffenseType
  index: number
}) {
  const { name, value } = question
  const itemValue = Array.isArray(value) && value.length > index ? value[index] : undefined
  const isChecked = itemValue?.type === type
  const year = itemValue?.year !== undefined ? dayjs(new Date(itemValue.year, 0, 1)) : null
  const [, setState] = useState(year) // for calendar re-render
  const onTypeChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      if (checked) {
        const copy = Array.isArray(value) ? [...value] : []
        if (copy.length === index) {
          copy.push({ type })
        } else {
          copy[index] = { type }
        }
        // eslint-disable-next-line no-param-reassign
        question.value = copy
      }
    },
    [index, question, type, value],
  )

  const onYearChanged = useCallback(
    (date: Dayjs | null) => {
      const copy = Array.isArray(value) ? [...value] : []
      copy[index].year = date?.year() || undefined
      setState(date)
      // eslint-disable-next-line no-param-reassign
      question.value = copy
    },
    [index, question, value],
  )

  const maxDate = useMemo(() => dayjs(), [])
  const minDate = useMemo(() => maxDate.add(-100, 'year'), [maxDate])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <FormControlLabel
        control={
          <Radio
            id={type + index}
            name={name}
            value={type}
            onChange={onTypeChanged}
            checked={isChecked}
            aria-label={type}
            sx={{ marginTop: '-10px' }}
          />
        }
        label={type}
        sx={{ alignItems: 'flex-start', p: '4px 0' }}
      />
      <Collapse in={isChecked}>
        <Box sx={{ p: '0 0 16px 32px', '& .MuiInputBase-input': { width: '136px' } }}>
          {isChecked && (
            <LocalizationProvider dateAdapter={YearOnlyAdapterDayjs}>
              <CustomDatePicker
                label="Year of Conviction"
                format="YYYY"
                value={year}
                onChange={onYearChanged}
                openTo="year"
                views={['year']}
                maxDate={maxDate}
                minDate={minDate}
                helperText={' '}
                error={false}
                onAccept={onYearChanged}
              />
            </LocalizationProvider>
          )}
        </Box>
      </Collapse>
    </Box>
  )
}
