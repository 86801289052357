// eslint-disable-next-line import/prefer-default-export

import dayjs, { Dayjs } from 'dayjs'
import { z } from 'zod'

export const TIME_OUT_LIMIT_IN_MILLIS = 100 * 24 * 60 * 60 * 1000
export const DO_NOT_SNOOZE_TIME_ISO = '3000-01-02T00:00:00Z'

export const DO_NOT_SNOOZE_TIME = dayjs(DO_NOT_SNOOZE_TIME_ISO)

export function hasSnoozeTime(date?: Dayjs | null) {
  return date && !DO_NOT_SNOOZE_TIME.isSame(date, 'month')
}

export function getMinArchiveDate() {
  return dayjs().add(1, 'day').startOf('day')
}

const snoozeSchema = z
  .instanceof(dayjs as unknown as typeof Dayjs, { message: 'Date is required' })
  .nullable()
  .refine((date) => !!date, { message: 'Date is required' })
  .refine((date) => date?.isValid(), { message: 'Date is invalid' })
  .refine((date) => !date?.isBefore(getMinArchiveDate(), 'day'), {
    message: 'Date must be set in the future',
  })

export const archiveSchema = z
  .object({
    reason: z.string().min(1, { message: 'Please select a reason' }),
    note: z.string().optional(),
  })
  .and(
    z
      .object({
        snoozeCheck: z.literal(false),
        snoozeDate: z.literal(null),
      })
      .or(
        z.object({
          snoozeCheck: z.literal(true),
          snoozeDate: snoozeSchema,
        }),
      ),
  )

export const archiveDefaultValues: z.infer<typeof archiveSchema> = {
  reason: '',
  note: '',
  snoozeCheck: false,
  snoozeDate: null,
}

export const editSnoozeSchema = z.object({
  snoozeDate: snoozeSchema,
})

export const otherReason = 'Other'
export const otherReasonOption = `${otherReason} (add message below)`

export const archivedReasons = [
  'Duplicate entry',
  'Candidate placed with council company',
  'Candidate no longer interested',
  'Candidate relocated',
  'Candidate outside of service area',
  'Candidate request',
  'Candidate unresponsive',
  'Candidate requires GED',
  'Candidate under 18',
  'Candidate has criminal record',
  otherReasonOption,
]
