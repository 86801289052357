import Button from '@mui/material/Button'
import { SurveyQuestionPanelDynamicRemoveButton } from 'survey-react-ui'
import Close from '../../icons/Close'

class MuiRemoveButton extends SurveyQuestionPanelDynamicRemoveButton {
  protected renderElement(): JSX.Element {
    return (
      <Button variant="text" size="small" startIcon={<Close />} onClick={this.handleClick} sx={{ pb: '24px' }}>
        {this.question.panelRemoveText}
      </Button>
    )
  }
}

export default MuiRemoveButton
