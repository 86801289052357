import React, { useCallback, useMemo, useRef, useState } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  MenuItem,
  Paper,
  PaperProps,
  SelectChangeEvent,
  TextField,
  useEventCallback,
} from '@mui/material'
import { z } from 'zod'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { runInAction } from 'mobx'
import { observer } from 'mobx-react'
import { useUpdateApplicationAnswers, useUpdateCandidateProfile } from '../../../api/hooks/candidateHooks'
import MaskedPhoneInput, { PHONE_REGEX } from '../../../../common/components/MaskedPhoneInput'
import MaskedZipCodeInput, { ZIP_CODE_REGEX } from '../../../../common/components/MaskedZipCodeInput'
import DialogCloseButton from '../../../../common/components/dialog/DialogCloseButton'
import CustomSelect, { SelectCheckboxMenuItem } from '../../../../common/select/CustomSelect'
import CustomAutocomplete from '../../../../common/select/CustomAutocomplete'
import { stateNames, states } from '../../../../common/survey/us-address'
import { Answers } from '../../../../demo/survey-model'
import CandidateProfile from '../profile/candidate-profile'

const addressAnswers = 'Mailing address' as const
const interestsAnswers = 'Career areas' as const
const otherInterestsAnswers = `${interestsAnswers}-Comment` as const

function SquarePaper({ square, children, ...others }: PaperProps) {
  return (
    <Paper square {...others}>
      {children}
    </Paper>
  )
}

// To-be replaced with actual data from survey after conversion
const jobInterestChoices = [
  'Patient Care',
  'Nursing (CNA, LPN, RN, etc.)',
  'Pharmacy (Pharmacist, Pharmacy technician, etc.)',
  'Behavioral Health (Technician, Social work, etc.)',
  'Radiology (Rad Tech, MRI Tech, etc.)',
  'Business Administration',
  'Information Technology',
  "I'm not sure yet",
  'Other',
] as const

const profileSchema = z.object({
  interests: z.object({
    options: z.array(z.string()).optional(),
    other: z.string().trim().optional(),
  }),
  phone: z
    .string()
    .trim()
    .regex(PHONE_REGEX, 'Enter a valid phone number')
    .or(z.literal(''))
    .nullish()
    .transform((s) => s || ''),
  addressLine1: z.string().trim().or(z.literal('')),
  addressLine2: z.string().trim().optional(),
  city: z.string().trim().or(z.literal('')),
  stateName: z.string().trim().or(z.literal('')),
  zipCode: z.string().trim().regex(ZIP_CODE_REGEX, 'Please enter a valid zip code').or(z.literal('')),
})

function CandidateEditor({
  candidate,
  open,
  onClose,
}: {
  candidate: CandidateProfile
  open: boolean
  onClose: (event: object) => void
}) {
  const { answers, dto } = candidate
  const { addressLine1, addressLine2, city, stateName, zipCode } = answers[addressAnswers] || {}
  const { phone } = dto
  const interests = answers[interestsAnswers]?.map((item) => (item === 'other' ? 'Other' : item))

  const parsedInterests = { options: interests || [], other: answers[otherInterestsAnswers] || '' }

  const cancelEditing = useCallback(() => {
    onClose(() => false)
  }, [onClose])

  const updateProfile = useUpdateCandidateProfile(dto.userId)
  const updateProfileAnswers = useUpdateApplicationAnswers(dto.userId)
  const interestMenuRef = useRef<HTMLDivElement>(null)

  const {
    register,
    getValues,
    setValue,
    control,
    handleSubmit,
    formState: { errors, isSubmitted, isSubmitting },
  } = useForm<z.infer<typeof profileSchema>>({
    resolver: zodResolver(profileSchema),
    defaultValues: {
      interests: parsedInterests,
      phone,
      addressLine1,
      addressLine2,
      city,
      stateName,
      zipCode,
    },
  })

  const [state, setState] = useState(stateName || '')

  const onSubmit = useEventCallback(
    handleSubmit(async (data) => {
      const { interests: updatedInterests, phone: updatedPhone, ...updated } = data
      runInAction(() => {
        answers[addressAnswers] = updated
        answers[interestsAnswers] = updatedInterests?.options as Answers[typeof interestsAnswers]
        answers[otherInterestsAnswers] = updatedInterests?.other
        dto.resultJsonString = JSON.stringify(answers)
        dto.phone = updatedPhone
      })
      await Promise.all([
        updateProfile.mutateAsync(dto),
        updateProfileAnswers.mutateAsync({
          workflowStateId: dto.workflowStateId,
          resultJsonString: dto.resultJsonString,
        }),
      ])
      onClose(() => false)
    }),
  )
  const stopPropagation = useCallback((e: { stopPropagation: () => void }) => e.stopPropagation(), [])
  const [options, setOptions] = useState(getValues('interests.options'))
  const optionsSet = useMemo(() => new Set(options), [options])

  const updateInterests = useEventCallback((e: SelectChangeEvent<unknown>) => {
    const hadOther = optionsSet.has('Other')
    const newOptions = e.target.value as string[]
    const hasOther = newOptions.includes('Other')
    setValue('interests.options', newOptions, { shouldValidate: isSubmitted })
    setOptions(newOptions)
    if (hadOther !== hasOther) {
      // fix scrolling
      setTimeout(() => {
        if (interestMenuRef.current) {
          interestMenuRef.current.scrollTop = interestMenuRef.current.scrollHeight
        }
      }, 16)
    }
  })

  return (
    <Dialog open={open} onClose={cancelEditing}>
      <DialogTitle>
        Edit Profile
        <DialogCloseButton onClick={cancelEditing} />
      </DialogTitle>
      <DialogContent>
        <FormControl fullWidth>
          <CustomSelect
            multiple
            label="Job Interests"
            {...register('interests.options')}
            value={options}
            onChange={updateInterests}
            renderValue={(selected) => (selected as string[]).join(', ')}
            MenuProps={{ PaperProps: { ref: interestMenuRef } }}
          >
            {jobInterestChoices.map((variant) => (
              <SelectCheckboxMenuItem key={variant} value={variant} selected={optionsSet.has(variant)}>
                {variant}
              </SelectCheckboxMenuItem>
            ))}
            <MenuItem
              sx={{
                display: optionsSet.has('Other') ? 'flex' : 'none',
              }}
            >
              <TextField
                sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}
                multiline
                {...register('interests.other')}
                onKeyDown={stopPropagation} // prevents selection of menu items
              />
            </MenuItem>
          </CustomSelect>
          <FormHelperText> </FormHelperText>
        </FormControl>
        <Controller
          control={control}
          render={({ field }) => (
            <TextField
              label="Phone Number"
              {...field}
              onInput={field.onChange}
              error={!!errors.phone}
              helperText={errors.phone?.message || ' '}
              InputProps={{
                inputComponent: MaskedPhoneInput,
              }}
              fullWidth
              inputProps={{
                maxLength: 100,
              }}
            />
          )}
          name="phone"
        />
        <TextField
          label="Address Line 1"
          {...register('addressLine1')}
          helperText=" "
          fullWidth
          inputProps={{ maxLength: 255 }}
        />
        <TextField
          label="Address Line 2"
          {...register('addressLine2')}
          helperText=" "
          fullWidth
          inputProps={{ maxLength: 255 }}
        />
        <TextField label="City" {...register('city')} helperText=" " fullWidth inputProps={{ maxLength: 50 }} />
        <Box display="flex" sx={{ flex: 1, columnGap: '16px' }}>
          <Controller
            render={({ field }) => (
              <CustomAutocomplete
                fullWidth
                disablePortal
                id="stateName"
                options={stateNames}
                isOptionEqualToValue={(option, value) => option === value || states.get(value) === option}
                sx={{ border: 'none' }}
                ListboxProps={{
                  style: {
                    maxHeight: 190,
                  },
                }}
                PaperComponent={SquarePaper}
                value={field.value}
                onChange={(e, v) => field.onChange(v)}
                inputValue={state}
                onInputChange={(e, v) => setState(v)}
                renderInput={(params) => (
                  <TextField
                    ref={field.ref}
                    onBlur={field.onBlur}
                    label="State"
                    {...params}
                    InputProps={{ ...params.InputProps }}
                  />
                )}
              />
            )}
            name="stateName"
            control={control}
          />
          <Controller
            control={control}
            render={({ field }) => (
              <TextField
                label="Zip Code"
                {...field}
                onInput={field.onChange}
                error={!!errors.zipCode}
                helperText={errors.zipCode?.message || ' '}
                InputProps={{
                  inputComponent: MaskedZipCodeInput,
                }}
              />
            )}
            name="zipCode"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={cancelEditing} fullWidth>
          Cancel
        </Button>
        <Button onClick={onSubmit} disabled={isSubmitting} fullWidth>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default observer(CandidateEditor)
