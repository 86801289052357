import { SurveyModel } from 'survey-core'
import { QuestionCheckboxModel } from 'question_checkbox'
import { QuestionRadiogroupModel } from 'question_radiogroup'
import { UsaAddress } from '../common/survey/us-address'
import { FileQuestionValue } from '../common/survey/file-question-value'
import { WorkExperience } from '../common/survey/work-experience/validator'
import { Compensation } from '../common/survey/compensation/CompensationModel'
import { GetChoices } from './survey-types'

const howDidYouLearnAboutUs = {
  title: 'Thank you for verifying your email address.',
  elements: [
    {
      name: 'How did you learn about us',
      title: 'How did you learn about us?',
      type: 'checkbox',
      description: 'Select at least one',
      choices: [
        'Health Career Lab Event',
        'Facebook',
        'LinkedIn',
        'Twitter',
        'Job Fair',
        'Google Search',
        'News Article',
        'Friend/Colleague',
        'Community Organization',
        'Employer',
      ],
      requiredErrorText: 'At least one selection is required to proceed',
      hideNumber: true,
      showOtherItem: true,
      otherText: 'Other<div class="helper-text">Please, describe below</div>',
      otherPlaceHolder: 'Other',
      otherErrorText: 'Please let us know how you heard about us',
      isRequired: true,
      colCount: 2,
    },
  ],
} as const

const haveYouAppliedBefore = {
  elements: [
    {
      name: 'Have you applied to Health Career Lab before',
      title: 'Have you applied to Health Career Lab before?',
      type: 'boolean',
      isRequired: true,
      hideNumber: true,
    },
  ],
} as const

const whyAreYouHere = {
  elements: [
    {
      name: 'Why you are here',
      title: 'Tell us what brings you here today.',
      description: 'Select at least one',
      type: 'checkbox',
      choices: ['Get a Job', 'Support to Get/Maintain a Job', 'Training/Education'],
      requiredErrorText: 'At least one selection is required to proceed',
      showOtherItem: true,
      otherText: 'Other<div class="helper-text">Please, describe below</div>',
      otherPlaceHolder: 'Other',
      otherErrorText: 'Please let us know why you are here',
      hideNumber: true,
      isRequired: true,
      colCount: 2,
    },
  ],
} as const

const highestEducationOrTraining = {
  elements: [
    {
      type: 'radiogroup',
      name: 'Highest degree',
      title: 'What is your highest level of education or training?',
      choices: [
        'Current High School Student',
        {
          value: 'Certificate/Trade',
          subQuestion: 'Certificate/Trade Details',
        },
        {
          value: 'Some High School (Did not graduate)',
          subQuestion: 'Some High School (Did not graduate) Details',
        },
        'Associate Degree',
        {
          value: 'High School/GED',
          subQuestion: 'High School/GED Details',
        },
        "Bachelor's Degree",
        'Some College (No degree)',
        "Master's Degree",
        'Doctoral Degree',
      ],
      requiredErrorText: 'One selection is required to proceed',
      showOtherItem: true,
      otherText: 'Other<div class="helper-text">Please, describe below</div>',
      otherPlaceHolder: 'Other',
      otherErrorText: 'Please enter your highest level of education',
      colCount: 2,
      isRequired: true,
      hideNumber: true,
    },
  ],
} as const

export const placementAttachments = {
  pages: [
    {
      elements: [
        {
          type: 'file',
          name: 'Offer Letter',
          title: 'Offer Letter',
          acceptedTypes: [
            '.pdf',
            '.doc',
            '.docx',
            '.txt',
            'text/plain',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/pdf',
          ],
          requiredErrorText: 'Please attach a file to proceed',
          storeDataAsText: false,
          showPreview: false,
          allowMultiple: true,
          allowImagesPreview: false,
          isRequired: false,
          hideNumber: true,
        },
      ],
    },
  ],
} as const

export const submissionAttachments = {
  pages: [
    {
      elements: [
        {
          type: 'file',
          name: 'Submission Attachments',
          title: 'Attachments',
          acceptedTypes: [
            '.pdf',
            '.doc',
            '.docx',
            '.txt',
            'text/plain',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/pdf',
          ],
          requiredErrorText: 'Please attach a file to proceed',
          storeDataAsText: false,
          showPreview: false,
          allowMultiple: true,
          allowImagesPreview: false,
          isRequired: false,
          hideNumber: true,
          dragAndDrop: true,
          showCheckboxes: true,
          hideDelete: true,
        },
      ],
    },
  ],
} as const

const attachments = {
  visibleIf: '{Resume Y/N} = true',
  title: 'Great!',
  elements: [
    {
      type: 'file',
      name: 'Attachments',
      title: 'Please upload your resume',
      description:
        'You may upload documents as a PDF, Word document, or TXT file. Up to two documents may be uploaded.',
      acceptedTypes: [
        '.pdf',
        '.doc',
        '.docx',
        '.txt',
        'text/plain',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/pdf',
      ],
      requiredErrorText: 'Please attach a file to proceed',
      storeDataAsText: false,
      showPreview: false,
      allowMultiple: true,
      allowImagesPreview: false,
      isRequired: true,
      hideNumber: true,
      maxFiles: 2,
    },
  ],
} as const

const jobExperience = {
  visibleIf: '{Resume Y/N} = false and {Work Experience Y/N} = true',
  elements: [
    {
      name: 'Work Experience',
      title: ' ',
      jobListTitle: "Here is what you've added so far, would you like to add another job?",
      firstJobTitle: "Great, let's start by adding your most recent job.",
      type: 'work-experience',
      hideNumber: true,
    },
  ],
} as const

const healthcareAreasOfInterest = {
  elements: [
    {
      name: 'Career areas',
      title: 'What healthcare career areas are you interested in?',
      description: 'Select at least one',
      type: 'checkbox',
      choices: [
        'Patient Care',
        'Nursing (CNA, LPN, RN, etc.)',
        'Pharmacy (Pharmacist, Pharmacy technician, etc.)',
        'Behavioral Health (Technician, Social work, etc.)',
        'Radiology (Rad Tech, MRI Tech, etc.)',
        'Business Administration',
        'Information Technology',
        "I'm not sure yet",
      ],
      requiredErrorText: 'At least one selection is required to proceed',
      hideNumber: true,
      isRequired: true,
      colCount: 2,
      showOtherItem: true,
      otherText: 'Other / not sure<div class="helper-text">Please, describe below</div>',
      otherPlaceHolder: 'Other',
      otherErrorText: 'Please provide your preferred career area',
    },
  ],
} as const

const trainingProgramInterest = {
  elements: [
    {
      name: 'Training Program Interest',
      title: 'Are you interested in participating in a free training program?',
      type: 'checkbox',
      choices: [
        {
          value: 'Digital Literacy Skills',
          toolTip:
            'In partnership with The University of Louisville’s Digital Transformation Center this program is designed to upskill community trainers, at no cost, by creating a foundational knowledge of three digital topics:\n' +
            '<ul>' +
            '<li>Conversational Artificial Intelligence</li>\n' +
            '<li>Data Analytics</li>\n' +
            '<li>Forms Data Collection</li>\n' +
            '</ul>' +
            'Participants are eligible to receive $500 upon completion of the program and a 1st round interview.\n',
          toolTipSelectLabel: 'Select Training',
        },
        {
          value: 'Foundational Skills',
          toolTip:
            'Led by Health Career Lab, Foundational Skills training provides participants with a comprehensive introduction to the healthcare industry and prepares them for potential careers in healthcare. Topics include:\n' +
            '<ul>' +
            '<li>Resume building, goal setting, interview prep</li>\n' +
            '<li>Organizational culture and customer service</li>\n' +
            '<li>Technical literacy</li>\n' +
            '</ul>' +
            'Participants are eligible to receive $19/hr. ($480) voucher upon completion of the program and a 1st round interview.\n',
          toolTipSelectLabel: 'Select Training',
        },
        {
          value: 'Amped CompTIA A+',
          toolTip:
            'In partnership with Amped, CompTIA A+ is a 12-week program providing advanced technology training, creating employment opportunities with Louisville-based healthcare companies. The coursework will prepare you for roles in help desk support, field tech support, and more!\n' +
            '<ul>' +
            '<li>In-depth coverage of the latest CompTia A+ material</li>\n' +
            '<li>Soft-skills training, including communication and customer service</li>\n' +
            '<li>Paid certification exam</li>\n' +
            '</ul>' +
            'Participants are eligible for a 1st round interview upon completion.\n',
          toolTipSelectLabel: 'Select Training',
        },
      ],
      description:
        'Participants are eligible for compensation upon completion of a program and a 1st round interview with a local healthcare company. Select all programs of interest',
      colCount: 2,
      hideNumber: true,
    },
  ],
} as const

const typeOfEmploymentSeeking = {
  elements: [
    {
      name: 'Type of employment',
      title: 'What type of employment are you seeking?',
      description: 'Select at least one',
      type: 'checkbox',
      choices: ['Full Time', 'Temporary/Contract', 'Part Time', 'Seasonal', 'PRN (as needed)'],
      requiredErrorText: 'At least one selection is required to proceed',
      showNoneItem: true,
      noneText: 'No Preference',
      hideNumber: true,
      isRequired: true,
    },
  ],
} as const

const preferredWorkShift = {
  elements: [
    {
      name: 'Preferred work shift',
      title: 'What is your preferred work shift?',
      description: 'Select at least one',
      type: 'checkbox',
      choices: ['1st Shift (Day)', '2nd Shift (Afternoon)', '3rd Shift (Evening)'],
      requiredErrorText: 'At least one selection is required to proceed',
      hideNumber: true,
      isRequired: true,
      showOtherItem: true,
      otherText: 'Other<div class="helper-text">Please, describe below</div>',
      otherPlaceHolder: 'Other',
      otherErrorText: 'Please provide your preferred work shift',
    },
  ],
} as const

const preferredWorkingEnvironment = {
  elements: [
    {
      name: 'Preferred working environment',
      title: 'What is your preferred working environment?',
      type: 'checkbox',
      choices: ['Hospital', 'Assisted living', 'Office setting'],
      requiredErrorText: 'At least one selection is required to proceed',
      hideNumber: true,
      isRequired: true,
      colCount: 2,
      showOtherItem: true,
      otherText: 'Other / not sure<div class="helper-text">Please, describe below</div>',
      otherPlaceHolder: 'Other',
      otherErrorText: 'Please provide your preferred work shift',
    },
  ],
} as const

const challengesToEmployment = {
  visible: 'false',
  elements: [
    {
      name: 'Barriers to work',
      title: 'What challenges exist to employment or training?',
      description: 'Select at least one',
      type: 'checkbox',
      choices: [
        'Limited English proficiency',
        'Criminal record',
        'Lack of childcare',
        'Unreliable or no transportation',
        'Limited or uncertain access to food resources',
        'Homelessness or housing issues',
        'Medical or mental illness',
        'Age',
        'No safe space free of sexual violence, harassment, or other attacks',
        'Lack of basic computer skills',
        'Drug and alcohol addiction',
        'Transition from military to civilian workforce',
        'No HS Diploma or GED',
      ],
      requiredErrorText: 'At least one selection is required to proceed',
      hideNumber: true,
      isRequired: true,
      colCount: 2,
      showOtherItem: true,
      otherText: 'Other<div class="helper-text">Please, describe below</div>',
      otherPlaceHolder: 'Other',
      otherErrorText: 'Please provide your barrier to work',
      showNoneItem: true,
      noneText: 'None',
    },
  ],
} as const

const importantFactorsToNewJob = {
  elements: [
    {
      name: 'New Job Priorities',
      title: 'What are the most important factors to you when considering a new job?',
      description: 'Select at least one',
      type: 'checkbox',
      choices: [
        'Ability to Work from Home',
        'Career Advancement',
        'Educational Benefits',
        'Flexible Hours',
        'Healthcare Benefits',
        'Home & Community Health',
        'Need to Relocate',
        'Location (proximity to work site)',
        'Pay',
        'Transportation',
      ],
      requiredErrorText: 'At least one selection is required to proceed',
      hideNumber: true,
      isRequired: true,
      colCount: 2,
      showOtherItem: true,
      otherText: 'Other<div class="helper-text">Please, describe below</div>',
      otherPlaceHolder: 'Other',
      otherErrorText: 'Please provide your preferred job priorities',
    },
  ],
} as const

const councilCompaniesInterestedIn = {
  elements: [
    {
      type: 'radiogroup',
      name: 'Interested council companies',
      title: 'Are there Council Companies that you`re interested in?',
      choices: [
        'No',
        {
          value: 'Yes',
          title: 'Yes<div class="helper-text">List companies below</div>',
          subQuestion: 'Interested council companies Details',
        },
      ],
      requiredErrorText: 'One selection is required to proceed',
      isRequired: true,
      hideNumber: true,
    },
  ],
} as const

const councilCompaniesAppliedTo = {
  elements: [
    {
      type: 'radiogroup',
      name: 'Council companies applied to',
      title: 'How you applied to any Council Companies?',
      choices: [
        'No',
        {
          value: 'Yes',
          title: 'Yes<div class="helper-text">List companies below</div>',
          subQuestion: 'Council companies applied to Details',
        },
      ],
      requiredErrorText: 'One selection is required to proceed',
      isRequired: true,
      hideNumber: true,
    },
  ],
} as const

const compensation = {
  elements: [
    {
      name: 'Current Compensation',
      title: 'Compensation',
      type: 'compensation',
      hideNumber: true,
      label: 'Enter Current Amount',
    },
    {
      startWithNewLine: false,
      name: 'Desired Compensation',
      titleLocation: 'hidden',
      type: 'compensation',
      hideNumber: true,
      label: 'Enter Desired Amount',
    },
  ],
} as const

const authorizedToWorkAndVisaRequirement = {
  elements: [
    {
      name: 'Work in the US',
      title: 'Please respond to each of the following questions.',
      description: 'Are you legally authorized to work in the United States?',
      type: 'radiogroup',
      choices: ['Yes', 'No'],
      requiredErrorText: 'One selection is required to proceed',
      hideNumber: true,
      isRequired: true,
    },
    {
      // startWithNewLine: false,
      name: 'Visa sponsorship',
      title: 'Will you now or in the future require visa sponsorship for employment?',
      type: 'radiogroup',
      choices: ['Yes', 'No'],
      requiredErrorText: 'One selection is required to proceed',
      isRequired: true,
      hideNumber: true,
    },
  ],
} as const

const dateOfBirth = {
  elements: [
    {
      name: 'Date of Birth',
      title: 'Please enter your Date of Birth',
      description:
        'Health Career Lab requires your date of birth for funding purposes. The information you provide will not impact your eligibility to receive training or gain employment.',
      type: 'dob',
      hideNumber: true,
    },
  ],
} as const

const gender = {
  title: "We're almost done!",
  elements: [
    {
      name: 'Gender',
      type: 'radiogroup',
      title: "Let's wrap up with a few voluntary self-identification questions.",
      description: 'Which of the following most closely describes your gender?',
      choices: ['Woman', 'Transgender Woman', 'Man', 'Transgender Man', 'Prefer not to state', 'Non-Binary', 'Other'],
      requiredErrorText: 'One selection is required to proceed',
      colCount: 2,
      isRequired: true,
      hideNumber: true,
    },
  ],
} as const

const raceEthnicity = {
  elements: [
    {
      name: 'Race/Ethnicity',
      type: 'checkbox',
      title: 'Race/Ethnicity',
      choices: [
        'Alaskan Native/American Indian/Indigenous American/Native American',
        'Hispanic or Latino (Inclusive of South American, Central American, Caribbean)',
        'Black/African or African American',
        'East Asian (Inclusive of Chinese, Japanese, Korean, Mongolian, Tibetan, Taiwanese)',
        'White',
        'Pacific Islander or Native Hawaiian',
        'Middle Eastern',
        'South Asian (Inclusive of Afghani, Bangladeshi, Bhutanese, Indian, Nepali, Pakistani, Sri Lankan)',
        'Other',
      ],
      requiredErrorText: 'At least one selection is required to proceed',
      colCount: 2,
      showNoneItem: true,
      noneText: 'Prefer not to say',
      isRequired: true,
      hideNumber: true,
    },
  ],
} as const

const veteran = {
  elements: [
    {
      name: 'Veteran',
      type: 'radiogroup',
      title: 'Have you ever enlisted or served as a commissioned officer in the U.S. Military?',
      choices: ['Yes', 'No', 'Prefer not to answer'],
      requiredErrorText: 'One selection is required to proceed',
      colCount: 1,
      isRequired: true,
      hideNumber: true,
    },
  ],
} as const

const disability = {
  elements: [
    {
      name: 'Disability',
      type: 'radiogroup',
      title: 'Voluntary self-identification of disability',
      choices: [
        { text: 'Yes, I have a disability, or have a history/record of having a disability', value: 'Yes' },
        { text: "No, I don't have a disability, or a history/record of having a disability", value: 'No' },
        { text: 'Prefer not to answer', value: 'Prefer not to answer' },
      ],
      requiredErrorText: 'One selection is required to proceed',
      colCount: 1,
      isRequired: true,
      hideNumber: true,
    },
  ],
} as const

export const overviewAndGoals = {
  textUpdateMode: 'onTyping',
  pages: [
    {
      elements: [
        {
          ...howDidYouLearnAboutUs.elements[0],
          otherText: 'Other<div class="helper-text">Please, describe below</div>',
          description: '',
          isRequired: false,
        },
        {
          ...whyAreYouHere.elements[0],
          otherText: 'Other<div class="helper-text">Please, describe below</div>',
          description: '',
          isRequired: false,
        },
        ...haveYouAppliedBefore.elements,
      ],
    },
  ],
} as const

const additionalTextElement = {
  type: 'textarea',
  rows: 4,
  visible: false,
  isRequired: false,
  placeholder: 'Add additional details',
  allowResize: false,
  autoGrove: true,
} as const
export const educationAndExperience = {
  textUpdateMode: 'onTyping',
  pages: [
    {
      elements: [
        {
          ...highestEducationOrTraining.elements[0],
          isRequired: false,
        },
        {
          ...additionalTextElement,
          name: 'High School/GED Details',
          title: 'High School/GED Details',
        },
        {
          ...additionalTextElement,
          name: 'Certificate/Trade Details',
          title: 'Certificate/Trade Details',
        },
        {
          ...additionalTextElement,
          name: 'Some High School (Did not graduate) Details',
          title: 'Some High School (Did not graduate) Details',
        },
        {
          ...trainingProgramInterest.elements[0],
          description: '',
        },
        { ...attachments.elements[0], maxFiles: undefined },
        jobExperience.elements[0],
      ],
    },
  ],
} as const

export const employmentAndPreferences = {
  textUpdateMode: 'onTyping',
  pages: [
    {
      elements: [
        {
          ...healthcareAreasOfInterest.elements[0],
          description: '',
          isRequired: false,
        },

        {
          ...typeOfEmploymentSeeking.elements[0],
          description: '',
          colCount: 2,
        },

        {
          ...preferredWorkShift.elements[0],
          description: '',
          colCount: 2,
          isRequired: false,
        },
        {
          ...preferredWorkingEnvironment.elements[0],
          isRequired: false,
        },
        {
          ...challengesToEmployment.elements[0],
          description: '',
          isRequired: false,
        },
        {
          ...importantFactorsToNewJob.elements[0],
          description: '',
          isRequired: false,
        },
        ...councilCompaniesInterestedIn.elements,
        {
          ...additionalTextElement,
          startWithNewLine: false,
          name: 'Interested council companies Details',
          title: 'Interested council companies Details',
        },

        {
          ...councilCompaniesAppliedTo.elements[0],
          startWithNewLine: false,
        },
        {
          ...additionalTextElement,
          startWithNewLine: false,
          name: 'Council companies applied to Details',
          title: 'Council companies applied to Details',
        },
        ...compensation.elements,
      ],
    },
  ],
} as const

export const candidateBackground = {
  textUpdateMode: 'onTyping',
  pages: [
    {
      elements: [
        {
          ...authorizedToWorkAndVisaRequirement.elements[0],
          title: 'Are you legally authorized to work in the United States?',
          description: undefined,
        },
        {
          ...authorizedToWorkAndVisaRequirement.elements[1],
          startWithNewLine: false,
        },
        {
          name: 'Background Check Y/N',
          title: 'Are you willing to submit to a background check?',
          type: 'radiogroup',
          choices: ['Yes', 'No'],
          requiredErrorText: 'One selection is required to proceed',
          isRequired: true,
          hideNumber: true,
        },
        {
          name: 'Drug test Y/N',
          title: 'Are you willing to submit to a drug test?',
          type: 'radiogroup',
          choices: ['Yes', 'No'],
          requiredErrorText: 'One selection is required to proceed',
          isRequired: true,
          hideNumber: true,
          startWithNewLine: false,
        },
        {
          name: 'Convicted of crime or felony',
          title: 'Have you been convicted of a crime or felony?',
          type: 'offense',
          requiredErrorText: 'One selection is required to proceed',
          isRequired: true,
          hideNumber: true,
        },
        {
          name: 'Covid vaccination proof',
          title: 'Can you provide proof of COVID-19 vaccination?',
          type: 'radiogroup',
          choices: ['Yes', 'No'],
          requiredErrorText: 'One selection is required to proceed',
          isRequired: true,
          hideNumber: true,
          startWithNewLine: false,
        },
      ],
    },
  ],
} as const

export const selfIdentification = {
  textUpdateMode: 'onTyping',
  pages: [
    {
      elements: [
        { ...dateOfBirth.elements[0], title: 'What’s your date of birth?', description: '' },
        {
          ...gender.elements[0],
          title: 'Which of the following most closely describes your gender?',
          description: '',
        },
        raceEthnicity.elements[0],
        veteran.elements[0],
        disability.elements[0],
      ],
    },
  ],
} as const

export const inputFieldElement = {
  type: 'textarea',
  title: ' ',
  titleLocation: 'hidden',
  rows: 4,
  hideNumber: true,
  isRequired: false,
  allowResize: false,
  autoGrove: true,
} as const

export const feedbackElement = {
  ...inputFieldElement,
  placeholder: 'Please add additional feedback...',
} as const
export const educationAndExperienceFeedback = {
  textUpdateMode: 'onTyping',
  pages: [
    {
      elements: [
        {
          ...feedbackElement,
          name: 'Education & Experience Feedback',
        },
      ],
    },
  ],
} as const
export const employmentAndPreferencesFeedback = {
  textUpdateMode: 'onTyping',
  pages: [
    {
      elements: [
        {
          ...feedbackElement,
          name: 'Employment & Preferences Feedback',
        },
      ],
    },
  ],
} as const

export const candidateBackgroundFeedback = {
  textUpdateMode: 'onTyping',
  pages: [
    {
      elements: [
        {
          ...feedbackElement,
          name: 'Candidate Background Feedback',
        },
      ],
    },
  ],
} as const

export const selfIdentificationFeedback = {
  textUpdateMode: 'onTyping',
  pages: [
    {
      elements: [
        {
          ...feedbackElement,
          name: 'Self Identification Feedback',
        },
      ],
    },
  ],
} as const

export const overviewAndGoalsFeedback = {
  textUpdateMode: 'onTyping',
  pages: [
    {
      elements: [
        {
          ...feedbackElement,
          name: 'Overview & Goals Feedback',
        },
      ],
    },
  ],
} as const

const mailingAddressPage = {
  elements: [
    {
      name: 'Mailing address',
      title: 'What’s your current mailing address?',
      type: 'usa-address',
      description: 'Required fields are marked with an asterisk (*)',
      hideNumber: true,
    },
  ],
} as const

const resumeYesNoPage = {
  elements: [
    {
      name: 'Resume Y/N',
      title: 'Do you have a resume to upload?',
      type: 'boolean',
      isRequired: true,
      hideNumber: true,
    },
  ],
} as const

const experienceYesNoPage = {
  visibleIf: `{${resumeYesNoPage.elements[0].name}} = false`,
  elements: [
    {
      name: 'Work Experience Y/N',
      title: "No problem. Do you have prior or current work experience you'd like to share?",
      type: 'boolean',
      isRequired: true,
      hideNumber: true,
    },
  ],
} as const

export const welcomeSurveyJson = {
  pages: [
    mailingAddressPage,
    howDidYouLearnAboutUs,
    haveYouAppliedBefore,
    whyAreYouHere,
    highestEducationOrTraining,
    trainingProgramInterest,
    typeOfEmploymentSeeking,
    preferredWorkShift,
    healthcareAreasOfInterest,
    challengesToEmployment,
    importantFactorsToNewJob,
    authorizedToWorkAndVisaRequirement,
    resumeYesNoPage,
    attachments,
    experienceYesNoPage,
    jobExperience,
    dateOfBirth,
    gender,
    raceEthnicity,
    veteran,
    disability,
  ],
  focusFirstQuestionAutomatic: false,
  showQuestionNumbers: false,
  pageNextText: 'Continue',
  requiredText: '',
  completedHtml: `
  <h5>Thanks, #{user.firstName}! You're finished.</h5>
  <div>A confirmation email has been sent to your mail address.
  You will be contacted by a member of the Health Career Lab staff to discuss your application and interests.</div>
  `,
  // showNavigationButtons: 'none',
  // widthMode: 'responsive',
  // checkErrorsMode: 'onValueChanged',
  // goNextPageAutomatic: true,
} as const

type Questions = (
  | (
      | typeof candidateBackground.pages
      | typeof candidateBackgroundFeedback.pages
      | typeof educationAndExperience.pages
      | typeof educationAndExperienceFeedback.pages
      | typeof employmentAndPreferences.pages
      | typeof selfIdentificationFeedback.pages
      | typeof selfIdentification.pages
      | typeof overviewAndGoalsFeedback.pages
      | typeof overviewAndGoals.pages
      | typeof employmentAndPreferencesFeedback.pages
      | typeof placementAttachments.pages
      | typeof submissionAttachments.pages
    )[number]
  | typeof mailingAddressPage
  | typeof resumeYesNoPage
  | typeof experienceYesNoPage
)['elements'][number]

export type QuestionNames = Questions['name']
type GetQuestionByName<Q extends { readonly name: QuestionNames }, Name extends QuestionNames> = Q extends {
  readonly name: Name
}
  ? Q
  : never

export type QuestionType = Questions['type']
type GetAnswerType<Q extends Questions> = Q extends { readonly type: 'boolean' }
  ? boolean
  : Q extends { readonly type: 'checkbox' }
  ? [
      | GetChoices<Q['choices']>
      | (Q extends { readonly showOtherItem: true } ? 'other' : never)
      | (Q extends { readonly showNoneItem: true }
          ? Q extends { readonly noneText: string }
            ? Q['noneText']
            : 'None'
          : never),
    ]
  : Q extends { readonly type: 'radiogroup' }
  ? GetChoices<Q['choices']> | (Q extends { readonly showOtherItem: true } ? 'other' : never)
  : Q extends { readonly type: 'usa-address' }
  ? UsaAddress
  : Q['type'] extends 'file'
  ? FileQuestionValue[]
  : Q['type'] extends 'work-experience'
  ? WorkExperience[]
  : Q['type'] extends 'dob' | 'textarea'
  ? string
  : Q['type'] extends 'compensation'
  ? Compensation
  : never

type AnswerValues = {
  [K in QuestionNames]?: GetAnswerType<GetQuestionByName<Questions, K>>
}

type WithComment<Q> = Q extends { readonly showOtherItem: true } ? Q : never
type QuestionsWithComment = WithComment<Questions>
type AnswerComments = {
  [K in QuestionsWithComment['name'] as `${K}-Comment`]?: string
}

export type Answers = AnswerValues & AnswerComments

export type Typed<Q extends Questions, T extends QuestionType> = Q extends { readonly ['type']: T } ? Q : never
export type CheckboxQuestion = Typed<Questions, 'checkbox'>
export type CheckboxQuestionName = CheckboxQuestion['name']
export type RadioQuestion = Typed<Questions, 'radiogroup'>
export type RadioQuestionName = RadioQuestion['name']
export const welcomeSurvey = new SurveyModel(welcomeSurveyJson)

export function getCheckboxQuestion(name: CheckboxQuestionName) {
  return welcomeSurvey.getQuestionByName(name) as QuestionCheckboxModel | null
}

export function getRadioQuestion(name: RadioQuestionName) {
  return welcomeSurvey.getQuestionByName(name) as QuestionRadiogroupModel | null
}
