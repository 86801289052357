import React from 'react'
import { IconProps } from './IconProps'

export default function FileAttachment({
  width = 24,
  height = 24,
  style = {},
}: IconProps & { style?: React.CSSProperties }) {
  return (
    <svg width={width} height={height} style={style} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.4167 1.66666H12.6667C14.0668 1.66666 14.7669 1.66666 15.3016 1.93915C15.772 2.17883 16.1545 2.56128 16.3942 3.03169C16.6667 3.56647 16.6667 4.26653 16.6667 5.66666V14.3333C16.6667 15.7335 16.6667 16.4335 16.3942 16.9683C16.1545 17.4387 15.772 17.8212 15.3016 18.0608C14.7669 18.3333 14.0668 18.3333 12.6667 18.3333H7.33333C5.9332 18.3333 5.23314 18.3333 4.69836 18.0608C4.22795 17.8212 3.8455 17.4387 3.60582 16.9683C3.33333 16.4335 3.33333 15.7335 3.33333 14.3333V13.75M13.3333 10.8333H9.58333M13.3333 7.5H10.4167M13.3333 14.1667H6.66667M5 8.33333V3.75C5 3.05964 5.55964 2.5 6.25 2.5C6.94036 2.5 7.5 3.05964 7.5 3.75V8.33333C7.5 9.71404 6.38071 10.8333 5 10.8333C3.61929 10.8333 2.5 9.71404 2.5 8.33333V5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
