import { Question } from 'survey-core'

export default class UsaAddressGroupModel extends Question {
  static readonly TYPE = 'usa-address'

  // eslint-disable-next-line class-methods-use-this
  getType() {
    return UsaAddressGroupModel.TYPE
  }
}
