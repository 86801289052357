import { UsaAddress } from '../../common/survey/us-address'

export function removeBlanks(...strings: Array<string | null | undefined>): Array<string> {
  return strings.map((s) => s?.trim()).filter((s) => !!s) as string[]
}

export function formatAddress(address?: UsaAddress) {
  if (!address) return ''
  const stateAndZip = removeBlanks(address.stateName, address.zipCode).join(' ')
  return removeBlanks(address.addressLine1, address.addressLine2, address.city, stateAndZip).join(', ')
}

const DATE_FORMATTING: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' }

export function formatDate(date: string) {
  return new Date(date).toLocaleDateString('en-US', DATE_FORMATTING)
}
