import formats from '../formats'

export type WorkExperience = {
  position: string
  employer: string
  current: boolean
  startDate: string
  endDate: string
}

function checkPosition(value: string) {
  return !value.trim() ? 'Position is required.' : ''
}

function checkEmployer(value: string) {
  return !value?.trim() ? 'Employer is required.' : ''
}

function checkStartDate(value: string) {
  const trimmed = value?.trim()
  if (!trimmed) return 'Start Date is required.'
  if (!formats.parseMonthYear(trimmed).isValid()) return 'Enter a valid Start Date.'
  return ''
}

function checkEndDate(endDate: string, startDate: string, current: boolean): string {
  if (!current) {
    const endDateTrimmed = endDate?.trim()
    if (!endDateTrimmed) return 'End Date is required.'
    const endDateParsed = formats.parseMonthYear(endDate)
    if (!endDateParsed.isValid()) return 'Enter a valid End Date.'
    const startDateTrimmed = startDate?.trim()
    if (startDateTrimmed) {
      const startDateParsed = formats.parseMonthYear(startDate)
      if (startDateParsed.isValid()) {
        if (startDateParsed.isAfter(endDateParsed)) {
          return 'End Date cannot be before Start Date.'
        }
      }
    }
  }
  return ''
}

export default function validate(value: WorkExperience): Map<keyof WorkExperience, string> {
  const errors: [keyof WorkExperience, string][] = [
    ['position', checkPosition(value.position)],
    ['employer', checkEmployer(value.employer)],
    ['startDate', checkStartDate(value.startDate)],
    ['endDate', checkEndDate(value.endDate, value.startDate, value.current)],
  ]

  return errors.reduce((m, [field, message]) => {
    if (message) m.set(field, message)
    return m
  }, new Map<keyof WorkExperience, string>())
}
