import { Question } from 'survey-core'

export default class WorkExperienceModel extends Question {
  static readonly TYPE = 'work-experience'

  // eslint-disable-next-line class-methods-use-this
  getType() {
    return WorkExperienceModel.TYPE
  }

  get jobListTitle() {
    return this.getPropertyValue('jobListTitle')
  }

  set jobListTitle(val) {
    this.setPropertyValue('jobListTitle', val)
  }

  get firstJobTitle() {
    return this.getPropertyValue('firstJobTitle')
  }

  set firstJobTitle(val) {
    this.setPropertyValue('firstJobTitle', val)
  }

  get addJobTitle() {
    return this.getPropertyValue('addJobTitle')
  }

  set addJobTitle(val) {
    this.setPropertyValue('addJobTitle', val)
  }

  get editJobTitle() {
    return this.getPropertyValue('editJobTitle')
  }

  set editJobTitle(val) {
    this.setPropertyValue('editJobTitle', val)
  }
}
