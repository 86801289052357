import React from 'react'
import { InputBaseComponentProps } from '@mui/material'
import { IMaskInput } from 'react-imask'

export const ZIP_CODE_REGEX = /^\d{5}(:?-\d{4})?/

// eslint-disable-next-line react/display-name
const MaskedZipCodeInput = React.forwardRef<HTMLElement, InputBaseComponentProps>((props, ref) => (
  <IMaskInput inputRef={ref} {...props} mask="00000-0000" />
))

export default MaskedZipCodeInput
