import React, { PropsWithChildren } from 'react'
import { Typography } from '@mui/material'

export default function Label({ children }: PropsWithChildren) {
  return (
    <Typography variant="body2" color="secondary">
      {children}
    </Typography>
  )
}
