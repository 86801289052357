import React, { useContext, useMemo } from 'react'
import { Route, Routes, useMatch } from 'react-router-dom'
import CandidateProfileContext from '../../candidate-profile/context/CandidateProfileContext'
import JobMatching from './JobMatching'
import JobMatchingDetails from './JobMatchingDetails'
import JobPostingsContext, { JobPostingsContextValue } from '../context/JobPostingsContext'
import { usePortalApi } from '../../../api/PortalApiContext'

export default function JobMatchingContent() {
  const { candidate } = useContext(CandidateProfileContext)
  const api = usePortalApi()
  const match = useMatch(`/candidates/${candidate.dto.userId}/job-matching/:jobPostingId`)
  const jobPostingId = Number(match?.params.jobPostingId)
  const jobPostingsContext = useMemo(() => new JobPostingsContextValue(api), [api])

  if (!candidate) return null
  return (
    <JobPostingsContext.Provider value={jobPostingsContext}>
      <Routes>
        <Route path="/job-matching" element={<JobMatching />} />
        <Route path="/job-matching/:jobPostingId" element={<JobMatchingDetails jobPostingId={jobPostingId} />} />
      </Routes>
    </JobPostingsContext.Provider>
  )
}
