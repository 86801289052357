import React from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { Box } from '@mui/material'
import { observable } from 'mobx'
import BackToJob from '../../components/jobs/BackToJob'
import JobInformation from '../../components/jobs/JobInformation'
import { usePortalApi } from '../../api/PortalApiContext'
import PageContentContainer from '../../components/PageContentContainer'

export default function JobDetailsPage() {
  const { id = '-1' } = useParams()
  const jobPostingId = +id
  const portalApi = usePortalApi()
  const { data } = useQuery(['getJobPosting', jobPostingId], async () =>
    portalApi.jobs.getJobPosting(jobPostingId).then((r) => observable(r.data)),
  )

  return (
    <PageContentContainer>
      <BackToJob to="/jobs">Back to Jobs</BackToJob>
      {data && (
        <Box sx={{ display: 'flex', gap: '16px' }}>
          <Box sx={{ flex: 1, width: '100%' }}>
            <JobInformation job={data} />
          </Box>
        </Box>
      )}
    </PageContentContainer>
  )
}
