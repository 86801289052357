import { RenderLeafProps } from 'slate-react'

type Marks = Record<'bold' | 'italic' | 'underline', boolean | undefined>
export default function CustomLeaf({ attributes, leaf, children }: RenderLeafProps) {
  const l = leaf as unknown as Marks
  let node = <span {...attributes}>{children}</span>
  if (l.bold) node = <strong>{node}</strong>
  if (l.italic) node = <em>{node}</em>
  if (l.underline) node = <u>{node}</u>
  return node
}
