import { makeAutoObservable } from 'mobx'
import { createContext } from 'react'

import { CoachingSessionDto } from '../../_generated/portal-api'

export class CoachingSessions {
  coachingSessions: CoachingSessionDto[] = []

  constructor(public readonly candidateId: number) {
    makeAutoObservable(this, undefined, { autoBind: true })
  }
}

const CoachingSessionContext = createContext(new CoachingSessions(0))

export default CoachingSessionContext
