import { Autocomplete, AutocompleteRenderInputParams, Box, InputAdornment, Tooltip, Typography } from '@mui/material'
import TextField from '@mui/material/TextField'
import React, {
  forwardRef,
  HTMLAttributes,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { runInAction } from 'mobx'
import { Observer, observer } from 'mobx-react'
import { ListChildComponentProps, VariableSizeList } from 'react-window'
import { useNavigate } from 'react-router-dom'
import Search from '../../../common/icons/Search'
import Archive from '../../../common/icons/Archive'
import Update from '../../../common/icons/Update'
import Candidate from './context/candidate'
import highlight from '../../utils/highlight'
import CandidatesContext, { useCandidates } from './context/CandidatesContext'
import Certificate from '../../../common/icons/Certificate'
import { palette } from '../../../common/mui-theme'

const PADDING = 6
const ITEM_HEIGHT = 48
let today = new Date()

export function SearchInput(params: AutocompleteRenderInputParams) {
  const { InputProps, inputProps, ...other } = params
  return (
    <TextField
      {...other}
      placeholder="Search Candidates"
      InputProps={{
        size: 'small',
        style: {
          padding: '10px 39px 10px 12px',
        },
        ...InputProps,
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
      }}
      inputProps={{
        maxLength: 1000,
        ...inputProps,
      }}
    />
  )
}

function getCandidateName(candidate: Candidate | string) {
  if (typeof candidate === 'string') return candidate
  return candidate.fullName || candidate.dto.email
}

function SmallText({ children }: React.PropsWithChildren) {
  return (
    <Typography noWrap fontSize="12px" color="rgba(14, 20, 37, 0.4)">
      {children}
    </Typography>
  )
}

const OuterElementContext = React.createContext<
  HTMLAttributes<HTMLElement> & { setItemHeight: (index: number, h: number) => void }
>({
  setItemHeight: () => ITEM_HEIGHT,
})

type OptionType = [Candidate, HTMLAttributes<HTMLElement>]

const CandidateRow = observer(({ index, style, data }: ListChildComponentProps<OptionType[]>) => {
  const { search } = useContext(CandidatesContext)
  const { setItemHeight } = useContext(OuterElementContext)
  const rowRef = useRef<HTMLDivElement | null>(null)
  const [candidate, props] = data[index]
  const { top, ...other } = style
  const nameMatches = highlight(candidate.fullName, search.text)
  const emailMatches = highlight(candidate.dto.email, search.text)
  const email = emailMatches.map((m) => (m.highlight ? <b key={m.text}>{m.text}</b> : m.text))
  useEffect(() => {
    if (rowRef.current) setItemHeight(index, rowRef.current.scrollHeight + 8)
  }, [index, setItemHeight])
  return (
    <div ref={rowRef} style={{ top: (top as number) + PADDING, ...other }} {...props}>
      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        {candidate.hasTrainingInterest && (
          <Certificate
            style={{ color: palette.primary.main, marginRight: '16px', minHeight: '24px', minWidth: '24px' }}
          />
        )}
        <Box sx={{ flexGrow: 1, overflow: 'ellipsis' }}>
          <Box>
            <Typography fontSize="14px" lineHeight="24px">
              {candidate.fullName
                ? nameMatches.map((m) => (m.highlight ? <b key={m.text}>{m.text}</b> : m.text))
                : email}
            </Typography>
          </Box>
          {candidate.fullName && (
            <Box>
              <SmallText>{email}</SmallText>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          {candidate.archived && (
            <Box
              sx={{
                marginRight: '5px',
                color: 'rgba(14, 20, 37, 0.8)',
              }}
            >
              <Archive />
              {candidate.hasSnoozeTime && (
                <Tooltip title={`Snoozed until ${candidate.snoozeDate?.format('MM/DD/YYYY')}`} placement="bottom">
                  <Update />
                </Tooltip>
              )}
            </Box>
          )}
          <SmallText>
            {candidate.lastActivity?.toLocaleDateString('en-US', {
              month: 'short',
              day: 'numeric',
              year: today.getFullYear() === candidate.lastActivity?.getFullYear() ? undefined : 'numeric',
            })}
          </SmallText>
        </Box>
      </Box>
    </div>
  )
})

// eslint-disable-next-line react/display-name
const OuterElementType = forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext)
  const { setItemHeight, ...otherOuterProps } = outerProps
  return <div ref={ref} {...props} {...otherOuterProps} />
})

function none<T>(arg: T) {
  return arg
}

// eslint-disable-next-line react/display-name
const PopUpVirtualList = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>((props, ref) => {
  const { children, ...other } = props
  const items = children as unknown as Array<OptionType>
  const height = ITEM_HEIGHT * Math.min(8, items.length) + 3 * PADDING
  const heightsRef = useRef<number[]>([])
  const listRef = useRef<VariableSizeList<OptionType[]> | null>(null)
  const getItemHeight = useCallback((index: number) => heightsRef.current[index] || ITEM_HEIGHT, [])
  const setItemHeight = useCallback((index: number, h: number) => {
    listRef.current?.resetAfterIndex(Math.max(0, index - 1))
    heightsRef.current[index] = Math.max(ITEM_HEIGHT, h)
  }, [])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const ctx = useMemo(() => ({ ...other, setItemHeight }), [props, setItemHeight])
  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={ctx}>
        <VariableSizeList
          ref={listRef}
          itemData={items}
          itemCount={items.length}
          estimatedItemSize={ITEM_HEIGHT}
          itemSize={getItemHeight}
          height={height}
          outerElementType={OuterElementType}
          innerElementType="div"
          width="100%"
        >
          {CandidateRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  )
})

function CandidatesSearch() {
  const navigate = useNavigate()
  const { search } = useCandidates()
  const [selected, setSelected] = useState<Candidate | string | null>(null)

  useEffect(() => {
    today = new Date()
  }, [])

  const setSearchText = useCallback(
    (e: unknown, text: string) =>
      runInAction(() => {
        search.text = text
      }),
    [search],
  )

  const onValueChanged = useCallback(
    (e: unknown, value: string | Candidate | null) => {
      setSelected(value)
      if (value === null) {
        runInAction(() => {
          search.text = ''
        })
      } else if (typeof value === 'string') {
        runInAction(() => {
          search.text = value
        })
      } else {
        runInAction(() => {
          search.text = value.fullName || value.dto.email
        })
        navigate(`/candidates/${value.dto.userId}`)
      }
    },
    [navigate, search],
  )

  return (
    <Observer>
      {() => (
        <Autocomplete
          freeSolo
          disableListWrap
          renderInput={SearchInput}
          options={search.options}
          getOptionLabel={getCandidateName}
          ListboxComponent={PopUpVirtualList}
          renderOption={(props, option) => [option, props] as unknown as ReactNode}
          value={selected}
          onChange={onValueChanged}
          inputValue={search.text}
          onInputChange={setSearchText}
          filterOptions={none}
          sx={{ minWidth: '368px' }}
        />
      )}
    </Observer>
  )
}

export default observer(CandidatesSearch)
