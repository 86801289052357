import React, { useMemo } from 'react'
import { SurveyModel } from 'survey-core'
import { overviewAndGoals, overviewAndGoalsFeedback } from '../../../demo/survey-model'
import IntakeSurvey from './IntakeSurvey'
import { enableEditMode } from '../../utils/survey-utils'
import { intakeQuestionGroupList } from './context/IntakeContext'

export default function OverviewAndGoalsIntake() {
  const model = useMemo(() => {
    const newModel = new SurveyModel(overviewAndGoals)
    enableEditMode(newModel.getAllQuestions(), newModel)
    return newModel
  }, [])

  const feedback = useMemo(() => new SurveyModel(overviewAndGoalsFeedback), [])

  return <IntakeSurvey item={intakeQuestionGroupList[0]} model={model} feedback={feedback} />
}
