import { IconButton, ListItem, ListItemText, Typography } from '@mui/material'
import { useState } from 'react'
import dayjs from 'dayjs'
import { WorkExperience } from './validator'
import { colors } from '../../mui-theme'
import MuiWorkExperienceEditor from './MuiWorkExperienceEditor'
import Trash from '../../icons/Trash'
import css from './MuiWorkExperience.module.css'
import formats from '../formats'

type Props = {
  experience: WorkExperience
  onDelete: () => void
  onUpdate: () => void
}

export default function MuiWorkExperienceListItem({ experience, onDelete, onUpdate }: Props) {
  const [editMode, setEditMode] = useState(false)
  const parsedStart = formats.parseMonthYear(experience.startDate)
  const parsedEnd = experience.endDate ? formats.parseMonthYear(experience.endDate) : null
  const formattedStart = parsedStart.format('MMM YYYY')
  const formattedEnd = parsedEnd ? parsedEnd.format('MMM YYYY') : 'Present'
  const years = Math.abs(parsedStart.diff(parsedEnd || dayjs(), 'year'))
  return (
    <ListItem
      onClick={() => {
        if (!editMode) {
          setEditMode(true)
        }
      }}
      secondaryAction={
        !editMode && (
          <div className={css.secondaryActions}>
            <Typography variant="body2" color={colors.neutral['300']}>
              {formattedStart} - {formattedEnd} &middot; {years} yrs
            </Typography>
            <IconButton onClick={onDelete}>
              <Trash />
            </IconButton>
          </div>
        )
      }
    >
      {!editMode ? (
        <ListItemText
          primary={
            <Typography variant="subtitle1" color={colors.neutral['600']}>
              {experience.position}
            </Typography>
          }
          secondary={
            <Typography variant="body2" color={colors.neutral['400']}>
              {experience.employer}
            </Typography>
          }
        />
      ) : (
        <MuiWorkExperienceEditor
          onSave={(newValue) => {
            Object.assign(experience, newValue)
            setEditMode(false)
            onUpdate()
          }}
          onCancel={() => {
            setEditMode(false)
            onUpdate()
          }}
          experience={experience}
          showCancel
          editMode
        />
      )}
    </ListItem>
  )
}
