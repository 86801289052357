import React, { useCallback, useEffect, useState } from 'react'
import { FormControl, ListItemText, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material'
import { observer } from 'mobx-react'

import { useUpdateCandidateAssignee } from '../api/hooks/candidateHooks'
import { PortalUserDto } from '../_generated/portal-api'
import { usePortalUsers } from './portal-user/context/PortalUserContext'
import KeyboardArrowDown from '../../common/icons/KeyboardArrowDown'
import nop from '../../common/nop'

export const menuProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  PaperProps: {
    sx: {
      '& .MuiMenuItem-root': {
        fontWeight: '400',
      },
      '& .MuiMenuItem-root.Mui-selected': {
        backgroundColor: 'white',
        span: {
          fontWeight: '500',
        },
        '.dot': {
          color: '#C95000',
        },
      },
    },
  },
} as const

const menuSx = {
  height: '32px',
  backgroundColor: 'white',
  paddingRight: '8px',
} as const

function Dot() {
  return <Typography className="dot">{'\u2022'}</Typography>
}

export function FixedIcon() {
  return (
    <KeyboardArrowDown width={20} height={20} style={{ pointerEvents: 'none', position: 'absolute', right: '8px' }} />
  )
}

function AssignDropdown({ userId, assigneeId }: { userId: number; assigneeId?: string }) {
  const store = usePortalUsers()

  const [assignee, setAssignee] = useState(assigneeId || '')
  const assignCandidate = useUpdateCandidateAssignee(userId)

  useEffect(() => setAssignee(assigneeId || ''), [assigneeId])

  const handleChange = useCallback(
    (event: SelectChangeEvent) => {
      setAssignee(event.target.value)
      assignCandidate
        .mutateAsync({
          userId,
          assigneeUuid: event.target.value === '' ? undefined : event.target.value,
        })
        .then(nop)
    },
    [assignCandidate, userId],
  )

  return (
    <FormControl>
      <Select
        id="assignee"
        value={assignee}
        color="secondary"
        IconComponent={FixedIcon}
        displayEmpty
        renderValue={(value) => store.indexedAssignees.get(value)?.fullName || 'Not Assigned'}
        MenuProps={menuProps}
        sx={menuSx}
        onChange={handleChange}
      >
        <MenuItem value="">
          <ListItemText primary="Not Assigned" />
          {assignee === '' && <Dot />}
        </MenuItem>
        {store.assignees.map((variant: PortalUserDto) => (
          <MenuItem key={variant.userObjectId} value={variant.userObjectId}>
            <ListItemText primary={variant.fullName} />
            {assignee === variant.userObjectId && <Dot />}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default observer(AssignDropdown)
