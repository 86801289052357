import { makeAutoObservable } from 'mobx'
import { createContext } from 'react'
import JobPosting from '../context/job-posting'
import { Column } from '../../candidates/grid/CandidatesGridContext'
import { jobPostingsContextDefaultValue, JobPostingsContextValue } from '../context/JobPostingsContext'

export const Columns = {
  jobTitle: {} as Column<JobPosting>,
  careerArea: {
    width: '20%',
  } as Column<JobPosting>,
  shift: {
    width: '15%',
  } as Column<JobPosting>,
  type: {
    width: '15%',
  } as Column<JobPosting>,
  favorite: {
    width: '60px',
  } as Column<JobPosting>,
  location: {
    width: '20%',
  } as Column<JobPosting>,
}

export class JobPostingsGridContextValue {
  columns = Columns

  scroller: HTMLElement | Window | null = null

  constructor(public readonly ctx: JobPostingsContextValue, public readonly isAdmin: boolean) {
    makeAutoObservable(this, undefined, { autoBind: true })
  }

  get jobPostings() {
    if (this.ctx.filter === '#favourite') return this.ctx.store.jobPostings?.filter((jp) => jp.dto.isFavourite) || []
    return this.ctx.store.jobPostings || []
  }

  get isEmpty() {
    return !this.ctx.store.jobPostings?.length
  }

  setScroller(scroller: HTMLElement | Window | null = null) {
    this.scroller = scroller
  }
}

const JobPostingsGridContext = createContext<JobPostingsGridContextValue>(
  new JobPostingsGridContextValue(jobPostingsContextDefaultValue, false),
)

export default JobPostingsGridContext
