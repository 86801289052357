import React, { forwardRef } from 'react'
import { PaperProps, TableContainer } from '@mui/material'

// eslint-disable-next-line react/display-name
const TableScroller = forwardRef<HTMLDivElement, PaperProps>((props, ref) => {
  const { style = {}, ...rest } = props
  style.overflowX = 'hidden'
  style.borderRadius = '16px'
  style.border = `1px solid #e0e0e0`
  return (
    <TableContainer
      style={style}
      component="div"
      {...rest}
      ref={ref}
      sx={{ '& > div': { position: 'unset !important' }, '& tbody': { height: '1px' } }}
    />
  )
})

export default TableScroller
