import React, { ChangeEvent, useCallback, useMemo, useState } from 'react'
import { Box, Button, Checkbox, FormControlLabel, TextField, Typography, useEventCallback } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import dayjs, { Dayjs } from 'dayjs'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import css from './MuiWorkExperience.module.css'
import validate, { WorkExperience } from './validator'
import formats from '../formats'
import CustomDatePicker from '../custom-date-picker/CustomDatePicker'
import { CheckCircle } from '../../icons/CheckCircle'
import { colors } from '../../mui-theme'

function TextFieldWithError({
  label,
  value,
  error,
  onChange,
  disabled,
}: {
  label: string
  value: string
  error?: string
  onChange: React.ChangeEventHandler<HTMLInputElement>
  disabled?: boolean
}) {
  return (
    <TextField
      label={label}
      helperText={error || ' '}
      error={!!error}
      value={value}
      onChange={onChange}
      disabled={disabled}
      inputProps={{
        maxLength: 1000,
      }}
    />
  )
}

export default function MuiWorkExperienceEditor({
  onSave,
  onCancel,
  experience,
  showCancel,
  editMode,
}: {
  onSave: (newValue: WorkExperience) => void
  onCancel: () => void
  experience: WorkExperience
  showCancel: boolean
  editMode?: boolean
}) {
  const [errors, setErrors] = useState(new Map<keyof WorkExperience, string>())
  const hasErrors = errors.size > 0
  const [state, setState] = useState<WorkExperience>({ ...experience })

  const update = useCallback(
    <K extends keyof WorkExperience>(key: K, value: WorkExperience[K]) => {
      state[key] = value
      setState({ ...state })
      if (hasErrors) {
        setErrors(validate(state))
      }
    },
    [hasErrors, state],
  )

  const onSaveClick = useEventCallback(() => {
    const validationErrors = validate(state)
    if (validationErrors.size > 0) {
      setErrors(validationErrors)
    } else {
      // eslint-disable-next-line no-param-reassign
      onSave(state)
    }
  })

  const updatePosition = useCallback((e: ChangeEvent<HTMLInputElement>) => update('position', e.target.value), [update])
  const updateEmployer = useCallback((e: ChangeEvent<HTMLInputElement>) => update('employer', e.target.value), [update])
  const updateCurrent = useCallback((e: ChangeEvent<HTMLInputElement>) => update('current', e.target.checked), [update])
  const updateStart = useCallback(
    (v: Dayjs | null) => update('startDate', v?.format(formats.monthYear) || ''),
    [update],
  )
  const updateEnd = useCallback((v: Dayjs | null) => update('endDate', v?.format(formats.monthYear) || ''), [update])

  const minDate = useMemo(() => dayjs().subtract(150, 'year'), [])
  const maxDate = useMemo(() => dayjs().add(50, 'year'), [])

  const parseMonthYear = useCallback((v: string) => {
    if (!v) return null
    return formats.parseMonthYear(v)
  }, [])

  return (
    <div className={css.container}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Typography variant="h6" sx={{ fontSize: '18px', lineHeight: '28px' }}>
          {editMode ? 'Edit ' : 'Add '}job experience
        </Typography>
        <TextFieldWithError
          label="Position"
          error={errors.get('position')}
          value={state.position}
          onChange={updatePosition}
        />
        <TextFieldWithError
          label="Employer"
          error={errors.get('employer')}
          value={state.employer}
          onChange={updateEmployer}
        />
        <FormControlLabel
          control={<Checkbox checked={state.current} onChange={updateCurrent} />}
          label={
            <Typography variant="body1" color={colors.neutral['600']}>
              I am currently working in this role
            </Typography>
          }
          sx={{ alignItems: 'center', margin: '0, 0, 0, -12px', color: colors.neutral['600'] }}
        />
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px' }}>
          <CustomDatePicker
            label="Start Date"
            value={parseMonthYear(state.startDate)}
            onChange={updateStart}
            format={formats.monthYear}
            openTo="year"
            views={['year', 'month']}
            minDate={minDate}
            maxDate={maxDate}
            helperText={errors.has('startDate') ? `${errors.get('startDate')}` : ''}
            error={errors.has('startDate')}
            onAccept={updateStart}
          />
          <CustomDatePicker
            label="End Date"
            value={state.current ? null : parseMonthYear(state.endDate)}
            onChange={updateEnd}
            format={formats.monthYear}
            openTo="year"
            views={['year', 'month']}
            minDate={minDate}
            maxDate={maxDate}
            disabled={state.current}
            helperText={errors.has('endDate') ? `${errors.get('endDate')}` : ''}
            error={errors.has('endDate')}
            onAccept={updateEnd}
          />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px' }}>
          <Button onClick={onSaveClick} startIcon={<CheckCircle />}>
            Save
          </Button>
          {showCancel && (
            <Button color="secondary" onClick={onCancel}>
              Cancel
            </Button>
          )}
        </Box>
      </LocalizationProvider>
    </div>
  )
}
