import { observer } from 'mobx-react'
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material'
import { useQuery } from 'react-query'
import { ChevronRight } from '@mui/icons-material'
import dayjs from 'dayjs'
import { colors } from '../../../common/mui-theme'
import { usePortalApi } from '../../api/PortalApiContext'
import formats from '../../../common/survey/formats'

function useEmailSentList(coachingSessionId: number) {
  const api = usePortalApi()
  return useQuery(['coaching-session-email-list', coachingSessionId], () =>
    api.coachingSession.coachingSessionEmails(coachingSessionId).then((r) => r.data),
  )
}
const ellipsis = {
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  maxWidth: '30ch',
}

function EmailSentList({ coachingSessionId }: { coachingSessionId: number }) {
  const { data } = useEmailSentList(coachingSessionId)
  return data && data.length > 0 ? (
    <Box>
      <Typography variant="subtitle1" sx={{ color: colors.neutral[600], mb: '24px' }}>
        Sent
      </Typography>
      {data.map((email) => (
        <Accordion
          key={email.id}
          sx={{
            '&&': {
              minHeight: '72px',
              borderRadius: '8px',
              border: `1px solid ${colors.neutral[100]}`,
              '&::before': {
                display: 'none',
              },
              mb: '16px',
            },
          }}
        >
          <AccordionSummary
            expandIcon={<ChevronRight width={20} height={20} />}
            sx={{
              minHeight: '72px',
              borderRadius: '8px',
            }}
          >
            <Box>
              <Typography
                variant="subtitle2"
                sx={{
                  '.MuiAccordionSummary-content:not(.Mui-expanded) > div > &&.MuiTypography-root': {
                    ...ellipsis,
                  },
                }}
              >
                {email.subject}
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="body2"
                sx={{
                  ...ellipsis,
                  '.Mui-expanded > div > &&.MuiTypography-root': { display: 'none' },
                }}
              >
                &nbsp;-&nbsp;{email.body}
              </Typography>
            </Box>
            <Box sx={{ ml: 'auto' }}>
              <Typography
                variant="body2"
                sx={{
                  color: colors.neutral[300],
                  whiteSpace: 'nowrap',
                }}
              >
                {email.sentDate ? dayjs(email.sentDate).format(formats.monthNameDate) : ''}
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2" whiteSpace="pre-line">
              {email.body}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  ) : null
}
export default observer(EmailSentList)
