import React from 'react'
import { SurveyQuestionCheckbox } from 'survey-react-ui'
import MuiOtherValueItem from './items/MuiOtherValueItem'

class MuiCheckbox extends SurveyQuestionCheckbox {
  protected renderOther(): JSX.Element {
    const { cssClasses } = this.question
    return (
      <div className="form-group">
        <MuiOtherValueItem
          question={this.question}
          otherCss={cssClasses.other}
          cssClasses={cssClasses}
          isDisplayMode={this.isDisplayMode}
        />
      </div>
    )
  }
}

export default MuiCheckbox
