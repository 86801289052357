import React from 'react'

export default function AlertAction({ size = 40 }: { size?: number }) {
  return (
    <svg width={size} height={size} viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_11837_25358)">
        <rect x="2" y="1" width="56" height="56" rx="12" fill="white" />
        <path
          d="M30 24.3333V28.9999M30 33.6666H30.0116M41.6666 28.9999C41.6666 35.4432 36.4433 40.6666 30 40.6666C23.5567 40.6666 18.3333 35.4432 18.3333 28.9999C18.3333 22.5566 23.5567 17.3333 30 17.3333C36.4433 17.3333 41.6666 22.5566 41.6666 28.9999Z"
          stroke="#4A4E5B"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect x="2.5" y="1.5" width="55" height="55" rx="11.5" stroke="#EAEBEC" />
      </g>
      <defs>
        <filter
          id="filter0_d_11837_25358"
          x="0"
          y="0"
          width="60"
          height="60"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_11837_25358" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_11837_25358" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}
