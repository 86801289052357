import { forwardRef, useContext, useEffect, useRef, useState } from 'react'
import AutoSizer from 'react-virtualized-auto-sizer'
import { Box, Paper, Slide, Table, TableBody, TableBodyProps, TableHead, Typography } from '@mui/material'
import { TableComponents, TableVirtuoso, TableVirtuosoHandle } from 'react-virtuoso'
import { observer } from 'mobx-react'
import Search from '../../../../common/icons/Search'
import FullHeight from '../../FullHeight'
import Candidate from '../context/candidate'
import CandidatesGridHeader from './CandidatesGridHeader'
import CandidatesGridRow from './CandidatesGridRow'
import CandidatesGridContext from './CandidatesGridContext'

import css from './CandidatesGrid.module.css'
import { usePortalUsers } from '../../portal-user/context/PortalUserContext'
import CandidateContentBox from '../CandidateContentBox'
import CandidatesContext from '../context/CandidatesContext'
import FilterPanel from '../filter/FilterPanel'
import TableScroller from '../../TableScroller'

const Components: TableComponents<Candidate> = {
  Scroller: TableScroller,
  Table: (props) => (
    <Paper variant="outlined" sx={{ border: 'none' }}>
      <Table {...props} sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} className={css.rounded} />
    </Paper>
  ),
  TableHead,
  // eslint-disable-next-line react/display-name
  TableBody: forwardRef<HTMLTableSectionElement, TableBodyProps>((props, ref) => <TableBody {...props} ref={ref} />),
}

function renderRow(index: number /* , candidate: Candidate */) {
  return <CandidatesGridRow index={index} />
}

export const CandidatesGridVirtuoso = observer(({ width, height }: { width?: number; height?: number }) => {
  usePortalUsers()
  const { candidates, ctx, setVirtuoso } = useContext(CandidatesGridContext)
  const virtuoso = useRef<TableVirtuosoHandle>(null)
  setVirtuoso(virtuoso)
  if (candidates.length === 0 && ctx.search.text)
    return (
      <Box
        width={width}
        maxHeight={height}
        sx={{ display: 'flex', alignItems: 'start', justifyContent: 'center', padding: '32px' }}
      >
        <Typography sx={{ display: 'flex', alignItems: 'center' }}>
          <Search /> No candidates matched your search
        </Typography>
      </Box>
    )
  return (
    <TableVirtuoso
      data={candidates}
      ref={virtuoso}
      totalCount={candidates.length}
      style={{ width, maxHeight: height, height: 'auto' }}
      components={Components}
      fixedHeaderContent={CandidatesGridHeader}
      itemContent={renderRow}
    />
  )
})

function CandidatesGrid() {
  const { filter } = useContext(CandidatesContext)
  const [filterWidth, setFilterWidth] = useState(0)
  const timeOut = 300
  const boxRef = useRef(null)
  useEffect(() => {
    if (filter.opened) {
      setFilterWidth(296)
      return undefined
    }
    const timer = setTimeout(() => setFilterWidth(0), timeOut)
    return () => clearTimeout(timer)
  }, [filter.opened, setFilterWidth])
  return (
    <Box ref={boxRef} sx={{ display: 'flex', width: '100%', overflow: 'hidden' }}>
      <FullHeight
        bottomMargin={24}
        style={{
          display: 'flex',
          marginTop: '16px',
          width: `${filterWidth}px`,
          marginRight: `${filterWidth && 16}px`,
        }}
      >
        <AutoSizer>
          {({ height }) => (
            <Slide
              container={boxRef.current}
              direction="right"
              in={filter.opened}
              mountOnEnter
              unmountOnExit
              timeout={timeOut}
            >
              <FilterPanel height={height} width={filterWidth} />
            </Slide>
          )}
        </AutoSizer>
      </FullHeight>
      <FullHeight bottomMargin={24} style={{ display: 'flex', marginTop: '16px', flex: 1 }}>
        <CandidateContentBox>
          <AutoSizer>{({ height, width }) => <CandidatesGridVirtuoso width={width} height={height} />}</AutoSizer>
        </CandidateContentBox>
      </FullHeight>
    </Box>
  )
}

export default observer(CandidatesGrid)
