import { Button, ButtonProps } from '@mui/material'
import React from 'react'

export default function SmallButton({ sx, children, ...other }: ButtonProps) {
  return (
    <Button
      variant="text"
      sx={{ padding: '0 8px', minWidth: '0', minHeight: '0', fontSize: '14px', fontWeight: 'normal', ...sx }}
      {...other}
    >
      {children}
    </Button>
  )
}
