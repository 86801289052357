import { AccordionProps } from '@mui/material'
import React, { useContext, useState } from 'react'
import { observer } from 'mobx-react'
import IntakeContext, { IntakeQuestionGroupTitle } from '../survey/context/IntakeContext'
import CollapsableSurveyAccordion from './CollapsableSurveyAccordion'

export type AnswersAccordionProps = AccordionProps & {
  title: IntakeQuestionGroupTitle
}

function IntakeSurveyAccordion({ children, title, ...other }: AnswersAccordionProps) {
  const { intakeQuestionGroup } = useContext(IntakeContext)
  const group = intakeQuestionGroup.get(title)
  const [expanded, setExpanded] = useState(true)

  return (
    <CollapsableSurveyAccordion
      title={title}
      expanded={expanded}
      setExpanded={setExpanded}
      saving={!!group?.saving}
      allQuestionCount={group?.allQuestionCount || 0}
      answeredQuestionCount={group?.answeredQuestionCount || 0}
      {...other}
    >
      {children}
    </CollapsableSurveyAccordion>
  )
}

export default observer(IntakeSurveyAccordion)
