import { Question, Serializer } from 'survey-core'

export const OffenseTypes = ['Felony', 'Crime'] as const

export type OffenseType = (typeof OffenseTypes)[number]

export type Offense = {
  type: OffenseType
  year?: number
}

export type OffenceAnswer = Array<Offense>

export default class OffenseQuestionModel extends Question {
  static readonly TYPE = 'offense'

  // eslint-disable-next-line class-methods-use-this
  getType(): string {
    return OffenseQuestionModel.TYPE
  }

  get value() {
    return super.value as OffenceAnswer
  }

  set value(answer) {
    super.value = answer
  }

  isEmpty(): boolean {
    return !this.value
  }
}

Serializer.addClass(OffenseQuestionModel.TYPE, [], () => new OffenseQuestionModel(''), 'question')
