import { z } from 'zod'
import dayjs, { Dayjs } from 'dayjs'
import { JobSubmission } from '../../../_generated/portal-api'
import { MAX_TIMESTAMP } from '../tabs/placement-schema'
import { PHONE_REGEX } from '../../../../common/components/MaskedPhoneInput'
import { Eq, Ex, KeysByType } from '../../../../common/type-utils'

const dateSchema = z
  .instanceof(dayjs as unknown as typeof Dayjs, { message: 'Date is required' })
  .nullable()
  .refine((date) => !date || (date.isValid() && date.isBefore(MAX_TIMESTAMP)), { message: 'Date is invalid' })

const phoneSchema = z.string().trim().regex(PHONE_REGEX, 'Enter a valid phone number').or(z.literal('')).optional()
const emailSchema = z
  .string()
  .trim()
  .email('Please enter a valid e-mail address')
  .optional()
  .or(z.literal(''))
  .optional()

const submissionSchema = z.object({
  dateTime: dateSchema,
  sent: z.boolean(),
  contactFirstName: z.string().trim().optional(),
  contactLastName: z.string().trim().optional(),
  contactEmail: emailSchema,
  contactPhone: phoneSchema,
  includeCover: z.boolean(),
  includeRecruiterDetails: z.boolean(),
  recruiter: z.string().trim().optional(),
  recruiterEmail: emailSchema,
  recruiterPhone: phoneSchema,
  includeCandidateEmail: z.boolean(),
  candidateEmail: emailSchema,
  includeCandidatePhone: z.boolean(),
  candidatePhone: phoneSchema,
  includeCandidateEducation: z.boolean(),
  includeCandidateBackground: z.boolean(),
  includeCandidateEmployment: z.boolean(),
  additionalDetails: z.string().trim().optional(),
  exportCreated: z.boolean(),
})

export default submissionSchema

export type SubmissionSchema = z.infer<typeof submissionSchema>

const readonlyFields = [
  'id',
  'jobDetailsId',
  'updatedBy',
  'updatedAt',
] as const satisfies readonly (keyof JobSubmission)[]

// const allQuestions = Object.keys(submissionSchema.shape) as Array<keyof z.infer<typeof submissionSchema>>

type JobSubmissionEditable = Exclude<keyof JobSubmission, (typeof readonlyFields)[number]>
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type AssertSameShapes = Ex<Eq<JobSubmissionEditable, keyof SubmissionSchema>>

type FieldsToConvert = keyof {
  [K in JobSubmissionEditable as Eq<JobSubmission[K], SubmissionSchema[K]> extends true ? never : K]: K
}

const dateFields = ['dateTime'] as const satisfies readonly (KeysByType<SubmissionSchema, Dayjs | null> &
  FieldsToConvert)[]
export const dateFieldsSet = new Set(dateFields)
export type DateFields = (typeof dateFields)[number]
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type AssertDateFields = Ex<Eq<DateFields, KeysByType<SubmissionSchema, Dayjs | null>>>

const uuidFields = ['recruiter'] as const satisfies readonly (KeysByType<JobSubmission, string> & FieldsToConvert)[]
export type UuidFields = (typeof uuidFields)[number]
export const uuidFieldsSet = new Set(uuidFields)

export type SameFields = Exclude<JobSubmissionEditable, FieldsToConvert>

export type BoolFields = keyof {
  [K in SameFields as JobSubmission[K] extends boolean ? K : never]: boolean
}
