import dayjs from 'dayjs'

export default {
  monthNameDate: 'MMM D, YYYY',
  date: 'MM/DD/YYYY',
  dateTime: 'MM/DD/YYYY, h:mm A',
  monthYear: 'MM/YYYY',
  parseDate(stringValue: string) {
    return dayjs(stringValue, this.date, true)
  },
  parseMonthYear(stringValue: string) {
    return dayjs(stringValue, this.monthYear, true)
  },
} as const
