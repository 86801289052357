import React, { useContext } from 'react'
import { Route, Routes } from 'react-router-dom'
import JobDetails from './tabs/JobDetails'
import CandidateJobs from './tabs/CandidateJobs'
import CandidateProfileContext from '../candidate-profile/context/CandidateProfileContext'

export default function PlacementContent() {
  const { candidate } = useContext(CandidateProfileContext)
  if (!candidate) return null
  return (
    <Routes>
      <Route path="/placement" element={<CandidateJobs />} />
      <Route path="/placement/:jobId" element={<JobDetails />} />
    </Routes>
  )
}
