import React, { useEffect, useRef } from 'react'
import { SurveyNavigationButton } from 'survey-react-ui'
import Button from '@mui/material/Button'
import IconButton, { IconButtonProps } from '@mui/material/IconButton'
import { Action, ActionContainer, PageModel, SurveyModel } from 'survey-core'
import useMediaQuery from '@mui/material/useMediaQuery'
import { ArrowBackward } from '../../icons/Arrows'
import css from './MuiActionButton.module.css'
import nop from '../../nop'

const originalContainerId = 'original-sv-nav-prev'
const questionsTypesWithoutNext = new Set<string>(['boolean'])

function PreviousButton(props: IconButtonProps & { page: PageModel }) {
  const ref = useRef<HTMLElement>(null)
  const { children, page, ...other } = props
  const shouldBeOnTop = useMediaQuery('(max-width: 1024px)')
  useEffect(() => {
    const element = ref.current
    if (!element) return nop
    const parent = document.getElementsByClassName(shouldBeOnTop ? 'sd-body__page' : 'sd-question__header')
    if (parent.length <= 0) return nop
    const first = parent.item(0)
    if (!first) return nop
    element.parentElement?.removeChild(element)
    element.className =
      (shouldBeOnTop ? css.top : css.left) + (page.title && page.title.trim() ? '' : ` ${css.noTitle}`)
    first.prepend(element)
    return () => {
      first.removeChild(element)
      element.className = ''
      const original = document.getElementById(originalContainerId)
      if (original) {
        original.prepend(element)
      }
    }
  }, [page.id, page.title, shouldBeOnTop]) // page in dependencies due we have to re-render it in case page changed

  return (
    <nav ref={ref}>
      <IconButton {...other}>{children}</IconButton>
    </nav>
  )
}

class MuiActionButton extends SurveyNavigationButton {
  protected renderElement(): JSX.Element {
    const action: Action = this.props.item
    const survey = (action.owner as ActionContainer).locOwner as SurveyModel
    const page = survey.currentPage
    const isNextOrCompleteAction = action.id === 'sv-nav-next' || action.id === 'sv-nav-complete'
    const isSingleQuestion = page.elements.length === 1
    const hideNextOrCompleteAction =
      isNextOrCompleteAction && isSingleQuestion && questionsTypesWithoutNext.has(page.elements[0].getType())
    if (hideNextOrCompleteAction) {
      return <span />
    }

    if (action.id === 'sv-nav-prev') {
      return (
        <span id={originalContainerId}>
          <PreviousButton
            disabled={this.item.disabled}
            onMouseDown={this.item.data && this.item.data.mouseDown}
            onClick={this.item.action}
            title={this.item.getTooltip()}
            color="secondary"
            size="large"
            page={page}
          >
            <ArrowBackward />
          </PreviousButton>
        </span>
      )
    }

    return (
      <Button
        disabled={this.item.disabled}
        onMouseDown={this.item.data && this.item.data.mouseDown}
        onClick={this.item.action}
        title={this.item.getTooltip()}
        value={this.item.title}
        sx={{ flexGrow: 1 }}
      >
        {this.item.title}
      </Button>
    )
  }
}

export default MuiActionButton
