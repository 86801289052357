import { SurveyQuestionElementBase } from 'survey-react-ui'
import { TextField } from '@mui/material'
import React from 'react'
import TextModel from './TextModel'
import { palette } from '../mui-theme'

export default class MuiText extends SurveyQuestionElementBase {
  get question(): TextModel {
    return this.questionBase as TextModel
  }

  protected renderElement(): JSX.Element | null {
    const placeholder = this.questionBase.renderedPlaceholder
    return (
      <div className="form-group">
        <TextField
          fullWidth
          helperText={this.question.helperText}
          required={this.questionBase.isRequired}
          id={this.questionBase.inputId}
          className={this.questionBase.className}
          disabled={this.questionBase.isInputReadOnly}
          label={placeholder}
          value={this.questionBase.value || ''}
          onChange={(e) => {
            this.questionBase.value = e.target.value
            this.setState({ value: e.target.value }) // just to rerender
          }}
          aria-required={this.questionBase.a11y_input_ariaRequired}
          aria-label={this.questionBase.a11y_input_ariaLabel}
          aria-labelledby={this.questionBase.a11y_input_ariaLabelledBy}
          aria-invalid={this.questionBase.a11y_input_ariaInvalid}
          aria-describedby={this.questionBase.a11y_input_ariaDescribedBy}
          style={{ resize: this.questionBase.resizeStyle }}
          InputProps={{ readOnly: this.questionBase.isInputReadOnly }}
          inputProps={{
            maxLength: 1000,
          }}
          sx={{
            '&': {
              '.MuiFormHelperText-root': { color: palette.text.secondary, mb: '2px' },
            },
          }}
        />
      </div>
    )
  }
}
