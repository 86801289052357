import React from 'react'

export default function AlertSuccess({ size = 46 }: { size?: number }) {
  return (
    <svg width={size} height={size} viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="3" y="3" width="40" height="40" rx="20" fill="#D1F0E1" />
      <g clipPath="url(#clip0_13089_45319)">
        <path
          d="M19.2498 23.0001L21.7498 25.5001L26.7498 20.5001M31.3332 23.0001C31.3332 27.6025 27.6022 31.3334 22.9998 31.3334C18.3975 31.3334 14.6665 27.6025 14.6665 23.0001C14.6665 18.3977 18.3975 14.6667 22.9998 14.6667C27.6022 14.6667 31.3332 18.3977 31.3332 23.0001Z"
          stroke="#17B26A"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <rect x="3" y="3" width="40" height="40" rx="20" stroke="#E8F7F0" strokeWidth="6" />
      <defs>
        <clipPath id="clip0_13089_45319">
          <rect width="20" height="20" fill="white" transform="translate(13 13)" />
        </clipPath>
      </defs>
    </svg>
  )
}
