import { useCallback, useContext, useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, useEventCallback } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { useQueryClient } from 'react-query'
import { Dayjs } from 'dayjs'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import CustomDatePicker from '../../../../common/survey/custom-date-picker/CustomDatePicker'
import formats from '../../../../common/survey/formats'
import { DO_NOT_SNOOZE_TIME_ISO, editSnoozeSchema, getMinArchiveDate, hasSnoozeTime } from './index'
import { useArchiveCandidate } from '../../../api/hooks/candidateHooks'
import DialogCloseButton from '../../../../common/components/dialog/DialogCloseButton'
import CandidateProfileContext from '../../candidate-profile/context/CandidateProfileContext'

export default function CandidateEditSnoozeDialog({
  open,
  onClose,
}: {
  open: boolean
  onClose: (event: object) => void
}) {
  const { candidate } = useContext(CandidateProfileContext)
  const validSnoozeDate = candidate.archived && hasSnoozeTime(candidate.snoozeDate)
  const {
    setValue,
    handleSubmit,
    watch,
    formState: { errors, isSubmitted, isSubmitting },
  } = useForm<z.infer<typeof editSnoozeSchema>>({
    resolver: zodResolver(editSnoozeSchema),
    defaultValues: {
      snoozeDate: validSnoozeDate ? candidate.snoozeDate : null,
    },
  })
  const snoozeDate = watch('snoozeDate')

  const client = useQueryClient()
  const onSnoozeDateChange = useCallback(
    (data: Dayjs | null) => {
      setValue('snoozeDate', data, { shouldValidate: isSubmitted })
    },
    [isSubmitted, setValue],
  )

  const closeDialog = useCallback(() => {
    onClose(() => false)
  }, [onClose])

  const archiveCandidate = useArchiveCandidate(candidate.dto.userId)
  const onSubmit = useEventCallback(
    handleSubmit(async (data) => {
      const { snoozeDate: date } = data
      const parsedSnoozeDate = date ? date.startOf('day').toISOString() : DO_NOT_SNOOZE_TIME_ISO
      const parsedFormData = {
        reason: candidate.dto.archiveReason,
        note: candidate.dto.archiveNote,
        snoozeDate: parsedSnoozeDate,
      }

      await archiveCandidate.mutateAsync(parsedFormData)
      closeDialog()
      client.invalidateQueries(['candidateProfile', candidate.dto.userId]).then()
    }),
  )

  const [unsnoozing, setUnsoozing] = useState(false)
  const onUnsnooze = useEventCallback(() => {
    setUnsoozing(true)
    const parsedFormData = {
      reason: candidate.dto.archiveReason,
      note: candidate.dto.archiveNote,
      snoozeDate: DO_NOT_SNOOZE_TIME_ISO,
    }
    archiveCandidate.mutateAsync(parsedFormData).then(() => {
      setUnsoozing(false)
      closeDialog()
      client.invalidateQueries(['candidateProfile', candidate.dto.userId])
    })
  })

  return (
    <Dialog open={open} onClose={closeDialog}>
      <DialogTitle>
        Snooze Settings
        <DialogCloseButton onClick={closeDialog} />
      </DialogTitle>
      <DialogContent>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <CustomDatePicker
            label="Date"
            format={formats.date}
            views={['year', 'month', 'day']}
            minDate={getMinArchiveDate()}
            value={snoozeDate}
            onChange={onSnoozeDateChange}
            onAccept={onSnoozeDateChange}
            error={!!errors.snoozeDate?.message}
            helperText={errors.snoozeDate?.message || ' '}
          />
        </LocalizationProvider>
      </DialogContent>
      <DialogActions sx={{ flexDirection: 'column' }}>
        <Button onClick={onSubmit} fullWidth disabled={isSubmitting}>
          Confirm
        </Button>
        <Button color="secondary" onClick={closeDialog} fullWidth>
          Cancel
        </Button>
        <Button variant="text" disabled={!validSnoozeDate || unsnoozing} onClick={onUnsnooze} color="error" fullWidth>
          Unsnooze
        </Button>
      </DialogActions>
    </Dialog>
  )
}
