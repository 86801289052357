import React, { useEffect, useState } from 'react'
import { Box, LinearProgress } from '@mui/material'

export default function DelayedProgressBar() {
  const [progress, setProgress] = useState(false)
  useEffect(() => {
    if (!progress) {
      const timeout = setTimeout(() => setProgress(true), 1000)
      return () => clearTimeout(timeout)
    }
    return undefined
  }, [progress])
  return !progress ? null : (
    <Box>
      <LinearProgress />
    </Box>
  )
}
