import { makeAutoObservable } from 'mobx'
import CandidatesSearch from './candidates-search'
import Candidate from './candidate'

export default class ArchivedCandidatesFilter {
  selection = new Set<string>()

  constructor(
    public readonly search: CandidatesSearch,
    private readonly predicates: { filterFunctions: Array<(c: Candidate) => boolean> },
  ) {
    makeAutoObservable(this, undefined, { autoBind: true })
  }

  get predicate() {
    return (c: Candidate) => !!c.dto.archiveReason && this.selection.has(c.dto.archiveReason)
  }

  get count() {
    return this.selection.size
  }

  get otherPredicates() {
    return this.predicates.filterFunctions.filter((f) => f !== this.predicate)
  }

  get archived() {
    return this.search.result?.filter((c) => c.archived)
  }

  get otherResult() {
    const filters = this.otherPredicates
    if (filters.length === 0) return this.archived
    return this.archived?.filter((c) => !filters.some((f) => !f(c)))
  }

  get counts() {
    return (this.otherResult || [])
      .filter((c) => c.archived)
      .reduce((map, candidate) => {
        if (candidate.dto.archiveReason) {
          map.set(candidate.dto.archiveReason, (map.get(candidate.dto.archiveReason) || 0) + 1)
        }
        return map
      }, new Map<string, number>())
  }

  clear() {
    this.selection.clear()
  }

  get empty() {
    return this.count === 0
  }

  get result() {
    if (this.empty) {
      return this.search.empty ? this.search.result?.filter((c) => !c.archived) : this.search.result
    }
    return this.search.result?.filter((c) => c.archived)
  }
}
