import { makeAutoObservable } from 'mobx'
import { NoteDto } from '../../../_generated/portal-api'

export default class Note {
  constructor(public dto: NoteDto) {
    makeAutoObservable(this, undefined, { autoBind: true })
  }

  get updatedAt() {
    return this.dto.timeStamp ? new Date(this.dto.timeStamp) : new Date()
  }

  get isEmpty() {
    return !this.dto.title && !this.dto.content
  }
}
