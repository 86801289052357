import { Autocomplete, AutocompleteProps, ListItemText, MenuItem } from '@mui/material'
import React, { ReactNode } from 'react'
import Check from '../icons/Check'
import muiTheme from '../mui-theme'
import KeyboardArrowDown from '../icons/KeyboardArrowDown'

export default function CustomAutocomplete<
  T extends ReactNode,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
>(other: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>): JSX.Element {
  return (
    <Autocomplete
      popupIcon={<KeyboardArrowDown width={20} height={20} />}
      renderOption={(props, option, { selected }) => (
        <MenuItem {...props}>
          <ListItemText primary={option} />
          {selected && <Check style={{ color: muiTheme.palette.primary.main }} />}
        </MenuItem>
      )}
      {...other}
    />
  )
}
