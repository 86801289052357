import React, { forwardRef, useContext } from 'react'
import AutoSizer from 'react-virtualized-auto-sizer'
import { Box, Paper, Table, TableBody, TableBodyProps, TableHead, Typography } from '@mui/material'
import { TableComponents, TableVirtuoso } from 'react-virtuoso'
import { observer } from 'mobx-react'
import Search from '../../../../common/icons/Search'
import FullHeight from '../../FullHeight'
import JobPostingsGridContext from './JobPostingsGridContext'
import JobPostingsGridHeader from './JobPostingsGridHeader'
import JobPosting from '../context/job-posting'
import JobPostingsGridRow from './JobPostingsGridRow'
import css from './JobPostingsGrid.module.css'
import JobPostingsTableRow from './JobPostingsTableRow'
import Star from '../../../../common/icons/Star'
import { BookmarkCheck } from '../../../../common/icons/BookmarkCheck'
import TableScroller from '../../TableScroller'
import { useMoreAdminJobPostings, useMoreUserJobPostings } from '../context/JobPostingsContext'

const Components: TableComponents<JobPosting> = {
  // eslint-disable-next-line react/display-name
  Scroller: TableScroller,
  Table: (props) => (
    <Paper variant="outlined" sx={{ border: 'none' }}>
      <Table {...props} sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} className={css.rounded} />
    </Paper>
  ),
  TableRow: JobPostingsTableRow,
  TableHead,
  // eslint-disable-next-line react/display-name
  TableBody: forwardRef<HTMLTableSectionElement, TableBodyProps>((props, ref) => <TableBody {...props} ref={ref} />),
}

function renderRow(index: number, data: JobPosting) {
  return <JobPostingsGridRow jobPosting={data} />
}

const nothingFoundIconStyle = { marginRight: '5px' }

export const JobPostingsGridVirtuoso = observer(({ width, height }: { width?: number; height?: number }) => {
  const { jobPostings, ctx, isAdmin, setScroller } = useContext(JobPostingsGridContext)
  const moreJobPostings = isAdmin ? useMoreAdminJobPostings() : useMoreUserJobPostings()

  if (jobPostings.length === 0)
    return (
      <Box
        width={width}
        maxHeight={height}
        sx={{ display: 'flex', alignItems: 'start', justifyContent: 'center', padding: '32px' }}
      >
        <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center' }}>
          {ctx.filter === '#favourite' && (
            <>
              <BookmarkCheck style={nothingFoundIconStyle} /> No favorite jobs yet
            </>
          )}
          {ctx.filter === '#recommended' && (
            <>
              <Star bgOpacity={0} style={nothingFoundIconStyle} /> No recommended jobs found
            </>
          )}
          {(isAdmin || ctx.filter === '#all') && (
            <>
              <Search style={nothingFoundIconStyle} /> No jobs matched your search
            </>
          )}
        </Typography>
      </Box>
    )

  return (
    <TableVirtuoso
      data={jobPostings}
      totalCount={ctx.allCount}
      style={{ width, maxHeight: height, height: jobPostings.length < 50 ? 'auto' : height }}
      components={Components}
      fixedHeaderContent={JobPostingsGridHeader}
      itemContent={renderRow}
      endReached={moreJobPostings}
      scrollerRef={setScroller}
      initialTopMostItemIndex={ctx.gridItemIndex}
      overscan={900}
      defaultItemHeight={67}
      increaseViewportBy={{ top: 800, bottom: 300 }}
    />
  )
})

function JobPostingsGrid() {
  return (
    <Box sx={{ display: 'flex', width: '100%', overflow: 'hidden' }}>
      <FullHeight bottomMargin={24} style={{ display: 'flex', flex: 1 }}>
        <Box sx={{ display: 'flex', flex: '1' }}>
          <AutoSizer>{({ height, width }) => <JobPostingsGridVirtuoso width={width} height={height} />}</AutoSizer>
        </Box>
      </FullHeight>
    </Box>
  )
}

export default observer(JobPostingsGrid)
