import { Box, Typography } from '@mui/material'
import { observer, Observer } from 'mobx-react'
import React, { useContext, useMemo } from 'react'
import RightRail from '../../RightRail'
import RightRailContainer from '../../RightRailContainer'
import NoteListCard from '../../notes/NoteListCard'
import JobPostingsGridContext, { JobPostingsGridContextValue } from '../grid/JobPostingsGridContext'
import DelayedProgressBar from '../../DelayedProgressBar'
import JobPostingsGrid from '../grid/JobPostingsGrid'
import JobPostingsContext, { useUserJobPostings } from '../context/JobPostingsContext'
import JobMatchingRightRail from './JobMatchingFilterRightRail'
import CandidateProfileContext from '../../candidate-profile/context/CandidateProfileContext'
import JobsSearch from '../JobsSearch'

const JobPostingsGridTitle = observer(() => {
  const { filter } = useContext(JobPostingsContext)

  return (
    <Typography variant="h4">
      Job Matching -{' '}
      {(filter === '#favourite' && 'Favorites') || (filter === '#recommended' && 'Recommended Jobs') || 'All Jobs'}
    </Typography>
  )
})

function JobMatching() {
  const { filter, query } = useContext(JobPostingsContext)
  const { candidate } = useContext(CandidateProfileContext)

  const ctx = useUserJobPostings(query || '', filter || '#all', candidate.dto.userId)
  const gridCtx = useMemo(() => new JobPostingsGridContextValue(ctx, false), [ctx])

  return (
    <JobPostingsGridContext.Provider value={gridCtx}>
      <JobPostingsGridTitle />
      <Box sx={{ pt: '16px', pb: '24px' }}>
        <JobsSearch />
      </Box>
      <Box sx={{ display: 'flex', gap: '16px' }}>
        <Observer>
          {() =>
            ctx.store.isLoading ? (
              <DelayedProgressBar />
            ) : (
              <Box sx={{ flex: 1, width: '100%' }}>
                <JobPostingsGrid />
              </Box>
            )
          }
        </Observer>

        <RightRail>
          <RightRailContainer>
            <NoteListCard />
            <JobMatchingRightRail />
          </RightRailContainer>
        </RightRail>
      </Box>
    </JobPostingsGridContext.Provider>
  )
}

export default observer(JobMatching)
