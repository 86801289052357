import { makeAutoObservable } from 'mobx'
import { createContext, useMemo } from 'react'
import CandidateProfile from '../../candidates/profile/candidate-profile'
import { CandidateProfileDto } from '../../../_generated/portal-api'

export class CandidateProfileContextClass {
  constructor(public readonly candidate: CandidateProfile) {
    makeAutoObservable(this, undefined, { autoBind: true })
  }
}

export function useCandidateProfileContextValue(candidate: CandidateProfile) {
  return useMemo(() => new CandidateProfileContextClass(candidate), [candidate])
}

const CandidateProfileContext = createContext(
  new CandidateProfileContextClass(
    new CandidateProfile({
      userId: 0,
      firstName: '',
      lastName: '',
      email: '',
      appliedDate: '',
      workflowStateId: 0,
    } as CandidateProfileDto),
  ),
)

export default CandidateProfileContext
