import { makeAutoObservable, ObservableMap } from 'mobx'
import JobPosting from './job-posting'
import { JobPostingDto } from '../../../_generated/portal-api'

export default class JobPostingStore {
  /**
   * JobPosting indexed by id
   */
  indexed: ObservableMap<number, JobPosting> = new ObservableMap<number, JobPosting>()

  jobPostings: JobPosting[] | null = null

  chipCount = 0

  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true })
  }

  get isLoading() {
    return this.jobPostings === null
  }

  get count() {
    return this.jobPostings !== null ? this.jobPostings.length : 0
  }

  init(jobPostings: Array<JobPostingDto>) {
    // initial load
    const observables = jobPostings.map((dto) => new JobPosting(dto))
    observables.forEach((jobPosting) => this.indexed.set(jobPosting.dto.id, jobPosting))
    this.jobPostings = observables
  }

  update(jobPostings: Array<JobPostingDto>) {
    if (jobPostings.length === 0) {
      if (this.jobPostings === null) {
        this.jobPostings = []
      }
      return
    }

    if (this.jobPostings === null) {
      // initial load
      const observables = jobPostings.map((dto) => new JobPosting(dto))
      observables.forEach((jobPosting) => this.indexed.set(jobPosting.dto.id, jobPosting))
      this.jobPostings = observables
      return
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const dto of jobPostings) {
      const existing = this.indexed.get(dto.id)

      if (existing) {
        existing.assign(dto)
      } else {
        const jobPosting = new JobPosting(dto)
        this.indexed.set(dto.id, jobPosting)
        this.jobPostings.push(jobPosting)
      }
    }
  }

  updateChipCount(count: number) {
    this.chipCount = count
  }
}
