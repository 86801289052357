import React from 'react'

export default function Calendar() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="calendar">
        <path
          id="Fill"
          opacity="0.12"
          d="M2.5 7.33331C2.5 5.93318 2.5 5.23312 2.77248 4.69834C3.01217 4.22793 3.39462 3.84548 3.86502 3.6058C4.3998 3.33331 5.09987 3.33331 6.5 3.33331H13.5C14.9001 3.33331 15.6002 3.33331 16.135 3.6058C16.6054 3.84548 16.9878 4.22793 17.2275 4.69834C17.5 5.23312 17.5 5.93318 17.5 7.33331V8.33331H2.5V7.33331Z"
          fill="currentColor"
        />
        <path
          id="Icon"
          d="M17.5 8.33335H2.5M13.3333 1.66669V5.00002M6.66667 1.66669V5.00002M6.5 18.3334H13.5C14.9001 18.3334 15.6002 18.3334 16.135 18.0609C16.6054 17.8212 16.9878 17.4387 17.2275 16.9683C17.5 16.4336 17.5 15.7335 17.5 14.3334V7.33335C17.5 5.93322 17.5 5.23316 17.2275 4.69838C16.9878 4.22797 16.6054 3.84552 16.135 3.60584C15.6002 3.33335 14.9001 3.33335 13.5 3.33335H6.5C5.09987 3.33335 4.3998 3.33335 3.86502 3.60584C3.39462 3.84552 3.01217 4.22797 2.77248 4.69838C2.5 5.23316 2.5 5.93322 2.5 7.33335V14.3334C2.5 15.7335 2.5 16.4336 2.77248 16.9683C3.01217 17.4387 3.39462 17.8212 3.86502 18.0609C4.3998 18.3334 5.09987 18.3334 6.5 18.3334Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  )
}
