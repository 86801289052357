import { Chip, TableCell, TableRow } from '@mui/material'
import { PropsWithChildren, useCallback, useContext } from 'react'
import IconButton from '@mui/material/IconButton'
import { runInAction } from 'mobx'
import { Observer, observer } from 'mobx-react'
import classNames from 'classnames'
import { ArrowDownward, ArrowUpward } from '../../../../common/icons/Arrows'

import Candidate from '../context/candidate'
import CandidatesGridContext, { Column } from './CandidatesGridContext'
import css from './CandidatesGrid.module.css'
import { useFeatureFlag } from '../../../feature-flags/FeatureFlagsContext'
import tableHeaderSx from '../../tableHeaderSx'

const SortableHeader = observer(
  ({
    children,
    getColumn,
    className,
  }: PropsWithChildren<{ getColumn: () => Column<Candidate>; className?: string }>) => {
    const column = getColumn()
    const ctx = useContext(CandidatesGridContext)
    const { sort } = ctx
    const onClick = useCallback(
      () =>
        runInAction(() => {
          if (sort === column.sort) {
            sort.desc = !sort.desc
          } else if (column.sort) {
            ctx.sort = column.sort
          }
        }),
      [column.sort, ctx, sort],
    )
    const current = sort === column.sort
    const arrowStyle = { opacity: current ? '1' : '0.5' }
    return (
      <TableCell className={className} width={column.width || undefined}>
        {children}
        {column.sort && (
          <IconButton onClick={onClick} className={classNames(css.sortable, { [css.current]: current })}>
            {column.sort.desc ? <ArrowDownward style={arrowStyle} /> : <ArrowUpward style={arrowStyle} />}
          </IconButton>
        )}
      </TableCell>
    )
  },
)

export default function CandidatesGridHeader() {
  const excludeAssignee = useFeatureFlag('EXCLUDE_ASSIGNEE_COLUMN')
  const { columns, candidates } = useContext(CandidatesGridContext)
  return (
    <TableRow sx={tableHeaderSx}>
      <SortableHeader getColumn={() => columns.fullName}>
        <b>Candidate</b>
        <Observer>{() => <Chip label={candidates.length} size="small" sx={{ margin: '0 8px' }} />}</Observer>
      </SortableHeader>
      {!excludeAssignee && (
        <SortableHeader getColumn={() => columns.staffAssignee}>
          <b>Staff Assignee</b>
        </SortableHeader>
      )}
      <SortableHeader getColumn={() => columns.experience}>
        <b>Experience</b>
      </SortableHeader>
      <SortableHeader getColumn={() => columns.appliedDate}>
        <b>Applied</b>
      </SortableHeader>
      <SortableHeader getColumn={() => columns.lastActivityDate}>
        <b>Last Activity</b>
      </SortableHeader>
    </TableRow>
  )
}
