import React from 'react'
import { SurveyQuestionCommentItem } from 'survey-react-ui'
import TextField from '@mui/material/TextField'

type CommentEventTarget = HTMLTextAreaElement | HTMLInputElement
type CommentEvent = React.ChangeEvent<CommentEventTarget> | React.FocusEvent<CommentEventTarget>

const MAX_TEXT_LENGTH = 500

export default class MuiCommentItem extends SurveyQuestionCommentItem {
  protected renderElement(): JSX.Element {
    const { question } = this.props
    const className = this.props.otherCss || this.cssClasses.comment
    const handleOnChange = (event: CommentEvent) => {
      this.setState({ comment: event.target.value })
      this.onCommentChange(event)
    }
    const questionComment = this.getComment()
    let stateComment: string | null | undefined = this.state?.comment
    if (stateComment && stateComment.trim() !== questionComment) {
      stateComment = questionComment
    }
    const comment = stateComment || questionComment || ''

    if (question.isReadOnlyRenderDiv()) {
      return <div>{comment}</div>
    }

    const hasError = question.errors.some((e: { text: string }) => e.text === question.otherErrorText)

    return (
      <TextField
        id={this.getId()}
        multiline
        className={className}
        value={comment}
        disabled={this.isDisplayMode}
        inputProps={{ maxLength: Math.min(question.getOthersMaxLength() || MAX_TEXT_LENGTH, MAX_TEXT_LENGTH) }}
        label={this.getPlaceholder() || question.locTitle.renderedHtml}
        onChange={handleOnChange}
        onBlur={(e) => {
          this.onCommentChange(e)
          handleOnChange(e)
        }}
        onInput={(e) => this.onCommentInput(e)}
        required={question.isRequired}
        minRows={5}
        error={hasError}
        sx={{
          boxShadow: 'none',
        }}
      />
    )
  }
}
