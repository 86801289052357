import { forwardRef, MouseEventHandler, ReactNode } from 'react'
import { Card, CardActions, CardContent, Typography } from '@mui/material'
import { colors } from '../../../common/mui-theme'

type InnerCardProps = {
  title: ReactNode
  children: ReactNode
  actions?: ReactNode
  onClick: MouseEventHandler
}

// eslint-disable-next-line react/display-name
const InnerCard = forwardRef<HTMLDivElement, InnerCardProps>(({ title, children, actions, onClick }, ref) => (
  <Card
    ref={ref}
    sx={{
      backgroundColor: colors.base.white,
      maxHeight: '198px',
      border: 'none',
      mb: '8px',
      display: 'flex',
      flexFlow: 'column',
    }}
  >
    <CardContent
      sx={{
        padding: '24px 16px',
        cursor: 'pointer',
        flexGrow: 1,
        WebkitLineClamp: '5',
        WebkitBoxOrient: 'vertical',
        display: '-webkit-box',
        overflow: 'hidden',
      }}
      onClick={onClick}
    >
      <Typography
        variant="overline"
        color={colors.neutral['300']}
        sx={{
          pb: '0px',
          overflowWrap: 'anywhere',
          WebkitLineClamp: '2',
          WebkitBoxOrient: 'vertical',
          display: '-webkit-box',
          overflow: 'hidden',
        }}
      >
        {title}
      </Typography>
      <Typography
        variant="body2"
        component="div"
        sx={{
          overflowWrap: 'anywhere',
          WebkitLineClamp: '3',
          WebkitBoxOrient: 'vertical',
          display: '-webkit-box',
          overflow: 'hidden',
          P: { marginTop: '8px', marginBottom: '0px' },
        }}
      >
        {children}
      </Typography>
    </CardContent>
    <CardActions sx={{ px: '16px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
      {actions}
    </CardActions>
  </Card>
))

export default InnerCard
