import parse from 'autosuggest-highlight/parse'
import removeAccents from 'remove-accents'

const specialCharsRegex = /[.*+?^${}()|[\]\\]/g

const emptyArray = [] as [number, number][]
const cache = {
  query: '',
  regex: /./,
}

function escapeRegExQuery(query: string) {
  return query.replace(specialCharsRegex, '\\$&')
}

export function getRegEx(query: string) {
  return new RegExp(escapeRegExQuery(removeAccents(query)), 'i')
}

function match(text: string, query: string): [number, number][] {
  if (query === '') return emptyArray
  if (cache.query !== query) {
    cache.query = query
    cache.regex = getRegEx(query)
  }
  const index = text.search(cache.regex)
  if (index === -1) return emptyArray
  return [[index, index + query.length]]
}

export default function highlight(text: string, query: string) {
  return parse(text, match(text, query))
}

export function limited<T>(filter: ((value: T, index: number, arr: T[]) => boolean) | null, limit: number) {
  if (!filter) return (value: T, index: number) => index < limit

  let currentLimit = 0
  return (value: T, index: number, arr: T[]) => {
    if (currentLimit >= limit) return false
    const result = filter(value, index, arr)
    if (result) currentLimit++
    return result
  }
}
