import React from 'react'
import { IconProps } from './IconProps'

export default function Check({ width = 24, height = 24, style }: IconProps) {
  return (
    <svg width={width} height={height} style={style} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20 6L9 17L4 12" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
