export default function Trash() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 16 18" fill="none">
      <path
        d="M11.3333 4.00008V3.33341C11.3333 2.39999 11.3333 1.93328 11.1517 1.57676C10.9919 1.26316 10.7369 1.00819 10.4233 0.848404C10.0668 0.666748 9.60009 0.666748 8.66667 0.666748H7.33333C6.39991 0.666748 5.9332 0.666748 5.57668 0.848404C5.26308 1.00819 5.00811 1.26316 4.84832 1.57676C4.66667 1.93328 4.66667 2.39999 4.66667 3.33341V4.00008M6.33333 8.58342V12.7501M9.66667 8.58342V12.7501M0.5 4.00008H15.5M13.8333 4.00008V13.3334C13.8333 14.7335 13.8333 15.4336 13.5608 15.9684C13.3212 16.4388 12.9387 16.8212 12.4683 17.0609C11.9335 17.3334 11.2335 17.3334 9.83333 17.3334H6.16667C4.76654 17.3334 4.06647 17.3334 3.53169 17.0609C3.06129 16.8212 2.67883 16.4388 2.43915 15.9684C2.16667 15.4336 2.16667 14.7335 2.16667 13.3334V4.00008"
        stroke="#868992"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
