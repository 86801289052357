import React from 'react'

interface FileProps {
  width?: number
  height?: number
  color?: string
  backgroundColor?: string
  highlightColor?: string
}

export default function File({ width = 36, height = 36, color, backgroundColor, highlightColor }: FileProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="2" y="2" width="32" height="32" rx="16" fill={backgroundColor} />
      <path
        d="M19.3334 11.513V14.2667C19.3334 14.6401 19.3334 14.8268 19.406 14.9694C19.4699 15.0948 19.5719 15.1968 19.6974 15.2607C19.84 15.3334 20.0267 15.3334 20.4 15.3334H23.1537M23.3334 16.6589V21.4667C23.3334 22.5868 23.3334 23.1469 23.1154 23.5747C22.9236 23.951 22.6177 24.257 22.2413 24.4487C21.8135 24.6667 21.2535 24.6667 20.1334 24.6667H15.8667C14.7466 24.6667 14.1865 24.6667 13.7587 24.4487C13.3824 24.257 13.0764 23.951 12.8847 23.5747C12.6667 23.1469 12.6667 22.5868 12.6667 21.4667V14.5334C12.6667 13.4133 12.6667 12.8532 12.8847 12.4254C13.0764 12.0491 13.3824 11.7431 13.7587 11.5514C14.1865 11.3334 14.7466 11.3334 15.8667 11.3334H18.0079C18.4971 11.3334 18.7416 11.3334 18.9718 11.3886C19.1759 11.4376 19.371 11.5184 19.5499 11.6281C19.7518 11.7518 19.9247 11.9247 20.2706 12.2706L22.3961 14.3961C22.742 14.742 22.9149 14.915 23.0386 15.1168C23.1483 15.2957 23.2291 15.4908 23.2781 15.6949C23.3334 15.9251 23.3334 16.1697 23.3334 16.6589Z"
        stroke={color}
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x="2" y="2" width="32" height="32" rx="16" stroke={highlightColor} strokeWidth="4" />
    </svg>
  )
}
