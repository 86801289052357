import React, { useContext } from 'react'
import { Box } from '@mui/material'
import { observer } from 'mobx-react'
import NoteListCard from '../notes/NoteListCard'
// import JobMatch from './JobMatch'
import PlacementContent from './PlacementContent'
import PlacementRightRail from './PlacementRightRail'
import PlacementContext from './context/PlacementContext'
import RightRail from '../RightRail'
import BackToJob from '../jobs/BackToJob'
import { useSelectedJob } from '../../api/hooks/placementHooks'
import RightRailContainer from '../RightRailContainer'
import CandidateProfileContext from '../candidate-profile/context/CandidateProfileContext'
import TabContentContainer from '../TabContentContainer'

function Placement() {
  const { candidate } = useContext(CandidateProfileContext)
  const { loading } = useContext(PlacementContext)
  const { job } = useSelectedJob()

  return (
    <>
      {job && <BackToJob to={`/candidates/${candidate.dto.userId}/placement`} />}
      <TabContentContainer>
        {/* <JobMatch /> */}
        <Box sx={{ flex: 1, width: '100%' }}>{!loading && <PlacementContent />}</Box>
        <RightRail>
          <RightRailContainer>
            <NoteListCard />
            {!loading && <PlacementRightRail />}
          </RightRailContainer>
        </RightRail>
      </TabContentContainer>
    </>
  )
}

export default observer(Placement)
