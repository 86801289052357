import React, { useCallback, useContext, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Checkbox,
  debounce,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useEventCallback,
} from '@mui/material'
import { z } from 'zod'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Observer, observer } from 'mobx-react'
import { action, runInAction } from 'mobx'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs, { Dayjs } from 'dayjs'
import MaskedPhoneInput from '../../../../common/components/MaskedPhoneInput'
import CustomAutocomplete from '../../../../common/select/CustomAutocomplete'
import { stateNames, states } from '../../../../common/survey/us-address'
import MaskedZipCodeInput from '../../../../common/components/MaskedZipCodeInput'
import { colors } from '../../../../common/mui-theme'
import Check from '../../../../common/icons/Check'
import Close from '../../../../common/icons/Close'
import MinimizedView from './MinimizedView'
import MinimizableSurveyAccordion from '../../survey-answers/MinimizableSurveyAccordion'
import AccordionHeaderBadge from '../../AccordionHeaderBadge'
import { JobDetailsDto, JobDto, Placement } from '../../../_generated/portal-api'
import AmountInput from '../../../../common/components/AmountInput'
import { usePortalApi } from '../../../api/PortalApiContext'
import InnerNote from '../../InnerNote'
import placementSchema, { MAX_TIMESTAMP } from './placement-schema'
import CustomDatePicker from '../../../../common/survey/custom-date-picker/CustomDatePicker'
import formats from '../../../../common/survey/formats'
import { Eq, Ex, KeysByType, WithRequired } from '../../../../common/type-utils'
import PlacementUploads from './PlacementUploads'
import { PLACE_CANDIDATE_ANCHOR, useInvalidateActivityLog } from '../../../api/hooks/placementHooks'
import PlacementContext from '../context/PlacementContext'
import { notEnteredYet } from '../../titles'

type PlacementSchema = z.infer<typeof placementSchema>

const readonlyFields = [
  'id',
  'jobDetailsId',
  'updatedBy',
  'updatedAt',
  'completed',
] as const satisfies readonly (keyof Placement)[]

type EditableFields = Exclude<keyof Placement, (typeof readonlyFields)[number]>
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type AssertSameShapes = Ex<Eq<EditableFields, keyof PlacementSchema>>

type FieldsToConvert = keyof {
  [K in EditableFields as Eq<Placement[K], PlacementSchema[K] | undefined> extends true ? never : K]: K
}
type SameFields = Exclude<EditableFields, FieldsToConvert>

const dateFields = ['startDate'] as const satisfies readonly (KeysByType<PlacementSchema, Dayjs | null> &
  FieldsToConvert)[]
const dateFieldsSet = new Set(dateFields)
type DateFields = (typeof dateFields)[number]
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type AssertDateFields = Ex<Eq<DateFields, KeysByType<PlacementSchema, Dayjs | null>>>
const numFields = ['salary'] as const satisfies readonly (KeysByType<PlacementSchema, string> & FieldsToConvert)[]
const numFieldsSet = new Set(numFields)
type NumFields = (typeof numFields)[number]
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type AssertNumFields = Ex<Eq<NumFields, KeysByType<Placement, number | undefined> & keyof PlacementSchema>>

type BoolFields = KeysByType<PlacementSchema, boolean | undefined>

const allQuestions = Object.keys(placementSchema.shape) as Array<keyof PlacementSchema>
const allQuestionCount = allQuestions.length - 4
const flexContainerStyles = {
  display: 'flex',
  gap: '16px',
}

// const controlledFiledOptions = { shouldValidate: true, shouldDirty: true }
function getDefaultValues(placement: Placement): PlacementSchema {
  return runInAction(() => {
    const { startDate, salary, ...p } = placement
    return {
      startDate: startDate ? dayjs(startDate) : null,
      salary: Number.isNaN(salary) || salary === null ? '' : `${salary}`,
      additionalDetails: p.additionalDetails || '',
      title: p.title || '',
      addressLine1: p.addressLine1 || '',
      addressLine2: p.addressLine2 || '',
      city: p.city || '',
      zip: p.zip || '',
      careerArea: p.careerArea || '',
      state: p.state || null,
      employer: p.employer || '',
      placed: p.placed,
      shift: p.shift || '',
      archiveAllOther: p.archiveAllOther || false,
      notPlacedReason: p.notPlacedReason || '',
      supervisorDirectPhone: p.supervisorDirectPhone || '',
      supervisorEmail: p.supervisorEmail || '',
      supervisorFirstName: p.supervisorFirstName || '',
      supervisorLastName: p.supervisorLastName || '',
      supervisorOfficePhone: p.supervisorOfficePhone || '',
    }
  })
}

function getStartDateString(date: Dayjs | null) {
  if (!date || !date.isValid()) return null
  const diff = dayjs().diff(date, 'day', true)
  const days = Math.abs(Math.floor(diff))
  if (days === 0) return `${date.format(formats.date)}, today`
  if (days === 1) return `${date.format(formats.date)}, 1 day ${diff > 0 ? 'since' : 'until'} start`
  return `${date.format(formats.date)}, ${days} days ${diff > 0 ? 'since' : 'until'} start`
}

function PlaceCandidateAccordion({ job }: { job: WithRequired<JobDto, 'details'> }) {
  const { placement } = job.details as Required<JobDetailsDto>
  const { jobs } = useContext(PlacementContext)
  const api = usePortalApi()
  const [saving, setSaving] = useState(false)
  const invalidateActivityLog = useInvalidateActivityLog()
  const [expanded, setExpanded] = useState(true)
  const expand = useCallback(() => setExpanded(true), [])
  const collapse = useCallback(() => setExpanded(false), [])
  const {
    control,
    register,
    handleSubmit,
    clearErrors,
    formState: { errors, isSubmitting },
    getFieldState,
    getValues,
    watch,
  } = useForm<z.infer<typeof placementSchema>>({
    resolver: zodResolver(placementSchema),
    mode: 'all',
    defaultValues: getDefaultValues(placement),
  })

  const [state, setState] = useState(placement.state || '')
  const [submitting, setSubmitting] = useState(false)

  const onNotPlaced = useEventCallback(() => {
    setSubmitting(true)
    api.jobs.placeJob({ jobId: placement.jobDetailsId, placed: false }).then(collapse)
    api.jobs
      .archiveJob(placement.jobDetailsId)
      .then(
        action(() => {
          placement.completed = true
          // eslint-disable-next-line no-param-reassign
          job.details.archived = true
        }),
      )
      .then(() => invalidateActivityLog(placement.jobDetailsId)) // it is better to navigate to the job list instead after archiving
      .finally(() => setSubmitting(false))
  })

  const onPlaced = useEventCallback(
    handleSubmit(async (data) => {
      if (!data.placed) {
        // should not happen
        return
      }
      await api.jobs.placeJob({ jobId: placement.jobDetailsId, placed: true })
      runInAction(() => {
        // eslint-disable-next-line no-param-reassign
        placement.completed = true
      })
      collapse()
      if (data.archiveAllOther) {
        await api.jobs.archiveOtherUserJobs(placement.jobDetailsId)
        runInAction(() => {
          jobs
            ?.filter((j) => j.details && !j.details.archived && j.details.id !== job.details.id)
            .forEach((j) => {
              const { details } = j
              if (details) details.archived = true
              // invalidateActivityLog(placement.jobDetailsId) - it will be invalidated if navigated
            })
        })
      }
    }),
  )

  const completePlacement = placement.placed ? onPlaced : onNotPlaced

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const savePlacement = useCallback(
    debounce(() => {
      api.jobs.updatePlacement(placement).then(() => setSaving(false))
    }, 1500),
    [api, placement],
  )

  useEffect(() => {
    const { unsubscribe } = watch((value, info) => {
      const p = placement
      setSaving(true)
      runInAction(() => {
        if (!info.name) return
        const key = info.name as keyof PlacementSchema
        if (p.completed && (p.placed !== false || key === 'placed')) p.completed = false
        if (dateFieldsSet.has(key as DateFields)) {
          const dt = value[key] as Dayjs | null
          p[key as DateFields] = dt && dt.isValid() && dt.isBefore(MAX_TIMESTAMP) ? dt.format() : undefined
          return
        }
        if (numFieldsSet.has(key as NumFields)) {
          const f = parseFloat(value[key as NumFields] || '')
          p[key as NumFields] = Number.isNaN(f) ? undefined : f
          return
        }
        if (Object.hasOwn(p, key)) {
          const v = value[key]
          if (typeof v === 'boolean') {
            p[key as BoolFields] = v
          } else if (typeof v === 'string' || v === undefined) {
            const k = key as Exclude<SameFields, BoolFields>
            p[k] = v
          }
        }
      })
      savePlacement()
    })

    return unsubscribe
  }, [placement, savePlacement, watch])

  const title = 'Placement'
  const answerCount = allQuestions.filter(
    (key) =>
      key !== 'notPlacedReason' &&
      key !== 'archiveAllOther' &&
      key !== 'placed' &&
      key !== 'additionalDetails' &&
      !!getValues(key) &&
      !getFieldState(key).invalid,
  ).length

  // eslint-disable-next-line no-nested-ternary
  const completedBadge = !placement.completed
    ? 'Not Completed'
    : placement.placed
    ? 'Candidate Placed'
    : 'Candidate Not Placed'

  const supervisorString =
    placement.completed && !placement.placed
      ? 'NA'
      : getValues(['supervisorFirstName', 'supervisorLastName']).filter(Boolean).join(' ') || notEnteredYet

  const startDateString = placement.completed && !placement.placed ? 'NA' : getStartDateString(getValues('startDate'))

  return (
    <MinimizableSurveyAccordion
      id={PLACE_CANDIDATE_ANCHOR}
      title={title}
      saving={saving}
      allQuestionCount={allQuestionCount}
      answeredQuestionCount={answerCount}
      expanded={expanded}
      setExpanded={setExpanded}
      minimizedHeaderBadge={
        <AccordionHeaderBadge variant={placement.completed ? 'info' : 'error'}>
          {completedBadge}
          {placement.completed ? <Check width={16} height={16} /> : <Close width={16} height={16} />}
        </AccordionHeaderBadge>
      }
      collapsedContent={
        <MinimizedView
          sx={{ p: '24px' }}
          isEditable
          onEdit={expand}
          items={[
            {
              title: 'Start Date',
              value: startDateString || notEnteredYet,
            },
            {
              title: 'Supervisor',
              value: supervisorString || notEnteredYet,
            },
          ]}
        />
      }
    >
      <>
        <Box
          sx={{
            p: '24px',
            borderBottom: `1px solid ${colors.neutral['100']}`,
            '& .MuiFormLabel-asterisk.MuiInputLabel-asterisk': {
              visibility: 'hidden',
              '&::before': {
                content: '"*"',
                visibility: 'visible',
              },
            },
          }}
        >
          <Box>
            <Typography variant="subtitle1" sx={{ color: colors.neutral['600'] }}>
              Confirm Placement
            </Typography>
            <Controller
              control={control}
              name="placed"
              render={({ field }) => (
                <RadioGroup
                  row
                  name="radio-buttons-placement"
                  value={field.value}
                  onChange={(e, value) => {
                    field.onChange(value === 'true')
                    if (value === 'false') clearErrors()
                  }}
                >
                  <FormControl sx={{ width: '50%' }}>
                    <FormControlLabel value control={<Radio />} label="Placed" />
                  </FormControl>
                  <FormControl>
                    <FormControlLabel value={false} control={<Radio />} label="Not Placed" />
                    <FormHelperText sx={{ pl: '37px', color: colors.neutral[400] }}>
                      This action will Archive this Job
                    </FormHelperText>
                  </FormControl>
                </RadioGroup>
              )}
            />
          </Box>
          <Box sx={{ borderBottom: `1px solid ${colors.neutral['100']}`, pb: '20px', mb: '20px' }}>
            <Observer>
              {() => {
                if (placement.placed)
                  return (
                    <FormControlLabel
                      control={<Checkbox checked={placement.archiveAllOther} {...register('archiveAllOther')} />}
                      label="Archive all other active placements, interviews & job submissions"
                    />
                  )
                if (placement.placed === false) {
                  return (
                    <>
                      <Typography variant="body1" sx={{ mt: '24px', mb: '16px' }}>
                        Explain why candidate was not placed
                      </Typography>
                      <TextField
                        placeholder="Enter details"
                        multiline
                        fullWidth
                        rows={5}
                        {...register('notPlacedReason')}
                      />
                    </>
                  )
                }
                return null
              }}
            </Observer>
          </Box>
          <Typography variant="body1" sx={{ mb: '16px' }}>
            Please complete the job placement form once the candidate has successfully been place with a company.
          </Typography>
          <Box sx={flexContainerStyles}>
            <TextField
              label="Employer"
              required
              fullWidth
              {...register('employer')}
              error={!!errors.employer}
              helperText={errors.employer?.message || ' '}
              inputProps={{ maxLength: 1000 }}
            />
            <Controller
              control={control}
              render={({ field }) => (
                <AmountInput
                  label="Salary"
                  required
                  fullWidth
                  {...field}
                  error={!!errors.salary}
                  helperText={errors.salary?.message || ' '}
                />
              )}
              name="salary"
            />
          </Box>
          <Box sx={flexContainerStyles}>
            <TextField
              label="Job Title"
              required
              sx={{ width: '50%' }}
              {...register('title')}
              error={!!errors.title}
              helperText={errors.title?.message || ' '}
              inputProps={{ maxLength: 1000 }}
            />
            <FormControl
              sx={{
                width: '50%',
                '&.MuiFormControl-root .MuiTextField-root': {
                  width: '100%',
                },
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Controller
                  control={control}
                  name="startDate"
                  render={({ field }) => (
                    <CustomDatePicker
                      label="Start Date"
                      required
                      maxDate={MAX_TIMESTAMP}
                      format={formats.date}
                      views={['year', 'month', 'day']}
                      inputRef={field.ref}
                      value={field.value}
                      onChange={field.onChange}
                      onAccept={field.onChange}
                      error={!!errors.startDate}
                      helperText={errors.startDate?.message || ' '}
                    />
                  )}
                />
              </LocalizationProvider>
            </FormControl>
          </Box>
          <Box sx={flexContainerStyles}>
            <TextField
              label="Career Area"
              required
              fullWidth
              {...register('careerArea')}
              error={!!errors.careerArea}
              helperText={errors.careerArea?.message || ' '}
              inputProps={{ maxLength: 1000 }}
            />
            <TextField
              label="Shift"
              required
              fullWidth
              {...register('shift')}
              error={!!errors.shift}
              helperText={errors.shift?.message || ' '}
              inputProps={{ maxLength: 100 }}
            />
          </Box>
          <Box>
            <PlacementUploads />
          </Box>

          <Typography variant="subtitle1" sx={{ mb: '16px', mt: '32px', color: colors.neutral['600'] }}>
            Supervisor Contact Details
          </Typography>
          <Box sx={flexContainerStyles}>
            <TextField
              label="First Name"
              required
              {...register('supervisorFirstName')}
              error={!!errors.supervisorFirstName}
              helperText={errors.supervisorFirstName?.message || ' '}
              fullWidth
              inputProps={{ maxLength: 45 }}
            />
            <TextField
              label="Last Name"
              required
              {...register('supervisorLastName')}
              error={!!errors.supervisorLastName}
              helperText={errors.supervisorLastName?.message || ' '}
              fullWidth
              inputProps={{ maxLength: 45 }}
            />
          </Box>
          <Box sx={flexContainerStyles}>
            <TextField
              label="Email Address"
              required
              {...register('supervisorEmail')}
              error={!!errors.supervisorEmail}
              helperText={errors.supervisorEmail?.message || ' '}
              fullWidth
              inputProps={{ maxLength: 255 }}
            />
            <Controller
              control={control}
              render={({ field }) => (
                <TextField
                  label="Office Phone"
                  required
                  {...field}
                  onInput={field.onChange}
                  error={!!errors.supervisorOfficePhone}
                  helperText={errors.supervisorOfficePhone?.message || ' '}
                  InputProps={{
                    inputComponent: MaskedPhoneInput,
                  }}
                  fullWidth
                />
              )}
              name="supervisorOfficePhone"
            />
          </Box>
          <Controller
            render={({ field }) => (
              <TextField
                label="Direct Phone"
                {...field}
                onInput={field.onChange}
                error={!!errors.supervisorDirectPhone}
                helperText={errors.supervisorDirectPhone?.message || ' '}
                InputProps={{
                  inputComponent: MaskedPhoneInput,
                }}
                sx={{ width: '50%', pr: '8px' }}
              />
            )}
            name="supervisorDirectPhone"
            control={control}
          />
          <Typography variant="subtitle1" sx={{ mb: '16px', color: colors.neutral['600'] }}>
            Location of Employment
          </Typography>
          <TextField
            label="Address line 1"
            required
            {...register('addressLine1')}
            error={!!errors.addressLine1}
            helperText={errors.addressLine1?.message || ' '}
            fullWidth
            inputProps={{ maxLength: 255 }}
          />
          <TextField
            label="Address line 2"
            {...register('addressLine2')}
            fullWidth
            helperText=" "
            inputProps={{ maxLength: 255 }}
          />
          <Box sx={flexContainerStyles}>
            <TextField
              label="City"
              required
              {...register('city')}
              error={!!errors.city}
              helperText={errors.city?.message || ' '}
              fullWidth
              inputProps={{ maxLength: 50 }}
            />
            <Controller
              control={control}
              name="state"
              render={({ field }) => (
                <CustomAutocomplete
                  fullWidth
                  autoComplete
                  autoSelect
                  disablePortal
                  value={field.value}
                  onChange={(e, v) => field.onChange(v)}
                  inputValue={state}
                  onInputChange={(e, v) => setState(v)}
                  onBlur={field.onBlur}
                  options={stateNames}
                  isOptionEqualToValue={(option, value) => option === value || states.get(value) === option}
                  sx={{ border: 'none' }}
                  ListboxProps={{
                    style: {
                      maxHeight: 190,
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      label="State"
                      required
                      ref={field.ref}
                      onBlur={field.onBlur}
                      {...params}
                      error={!!errors.state}
                      helperText={errors.state?.message || ' '}
                      InputProps={{ ...params.InputProps }}
                    />
                  )}
                />
              )}
            />
            <Controller
              control={control}
              name="zip"
              render={({ field }) => (
                <TextField
                  label="Zip Code"
                  required
                  {...field}
                  onInput={field.onChange}
                  error={!!errors.zip}
                  helperText={errors.zip?.message || ' '}
                  fullWidth
                  InputProps={{
                    inputComponent: MaskedZipCodeInput,
                  }}
                />
              )}
            />
          </Box>
          <Button
            fullWidth
            disabled={placement.placed === undefined || placement.placed === null || isSubmitting || submitting}
            onClick={completePlacement}
          >
            Complete Placement
          </Button>
        </Box>
        <InnerNote title="Additional Employment Details">
          <TextField
            placeholder="Elaborate on any additional placement information..."
            multiline
            fullWidth
            rows={5}
            {...register('additionalDetails')}
            inputProps={{ maxLength: 1000 }}
          />
        </InnerNote>
      </>
    </MinimizableSurveyAccordion>
  )
}

export default observer(PlaceCandidateAccordion)
