import { Box, BoxProps } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { autorun } from 'mobx'
import AlertsContext from '../../common/alerts/AlertsContext'

export default function RightRail({ sx, children, ...other }: BoxProps) {
  const [top, setTop] = useState(88)
  const [height, setHeight] = useState(200)
  const { alerts } = useContext(AlertsContext)
  useEffect(() => {
    let t = 0
    const disposer = autorun(() => {
      alerts.forEach(() => {}) // trigger if alert changed
      t = window.setTimeout(() => {
        const headers = document.getElementsByTagName('header') || []
        if (headers.length > 0) {
          const header = headers[0]
          setTop(header.getBoundingClientRect().height + 12)
        }
        setHeight(window.innerHeight)
      }, 750)
    })
    return () => {
      disposer()
      clearTimeout(t)
    }
  }, [alerts])
  return (
    <Box
      position="sticky"
      sx={{
        width: 400,
        transition: 'top 100ms,height 100ms',
        overflowY: 'auto',
        paddingBottom: '24px',
        ...sx,
      }}
      {...other}
      style={{
        top: `${top}px`,
        height: `${height - top}px`,
      }}
    >
      {children}
    </Box>
  )
}
