import { observer } from 'mobx-react'
import { Checkbox, Chip, FormControlLabel } from '@mui/material'
import { useCallback, useContext } from 'react'
import { runInAction } from 'mobx'
import FilterAccordion from '../FilterAccordion'
import { usePortalUsers } from '../../../portal-user/context/PortalUserContext'
import { PortalUserDto } from '../../../../_generated/portal-api'
import CandidatesContext from '../../context/CandidatesContext'

const AssigneeCheckbox = observer(({ assignee }: { assignee: PortalUserDto }) => {
  const { filter } = useContext(CandidatesContext)
  const checked = filter.assignees.selection.has(assignee.userObjectId)
  const assignedCount = filter.assignees.counts.get(assignee.userObjectId) || 0
  const disabled = !assignedCount && !checked
  const toggleAssignee = useCallback(() => {
    if (checked) runInAction(() => filter.assignees.selection.delete(assignee.userObjectId))
    else runInAction(() => filter.assignees.selection.add(assignee.userObjectId))
  }, [assignee.userObjectId, checked, filter.assignees])

  return (
    <FormControlLabel
      control={<Checkbox checked={checked} onChange={toggleAssignee} disabled={disabled} />}
      label={
        <>
          {assignee.fullName} {!!assignedCount && <Chip size="small" label={assignedCount} />}
        </>
      }
    />
  )
})

function StaffAssignee() {
  const { assignees } = usePortalUsers()
  const { filter } = useContext(CandidatesContext)

  return (
    <FilterAccordion id="staff-assignee" title="Staff Assignee" chip={filter.assignees.selection.size || undefined}>
      {assignees.length === 0 && 'No Assignees'}
      {assignees.map((assignee) => (
        <AssigneeCheckbox key={assignee.userObjectId} assignee={assignee} />
      ))}
    </FilterAccordion>
  )
}

export default observer(StaffAssignee)
