import React from 'react'
import { colors } from '../mui-theme'

type WarningAlertIconProps = {
  size?: number
  color1?: string
  color2?: string
  color3?: string
}

export default function AlertWarning({
  size = 46,
  color1 = colors.warning['100'],
  color2 = colors.warning['500'],
  color3 = colors.warning['50'],
}: WarningAlertIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="5" y="5" width="56" height="56" rx="28" fill={color1} />
      <path
        d="M33 28.3333V32.9999M33 37.6666H33.0116M44.6666 32.9999C44.6666 39.4432 39.4433 44.6666 33 44.6666C26.5567 44.6666 21.3333 39.4432 21.3333 32.9999C21.3333 26.5566 26.5567 21.3333 33 21.3333C39.4433 21.3333 44.6666 26.5566 44.6666 32.9999Z"
        stroke={color2}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x="5" y="5" width="56" height="56" rx="28" stroke={color3} strokeWidth="10" />
    </svg>
  )
}
