import { makeAutoObservable } from 'mobx'
import dayjs, { Dayjs } from 'dayjs'
import { CandidateDto } from '../../../_generated/portal-api'
import PortalUserStore from '../../portal-user/context/portal-user-store'
import { hasSnoozeTime } from '../archive'
import { Answers } from '../../../../demo/survey-model'
import { IDPAnswers } from '../../retention/retention-surveys'

export default class Candidate {
  constructor(
    public readonly dto: CandidateDto,
    private readonly store: { portalUsers: PortalUserStore; currentTime: Dayjs },
  ) {
    makeAutoObservable(this, undefined, { autoBind: true })
  }

  get applied() {
    return this.dto.appliedDate ? new Date(this.dto.appliedDate) : null
  }

  get appliedDayjs() {
    return this.applied ? dayjs(this.applied).startOf('day') : null
  }

  get lastActivity() {
    return this.dto.lastActivityDate ? new Date(this.dto.lastActivityDate) : null
  }

  get lastActivityDayjs() {
    return this.lastActivity ? dayjs(this.lastActivity).startOf('day') : null
  }

  get fullName() {
    return [this.dto.firstName, this.dto.lastName].filter((s) => !!s).join(' ')
  }

  get lastFirstNameUpperCased() {
    return [this.dto.lastName, this.dto.firstName]
      .filter((s) => !!s)
      .join(' ')
      .toUpperCase()
  }

  assign(dto: CandidateDto) {
    Object.assign(this.dto, dto)
  }

  get assignee() {
    return this.store.portalUsers.getPortalUser(this.dto.assigneeId)
  }

  get answers(): Answers & IDPAnswers {
    return this.dto.resultJsonString && this.dto.resultJsonString !== '{}' ? JSON.parse(this.dto.resultJsonString) : {}
  }

  get experience() {
    const { answers } = this
    if (answers.Attachments?.length) return 'Attachment'
    if (answers['Work Experience']?.length) return 'Manual Entry'
    return 'Not Provided'
  }

  get assigneeFullNameLowerCased() {
    return this.assignee?.fullName?.toLowerCase() || ''
  }

  get assigneeLabel() {
    return this.assignee ? this.assignee.fullName : 'Not Assigned'
  }

  get snoozeDate(): Dayjs | undefined {
    return this.dto.archiveSnoozeDate ? dayjs(this.dto.archiveSnoozeDate) : undefined
  }

  get archived() {
    return this.snoozeDate && this.snoozeDate.isAfter(this.store.currentTime)
  }

  get hasSnoozeTime() {
    return hasSnoozeTime(this.snoozeDate)
  }

  get hasTrainingInterest() {
    const { answers } = this
    return !!answers['Training Program Interest']?.length
  }
}
