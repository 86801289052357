import React from 'react'
import { InputBaseComponentProps } from '@mui/material'
import { IMaskInput } from 'react-imask'

const MASK_DEFINITION = { '#': /[1-9]/ }
export const PHONE_REGEX = /^\+1 \([1-9]\d{2}\) \d{3}-\d{4}$/

// eslint-disable-next-line react/display-name
const MaskedPhoneInput = React.forwardRef<HTMLElement, InputBaseComponentProps>((props, ref) => (
  <IMaskInput mask="+1 (#00) 000-0000" definitions={MASK_DEFINITION} {...props} inputRef={ref} />
))

export default MaskedPhoneInput
