import React, { useCallback, useContext } from 'react'
import { observer } from 'mobx-react'
import { Checkbox, Chip, FormControlLabel } from '@mui/material'
import { runInAction } from 'mobx'
import CandidatesContext from '../../context/CandidatesContext'
import FilterAccordion from '../FilterAccordion'
import { archivedReasons } from '../../archive'

const reasons = archivedReasons.map((reason) => (reason.includes('Other') ? 'Other' : reason))

const ArchivedReasonCheckbox = observer(({ reason }: { reason: string }) => {
  const { filter } = useContext(CandidatesContext)
  const checked = filter.archived.selection.has(reason)
  const count = filter.archived.counts.get(reason) || 0
  const disabled = !count && !checked
  const toggleReason = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) runInAction(() => filter.archived.selection.add(reason))
      else runInAction(() => filter.archived.selection.delete(reason))
    },
    [filter.archived.selection, reason],
  )

  return (
    <FormControlLabel
      key={reason}
      control={<Checkbox checked={checked} onChange={toggleReason} disabled={disabled} />}
      label={
        <>
          {reason} {!!count && <Chip size="small" label={count} />}
        </>
      }
    />
  )
})

function Archived() {
  const { filter } = useContext(CandidatesContext)

  const toggleShowAll = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) runInAction(() => reasons.map((r) => filter.archived.selection.add(r)))
      else runInAction(() => filter.archived.clear())
    },
    [filter.archived],
  )
  const allChecked = filter.archived.selection.size === reasons.length
  const someChecked = !allChecked && !!filter.archived.selection.size
  const selectedCount = [...filter.archived.counts.entries()]
    .filter(([key]) => filter.archived.selection.has(key))
    .reduce((total, [, count]) => total + count, 0)
  const totalCount = [...filter.archived.counts.values()].reduce((total, count) => total + count, 0)
  const disabled = !totalCount && !someChecked

  return (
    <FilterAccordion id="archived" title="Archived" chip={filter.archived.selection.size || undefined}>
      <FormControlLabel
        control={
          <Checkbox checked={allChecked} indeterminate={someChecked} onChange={toggleShowAll} disabled={disabled} />
        }
        label={<>Show All {!!totalCount && <Chip size="small" label={`${selectedCount}/${totalCount}`} />}</>}
      />
      {reasons.map((r: string) => (
        <ArchivedReasonCheckbox key={r} reason={r} />
      ))}
    </FilterAccordion>
  )
}

export default observer(Archived)
