import { Box, Chip, IconButton, styled, SxProps, TableCell, Theme, Tooltip } from '@mui/material'
import React, {
  ForwardedRef,
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { observer } from 'mobx-react'
import { PublicClientApplication } from '@azure/msal-browser'
import { runInAction } from 'mobx'
import JobPostingsGridContext from './JobPostingsGridContext'
import { colors, palette } from '../../../../common/mui-theme'
import BookmarkPlus from '../../../../common/icons/BookmarkPlus'
import { PortalApi } from '../../../_generated/portal-api'
import JobPosting from '../context/job-posting'
import { usePortalApi } from '../../../api/PortalApiContext'
import CandidateProfileContext from '../../candidate-profile/context/CandidateProfileContext'
import Star from '../../../../common/icons/Star'
import BookmarkFilledCheck from '../../../../common/icons/BookmarkFilledCheck'

type JobPostingProps = {
  jobPosting: JobPosting
  cellStyle: SxProps<Theme>
}

const OneLineText = styled('div')(() => ({
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
}))

const OneLineHelperText = styled(OneLineText)(() => ({
  fontSize: '12px',
  lineHeight: '16px',
  color: palette.text.secondary,
}))

const TwoRowsContent = styled('div')(() => ({
  display: '-webkit-box',
  maxWidth: '400px',
  WebkitLineClamp: '2',
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}))

const CareerAreaChip = styled(Chip)(() => ({
  margin: '1px 0',
  borderRadius: '6px',
  height: '18px',
  cursor: 'pointer',
  backgroundColor: colors.neutral['75'],
  '& > .MuiChip-label': {
    paddingLeft: '6px',
    paddingRight: '6px',
    fontSize: '12px',
  },
}))

type OverflowTooltipProps = {
  children: (ref: ForwardedRef<HTMLDivElement>, text: ReactNode) => ReactElement
  content: ReactNode
  selector?: string
}

function OverflowTooltip({ children, content, selector }: OverflowTooltipProps) {
  const ref = useRef<HTMLDivElement>(null)
  const [tooltipText, setTooltipText] = useState<ReactNode>(null)

  useEffect(() => {
    if (ref.current) {
      const el = selector ? ref.current.querySelector(selector) : ref.current

      if (el && (el.scrollHeight > el.clientHeight || el.scrollWidth > el.clientWidth)) {
        setTooltipText(content)
      }
    }
  }, [content, selector])

  return <Tooltip title={tooltipText}>{children(ref, content)}</Tooltip>
}

const tableCellStyle: SxProps<Theme> = { overflowWrap: 'anywhere', cursor: 'pointer' }

const highlightTableCellStyle: SxProps<Theme> = {
  ...tableCellStyle,
  backgroundColor: colors.primary['100'],
}

const JobTitle = observer(({ jobPosting, cellStyle }: JobPostingProps) => (
  <TableCell sx={cellStyle}>
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex' }}>
        <OverflowTooltip content={jobPosting.jobTitle}>
          {(ref, content) => <OneLineText ref={ref}>{content}</OneLineText>}
        </OverflowTooltip>

        {jobPosting.dto.isRecommended && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              color: palette.primary.main,
              paddingLeft: '4px',
            }}
          >
            <Star width={18} height={18} bgOpacity={1} />
          </Box>
        )}
      </Box>
      <OverflowTooltip content={jobPosting.company}>
        {(ref, content) => <OneLineHelperText ref={ref}>{content}</OneLineHelperText>}
      </OverflowTooltip>
    </Box>
  </TableCell>
))

const CareerArea = observer(({ jobPosting, cellStyle }: JobPostingProps) => (
  <TableCell sx={cellStyle}>
    {jobPosting.dto.careerAreas.map((el) => (
      <OverflowTooltip key={el} content={el} selector=".MuiChip-label">
        {(ref, content) => <CareerAreaChip ref={ref} label={content} />}
      </OverflowTooltip>
    ))}
  </TableCell>
))

const Shift = observer(({ jobPosting, cellStyle }: JobPostingProps) => (
  <TableCell sx={cellStyle}>
    <OverflowTooltip content={jobPosting.dto.shifts.join(', ')}>
      {(ref, content) => <TwoRowsContent ref={ref}>{content}</TwoRowsContent>}
    </OverflowTooltip>
  </TableCell>
))

const Type = observer(({ jobPosting, cellStyle }: JobPostingProps) => (
  <TableCell sx={cellStyle}>
    <OverflowTooltip content={jobPosting.dto.employmentTypes.join(', ')}>
      {(ref, content) => <TwoRowsContent ref={ref}>{content}</TwoRowsContent>}
    </OverflowTooltip>
  </TableCell>
))

const Favorite = observer(
  ({
    jobPosting,
    cellStyle,
    portalApi,
    userId,
  }: JobPostingProps & { userId: number; portalApi: PortalApi<PublicClientApplication> }) => {
    const {
      ctx: { increaseFavouriteCount, decreaseFavouriteCount },
    } = useContext(JobPostingsGridContext)

    const toggleFavourite = useCallback(
      (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation()

        portalApi.candidates[jobPosting.dto.isFavourite ? 'deleteFeatureJob' : 'addFeatureJob'](
          userId,
          jobPosting.dto.id,
        ).then(() => {
          runInAction(() => {
            // eslint-disable-next-line no-param-reassign
            jobPosting.dto.isFavourite = !jobPosting.dto.isFavourite

            if (jobPosting.dto.isFavourite) {
              increaseFavouriteCount()
            } else {
              decreaseFavouriteCount()
            }
          })
        })
      },
      [decreaseFavouriteCount, increaseFavouriteCount, jobPosting.dto, portalApi.candidates, userId],
    )

    return (
      <TableCell sx={{ ...cellStyle, textAlign: 'center' }}>
        <IconButton
          aria-label={jobPosting.dto.isFavourite ? 'remove from favourite' : 'add to favourite'}
          onClick={toggleFavourite}
          sx={{ color: jobPosting.dto.isFavourite ? palette.primary.main : colors.neutral['200'], padding: 0 }}
        >
          {jobPosting.dto.isFavourite ? <BookmarkFilledCheck /> : <BookmarkPlus />}
        </IconButton>
      </TableCell>
    )
  },
)

const Location = observer(({ jobPosting, cellStyle }: JobPostingProps) => (
  <TableCell sx={cellStyle}>
    <OneLineHelperText>{jobPosting.location}</OneLineHelperText>
  </TableCell>
))

function JobPostingsGridRow({ jobPosting }: { jobPosting: JobPosting }) {
  const { isAdmin } = useContext(JobPostingsGridContext)
  const { candidate } = useContext(CandidateProfileContext)
  const api = usePortalApi()
  const cellStyle =
    !isAdmin && (jobPosting.dto.isFavourite || jobPosting.dto.isRecommended) ? highlightTableCellStyle : tableCellStyle
  return (
    <>
      <JobTitle jobPosting={jobPosting} cellStyle={cellStyle} />
      <CareerArea jobPosting={jobPosting} cellStyle={cellStyle} />
      <Shift jobPosting={jobPosting} cellStyle={cellStyle} />
      <Type jobPosting={jobPosting} cellStyle={cellStyle} />
      {!isAdmin && (
        <Favorite jobPosting={jobPosting} cellStyle={cellStyle} portalApi={api} userId={candidate.dto.userId} />
      )}
      {isAdmin && <Location jobPosting={jobPosting} cellStyle={cellStyle} />}
    </>
  )
}

export default JobPostingsGridRow
