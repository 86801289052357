import React from 'react'
import { IMaskInput } from 'react-imask'
import { MaskedBaseInputProps } from './MaskedBaseInput'

// eslint-disable-next-line react/display-name
const MaskedAmountInput = React.forwardRef<HTMLElement, MaskedBaseInputProps>((props, ref) => (
  <IMaskInput
    {...props}
    inputRef={ref}
    mask="num"
    blocks={{
      num: {
        mask: Number,
        scale: 2,
        radix: '.',
        min: 0.01,
        max: 9999999999,
      },
    }}
  />
))

export default MaskedAmountInput
