import { LogLevel, PublicClientApplication } from '@azure/msal-browser'
import appInsight, { SeverityLevel } from '../../common/app-insights'

export const APP_INSIGHT_CUSTOM_PROPS = { Component: 'MSAL-AD' }

function toSeverity(logLevel: LogLevel): SeverityLevel {
  switch (logLevel) {
    case LogLevel.Error:
      return SeverityLevel.Error
    case LogLevel.Warning:
      return SeverityLevel.Warning
    case LogLevel.Info:
      return SeverityLevel.Information
    default:
      return SeverityLevel.Verbose
  }
}

const msal = new PublicClientApplication({
  auth: {
    clientId: import.meta.env.VITE_AD_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${import.meta.env.VITE_AD_TENANT_ID}/`,
    redirectUri: import.meta.env.VITE_AD_REDIRECT_URI,
    navigateToLoginRequestUrl: true,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message) => {
        appInsight.trackTrace(
          {
            message,
            severityLevel: toSeverity(level),
          },
          APP_INSIGHT_CUSTOM_PROPS,
        )
      },
    },
  },
})

// initialize redirect handler
msal.handleRedirectPromise().catch((error) =>
  appInsight.trackTrace({
    severityLevel: SeverityLevel.Error,
    message: error,
  }),
)

/**
 * idTokenClaims : {
 * at_hash : "zqTH0NfpBp6xD3ADGAZbZQ"
 * aud : "e77fe26f-abef-4ecf-ba1c-18bbca92a913"
 * auth_time : 1677837268
 * emails : ["gyermakavets+2@productiveedge.com"]
 * exp : 1677840869
 * family_name : "Yermakavets II"
 * given_name : "Henadz"
 * iat : 1677837269
 * iss : "https://ceocwebdev.b2clogin.com/27807252-bcc9-49f0-b060-b0e7c3625ba8/v2.0/"
 * nbf : 1677837269
 * nonce : "03f836a2-29f3-4682-ad00-72ab06e36487"
 * oid : "eca44773-45d3-400b-a0f0-c8041b6d48dd"
 * sub : "eca44773-45d3-400b-a0f0-c8041b6d48dd"
 * tfp : "B2C_1_susi_v2"
 * ver : "1.0"
 */
/*
export const CustomClaims = {
  emails: 'emails',
  family_name: 'family_name',
  given_name: 'given_name',
  oid: 'oid',
} as const
*/

export default msal
