import React from 'react'
import { SurveyQuestionRadioItem } from 'survey-react-ui'
import { Box, FormControlLabel, Radio } from '@mui/material'
import { Question, Serializer } from 'survey-core'
import { ISurveyCreator } from 'react/reactquestion'

class MuiRadioItem extends SurveyQuestionRadioItem {
  get creator(): ISurveyCreator {
    return this.question.creator as ISurveyCreator
  }

  protected renderElement(): JSX.Element {
    const id = this.question.getItemId(this.item)
    const subQuestionName = this.item.getPropertyValue('subQuestion') as string | undefined
    const subQuestion = (subQuestionName && this.question.survey.getQuestionByName(subQuestionName)) || null
    const subQuestionComponent = subQuestion && this.isChecked && this.creator && (
      <div style={{ paddingTop: '8px', paddingLeft: '36px' }}>
        {this.creator.createQuestionElement(subQuestion as Question)}
      </div>
    )
    const itemClass = this.question.getItemClass(this.item)
    const label = !this.props.hideCaption ? this.renderLocString(this.item.locText) : ''
    return (
      <div className={itemClass} role="presentation">
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <FormControlLabel
            control={
              <Radio
                id={id}
                name={this.question.name}
                value={this.item.value}
                onChange={this.handleOnChange}
                checked={this.isChecked}
                disabled={!this.question.getItemEnabled(this.item)}
                inputProps={{ 'aria-describedby': this.question.ariaDescribedBy }}
                aria-label={this.question.getAriaItemLabel(this.item)}
                sx={{ marginTop: '-10px' }}
              />
            }
            label={label}
            sx={{ alignItems: 'flex-start' }}
          />
          {subQuestionComponent}
        </Box>
      </div>
    )
  }
}

Serializer.addProperty('itemvalue', { name: 'subQuestion', type: 'string' })

export default MuiRadioItem
