import React, { PropsWithChildren, useCallback } from 'react'
import { Alert as MuiAlert, AlertTitle, Button, createTheme, useTheme } from '@mui/material'
import ThemeProvider from '@mui/material/styles/ThemeProvider'
import { Alert } from './AlertsContext'
import css from './Alerts.module.css'
import AlertAction from '../icons/AlertAction'
import { colors } from '../mui-theme'

function ActionableAlertTheme({ children }: PropsWithChildren) {
  const theme = useTheme()

  const modified = createTheme({
    ...theme,
    components: {
      ...theme.components,
      MuiAlert: {
        ...theme.components?.MuiAlert,
        styleOverrides: {
          ...theme.components?.MuiAlert?.styleOverrides,
          standardWarning: {
            ...(theme.components?.MuiAlert?.styleOverrides?.standardWarning as object),
            color: colors.primary['600'],
            borderColor: colors.primary['200'],
            backgroundColor: colors.primary['100'],
            '.MuiAlertTitle-root': {
              color: colors.primary['600'],
            },
          },
        },
      },
    },
  })

  return <ThemeProvider theme={modified}>{children}</ThemeProvider>
}

function ActionableAlert({
  alert,
  onClose,
  onClick,
}: {
  alert: Alert
  onClose: (alert: Alert) => void
  onClick: (alert: Alert) => void
}) {
  const onIgnore = useCallback(() => onClose(alert), [alert, onClose])
  const onMerge = useCallback(() => onClick(alert), [alert, onClick])

  return (
    <ActionableAlertTheme>
      <MuiAlert
        className={css.actionableAlert}
        severity={alert.severity}
        icon={<AlertAction />}
        action={
          <>
            <Button size="small" color="secondary" onClick={onIgnore}>
              Ignore
            </Button>
            <Button size="small" onClick={onMerge}>
              Merge Candidates
            </Button>
          </>
        }
        sx={{
          '.MuiAlert-icon': {
            paddingLeft: '3px',
            paddingRight: '3px',
          },
        }}
      >
        <div className={css.actionableAlert}>
          <AlertTitle className={css.alertTitle}>{alert.title}</AlertTitle>
          {alert.message ? <span className={css.alertDescription}>{alert.message}</span> : null}
        </div>
      </MuiAlert>
    </ActionableAlertTheme>
  )
}

export default ActionableAlert
