import dayjs from 'dayjs'

function LapsedDays({ date }: { date: Date }) {
  const d = dayjs(date)
  const now = dayjs()
  const diff = Math.abs(d.diff(now, 'day'))
  const s = diff === 1 ? '' : 's'
  return (
    <>
      {d.isBefore(now, 'date') && `${diff} day${s} ago`}
      {d.isAfter(now, 'date') && `${diff} day${s} away`}
      {d.isSame(now, 'date') && 'Today'}
    </>
  )
}

export default LapsedDays
