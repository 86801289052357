import { SurveyValidator, ValidatorResult } from 'survey-core'
import FieldError from '../field-error'
import UsaAddressGroupModel from '../../us-address/UsaAddressGroupModel'

const zipRegex = /^\d{5}(?:-\d{4})?$/

export default class ZipCodeValidator extends SurveyValidator {
  constructor(public readonly errorMessage: string) {
    super()
  }

  override validate(value: UsaAddressGroupModel): ValidatorResult {
    const error = value?.zipCode?.match(zipRegex) ? undefined : new FieldError('zipCode', this.errorMessage)
    return new ValidatorResult(value?.zipCode, error)
  }
}
