import { DetailedHTMLProps, HTMLAttributes, useEffect, useRef, useState } from 'react'
import { debounce } from '@mui/material'

type HeightMode = 'min' | 'max' | 'both'

export default function FullHeight(
  props: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    bottomMargin: number
    mode?: HeightMode
  },
) {
  const { style, children, bottomMargin, mode = 'both', ...other } = props
  const [height, setHeight] = useState(0)
  const modified = style ? { ...style } : {}
  modified.minHeight = mode === 'max' ? undefined : `${height}px`
  modified.maxHeight = mode === 'min' ? undefined : `${height}px`

  const ref = useRef<HTMLDivElement>(null)
  useEffect(() => {
    const onResize = debounce(() => {
      if (ref.current) {
        const rect = ref.current.getBoundingClientRect()
        setHeight(window.innerHeight - rect.top - bottomMargin)
      }
    }, 160)
    onResize()
    window.addEventListener('resize', onResize)
    const interval = setInterval(onResize, 200)
    return () => {
      window.removeEventListener('resize', onResize)
      onResize.clear()
      clearInterval(interval)
    }
  }, [bottomMargin])

  return (
    <div ref={ref} style={modified} {...other}>
      {children}
    </div>
  )
}
