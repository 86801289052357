import { Box, Link } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'

export default function NotFoundPage() {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <h5>Sorry, the page you are looking for does not exist.</h5>
      <Link component={RouterLink} to="/" underline="hover">
        Return Home
      </Link>
    </Box>
  )
}
