import React, { useCallback, useContext, useState } from 'react'
import { Button, Grid, Paper, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useAddInterview, useAddPlacement, useAddSubmission } from '../../../api/hooks/placementHooks'
import { JobDto } from '../../../_generated/portal-api'
import CandidateProfileContext from '../../candidate-profile/context/CandidateProfileContext'

const toCenter = { display: 'flex', justifyContent: 'center' }

export default function GetStarted() {
  const [submitting, setSubmitting] = useState(false)
  const { candidate } = useContext(CandidateProfileContext)
  const navigate = useNavigate()
  const createSubmission = useAddSubmission()
  const createInterview = useAddInterview()
  const createPlacement = useAddPlacement()

  const scrollTo = useCallback(
    (j: JobDto, sectionID: string) => {
      const anchor = sectionID ? `#${sectionID}` : ''
      navigate(`/candidates/${candidate.dto.userId}/placement/${j.details?.id}${anchor}`, {
        replace: !!anchor,
      })
    },
    [candidate.dto.userId, navigate],
  )

  const onSubmitCandidate = useCallback(() => {
    setSubmitting(true)
    createSubmission()
      .then((j) => scrollTo(j, ''))
      .finally(() => setSubmitting(false))
  }, [createSubmission, scrollTo])

  const onAddNewInterview = useCallback(() => {
    setSubmitting(true)
    createInterview()
      .then((j) => scrollTo(j, ''))
      .finally(() => setSubmitting(false))
  }, [createInterview, scrollTo])

  const onPlaceCandidate = useCallback(() => {
    setSubmitting(true)
    createPlacement()
      .then((j) => scrollTo(j, ''))
      .finally(() => setSubmitting(false))
  }, [createPlacement, scrollTo])

  return (
    <>
      <Typography variant="h5" sx={{ mt: '11px', mb: '33px' }}>
        Job Placement
      </Typography>
      <Paper sx={{ p: '24px', position: 'relative', borderRadius: '16px', mb: '15px' }}>
        <Grid container spacing={2} sx={{ mb: '20px' }}>
          <Grid item xs={12} sx={{ ...toCenter, pt: '15px', mb: '20px' }}>
            <Typography variant="h6">Get Started</Typography>
          </Grid>
          <Grid item xs={4} sx={toCenter}>
            <Button color="secondary" onClick={onSubmitCandidate} disabled={submitting}>
              Submit Candidate
            </Button>
          </Grid>
          <Grid item xs={4} sx={toCenter}>
            <Button color="secondary" onClick={onAddNewInterview} disabled={submitting}>
              Add New Interview
            </Button>
          </Grid>
          <Grid item xs={4} sx={toCenter}>
            <Button color="secondary" onClick={onPlaceCandidate} disabled={submitting}>
              Place Candidate
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </>
  )
}
