import { useEffect } from 'react'

const timeouts = new Map<HTMLElement, number>()
const SCROLLING_CLASS = 'scrolling'
export default function useScrollHighlighter() {
  useEffect(() => {
    const scrollListener = (e: Event) => {
      if (e.target instanceof HTMLElement) {
        if (!e.target.classList.contains(SCROLLING_CLASS)) {
          e.target.classList.add(SCROLLING_CLASS)
        }
        timeouts.set(e.target, new Date().getTime())
      }
    }
    window.addEventListener('scroll', scrollListener, { capture: true })
    const interval = setInterval(() => {
      const expired = new Date().getTime() - 500
      Array.from(timeouts.entries())
        .filter(([, t]) => t < expired)
        .forEach(([e]) => {
          timeouts.delete(e)
          e.classList.remove(SCROLLING_CLASS)
        })
    }, 500)
    return () => {
      window.removeEventListener('scroll', scrollListener, { capture: true })
      clearInterval(interval)
    }
  })
}
