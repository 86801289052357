import { styled } from '@mui/material'
import { colors } from '../../common/mui-theme'

const DottedDivider = styled('div')(() => ({
  margin: '1px 0 24px',
  height: '1px',
  background: 'transparent',
  backgroundPosition: 'top',
  backgroundImage: `linear-gradient(to right, ${colors.base.white} 80%, ${colors.neutral['200']} 0%)`,
  backgroundSize: '5px 1px',
  backgroundRepeat: 'repeat-x',
}))

export default DottedDivider
