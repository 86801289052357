import { TableRow } from '@mui/material'
import { TableRowProps } from '@mui/material/TableRow/TableRow'
import { useNavigate } from 'react-router-dom'
import { useCallback, useContext } from 'react'
import { ItemProps } from 'react-virtuoso'
import JobPosting from '../context/job-posting'
import CandidateProfileContext from '../../candidate-profile/context/CandidateProfileContext'
import JobPostingsGridContext from './JobPostingsGridContext'

function JobPostingsTableRow({ item, ...props }: TableRowProps & ItemProps<JobPosting>) {
  const navigate = useNavigate()
  const {
    isAdmin,
    ctx: { saveGridPosition },
    scroller,
  } = useContext(JobPostingsGridContext)
  const { candidate } = useContext(CandidateProfileContext)
  const navigateTo = useCallback(() => {
    if (scroller instanceof HTMLElement) saveGridPosition(scroller.scrollTop, props['data-item-index'])
    else saveGridPosition(undefined, props['data-item-index'])

    navigate(isAdmin ? `/jobs/${item.dto.id}` : `/candidates/${candidate.dto.userId}/job-matching/${item.dto.id}`, {
      state: { fromJobPostingGrid: true },
    })
  }, [candidate.dto.userId, isAdmin, item.dto.id, navigate, props, saveGridPosition, scroller])

  return <TableRow {...props} onClick={navigateTo} />
}

export default JobPostingsTableRow
