import { useCallback, useContext } from 'react'
import { observer } from 'mobx-react'
import { Button, Chip } from '@mui/material'
import CandidatesContext from '../context/CandidatesContext'
import FilterIcon from '../../../../common/icons/FilterIcon'
import { ArrowBackward } from '../../../../common/icons/Arrows'

function FilterToggle() {
  const { filter } = useContext(CandidatesContext)
  const toggleFilter = useCallback(() => filter.toggle(), [filter])
  return (
    <Button
      size="large"
      color="secondary"
      startIcon={filter.opened ? <ArrowBackward /> : <FilterIcon />}
      onClick={toggleFilter}
    >
      {filter.opened ? 'Hide ' : ''}Filter
      {!filter.opened && !filter.empty && (
        <Chip size="small" label={filter.filterFunctions.length} sx={{ marginLeft: '8px' }} />
      )}
    </Button>
  )
}

export default observer(FilterToggle)
