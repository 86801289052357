import { Box, Link, SxProps, TableCell, Theme, Tooltip } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import IconButton from '@mui/material/IconButton'
import { useCallback, useContext } from 'react'
import { observer } from 'mobx-react'
import ContentCopy from '../../../../common/icons/ContentCopy'
import Update from '../../../../common/icons/Update'
import Archive from '../../../../common/icons/Archive'
import Candidate from '../context/candidate'
import CandidatesContext from '../context/CandidatesContext'
import highlight from '../../../utils/highlight'
import css from './CandidatesGrid.module.css'
import CandidatesGridContext from './CandidatesGridContext'
import { useFeatureFlag } from '../../../feature-flags/FeatureFlagsContext'
import Certificate from '../../../../common/icons/Certificate'
import { palette } from '../../../../common/mui-theme'

const DateFormatOptions = {
  month: 'short',
  day: 'numeric',
  year: 'numeric',
} as const

type CandidateProps = {
  candidate: Candidate
  cellStyle: SxProps<Theme>
}

const tableCellStyle: SxProps<Theme> = { overflowWrap: 'anywhere' }

const highlightTableCellStyle: SxProps<Theme> = {
  overflowWrap: 'anywhere',
  animation: 'bg 5s ease-in',
  '@keyframes bg': { from: { background: '#FEEDE6' }, '100%': { background: 'white' } },
}

const FullName = observer(({ candidate, cellStyle }: CandidateProps) => {
  const { search } = useContext(CandidatesContext)
  const to = `/candidates/${candidate.dto.userId}`
  const fullNameParts = highlight(candidate.fullName, search.text)
  const highlightAsBold = useCallback(
    ({ highlight: highlighted, text }: { highlight: boolean; text: string }, index: number) =>
      highlighted ? <b key={text + index}>{text}</b> : text,
    [],
  )
  const { email } = candidate.dto
  const namedEmail = candidate.fullName ? `${candidate.fullName}<${email}>` : email
  const copyEmail = useCallback(() => navigator.clipboard.writeText(namedEmail), [namedEmail])
  const emailParts = highlight(email, search.text)
  return (
    <TableCell sx={cellStyle}>
      <div className={css.fullName}>
        {candidate.hasTrainingInterest && (
          <Certificate
            style={{ color: palette.primary.main, marginRight: '16px', minHeight: '24px', minWidth: '24px' }}
          />
        )}
        <div>
          <div>
            <Link component={RouterLink} to={to} underline="hover">
              {candidate.fullName ? fullNameParts.map(highlightAsBold) : emailParts.map(highlightAsBold)}
            </Link>
          </div>
          {candidate.fullName && <div className={css.email}>{emailParts.map(highlightAsBold)}</div>}
        </div>
        {candidate.archived && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              color: 'rgba(14, 20, 37, 0.8)',
              paddingLeft: '24px',
            }}
          >
            <Archive />
            {candidate.hasSnoozeTime && (
              <Tooltip title={`Snoozed until ${candidate.snoozeDate?.format('MM/DD/YYYY')}`} placement="bottom">
                <Update />
              </Tooltip>
            )}
          </Box>
        )}
        <IconButton onClick={copyEmail} className={css.copyIcon}>
          <ContentCopy />
        </IconButton>
      </div>
    </TableCell>
  )
})

/*
const Email = observer(({ candidate }: CandidateProps) => {
  const { search } = useContext(CandidatesContext)
  const { email } = candidate.dto
  const namedEmail = `${candidate.fullName}<${email}>`
  const copyEmail = useCallback(() => navigator.clipboard.writeText(namedEmail), [namedEmail])
  const parts = highlight(email, search.text)

  return (
    <TableCell className={css.emailCell}>
      <div>
        <Link color="secondary" href={`mailto:${candidate.fullName}<${email}>`} underline="hover">
          {parts.map((m) => (m.highlight ? <b key={m.text}>{m.text}</b> : m.text))}
        </Link>
        <IconButton onClick={copyEmail} className={css.copyIcon}>
          <ContentCopy />
        </IconButton>
      </div>
    </TableCell>
  )
})
*/

const Experience = observer(({ candidate, cellStyle }: CandidateProps) => (
  <TableCell sx={cellStyle}>{candidate.experience}</TableCell>
))
const AppliedDate = observer(({ candidate, cellStyle }: CandidateProps) => (
  <TableCell sx={cellStyle}>{candidate.applied?.toLocaleDateString('en-US', DateFormatOptions)}</TableCell>
))
const LastActivityDate = observer(({ candidate, cellStyle }: CandidateProps) => (
  <TableCell sx={cellStyle}>{candidate.lastActivity?.toLocaleDateString('en-US', DateFormatOptions)}</TableCell>
))

const StaffAssignee = observer(({ candidate, cellStyle }: CandidateProps) => (
  <TableCell sx={cellStyle}>{candidate.assigneeLabel}</TableCell>
))

function CandidatesGridRow({ index }: { index: number }) {
  const excludeAssignee = useFeatureFlag('EXCLUDE_ASSIGNEE_COLUMN')
  const { candidates, sort, highlightedCandidateId } = useContext(CandidatesGridContext)
  if (!candidates) return null
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { desc } = sort // important to trigger - do not remove
  if (index >= candidates.length && desc !== null) return null
  const candidate = candidates[index]
  const cellStyle = candidate.dto.userId === highlightedCandidateId ? highlightTableCellStyle : tableCellStyle
  return (
    <>
      <FullName candidate={candidate} cellStyle={cellStyle} />
      {/* <Email candidate={candidate} cellStyle={cellStyle} /> */}
      {!excludeAssignee && <StaffAssignee candidate={candidate} cellStyle={cellStyle} />}
      <Experience candidate={candidate} cellStyle={cellStyle} />
      <AppliedDate candidate={candidate} cellStyle={cellStyle} />
      <LastActivityDate candidate={candidate} cellStyle={cellStyle} />
    </>
  )
}

export default observer(CandidatesGridRow)
