import { Chip, TableCell, TableRow } from '@mui/material'
import { PropsWithChildren, useContext } from 'react'
import { Observer, observer } from 'mobx-react'
import JobPostingsGridContext from './JobPostingsGridContext'
import { Column } from '../../candidates/grid/CandidatesGridContext'
import JobPosting from '../context/job-posting'
import tableHeaderSx from '../../tableHeaderSx'

const Header = observer(
  ({
    children,
    getColumn,
    className,
  }: PropsWithChildren<{ getColumn: () => Column<JobPosting>; className?: string }>) => {
    const column = getColumn()
    return (
      <TableCell className={className} width={column.width || undefined}>
        {children}
      </TableCell>
    )
  },
)

export default function JobPostingsGridHeader() {
  const { columns, isAdmin, ctx } = useContext(JobPostingsGridContext)
  // const ctx = useContext(JobPostingsContext)
  return (
    <TableRow sx={tableHeaderSx}>
      <Header getColumn={() => columns.jobTitle}>
        <b>Job Title</b>
        {isAdmin && (
          <Observer>{() => <Chip label={ctx.store.chipCount} size="small" sx={{ margin: '0 8px' }} />}</Observer>
        )}
      </Header>
      <Header getColumn={() => columns.careerArea}>
        <b>Career Area</b>
      </Header>
      <Header getColumn={() => columns.shift}>
        <b>Shift</b>
      </Header>
      <Header getColumn={() => columns.type}>
        <b>Type</b>
      </Header>
      {!isAdmin && <Header getColumn={() => columns.favorite} />}
      {isAdmin && (
        <Header getColumn={() => columns.location}>
          <b>Location</b>
        </Header>
      )}
    </TableRow>
  )
}
